import { HAULIER_NAME } from '@/config/config';
import { dpmpIcons } from '@/themes/dpmp/icons';
import colors from 'vuetify/lib/util/colors.mjs';
import { HAULIERS } from '@/constants/Constants';
import { dpmpColors } from '@/themes/dpmp/colors';
import { nitraColors } from '@/themes/nitra/colors';
import { trencinColors } from '@/themes/trencin/colors';
import { ruzomberokColors } from './ruzomberok/colors';
import { dpbColors } from './dpb/colors';
import { polandColors } from './poland/colors';
import { aliases } from 'vuetify/iconsets/mdi';
import { viseudesusColors } from '@/themes/viseudesus/colors.ts';
import { dpmoColors } from './dpmo/colors';
import { dpmlbColors } from './dpmlb/colors';

export interface AppColors {
  systemPrimary: string;
  systemSecondary: string;
  buttonPrimary: string;
  primary: string;
  buttonWarning: string;
  buttonConfirm: string;
  buttonDefault: string;
  rdpbackground: string;
  leftpanelhover: string;
}

// default colors from RDP-1528 and RDP-1529
const defaultAppColors: Partial<AppColors> = {
  systemPrimary: '#E40613', // see RDP-1528
  systemSecondary: '#073855', // see RDP-1528
  buttonPrimary: '#073855', // see RDP-1528
  primary: '#0C7396', // see RDP-1529
  buttonWarning: colors.red.darken1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#d4d4d4',
  leftpanelhover: '#c46064',
};

export const defaultAppIcons = {
  cardManagingAccountIcon: 'mdi-card-account-details-outline',
  ticketFareIcon: 'mdi-ticket-account',
  electronicWalletIcon: 'mdi-cash-multiple',
  shoppingCartIcon: 'mdi-cart-outline',
  shoppingCartTopIcon: 'mdi-cart-outline',
  userAccountIcon: 'mdi-account',
  userAccountTopIcon: 'mdi-account',
  orderListIcon: 'mdi-cash-multiple',
  chipCardIcon: 'mdi-card-account-details-outline',
  assignedCardListIcon: 'mdi-human-male-child',
  managedCardListIcon: 'mdi-account-group',
  cardApplicationListIcon: 'mdi-form-select',
  pakTransferIcon: 'mdi-wallet-plus-outline',
  newCardApplicationIcon: 'mdi-card-account-details-outline',
  assignCardIcon: 'mdi-credit-card-plus-outline',
  payByCardIcon: 'mdi-credit-card-outline',
  payByInvoiceIcon: 'mdi-bank-transfer',
  cancelAllIcon: 'mdi-cancel',
  manageCardRequestIcon: 'mdi-card-plus-outline',
  logoutIcon: 'mdi-logout',
  loginIcon: 'mdi-login',
  registrationSendIcon: 'mdi-account-plus',
  forgottenPasswordIcon: 'mdi-email-outline',
  verificationCodeIcon: 'mdi-email-outline',
  notificationSettingIcon: 'mdi-bell',
  assignCustodianIcon: 'mdi-account-child',
  reclamationIcon: 'mdi-repeat',
};

export const getThemeColors = () => {
  switch (HAULIER_NAME) {
    case HAULIERS.DPMP:
      return dpmpColors;
    case HAULIERS.NITRA:
      return nitraColors;
    case HAULIERS.TRENCIN:
      return trencinColors;
    case HAULIERS.RUZOMBEROK:
      return ruzomberokColors;
    case HAULIERS.DPB:
      return dpbColors;
    case HAULIERS.POLAND:
      return polandColors;
    case HAULIERS.VISEUDESUS:
      return viseudesusColors;
    case HAULIERS.DPMO:
      return dpmoColors;
    case HAULIERS.DPMLB:
      return dpmlbColors;
    default:
      return defaultAppColors;
  }
};

export const getIcons = () => {
  switch (HAULIER_NAME) {
    case HAULIERS.DPMP:
      return dpmpIcons;
    default:
      return defaultAppIcons;
  }
};

export const getIconsAliases = () => {
  switch (HAULIER_NAME) {
    case HAULIERS.DPMP:
      return Object.keys(dpmpIcons).reduce(
        (acc, key) => {
          acc[key] = `custom:${key}`;
          return acc;
        },
        {} as { [key: string]: string },
      );
    default:
      return {
        ...aliases,
        ...defaultAppIcons,
      };
  }
};
