<template>
  <v-dialog
    v-model="show"
    max-width="600"
    scrollable
    persistent
    :fullscreen="$vuetify.display.mobile"
    data-cy="update-password-dialog"
    @keydown.esc="cancelDialog"
  >
    <v-card>
      <v-card-title class="text-h5 pl-3 d-flex bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white">mdi-lock-question</v-icon>
        <div class="align-self-center">{{ $t('updatePassword.title') }}</div>
      </v-card-title>
      <v-card-text class="card-text">
        <v-form ref="form" validate-on="lazy" data-cy="updatePassword-dialog" @submit.prevent>
          <v-row>
            <v-col class="pt-0 mt-3">
              <v-text-field
                v-model="changePassword.password"
                prepend-icon="mdi-lock"
                autocomplete="new-password"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :label="t('password')"
                :rules="validations.password"
                :data-cy="cy('password')"
                variant="underlined"
                @click:append="showPass = !showPass"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="py-1 pt-0">
              <v-text-field
                v-model="changePassword.passwordAgain"
                prepend-icon="mdi-lock"
                autocomplete="new-password"
                :type="showPassAgain ? 'text' : 'password'"
                :append-icon="showPassAgain ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="validations.passwordAgain"
                :label="t('passwordAgain')"
                :data-cy="cy('passwordAgain')"
                variant="underlined"
                @click:append="showPassAgain = !showPassAgain"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn color="buttonWarning" :data-cy="`${dataCy}-cancel-button`" variant="elevated" @click="cancelDialog">
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>
          <ConfirmButton
            dark
            :confirm="updatePassword"
            :text="$t('buttons.ok')"
            icon="mdi-check"
            :data-cy="`${dataCy}-confirm-button`"
            :class="$vuetify.display.mobile ? '' : 'mx-2'"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import { FormInterface } from '@/components/common/FormInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import ToastModule from '@/store/modules/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { confirmPasswordValidator, strongPasswordValidator } from '@/utils/validators';
import authServices from '@/services/api/authService';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast';
import { getApiError } from '@/utils/toast';
import RDPComponentBase from '@/components/common/ComponentBase';

@Component({
  components: { ConfirmButton },
})
export default class ChangePasswordDialog extends RDPComponentBase {
  @Ref()
  readonly form!: FormInterface;
  showPass = false;
  showPassAgain = false;
  changePassword = {
    password: '',
    passwordAgain: '',
  };

  show = false;

  get validations() {
    return {
      password: [(v: string) => strongPasswordValidator(v)],
      passwordAgain: [(v: string) => confirmPasswordValidator(v, this.changePassword.password)],
    };
  }

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex ml-auto gap-1 ml-0' : '';
  }

  i18nGroupKey = 'updatePassword';
  dataCy = 'updatePassword';

  async updatePassword() {
    if ((await this.form.validate()).valid) {
      const toastPosition = {
        x: 'center',
        y: 'top',
        timeout: PERMANENT_TOAST_INTERVAL,
      };
      try {
        const { password } = this.changePassword;
        await authServices.updatePassword({
          password,
        });
        ToastModule.success({
          message: this.$t('updatePassword.success'),
          options: toastPosition,
        });
        this.closeDialog();
      } catch (e) {
        ToastModule.error({ message: getApiError(e as ApiErrorInterface, 'updatePassword') });
      }
    }
  }

  async openDialog() {
    this.show = true;
  }

  cancelDialog() {
    this.closeDialog();
  }

  closeDialog() {
    this.resetFormValues();
    this.show = false;
  }

  resetFormValues() {
    this.changePassword = {
      password: '',
      passwordAgain: '',
    };
    this.form.resetValidation();
  }
}
</script>

<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
