import { PAYMENT_CSOB_GATEWAY_BASE_URL, PAYMENT_GP_GATEWAY_BASE_URL } from '@/config/config';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { gpInitPaymentDataNames } from '@/constants/gpInitPaymentDataNames';
import { isArrayEmpty } from '@common/utils/isDefined';
import {
  CreateEshopOrderCommand,
  CsobPaymentVm,
  EshopOrderDetailDto,
  EshopOrderDto,
  EshopOrderListResponse,
  EshopOrderQuery,
  EshopOrderService,
  GpPaymentVm,
  PaymentVm,
  VerifyIfCardApplicationCanBeCreatedQuery,
} from '@/api';
import paymentStatus = CsobPaymentVm.paymentStatus;
import { getRequestBody, getQueryParams } from '@/utils/api';
import ToastModule from '@/store/modules/toast';
import i18n from '@/i18n';

const toGpInputData = (name: string, value: string | number): HTMLInputElement => {
  const input = document.createElement('input');

  input.type = 'hidden';
  input.name = name;
  input.value = value as string;

  return input;
};

const processCsobPayment = (payment: CsobPaymentVm): null | string => {
  let windowUrl: string | null = null;
  if (payment.redirect) {
    if (payment.paymentStatus === paymentStatus.PENDING) {
      const { merchantId, payId, dttm, signature } = payment.redirect;
      windowUrl = `${PAYMENT_CSOB_GATEWAY_BASE_URL}/payment/process/${merchantId}/${payId}/${dttm}/${encodeURIComponent(
        signature,
      )}`;
    } else if (payment.paymentStatus === paymentStatus.REJECTED) {
      ToastModule.error({
        message: i18n.global.t('shoppingCart.paymentRejected'),
      });
      windowUrl = null;
    }
  }
  return windowUrl;
};

const processGpPayment = (payment: GpPaymentVm): HTMLFormElement => {
  const form = document.createElement('form');
  form.action = PAYMENT_GP_GATEWAY_BASE_URL;
  form.method = 'POST';

  const merchantNumberInput = toGpInputData(gpInitPaymentDataNames.merchantNumber, payment.merchantNumber);
  form.appendChild(merchantNumberInput);

  const operationInput = toGpInputData(gpInitPaymentDataNames.operation, payment.operation);
  form.appendChild(operationInput);

  const merordernumInput = toGpInputData(gpInitPaymentDataNames.merordernum, payment.merordernum);
  form.appendChild(merordernumInput);

  const orderNumberInput = toGpInputData(gpInitPaymentDataNames.orderNumber, payment.orderNumber);
  form.appendChild(orderNumberInput);

  const amountInput = toGpInputData(gpInitPaymentDataNames.amount, payment.amount);
  form.appendChild(amountInput);

  const currencyInput = toGpInputData(gpInitPaymentDataNames.currency, payment.currency);
  form.appendChild(currencyInput);

  const depositInput = toGpInputData(gpInitPaymentDataNames.depositFlag, payment.depositFlag);
  form.appendChild(depositInput);

  const urlInput = toGpInputData(gpInitPaymentDataNames.url, payment.url);
  form.appendChild(urlInput);

  if (typeof payment.addInfo === 'string') {
    const addInfoInput = toGpInputData(gpInitPaymentDataNames.addInfo, payment.addInfo);
    form.appendChild(addInfoInput);
  }

  const digestInput = toGpInputData(gpInitPaymentDataNames.digest, payment.digest);
  form.appendChild(digestInput);

  document.body.appendChild(form);

  return form;
};

export default {
  getOrderList: async (query: EshopOrderQuery): Promise<EshopOrderListResponse> => {
    return EshopOrderService.eshopOrderControllerGetAll(getQueryParams(query));
  },

  getOrder: async (id: string): Promise<EshopOrderDetailDto> => {
    const response = await EshopOrderService.eshopOrderControllerGetOrderDetail({ id });
    return response.data;
  },

  verifyIfCardApplicationCanBeCreated: async (query: VerifyIfCardApplicationCanBeCreatedQuery): Promise<boolean> => {
    const response = await EshopOrderService.eshopOrderControllerVerifyIfCardApplicationCanBeCreated(query);

    return isArrayEmpty(response.errors);
  },

  createOrder: async (payload: CreateEshopOrderCommand): Promise<EshopOrderDetailDto> => {
    const response = await EshopOrderService.eshopOrderControllerCreateOrder({
      requestBody: getRequestBody(payload),
    });
    return response.data;
  },

  initPayment: async (id: string): Promise<PaymentVm> => {
    const response = await EshopOrderService.eshopOrderControllerInitPayment({
      id,
    });
    return response.data;
  },

  payAgain: async (id: string): Promise<PaymentVm> => {
    const response = await EshopOrderService.eshopOrderControllerPayAgain({
      id,
    });
    return response.data;
  },

  cancelOrder: async (id: string): Promise<EshopOrderDto> => {
    const response = await EshopOrderService.eshopOrderControllerCancel({ id });
    return response.data;
  },

  sendOrderPaidEmail: async (id: string): Promise<EshopOrderDto> => {
    const response = await EshopOrderService.eshopOrderControllerSendOrderPaidEmail({ id });

    return response.data;
  },

  processedPayment: (payment: PaymentVm): void => {
    if (payment.csobPayment && SystemConfigurationModule.isCsobPaymentGateway) {
      const windowUrl = processCsobPayment(payment.csobPayment);

      if (windowUrl) {
        window.location.href = windowUrl;
      }
    } else if (payment.gpPayment && SystemConfigurationModule.isGpPaymentGateway) {
      const form = processGpPayment(payment.gpPayment);
      form.submit();
    }
  },
};
