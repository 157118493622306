import { CARD_NUMBER_MASK } from '@/config/config';

const MAP_CARD_PREFIX = '9203';

export const isVirtualCardNumber = (cardNumber: string): boolean => {
  return !!cardNumber && !cardNumber.startsWith(MAP_CARD_PREFIX);
};

export const toMapCardNumber = (value: string): string => {
  if (isVirtualCardNumber(value)) {
    return value;
  }

  let i = 0;
  return value ? CARD_NUMBER_MASK.replace(/#/g, () => value[i++] || '').trim() : '-';
};

export const toApiMapCardNumber = (value: string): string => {
  if (isVirtualCardNumber(value)) {
    return value;
  }
  return value.replace(/\s/g, '');
};
