import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const dpmlbColors: Partial<AppColors> = {
  systemPrimary: '#113A98',
  systemSecondary: '#113A98',
  buttonPrimary: '#113A98',
  primary: '#113A98',
  buttonWarning: '#FF0000',
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#FFFFFF',
  leftpanelhover: '#113A98',
};
