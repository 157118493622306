/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiMetadata } from './ApiMetadata';
export type HealthCheckResponse = {
  status: HealthCheckResponse.status;
  /**
   * Response Message
   */
  message: string;
  /**
   * HTTP Status Code
   */
  httpCode: number;
  metadata?: ApiMetadata;
};
export namespace HealthCheckResponse {
  export enum status {
    HEALTHY = 'HEALTHY',
    UNHEALTHY = 'UNHEALTHY',
  }
}
