<template>
  <div class="d-flex flex-wrap align-center ml-sm-2" :class="{ footer: !$vuetify.display.mobile }">
    <a
      v-for="(link, index) of links"
      :key="`link-${index + 1}`"
      :href="link[1]"
      class="text-no-wrap pr-3"
      :class="{ 'text-white': dark }"
      :data-cy="cy(link[0])"
      target="_blank"
      >{{ t(link[0]) }}</a
    >

    <!-- If there is only one supported locale, we dont need to show anything -->
    <!-- If there are two supported locales, show icon of locale that is not selected -->
    <!-- If there are more than two supported locales, show select -->
    <template v-if="locales.length === 2">
      <v-btn variant="flat" size="small" @click="selectLocale(notSelectedLocale)">
        <img style="width: 25px" :src="getLocaleImage(notSelectedLocale)" />
      </v-btn>
    </template>
    <template v-else-if="locales.length > 2">
      <!-- TODO: support more than two locales. We dont need it for now...  -->
    </template>
  </div>
</template>
<script lang="ts">
import isDefined from '@common/utils/isDefined';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import {
  TERMS_AND_CONDITIONS_URL,
  GDPR_URL,
  FAQ_URL,
  DECLARATION_OF_ACCESSIBILITY,
  CONTACTS_URL,
} from '@/config/config';
import LocaleModule from '@/store/modules/locale';

@Component
export default class FooterLinks extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  readonly dark!: boolean;

  localeModule = LocaleModule;

  get links() {
    const footerLinks = [
      ['termsAndConditions', TERMS_AND_CONDITIONS_URL.REGISTER],
      ['gdpr', GDPR_URL.REGISTER],
    ];

    if (isDefined(FAQ_URL)) {
      footerLinks.push(['faq', FAQ_URL!]);
    }

    if (isDefined(DECLARATION_OF_ACCESSIBILITY)) {
      footerLinks.push(['declarationOfAccessibility', DECLARATION_OF_ACCESSIBILITY!]);
    }
    if (isDefined(CONTACTS_URL)) {
      footerLinks.push(['contacts', CONTACTS_URL!]);
    }
    return footerLinks;
  }

  get locales() {
    return this.localeModule.supportedLocales;
  }

  get selectedLocale() {
    return this.localeModule.locale;
  }

  get notSelectedLocale() {
    return this.locales.find(l => l !== this.selectedLocale);
  }

  getLocaleImage(locale) {
    return new URL(`../../assets/locales/${locale}.svg`, import.meta.url).href;
  }

  selectLocale(locale) {
    this.localeModule.changeLocale(locale);
  }

  i18nGroupKey = 'footerLinks';
  dataCy = 'footer-links';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any) {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }
}
</script>
<style lang="scss" scoped>
.footer {
  height: 40px;
}
</style>
