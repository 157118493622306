<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="!$vuetify.display.mobile && rail"
    :rail-width="72"
    width="380"
    :permanent="!$vuetify.display.mobile && permanent"
    :expand-on-hover="!$vuetify.display.mobile"
    :scrim="$vuetify.display.mobile"
    data-cy="navigation-drawer"
  >
    <v-row v-if="$vuetify.display.mobile" justify="end" class="mt-4 mr-4">
      <v-icon size="x-large" @click="toggleMenu">mdi-close</v-icon>
    </v-row>
    <v-list id="left-menu" class="px-2" data-cy="left-menu">
      <v-list-item
        v-for="(menuItem, i) of menuItems"
        :key="i"
        :active="i === activeItem"
        class="left-menu-item"
        :data-cy="`left-menu-${menuItem.dataCy}`"
        height="68"
        @click="navigate(menuItem)"
      >
        <template #prepend>
          <v-icon size="x-large">{{ menuItem.icon }}</v-icon>
        </template>
        <v-list-item-title>
          {{ $t(menuItem.titleI18nKey) }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="$vuetify.display.mobile">
        <LogoutButton tooltip-right show-text />
      </v-list-item>
    </v-list>
    <template #append>
      <v-list>
        <div class="px-2 left-menu-logout-section">
          <v-divider />
          <v-list-item>
            <template #prepend>
              <v-icon>mdi-account-circle</v-icon>
            </template>
            <v-list-item-title>
              {{ authModule.loggedInAccountDisplayName }}
            </v-list-item-title>
            <template v-if="!$vuetify.display.mobile" #append>
              <LogoutButton tooltip-right />
            </template>
          </v-list-item>
        </div>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import Paths from '@/constants/Paths';
import AuthModule from '@/store/modules/auth';
import ShoppingCardModule from '@/store/modules/shoppingCart';
import PageTitleI18nKeys from '@/constants/PageTitleI18nKeys';
import LogoutButton from '@/components/layout/LogoutButton.vue';
import { MODULE } from '@/constants/Module';
import { showModule } from '@/utils/module';
import SystemConfiguration from '@/store/modules/systemConfiguration.ts';

interface MenuItem {
  titleI18nKey: string;
  icon: string;
  path: string;
  dataCy: string;
}

@Component({
  components: {
    LogoutButton,
  },
  expose: ['toggleMenu', 'toggleRail'],
})
export default class LeftMenu extends Vue {
  authModule = AuthModule;
  shoppingCardModule = ShoppingCardModule;
  systemConfiguration = SystemConfiguration;

  drawer = true;
  menuItems: MenuItem[] = [];
  activeItem: number | null = null;
  rail = true;
  permanent = false;

  initMenuItem() {
    this.menuItems = [
      {
        titleI18nKey: PageTitleI18nKeys.USER_ACCOUNT,
        icon: '$userAccountIcon',
        path: Paths.USER_ACCOUNT,
        dataCy: 'user-account',
      },
    ];
    if (showModule(MODULE.ASSIGNED_CARD_LIST)) {
      this.menuItems.push({
        titleI18nKey: PageTitleI18nKeys.ASSIGNED_CARD_LIST,
        icon: '$assignedCardListIcon',
        path: Paths.ASSIGNED_CARD_LIST,
        dataCy: 'assigned-card-list',
      });
    }
    if (showModule(MODULE.MANAGED_CARD_LIST)) {
      this.menuItems.push({
        titleI18nKey: PageTitleI18nKeys.MANAGED_CARD_LIST,
        icon: '$managedCardListIcon',
        path: Paths.MANAGED_CARD_LIST,
        dataCy: 'managed-card-list',
      });
    }
    if (showModule(MODULE.CARD_MANAGING_ACCOUNT_LIST)) {
      this.menuItems.push({
        titleI18nKey: PageTitleI18nKeys.CARD_MANAGING_ACCOUNT_LIST,
        icon: '$cardManagingAccountIcon',
        path: Paths.CARD_MANAGING_ACCOUNT_LIST,
        dataCy: 'card-manages-list',
      });
    }
    if (showModule(MODULE.NOTIFICATION_SETTING)) {
      this.menuItems.push({
        titleI18nKey: PageTitleI18nKeys.NOTIFICATION_SETTING,
        icon: '$notificationSettingIcon',
        path: Paths.NOTIFICATION_SETTING,
        dataCy: 'notification-setting',
      });
    }

    this.menuItems.push({
      titleI18nKey: PageTitleI18nKeys.ORDER_LIST,
      icon: '$orderListIcon',
      path: Paths.ORDER_LIST,
      dataCy: 'order-list',
    });

    this.menuItems.push({
      titleI18nKey: PageTitleI18nKeys.SHOPPING_CART,
      icon: '$shoppingCartIcon',
      path: Paths.SHOPPING_CART,
      dataCy: 'shopping-cart',
    });

    this.menuItems.push({
      titleI18nKey: PageTitleI18nKeys.ACCOUNT_SETTING,
      icon: 'mdi-account-cog',
      path: Paths.ACCOUNT_SETTING,
      dataCy: 'account-setting',
    });
  }

  created() {
    this.drawer = !this.$vuetify.display.mobile;
    this.initMenuItem();
    this.$router.afterEach(async () => {
      if (!this.$router.currentRoute.value.path.includes(Paths.LOGIN)) {
        await this.shoppingCardModule.updateShoppingCart();
      }
      this.activeItem = this.menuItems.findIndex(menu => {
        return this.$router.currentRoute.value.path.includes(menu.path);
      });
    });
    if (this.systemConfiguration.isPersistSideMenuAllowed) {
      this.permanent = true;
    }
  }

  toggleMenu() {
    if (this.systemConfiguration.isPersistSideMenuAllowed && !this.$vuetify.display.mobile) {
      this.rail = !this.rail;
    } else {
      this.drawer = !this.drawer;
    }
  }

  navigate(menuItem: MenuItem) {
    this.$router.push(menuItem.path);
  }
}
</script>
<style lang="scss" scoped>
.v-btn--icon.v-size--default.logout-btn {
  width: 24px;
  height: 24px;
}

.menu-block {
  margin-bottom: 20px;
}

#left-menu .left-menu-item:hover {
  color: rgb(var(--v-theme-leftpanelhover)) !important;

  .v-icon {
    color: rgb(var(--v-theme-leftpanelhover));
  }
}

#left-menu .left-menu-item {
  color: #757575 !important;

  .v-icon {
    color: #757575;
  }

  padding: 0 8px;
}

#left-menu .left-menu-item.v-list-item--active {
  color: rgb(var(--v-theme-leftpanelhover)) !important;

  .v-icon {
    color: rgb(var(--v-theme-leftpanelhover));
  }
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant {
  .v-list-item {
    justify-content: left !important;
  }
}

.v-list-item__action,
.v-list-item__avatar,
.v-list-item__icon {
  min-width: 40px !important;
}

.left-menu-logout-section {
  div.v-list-item {
    min-height: 52px;
  }
}
</style>
