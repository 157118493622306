<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row class="text-center flex-column" align="center" justify="center">
        <v-col cols="12">
          <RDPHomePageLogo />
        </v-col>
        <h3 v-if="loading">{{ t('loading') }}</h3>
        <h3 v-else class="text-red">{{ errorMessage }}</h3>
        <div class="mt-4">
          <h3>
            <router-link :to="paths.USER_ACCOUNT">{{ t('backToApp') }}</router-link>
          </h3>
        </div>
      </v-row>
    </v-container>
    <v-footer app theme="dark">
      <FooterLinks dark />
    </v-footer>
  </v-main>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import Paths from '@/constants/Paths';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import ComponentBase from '@/components/common/ComponentBase';
import RDPHomePageLogo from '@/components/common/RDPHomePageLogo.vue';
import ToastModule from '@/store/modules/toast';
import { getNumberedApiError } from '@/utils/toast';
import { nextTick } from 'vue';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({
  components: {
    ConfirmButton,
    FooterLinks,
    RDPHomePageLogo,
  },
})
export default class ApproveManagedCardPage extends ComponentBase {
  paths = Paths;
  loading = true;
  errorMessage = '';

  dataCy = 'approve-managed-card-page';
  i18nGroupKey = 'approveManagedCardPage';

  async created() {
    await nextTick(async () => {
      await this.approve();
    });
  }

  async approve() {
    try {
      await customerAccountLinkService.approveManagedCardRequest({
        value: this.$router.currentRoute.value.query.token as string,
      });
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.successfullyApproved`),
      });
      await this.$router.push(Paths.MANAGED_CARD_LIST);
    } catch (e) {
      const translatedError = getNumberedApiError(e as ApiErrorInterface, this.i18nGroupKey, 'approvalFailed');
      this.errorMessage = translatedError;
      ToastModule.error({
        message: translatedError,
      });
    } finally {
      this.loading = false;
    }
  }
}
</script>
