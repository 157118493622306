<template>
  <!--
    from file(s): sprava_karty.svg, sprava_karty_red.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="9mm"
    height="14mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 900 1400"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M10 1214.74l0 -1029.48c0,-94.9 77.49,-172.4 172.39,-172.4l535.22 0c94.9,0 172.39,77.5 172.39,172.4l0 1029.48c0,94.9 -77.49,172.4 -172.39,172.4l-535.22 0c-94.9,0 -172.39,-77.5 -172.39,-172.4z"
      />
      <path
        class="fil1"
        d="M830 1033.55l0 -848.29c0,-61.78 -50.61,-112.4 -112.39,-112.4l-535.22 0c-61.78,0 -112.39,50.62 -112.39,112.4l0 848.29 760 0zm-556.14 -850.52l17.85 -1.98 -23.44 24.89c-3.11,3.43 -4.93,4.75 -7.72,9.84 -5.39,9.83 -18.35,33.86 -17.84,47.69 2.83,-3.11 6.3,-7.1 8.62,-10.48 -6.57,21.78 -19.42,51.72 -3.39,71.64 12.7,15.8 -2.87,10.01 7.64,33.42 5.15,11.48 7.75,22.38 21.02,24.73 2.79,17.6 5.66,38.61 15.23,48.35 8.08,8.21 10.79,5.7 10.81,21.15 0.01,10.3 -0.87,20.19 -2.54,30.15 -13.46,8.18 -11.81,2.13 -18.65,18.46 -42.81,2.21 -67.42,4.15 -89.15,29.86 -8.78,10.38 -12.02,25.68 -16.45,42.26 -0.73,2.74 -1.53,5.58 -2.36,8.5l0 38.45 363.24 0 0 -60.39c-3.91,-11.9 -7.84,-21.9 -11.03,-26.49 -17.93,-25.76 -43.56,-28.87 -85.57,-31.82 -10.24,-13.74 1.78,-11.17 -25.08,-18.83 -1.83,-9.33 -1.62,-18.91 -1.54,-28.56 0.39,-42.58 11.48,7.54 23.95,-71.02 14.47,-2.29 14.97,-9.64 20.93,-21.58 9.46,-18.94 0.06,-13.3 10.19,-34.2 13.19,-27.21 5.96,-40.82 -4.18,-66.22 4.6,2.61 9.17,6.06 13.49,7.85 -7.16,-25.45 -18.44,-38.33 -30.28,-55.51l10.56 3.01c-3.64,-13.46 -21.35,-26.87 -33.86,-33.16 -20.21,-10.15 -32.24,-8.5 -56.3,-7.13 -9.15,0.52 -21.13,-15.55 -49.72,-13.13 -14.68,1.24 -38.95,10.72 -44.43,20.25zm-100.37 506.96l553.01 0 0 47.06 -553.01 0 0 -47.06zm0 88.25l398.54 0 0 47.06 -398.54 0 0 -47.06zm0 88.25l333.82 0 0 47.06 -333.82 0 0 -47.06z"
      />
      <rect class="fil1" x="173.49" y="1169.44" width="553.01" height="47.06" />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class CardManagingAccountIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}

.fil1 {
  fill: #fefefe;
}
</style>
