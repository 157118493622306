import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { CustomerAccountLinkHeaderVm, CustomerDetailVm, CustomerDto, CustomerHeaderVm, EnumsDto } from '@/api';
import isDefined from '@common/utils/isDefined';

const _customer = {
  firstName: '',
  lastName: '',
  id: '',
  external: false,
};

export const _custodianData = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  birthDay: '',
  email: '',
  phoneNumber: '',
  address: {
    street: '',
    streetNumber: '',
    city: '',
    zip: '',
  },
  draft: false,
  originId: '',
} as CustomerDto;

@Module({ dynamic: true, store, namespaced: true, name: 'customer' })
class Customer extends VuexModule {
  public customer = { ..._customer };
  /**
   * Used to show detail of assigned/managed account from AssignedCardListPage.vue and ManagedCardListPage.vue
   */
  private linkedAccountCustomer = {
    customer: { ..._customer },
    linkType: EnumsDto.customerAccountLinkTypes.ASSIGNED,
  };

  @Mutation
  public setCustomer(customer: CustomerHeaderVm | CustomerDetailVm | CustomerDto) {
    this.customer = {
      firstName: customer.firstName,
      lastName: customer.lastName || '',
      id: customer.id,
      external: customer.external,
    };
  }

  @Mutation
  public setAssignedAccountCustomer(link: CustomerAccountLinkHeaderVm): void {
    this.linkedAccountCustomer.customer.id = link.customer.id;
    this.linkedAccountCustomer.customer.firstName = link.customer.firstName;
    this.linkedAccountCustomer.customer.lastName = link.customer.lastName || '';
    this.linkedAccountCustomer.customer.external = link.customer.external;
    this.linkedAccountCustomer.linkType = EnumsDto.customerAccountLinkTypes.ASSIGNED;
  }

  @Mutation
  public setManagedAccountCustomer(link: CustomerAccountLinkHeaderVm): void {
    this.linkedAccountCustomer.customer.id = link.customer.id;
    this.linkedAccountCustomer.customer.firstName = link.customer.firstName;
    this.linkedAccountCustomer.customer.lastName = link.customer.lastName || '';
    this.linkedAccountCustomer.customer.external = link.customer.external;
    this.linkedAccountCustomer.linkType = EnumsDto.customerAccountLinkTypes.MANAGED;
  }

  @Mutation
  public setLinkedAccountCustomer(link: CustomerAccountLinkHeaderVm): void {
    this.linkedAccountCustomer.customer.id = link.customer.id;
    this.linkedAccountCustomer.customer.firstName = link.customer.firstName;
    this.linkedAccountCustomer.customer.lastName = link.customer.lastName || '';
    this.linkedAccountCustomer.customer.external = link.customer.external;
    this.linkedAccountCustomer.linkType = link.linkType as EnumsDto.customerAccountLinkTypes;
  }

  @Mutation
  public clearLinkedCustomer(): void {
    // if the linked account customer is clear, the main account will always be selected
    this.linkedAccountCustomer = {
      customer: { ..._customer },
      linkType: EnumsDto.customerAccountLinkTypes.ASSIGNED,
    };
  }

  @Mutation
  public clearCustomer(): void {
    this.customer = { ..._customer };
  }

  public get customerConnected() {
    return !!this.customer.id;
  }

  public get linkedCustomer() {
    return this.linkedAccountCustomer.customer;
  }

  public get currentlySelectedCustomerId(): string {
    return this.isLinkedCustomerSelected ? this.linkedCustomer.id : this.customer.id;
  }

  public get currentlySelectedCustomer() {
    return this.isLinkedCustomerSelected ? this.linkedCustomer : this.customer;
  }

  public get registeredCustomer() {
    return this.customer;
  }

  public get isLinkedCustomerSelected() {
    return isDefined(this.linkedCustomer.id);
  }

  public get isManagedCustomerSelected() {
    return (
      this.isLinkedCustomerSelected && this.linkedAccountCustomer.linkType === EnumsDto.customerAccountLinkTypes.MANAGED
    );
  }

  public get isAssignedCustomerSelected() {
    return (
      this.isLinkedCustomerSelected &&
      this.linkedAccountCustomer.linkType === EnumsDto.customerAccountLinkTypes.ASSIGNED
    );
  }

  public get hasLoggedInAccountCustomer() {
    return !!this.customer.id;
  }
}

export default getModule(Customer);
