<template>
  <div>
    <v-dialog
      v-model="show"
      :fullscreen="$vuetify.display.mobile"
      :max-width="maxWidth"
      persistent
      @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex dialog-title">
          <v-icon size="large" start> mdi-help-circle</v-icon>
          <div class="align-self-center">{{ title }}</div>
        </v-card-title>

        <v-card-text class="text-body-1">
          <v-container>
            {{ text }}
          </v-container>
          <v-container>
            <slot />
          </v-container>
        </v-card-text>

        <v-card-actions class="pr-6 pb-6">
          <v-spacer />
          <v-btn
            color="buttonWarning"
            :data-cy="`${dataCy}-cancel-button`"
            variant="elevated"
            class="mr-2"
            @click="cancelDialog"
          >
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.no') }}
          </v-btn>
          <ConfirmButton
            :confirm="confirmDialog"
            :text="$t('buttons.yes')"
            :dark="!confirmDisabled"
            button-color="buttonPrimary"
            :data-cy="`${dataCy}-confirm-button`"
            :disabled="confirmDisabled"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import ToastModule from '@/store/modules/toast';

@Component({
  components: {
    ConfirmButton,
  },
})
export default class RDPConfirmDialog extends Vue implements RDPDialogInterface {
  show = false;

  /* Props */
  @Prop({ required: false, type: String, default: '' })
  readonly dataCy!: string;

  @Prop({
    required: false,
    type: Function,
    default: () => {
      console.error('Not implemented');
    },
  })
  readonly confirm!: () => void;

  @Prop({ type: String })
  readonly text!: string;

  @Prop({
    required: false,
    type: Function,
  })
  readonly cancel!: () => void;

  @Prop({ required: false, type: Boolean, default: false })
  readonly confirmDisabled!: boolean;

  @Prop({ required: true, type: String })
  readonly title!: string;

  @Prop({ required: false, type: String, default: null })
  readonly successText!: string;

  @Prop({ required: false, type: String, default: '600px' })
  readonly maxWidth!: string;

  /* Methods */
  openDialog() {
    this.show = true;
  }

  cancelDialog() {
    this.cancel && this.cancel();
    this.closeDialog();
  }

  closeDialog() {
    this.show = false;
  }

  showSuccess() {
    if (this.successText) {
      ToastModule.success({ message: this.successText });
    }
  }

  async confirmDialog() {
    try {
      await this.confirm();
      this.closeDialog();
      this.showSuccess();
    } catch (e) {
      console.error(e);
    }
  }
}
</script>
