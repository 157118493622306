<template>
  <v-card width="100%" class="fill-height pa-6">
    <RDPDataTable
      v-if="dataLoaded"
      ref="dataTable"
      :headers="activeTicketsheaders"
      :filter="filter"
      :search-items-callback="searchActiveTickets"
      :i18n-group-key="i18nGroupKey"
      :disable-pagination="true"
      :data-cy="cy('active-tickets-table')"
      :class="`account-active-tickets-list-table ${canDisplayTable ? 'hidden-table' : ''}`"
    >
      <template #head-panel.start>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 6">
            <v-alert
              v-if="!canDeleteAccount"
              border="top"
              type="warning"
              elevation="2"
              :data-cy="cy('alert')"
              variant="text"
            >
              {{ t('deleteCustomerAccountAlert') }}
            </v-alert>
            <v-alert
              v-if="hasActiveTickets && !isDisableDeleteAccountAllowed"
              border="top"
              type="warning"
              elevation="2"
              :data-cy="cy('alert')"
              variant="text"
            >
              {{ t('deleteCustomerAccountWarning') }}
            </v-alert>
          </v-col>
          <v-col
            :cols="$vuetify.display.mobile ? 12 : 6"
            :class="$vuetify.display.mobile ? '' : 'delete-account-btn-section'"
          >
            <v-btn
              :data-cy="cy('changePasswordButton')"
              class="mb-3 rdp-button account-setting-action-button mr-3"
              theme="dark"
              color="buttonPrimary"
              @click.stop="openChangePasswordDialog()"
            >
              <span class="rdp-button-text">
                {{ t('changePassword') }}
              </span>
            </v-btn>
            <v-btn
              :data-cy="cy('deleteAccountButton')"
              class="mb-3 rdp-button account-setting-action-button"
              :disabled="!canDeleteAccount"
              theme="dark"
              color="buttonWarning"
              @click.stop="openDeleteAcountDialog()"
            >
              <span class="rdp-button-text">
                {{ t('deleteCustomerAccount') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #item.customerProfile.displayName="{ item }">
        {{ getCustomerProfileDisplayName(item.customerProfile) }}
      </template>
      <template #item.tariffProfile.displayName="{ item }">
        {{ getTariffProfileDisplayName(item.tariffProfile) }}
      </template>
    </RDPDataTable>
    <RDPConfirmDialog
      ref="deleteAccountConfirmDialog"
      :title="t('deleteCustomerAccountDialog.title')"
      :text="t('deleteCustomerAccountDialog.text')"
      :confirm="deleteAccount"
      :data-cy="cy('delete-customer-account-dialog')"
    >
      <RDPDataTable
        v-if="managedAccounts?.data.length"
        ref="managedCustomerTable"
        :headers="managedHeaders"
        :filter="managedFilter"
        :search-items-callback="searchManagedCustomers"
        :i18n-group-key="i18nGroupKey"
        :disable-pagination="true"
        :data-cy="cy('managed-customers-table')"
        class="managed-customers-table"
      >
      </RDPDataTable>
    </RDPConfirmDialog>

    <ChangePasswordDialog ref="changePassowrdDialog" />
  </v-card>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import {
  AccountActiveTicketListResponse,
  CustomerAccountLinkHeaderVm,
  CustomerAccountLinkListResponse,
  CustomerAccountLinkQuery,
  CustomerProfileInfoVm,
  EshopOrderQuery,
  TariffProfileInfoVm,
} from '@/api';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import accountService from '@/services/api/accountService';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import ToastModule from '@/store/modules/toast';
import * as filters from '@/filters';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import { formatNameReverse } from '@/utils/customer';
import AuthModule from '@/store/modules/auth';
import UserAccountModule from '@/store/modules/userAccount';
import ComponentBase from '@/components/common/ComponentBase';
import ChangePasswordDialog from '@/components/accountSetting/dialogs/ChangePasswordDialog.vue';
import { getCustomerProfileDisplayName, getTariffProfileDisplayName } from '@/utils/i18n';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';

const DEFAULT_SORT_BY_COLUMN = 'number';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDataTable,
    RDPConfirmDialog,
    ChangePasswordDialog,
  },
})
export default class AccountSettingPage extends ComponentBase {
  @Ref()
  readonly deleteAccountConfirmDialog!: RDPDialogInterface;
  @Ref()
  readonly dataTable!: RDPDataTableInterface;
  @Ref()
  readonly managedCustomerTable!: RDPDataTableInterface;
  @Ref()
  readonly changePassowrdDialog!: RDPDialogInterface;

  filter = {
    paymentType: undefined,
    state: undefined,
    date: '',
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
  } as EshopOrderQuery;
  filters = filters;

  managedFilter: CustomerAccountLinkQuery = {
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
    customerAccountLinkType: CustomerAccountLinkQuery.customerAccountLinkType.MANAGED,
  };

  i18nGroupKey = 'accountSetting';
  dataCy = 'account-setting';

  dataLoaded = false;
  activeTickets = {} as AccountActiveTicketListResponse;
  managedAccounts = {} as CustomerAccountLinkListResponse;
  systemConfiguration = SystemConfigurationModule;

  get activeTicketsheaders() {
    return [
      { text: 'customerName', sortable: false },
      {
        text: 'cardNumber',
        sortable: false,
      },
      { text: 'validFrom', type: DATA_TABLE_HEADER_TYPES.DATE, sortable: false },
      { text: 'validTo', type: DATA_TABLE_HEADER_TYPES.DATE, sortable: false },
      {
        text: 'customerProfile.displayName',
        sortable: false,
      },
      { text: 'tariffProfile.displayName', sortable: false },
      { text: 'price', type: DATA_TABLE_HEADER_TYPES.PRICE, align: 'end', sortable: false },
    ];
  }

  managedHeaders = [
    {
      text: 'name',
      transform: (link: CustomerAccountLinkHeaderVm) => formatNameReverse(link.customer),
      sortable: false,
    },
    { text: 'customer.birthDay', type: DATA_TABLE_HEADER_TYPES.DATE, sortable: false },
    { text: 'activeCardNumber', sortable: false },
  ];

  get hasActiveTickets() {
    return this.dataLoaded && this.activeTickets?.data?.length;
  }

  get canDisplayTable() {
    return this.dataLoaded && !this.activeTickets?.data?.length;
  }

  get isDisableDeleteAccountAllowed() {
    return this.systemConfiguration.isDisableDeleteAccountAllowed;
  }

  get canDeleteAccount() {
    if (this.systemConfiguration.isDisableDeleteAccountAllowed) {
      return this.dataLoaded && !this.activeTickets?.data?.length;
    }
    return true;
  }

  getCustomerProfileDisplayName(profile: CustomerProfileInfoVm | undefined): string {
    return getCustomerProfileDisplayName(profile?.id);
  }

  getTariffProfileDisplayName(profile: TariffProfileInfoVm | undefined): string {
    return getTariffProfileDisplayName(profile?.id);
  }

  async created() {
    await this.getAllInformationsAboutAccount();
  }

  async getAllInformationsAboutAccount() {
    await this.checkIfAccountCanBeDeleted();
    await this.getManagedAccounts();
  }

  async getManagedAccounts() {
    const query: CustomerAccountLinkQuery = {
      sortBy: DEFAULT_SORT_BY_COLUMN,
      sortDesc: DEFAULT_SORT_DESC,
      customerAccountLinkType: CustomerAccountLinkQuery.customerAccountLinkType.MANAGED,
    };
    this.managedAccounts = await customerAccountLinkService.getLinkList(query);
  }

  async checkIfAccountCanBeDeleted() {
    try {
      this.activeTickets = await accountService.getActiveTicketsList();
      this.dataLoaded = true;
    } catch (e) {
      ToastModule.error({
        message: this.$t('accountSetting.loadingActiveTicketsFailed'),
      });
      this.dataLoaded = false;
    }
  }

  searchActiveTickets() {
    return this.activeTickets;
  }

  refreshTable() {
    this.dataTable.refresh();
  }

  searchManagedCustomers() {
    return this.managedAccounts;
  }

  openChangePasswordDialog() {
    this.changePassowrdDialog.openDialog();
  }

  openDeleteAcountDialog() {
    this.deleteAccountConfirmDialog.openDialog();
  }

  async deleteAccount() {
    try {
      await accountService.removeCustomerAccount();
      ToastModule.success({
        message: this.$t('accountSetting.accountDeleted'),
      });
      await this.logout();
    } catch (e) {
      this.getAllInformationsAboutAccount();
      ToastModule.error({
        message: this.$t('accountSetting.deleteAccountFailed'),
      });
    }
  }

  async logout() {
    AuthModule.logout();
    UserAccountModule.clearCardData();
    UserAccountModule.setCardApplicationActive(false);
    UserAccountModule.setVirtualCardApplicationCanBeCreated(true);
  }
}
</script>

<style scoped>
.delete-account-btn-section {
  display: flex;
  justify-content: flex-end;
}
</style>

<style>
.account-active-tickets-list-table.hidden-table {
  .v-row.table-container {
    display: none;
  }
}

.account-setting-action-button .rdp-button-text {
  margin-left: 8px;
  margin-right: 16px;
  text-align: center;
  width: auto;
}
</style>
