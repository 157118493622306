<template>
  <v-snackbar
    v-if="data"
    v-model="active"
    :timeout="data.options?.timeout"
    :color="data.options?.color"
    :location="location"
    :multi-line="data.options?.multiLine"
    :vertical="data.options?.vertical"
    class="vts"
    :class="data.options?.classes"
    role="alert"
    @click="dismiss"
  >
    <v-icon v-if="!!data.options?.icon" theme="dark" start class="vts__icon" :color="data.options?.iconColor">
      {{ data.options.icon }}
    </v-icon>

    <div
      class="vts__message rdp-toast"
      :class="{
        'vts__message--padded': data.options?.showClose && !data.options?.closeText,
      }"
    >
      <div :class="data.options?.color" v-html="data.message"></div>
      <slot></slot>
    </div>

    <template #actions>
      <v-btn
        v-if="data.options?.showClose"
        :icon="!data.options?.closeText"
        :variant="data.options?.closeText ? 'text' : 'plain'"
        class="vts__close"
        :class="{ 'vts__close--icon': !data.options?.closeText }"
        :color="data.options?.closeColor"
        @click="close"
      >
        <v-icon v-if="!data.options?.closeText">{{ data.options?.closeIcon || 'mdi-close' }}</v-icon>
        <span v-if="!!data.options?.closeText">{{ data.options?.closeText }}</span>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import ToastModule from '@/store/modules/toast';

const active = ref(false);

const data = computed(() => ToastModule.toast);

const show = () => {
  active.value = true;
};

const close = () => {
  active.value = false;
};

const dismiss = () => {
  if (data.value?.options?.dismissable) {
    close();
  }
};

watch(active, (isActive, wasActive) => {
  if (wasActive && !isActive) {
    ToastModule.hide();
  }
});

watch(data, () => {
  if (!active.value) {
    show();
  }
});

const location = computed(() => {
  if (data.value?.options?.y === 'bottom') {
    return 'bottom';
  } else if (data.value?.options?.y === 'top') {
    return 'top';
  } else if (data.value?.options?.x === 'left') {
    return 'left';
  } else if (data.value?.options?.x === 'right') {
    return 'right';
  }

  return undefined;
});
</script>

<style>
.vts {
  max-width: none !important;
  width: auto !important;
}

.vts .v-snack__content {
  justify-content: flex-start;
}

.vts__icon {
  margin-right: 12px;
}

.vts__message {
  margin-right: auto;
}

.vts__close {
  margin: 0 -8px 0 24px !important;
  justify-self: flex-end;
}

.vts.v-snack--vertical .vts__icon {
  margin: 0 0 12px !important;
}

.vts.v-snack--vertical .v-snack__content {
  padding-bottom: 16px !important;
}

.vts.v-snack--vertical .vts__message--padded {
  padding: 12px 0 0;
}

.vts.v-snack--vertical .vts__icon + .vts__message {
  padding-top: 0;
}

.vts.v-snack--vertical .vts__close {
  margin: 12px 0 -8px !important;
}

.vts.v-snack--vertical .vts__close--icon {
  margin: 0 !important;
  position: absolute;
  right: 4px;
  top: 4px;
  transform: scale(0.75);
  padding: 4px !important;
}
</style>
