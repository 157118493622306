<template>
  <v-btn
    :class="$vuetify.display.mobile ? 'px-5 ml-0' : null"
    :disabled="isLoading || disabled"
    :color="buttonColor"
    :theme="dark && !isLoading ? 'dark' : ''"
    :block="block"
    :type="type"
    :height="height"
    :data-cy="dataCy"
    variant="elevated"
    @click="confirmCallback"
  >
    <template #loader>
      <v-progress-circular v-if="isLoading" :color="progressColor" indeterminate size="24" />
    </template>
    <template #prepend>
      <v-icon :size="largeIcon ? 'large' : 'medium'" :icon="icon" />
    </template>
    {{ text || $t('buttons.ok') }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

export interface RDPConfirmButtonInterface {
  setLoading: (loading: boolean) => void;
}

@Component
export default class RDPConfirmButton extends Vue {
  /* Data */
  isLoading = false;

  /* Props */
  @Prop({
    required: false,
    type: Function,
    default: () => {
      console.error('Not implemented');
    },
  })
  readonly confirm!: () => void;

  @Prop({ required: false, type: String, default: '' })
  readonly dataCy!: string;

  @Prop({ required: false, type: String, default: 'mdi-check' })
  readonly icon!: string;

  @Prop({ required: false, type: String, default: '' })
  readonly text!: string;

  @Prop({ required: false, type: String, default: 'buttonPrimary' })
  readonly buttonColor!: string;

  @Prop({ required: false, type: Boolean, default: true })
  readonly dark!: boolean;

  @Prop({ required: false, type: String, default: 'blue-grey darken-4' })
  readonly progressColor!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly block!: boolean;

  @Prop({ required: false, type: String, default: 'button' })
  readonly type!: string;

  @Prop({ required: false, type: String, default: null })
  readonly height!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly largeIcon!: string;

  /* Methods */
  async confirmCallback() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await this.confirm();
    this.isLoading = false;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }
}
</script>
