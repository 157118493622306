import { CustomerCardDetailVm, EshopCustomerCardService } from '@/api';

export default {
  getCard: async (id: string): Promise<CustomerCardDetailVm> => {
    const response = await EshopCustomerCardService.eshopCustomerCardControllerGetOneById({
      id,
    });

    return response.data;
  },
  marketingConsent: async (id: string, marketingConsent: boolean): Promise<CustomerCardDetailVm> => {
    const response = await EshopCustomerCardService.eshopCustomerCardControllerMarketingConsent({
      id,
      requestBody: { value: marketingConsent },
    });

    return response.data;
  },
  setActiveMainCard: async (id: string) => {
    await EshopCustomerCardService.eshopCustomerCardControllerSetActiveMainCard({ requestBody: { id } });
  },
};
