<template>
  <v-main>
    <v-container class="fill-height bg-rdpbackground" fluid>
      <v-row align="center" justify="center" class="text-center">
        <v-col cols="12">
          <RDPHomePageLogo />
        </v-col>
        <v-col cols="12">
          <login-form />
        </v-col>
        <v-col cols="12" md="5" sm="7" class="d-flex justify-space-between pt-0">
          <router-link :to="paths.REGISTRATION" class="pr-2 text-primary" data-cy="registration-redirect-btn">
            {{ $t('registration.title') }}
          </router-link>
          <router-link :to="paths.VERIFICATION" class="pr-2 text-primary" data-cy="verification-redirect-btn">
            {{ $t('verification.title') }}
          </router-link>
          <router-link :to="paths.FORGOTTEN_PASSWORD" class="text-primary" data-cy="forgottenPassword-redirect-btn">
            {{ `${$t('forgottenPassword.title')}?` }}
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app theme="dark">
      <FooterLinks dark />
    </v-footer>
  </v-main>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LoginForm from '@/components/login/LoginForm.vue';
import Paths from '@/constants/Paths';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import RDPHomePageLogo from '@/components/common/RDPHomePageLogo.vue';

@Component({
  components: {
    FooterLinks,
    LoginForm,
    RDPHomePageLogo,
  },
})
export default class Login extends Vue {
  paths = Paths;
}
</script>
