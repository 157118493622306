<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="850"
    scrollable
    persistent
    :data-cy="cy('dialog')"
  >
    <v-card>
      <v-card-title class="text-h5 d-flex pl-3 bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white" icon="$reclamationIcon" />
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-row>
            <v-col>
              <v-text-field
                v-model="reclamation.customerFirstName"
                :rules="validations.customerFirstName"
                :label="t('customerFirstName')"
                :data-cy="cy('customerFirstName')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="reclamation.customerLastName"
                :rules="validations.customerLastName"
                :label="t('customerLastName')"
                :data-cy="cy('customerLastName')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="reclamation.customerPhoneNumber"
                :rules="validations.customerPhoneNumber"
                :label="t('customerPhoneNumber')"
                :data-cy="cy('customerPhoneNumber')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="reclamation.customerAddress.street"
                :rules="validations.addressStreet"
                :label="t('addressStreet')"
                :data-cy="cy('addressStreet')"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="reclamation.customerAddress.streetNumber"
                :rules="validations.addressStreetNumber"
                :label="t('addressStreetNumber')"
                :data-cy="cy('addressStreetNumber')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="reclamation.customerAddress.city"
                :rules="validations.addressCity"
                :label="t('addressCity')"
                :data-cy="cy('addressCity')"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="reclamation.customerAddress.zip"
                :rules="validations.addressZipCode"
                :label="t('addressZipCode')"
                :data-cy="cy('addressZipCode')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="reclamation.note"
                :rules="validations.note"
                :label="t('note')"
                :data-cy="cy('note')"
                row-height="27"
                rows="4"
                max-rows="8"
                auto-grow
                variant="underlined"
              />
            </v-col>
          </v-row>
          <div class="d-flex align-center">
            <v-checkbox
              v-model="reclamation.gdprTermsAndConditionsApproved"
              :rules="validations.gdprTermsAndConditionsApproved"
              :data-cy="cy('gdprTermsAndConditionsApproved')"
            >
              <template #label>
                <div>
                  <span class="pr-1">
                    {{ t('gdprTermsAndConditionsApprovedText') }}
                  </span>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn color="buttonWarning" :data-cy="cy('close-button')" variant="elevated" @click="closeDialog">
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>

          <ConfirmButton
            dark
            :manual-loading="true"
            :confirm="confirmDialog"
            :text="$t('buttons.send')"
            icon="mdi-check"
            :data-cy="cy('confirm-button')"
            :class="$vuetify.display.mobile ? '' : 'mx-2'"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import CustomerDetailForDialog from '@/components/userAccount/dialogs/CustomerDetailForDialog.vue';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { maxLengthValidator, requiredValidator } from '@/utils/validators';
import { FormInterface } from '@/components/common/FormInterface';
import ComponentBase from '@/components/common/ComponentBase';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { CreateEshopReclamationCommand, SaveAddressCommand } from '@/api';
import reclamationService from '@/services/api/reclamationService.ts';

@Component({
  components: {
    CustomerDetailForDialog,
    ConfirmButton,
  },
})
export default class ReclamationDialog extends ComponentBase {
  @Ref()
  readonly form!: FormInterface;

  show = false;
  validations = {
    customerFirstName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    customerLastName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    customerPhoneNumber: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 20)],
    addressStreet: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    addressStreetNumber: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 15)],
    addressCity: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    addressZipCode: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 6)],
    note: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 4000)],
    gdprTermsAndConditionsApproved: [(v: string) => requiredValidator(v)],
  };
  reclamation = {
    customerAddress: {} as SaveAddressCommand,
  } as CreateEshopReclamationCommand;
  dataCy = 'userAccount-reclamationDialog';
  i18nGroupKey = 'userAccountPage.reclamationDialog';

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto gap-1 ml-0' : '';
  }

  async openDialog() {
    this.show = true;
  }

  closeDialog() {
    this.resetForm();
  }

  async confirmDialog() {
    try {
      if ((await this.form.validate()).valid) {
        const reclamationCreateCommand = {
          ...this.reclamation,
        } as CreateEshopReclamationCommand;
        await reclamationService.createReclamation(reclamationCreateCommand);
        this.closeDialog();
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.saveSuccess`),
        });
      }
    } catch (e) {
      ToastModule.error({ message: getApiError(e as ApiErrorInterface, this.i18nGroupKey) });
    }
  }

  resetForm() {
    this.reclamation = {
      customerAddress: {} as SaveAddressCommand,
    } as CreateEshopReclamationCommand;
    this.form.resetValidation();
    this.show = false;
  }
}
</script>

<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
