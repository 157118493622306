<template>
  <div class="text-center">
    <v-overlay :model-value="show" absolute persistent class="align-center justify-center">
      <v-progress-circular indeterminate color="primary" />
      <p>{{ loadingMessage }}</p>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component
export default class RDPSpinner extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;
  @Prop({ required: false, type: String, default: '' })
  readonly i18nKey!: string;

  get loadingMessage() {
    return this.i18nKey ? this.$t(this.i18nKey) : '';
  }
}
</script>
