import { MIN_CUSTOMER_AGE } from '@/config/config';
import { EnumsDto } from '@/api';

export enum CardApplicationDialogType {
  Create = 'CREATE',
  CreateAssignedCard = 'CREATE_ASSIGNED_CARD',
  Detail = 'DETAIL',
}

export const CARD_APPLICATION_DIALOG_TYPE: CardApplicationDialogTypes = {
  CREATE: {
    icon: '$newCardApplicationIcon',
    title: {
      [EnumsDto.carrierTypes.HAULIER_CARD]: { translate: 'cardApplication.create' },
      [EnumsDto.carrierTypes.VIRTUAL_CARD]: { translate: 'cardApplication.createVirtualCard' },
      [EnumsDto.carrierTypes.BANK_CARD]: { translate: 'cardApplication.createBankCard' },
    },
    name: CardApplicationDialogType.Create,
  },
  CREATE_ASSIGNED_CARD: {
    icon: '$newCardApplicationIcon',
    title: {
      [EnumsDto.carrierTypes.HAULIER_CARD]: {
        translate: 'cardApplication.createAssignedCard',
        param: { minCustomerAge: MIN_CUSTOMER_AGE },
      },
      [EnumsDto.carrierTypes.VIRTUAL_CARD]: { translate: 'cardApplication.createAssignedCard' },
      [EnumsDto.carrierTypes.BANK_CARD]: {
        translate: 'cardApplication.createAssignedBankCard',
        param: { minCustomerAge: MIN_CUSTOMER_AGE },
      },
    },
    name: CardApplicationDialogType.CreateAssignedCard,
  },
  DETAIL: {
    icon: 'mdi-eye',
    title: {
      [EnumsDto.carrierTypes.HAULIER_CARD]: { translate: 'cardApplication.detail' },
      [EnumsDto.carrierTypes.VIRTUAL_CARD]: { translate: 'cardApplication.detailVirtualCard' },
      [EnumsDto.carrierTypes.BANK_CARD]: { translate: 'cardApplication.detailBankCard' },
    },
    name: CardApplicationDialogType.Detail,
  },
};

export interface CardApplicationDialogTypes {
  CREATE: CardApplicationDialogTypeItem;
  CREATE_ASSIGNED_CARD: CardApplicationDialogTypeItem;
  DETAIL: CardApplicationDialogTypeItem;
}

export interface CardApplicationDialogTypeItem {
  icon: string;
  title: { [key in EnumsDto.carrierTypes]?: { translate: string; param?: Record<string, unknown> } };
  name: CardApplicationDialogType;
}
