/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateTicketCombinationCommand } from '../models/CreateTicketCombinationCommand';
import type { ShortTermTicketCombinationListResponse } from '../models/ShortTermTicketCombinationListResponse';
import type { TicketCombinationListResponse } from '../models/TicketCombinationListResponse';
import type { TicketCombinationResponse } from '../models/TicketCombinationResponse';
import type { UpdateTicketCombinationCommand } from '../models/UpdateTicketCombinationCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TicketCombinationService {
  /**
   * Get ticket combinations.
   * @returns TicketCombinationListResponse
   * @throws ApiError
   */
  public static ticketCombinationControllerGetAll({
    customerProfileId,
    customerProfileType,
    validity,
    reduced,
    typeName,
    counterSale,
    eshopSale,
    mobileVirtualCardSale,
    mobileEshopAccountSale,
    timezoneOffset,
    limit,
    offset,
    sortBy,
    sortDesc,
    sortLocale,
    date,
  }: {
    customerProfileId?: string;
    customerProfileType?: 'IMPERSONAL' | 'PERSONAL';
    validity?: 'PAST' | 'PRESENT' | 'FUTURE' | 'PRESENT_OR_FUTURE';
    reduced?: boolean;
    typeName?: 'SINGLE' | 'TIME';
    counterSale?: boolean;
    eshopSale?: boolean;
    mobileVirtualCardSale?: boolean;
    mobileEshopAccountSale?: boolean;
    timezoneOffset?: number;
    limit?: number;
    offset?: number;
    sortBy?: string;
    sortDesc?: boolean;
    sortLocale?: string;
    date?: string;
  }): CancelablePromise<TicketCombinationListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/ticket-combinations',
      query: {
        customerProfileId: customerProfileId,
        customerProfileType: customerProfileType,
        validity: validity,
        reduced: reduced,
        typeName: typeName,
        counterSale: counterSale,
        eshopSale: eshopSale,
        mobileVirtualCardSale: mobileVirtualCardSale,
        mobileEshopAccountSale: mobileEshopAccountSale,
        timezoneOffset: timezoneOffset,
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDesc: sortDesc,
        sortLocale: sortLocale,
        date: date,
      },
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create ticket combination.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_VALID_TO** - ValidFrom is after ValidTo OR New ValidFrom is null or new validFrom is before old ValidFrom.<br>
   * + **INVALID_CUSTOMER_PROFILE** - Customer profile does not exist, is not valid for the customer, is impersonal, is not primary or has invalid properties combination.<br>
   * + **INVALID_TARIFF_PROFILE** - Tariff profile does not exist.<br>
   * + **INVALID_PRICE_LIST** - Price list does not exist.<br>
   * + **INVALID_TICKET_COMBINATION** - Ticket combination does not exist, is expired or isn´t enable for sell.
   * @returns TicketCombinationResponse
   * @throws ApiError
   */
  public static ticketCombinationControllerCreate({
    requestBody,
  }: {
    /**
     * CreateTicketCombinationCommand
     */
    requestBody?: CreateTicketCombinationCommand;
  }): CancelablePromise<TicketCombinationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/public/ticket-combinations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get ticket combination by id.
   * @returns TicketCombinationResponse
   * @throws ApiError
   */
  public static ticketCombinationControllerGetOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<TicketCombinationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/ticket-combinations/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Update ticket combination.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.
   * @returns TicketCombinationResponse
   * @throws ApiError
   */
  public static ticketCombinationControllerUpdate({
    id,
    requestBody,
  }: {
    id: string;
    /**
     * UpdateTicketCombinationCommand
     */
    requestBody?: UpdateTicketCombinationCommand;
  }): CancelablePromise<TicketCombinationResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/public/ticket-combinations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Delete ticket combination.
   * If an entity is being used, 409 Conflict will be returned.<br>
   * Require: `PERMISSION_ADMINISTRATION_VIEW`
   * @returns ApiResponse
   * @throws ApiError
   */
  public static ticketCombinationControllerDelete({ id }: { id: string }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/public/ticket-combinations/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        409: `Request couldn't be completed due to a conflict.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get all possible combinations of short-term tickets for user. Which can be purchased in the mobile application.
   * @returns ShortTermTicketCombinationListResponse
   * @throws ApiError
   */
  public static ticketCombinationControllerGetShortTermTicketCombinationList({
    mapPhoneAuthToken,
  }: {
    mapPhoneAuthToken?: any;
  }): CancelablePromise<ShortTermTicketCombinationListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/ticket-combinations-short-terms',
      headers: {
        'map-phone-auth-token': mapPhoneAuthToken,
      },
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
}
