<template>
  <div class="align-center justify-center" data-cy="card-data-form">
    <v-form ref="form" validate-on="lazy">
      <v-card-text class="card-text">
        <div v-if="dialogType !== dialogTypes.Detail">
          <v-alert
            v-if="!cardApplicationModule.isBankCardType"
            border="top"
            type="info"
            variant="text"
            elevation="2"
            class="mb-4"
            data-cy="card-data-validTo-alert"
          >
            {{ validityInfoMessage }}
          </v-alert>
          <v-alert
            v-if="!isCardApplicationAttachmentAllowed"
            border="top"
            type="info"
            variant="text"
            elevation="2"
            class="mb-4"
            data-cy="card-data-customerProfile-alert"
          >
            {{ customerProfileInfoMessage }}
          </v-alert>
          <v-alert
            v-if="isCardApplicationAttachmentAllowed"
            border="top"
            type="info"
            variant="text"
            elevation="2"
            class="mb-4"
            data-cy="card-data-attachmentInfo-alert"
          >
            {{ $t('cardApplication.attachmentInfo') }}
          </v-alert>
        </div>
        <component
          :is="profileComponent"
          ref="profileOneComponent"
          :customer-profile="cardData.profileOne"
          data-cy="card-data-profileOne"
          i18n-group-key="cardApplication.cardData.profileOne"
          :disabled="profileOneDisabled"
          :is-profile-one="true"
          :is-virtual-card="cardApplicationModule.isVirtualCardType"
          :card-type-allowed-for-isic="cardApplicationModule.isVirtualCardType || cardApplicationModule.isBankCardType"
          class="py-4"
          @add-profile="addProfileTwo"
          @change-profile="profileChanged"
        />
        <component
          :is="profileComponent"
          v-if="showProfileTwo"
          ref="profileTwoComponent"
          :customer-profile="cardData.profileTwo"
          data-cy="card-data-profileTwo"
          i18n-group-key="cardApplication.cardData.profileTwo"
          :disabled="profileTwoDisabled"
          :is-profile-one="false"
          :is-virtual-card="cardApplicationModule.isVirtualCardType"
          :card-type-allowed-for-isic="cardApplicationModule.isVirtualCardType || cardApplicationModule.isBankCardType"
          class="py-4"
          @remove-profile="removeProfileTwo"
          @change-profile="profileChanged"
        />
        <template v-if="cardApplicationModule.isVirtualCardType">
          <v-select
            :model-value="{
              title: $t('cardApplication.cardData.mobilePhone'),
            }"
            :items="[
              {
                title: $t('cardApplication.cardData.mobilePhone'),
              },
            ]"
            :label="$t('cardApplication.cardData.deliveryType')"
            class="disable-events"
            data-cy="card-data-virtual-card-delivery"
            variant="underlined"
          />
        </template>
        <template v-else-if="!cardApplicationModule.isBankCardType">
          <div
            v-if="systemConfigurationModule.isAllowedFreeCardApplication && !cardApplicationModule.isVirtualCardType"
          >
            <v-alert
              border="top"
              type="info"
              variant="text"
              elevation="2"
              class="mb-4"
              data-cy="card-data-cardForFree-alert"
            >
              {{ $t('cardApplication.cardData.cardForFreeInfo') }}
            </v-alert>
            <v-checkbox
              v-model="cardData.cardForFree"
              :class="{ 'disable-events': disabled }"
              :label="$t('cardApplication.cardData.allowFreeCardApplication')"
              data-cy="card-data-allowFreeCardApplication"
            />
          </div>
          <div class="mt-4">
            <v-select
              v-model="deliveryType"
              :class="{ 'disable-events': disabled }"
              :items="deliveryTypeItems"
              :rules="validations.deliveryType"
              :label="$t('cardApplication.cardData.deliveryType')"
              :no-data-text="$t('common.noData')"
              data-cy="card-data-deliveryType"
              variant="underlined"
            />
          </div>
          <v-row
            :class="
              deliveryType === deliveryTypes.POST_OFFICE ? 'show-input-with-animation' : 'hide-input-with-animation'
            "
          >
            <v-col cols="12">
              <h4>{{ $t('cardApplication.cardData.recipientAddress') }}</h4>
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="recipientAddressData.street"
                :rules="deliveryType === deliveryTypes.POST_OFFICE ? validations.addressStreet : []"
                :label="$t('cardApplication.cardData.addressStreet')"
                :class="{ 'disable-events': disabled }"
                data-cy="card-data-recipientAddressStreet"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="recipientAddressData.streetNumber"
                :rules="deliveryType === deliveryTypes.POST_OFFICE ? validations.addressStreetNumber : []"
                :label="$t('cardApplication.cardData.addressStreetNumber')"
                :class="{ 'disable-events': disabled }"
                data-cy="card-data-recipientAddressStreetNumber"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="recipientAddressData.city"
                :rules="deliveryType === deliveryTypes.POST_OFFICE ? validations.addressCity : []"
                :label="$t('cardApplication.cardData.addressCity')"
                :class="{ 'disable-events': disabled }"
                data-cy="card-data-recipientAddressCity"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="recipientAddressData.zip"
                :rules="deliveryType === deliveryTypes.POST_OFFICE ? validations.addressZipCode : []"
                :label="$t('cardApplication.cardData.addressZipCode')"
                :class="{ 'disable-events': disabled }"
                data-cy="card-data-recipientAddressZipCode"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="!cardApplicationModule.isVirtualCardType"
            :class="deliveryType === deliveryTypes.BRANCH ? 'show-input-with-animation' : 'hide-input-with-animation'"
          >
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-select
                v-model="branchOfficeData.id"
                :class="{ 'disable-events': disabled }"
                :items="branchOfficeItems"
                :rules="deliveryType === deliveryTypes.BRANCH ? validations.branchOffice : []"
                :label="$t('cardApplication.cardData.branchOffice')"
                :no-data-text="$t('common.noData')"
                data-cy="card-data-branchOffice"
                variant="underlined"
              />
            </v-col>
            <v-col v-if="branchOfficeData.id" cols="12">
              <v-col cols="12" class="pl-0 pt-0">
                <h4>
                  {{ $t('cardApplication.cardData.branchOfficeAddress') }}
                </h4>
              </v-col>
              <v-col cols="12" class="pl-0" data-cy="card-data-branchOfficeAddress">
                {{ branchOfficeAddress }}
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="!disabled">
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="customerData.pin"
                name="pin"
                :rules="validations.pin"
                :label="$t('cardApplication.cardData.pin')"
                data-cy="card-data-pin"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <div>
            <v-checkbox
              v-model="cardData.contactMeIfCardFound"
              :class="{ 'disable-events': disabled }"
              :label="`${$t('cardApplication.cardData.contactMeIfCardFound')}.`"
              data-cy="card-data-contactMeIfCardFound"
            />
          </div>
        </template>
        <template v-else>
          <div>
            <v-select
              v-model="bankCardId"
              :items="existingBankCards"
              :rules="validations.bankCard"
              :disabled="disabled"
              :label="$t('cardApplication.cardData.bankCard')"
              :no-data-text="$t('common.noData')"
              data-cy="card-data-bankCard"
              variant="underlined"
            />
          </div>
        </template>
      </v-card-text>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-facing-decorator';
import { maxLengthValidator, requiredValidator } from '@/utils/validators';
import CardApplicationModule, { REGISTER_NEW_BANK_CARD } from '@/store/modules/cardApplication';
import GlobalSettingModule from '@/store/modules/globalSetting';
import {
  AddressDto,
  BranchOfficeDto,
  BranchOfficeQuery,
  CardApplicationDto,
  CardApplicationProfileDto,
  CustomerDetailVm,
  VerifyIfCardCanBeSendByPostQuery,
} from '@/api';
import globalSettingService from '@/services/api/globalSettingService';
import { CardApplicationDialogType } from '@/components/cardApplication/cardApplicationDialogType';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import { DeliveryTypeI18nKeys, DeliveryTypes } from '@/constants/DeliveryTypes';
import cloneDeep from 'lodash/cloneDeep';
import branchOfficeService from '@/services/api/branchOfficeService';
import BranchOfficeModule from '@/store/modules/branchOffice';
import cardApplicationService from '@/services/api/cardApplicationService';
import SimpleProfileComponent, {
  CustomerProfileComponentInterface,
} from '@/components/cardApplication/dialogSteps/components/SimpleProfileComponent.vue';
import AdvancedProfileComponent from '@/components/cardApplication/dialogSteps/components/AdvancedProfileComponent.vue';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import CustomerModule from '@/store/modules/customer';
import bankCardService from '@/services/api/bankCardService';
import isDefined, { isArrayEmpty } from '@common/utils/isDefined';
import { getFormattedAddress } from '@/utils/getFormattedAddress';
import { ApiErrorInterface } from '@/models/ApiClientError';
import ToastModule from '@/store/modules/toast';
import { FormInterface } from '@/components/common/FormInterface';
import { ValidationInterface } from '@/components/common/ValidationInterface';

@Component({
  components: {
    SimpleProfileComponent,
    AdvancedProfileComponent,
  },
  expose: ['form', 'validate', 'initExistingBankCardsData', 'stepActive'],
})
export default class CardDataForm extends Vue implements ValidationInterface {
  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: String, default: CardApplicationDialogType.Detail })
  readonly dialogType!: CardApplicationDialogType;

  @Prop({ required: false, type: Boolean, default: false })
  readonly stepActive!: boolean;

  @Ref()
  readonly form!: FormInterface;

  @Ref()
  readonly profileOneComponent!: CustomerProfileComponentInterface;

  @Ref()
  readonly profileTwoComponent!: CustomerProfileComponentInterface;

  cardApplicationModule = CardApplicationModule;
  globalSettingModule = GlobalSettingModule;
  validToYearsDuration = 0;
  dialogTypes = CardApplicationDialogType;
  deliveryTypes = DeliveryTypes;
  deliveryType: string | number | object | undefined = '';
  branchOfficeList: BranchOfficeDto[] = [];
  branchOfficeModule = BranchOfficeModule;
  showProfileTwo = false;
  canSendCardByPost = true;
  systemConfigurationModule = SystemConfigurationModule;
  deliveryTypeItems: VuetifySelectOptionsVm[] = [];
  existingBankCards: VuetifySelectOptionsVm[] = [];
  customerModule = CustomerModule;

  validations = {
    customerProfile: [(v: string) => requiredValidator(v)],
    pin: [(v: string) => maxLengthValidator(v, 50)],
    deliveryType: [(v: string) => requiredValidator(v)],
    bankCard: [(v: string) => requiredValidator(v)],
    branchOffice: [(v: string) => requiredValidator(v)],
    addressStreet: [(v: string) => requiredValidator(v)],
    addressStreetNumber: [(v: string) => requiredValidator(v)],
    addressCity: [(v: string) => requiredValidator(v)],
    addressZipCode: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 6)],
  };

  get isCardApplicationAttachmentAllowed() {
    return this.systemConfigurationModule.cardApplicationAttachmentAllowed;
  }

  get profileComponent() {
    return this.isCardApplicationAttachmentAllowed ? 'advanced-profile-component' : 'simple-profile-component';
  }

  get validityInfoMessage() {
    let i18nKey: string;
    if (this.validToYearsDuration === 1) {
      i18nKey = 'validToInfoMessage1year';
    } else if (this.validToYearsDuration > 1 && this.validToYearsDuration < 5) {
      i18nKey = 'validToInfoMessage2to4years';
    } else {
      i18nKey = 'validToInfoMessage';
    }
    return this.$t(`cardApplication.cardData.${i18nKey}`, {
      validity: this.validToYearsDuration,
    });
  }

  get customerProfileInfoMessage() {
    let i18nKey = 'customerProfileInfoMessage';
    if (this.dialogType === CardApplicationDialogType.CreateAssignedCard) {
      i18nKey = 'customerProfileInfoMessageChild';
    }
    return this.$t(`cardApplication.cardData.${i18nKey}`);
  }

  async created() {
    try {
      const globalSetting = await globalSettingService.getGlobalSetting();
      this.globalSettingModule.setGlobalSettings(globalSetting);
      this.validToYearsDuration = this.globalSettingModule.customerCardValidity.value;
    } catch (e) {
      const errorName = `cardApplication.errors.${(e as ApiErrorInterface)?.data?.name}`;
      if (this.$te(errorName)) {
        ToastModule.error({ message: this.$t(errorName) });
      }
    }

    const branchOfficeQuery: BranchOfficeQuery = {
      cardDeliveryAllowed: true,
      sortBy: 'branchNumber',
      sortDesc: true,
      limit: 999,
      offset: 0,
    };

    try {
      this.branchOfficeList = await branchOfficeService.getBranchOfficeList(branchOfficeQuery);
      this.branchOfficeModule.setBranchOfficeList(this.branchOfficeList);
      this.setBranchOfficeImmediately();
    } catch (e) {
      const errorName = `cardApplication.errors.${(e as ApiErrorInterface)?.data?.name}`;
      if (this.$te(errorName)) {
        ToastModule.error({ message: this.$t(errorName) });
      }
    }
  }

  get profileOneDisabled() {
    return (
      this.disabled ||
      (this.cardApplicationModule.customerProfileAutoUpdated &&
        this.cardApplicationModule.isVirtualCardType &&
        !this.systemConfigurationModule.cardApplicationAttachmentAllowed)
    );
  }

  get profileTwoDisabled() {
    return (
      this.disabled ||
      (this.cardApplicationModule.customerProfileAutoUpdated &&
        this.cardApplicationModule.isVirtualCardType &&
        !this.systemConfigurationModule.cardApplicationAttachmentAllowed)
    );
  }

  get customerData(): CustomerDetailVm {
    return this.cardApplicationModule.customerData;
  }

  get cardData(): CardApplicationDto {
    return this.cardApplicationModule.cardData;
  }

  set cardData(data: CardApplicationDto) {
    this.cardApplicationModule.setCardData(data);
  }

  get bankCardId(): string {
    return this.cardApplicationModule.bankCardId;
  }

  set bankCardId(data: string) {
    this.cardApplicationModule.setBankCardId(data);
  }

  get branchOfficeData(): BranchOfficeDto {
    return this.cardApplicationModule.deliveryBranchOfficeData;
  }

  set branchOfficeData(data: BranchOfficeDto) {
    this.cardApplicationModule.setBranchOfficeData(data);
  }

  get recipientAddressData(): AddressDto {
    return this.cardApplicationModule.recipientAddressData;
  }

  set recipientAddressData(data: AddressDto) {
    this.cardApplicationModule.setRecipientAddressData(data);
  }

  setBranchOfficeImmediately() {
    if (this.branchOfficeList.length === 1 && this.dialogType === CardApplicationDialogType.Create) {
      this.cardApplicationModule.setBranchOfficeData(this.branchOfficeList[0]);
    }
  }

  mapResidentAddressToRecipientAddress() {
    if (this.cardApplicationModule.recipientAddressData.street === '') {
      this.recipientAddressData = cloneDeep(this.cardApplicationModule.customerData.address);
    }
  }

  @Watch('stepActive')
  async stepActiveChanged() {
    if (this.stepActive) {
      if (isArrayEmpty(this.existingBankCards)) {
        await this.initExistingBankCardsData();
      }
      if (this.cardData.profileTwo?.profile.id) {
        this.profileOneComponent.showProfileTwo();
        await this.addProfileTwo();
      } else {
        this.removeProfileTwo();
      }
      await this.checkIfCanSendByPost();
      this.initDeliveryType();
      this.initDeliveryData();
    }
  }

  removeProfileTwo() {
    this.showProfileTwo = false;
    this.clearProfileTwo();
    this.cardApplicationModule.setProfilesInCollision(false);
    this.profileOneComponent?.hideProfileTwo();
  }

  initDeliveryType() {
    this.deliveryTypeItems = [];
    this.deliveryTypeItems.push({
      value: DeliveryTypes.BRANCH,
      title: this.$t(DeliveryTypeI18nKeys[DeliveryTypes.BRANCH]),
    });
    if (this.canSendCardByPost && !this.cardData.cardForFree) {
      this.deliveryTypeItems.push({
        value: DeliveryTypes.POST_OFFICE,
        title: this.$t(DeliveryTypeI18nKeys[DeliveryTypes.POST_OFFICE]),
      });
    }
    if (!this.deliveryType) {
      if (
        this.dialogType === CardApplicationDialogType.Create ||
        this.dialogType === CardApplicationDialogType.CreateAssignedCard
      ) {
        this.deliveryType = this.deliveryTypeItems[0].value;
      } else {
        this.deliveryType =
          this.branchOfficeData && this.branchOfficeData.id !== ''
            ? this.deliveryTypeItems[0].value
            : this.deliveryTypeItems[1].value;
      }
    }

    this.setBranchOfficeImmediately();
  }

  async initExistingBankCardsData() {
    this.existingBankCards = [
      {
        value: REGISTER_NEW_BANK_CARD,
        title: this.$t('cardApplication.cardData.registerNewBankCard'),
      },
    ];

    if (isDefined(this.customerData.id) && this.cardApplicationModule.isBankCardType) {
      const bankCards = await bankCardService.getBankCards(this.customerData.id);

      for (const bankCard of bankCards) {
        this.existingBankCards.push({
          value: bankCard.id,
          title: bankCard.cardNumber,
        });
      }
    }

    if (this.existingBankCards.length === 1) {
      this.cardApplicationModule.setBankCardId(REGISTER_NEW_BANK_CARD);
    } else {
      const bankCardExist = this.existingBankCards.find(card => card.value === this.bankCardId);
      if (!bankCardExist) {
        this.cardApplicationModule.setBankCardId('');
      }
    }
  }

  initDeliveryData() {
    if (this.deliveryType === DeliveryTypes.BRANCH) {
      this.cardApplicationModule.resetRecipientAddressData();
    } else {
      this.cardApplicationModule.resetBranchOfficeData();
      this.mapResidentAddressToRecipientAddress();
    }
  }

  async addProfileTwo() {
    this.showProfileTwo = true;
    await this.$nextTick(() => {
      this.profileTwoComponent?.showProfileTwo();
    });
  }

  clearProfileTwo() {
    if (this.cardData.profileTwo) {
      this.clearProfile(this.cardData.profileTwo);
    }
  }

  clearProfile(cp: CardApplicationProfileDto) {
    cp.profile = {
      id: '',
      name: '',
    };
    cp.validFrom = '';
    cp.validTo = '';
    cp.confirmationChecked = '';
  }

  @Watch('deliveryType')
  deliveryTypeChanged() {
    if (this.deliveryType === DeliveryTypes.BRANCH) {
      this.cardApplicationModule.resetRecipientAddressData();
    } else {
      this.cardApplicationModule.resetBranchOfficeData();
      this.mapResidentAddressToRecipientAddress();
    }
  }

  profileChanged() {
    if (
      this.systemConfigurationModule.isIsicAllowed &&
      !this.profileOneComponent?.selectedProfile?.isic &&
      !this.profileTwoComponent?.selectedProfile?.isic
    ) {
      this.customerData.isicNumber = '';
    }
  }

  @Watch('cardData.cardForFree')
  allowFreeCardApplicationChanged() {
    if (this.cardData.cardForFree) {
      this.deliveryType = DeliveryTypes.BRANCH;
    }
    this.initDeliveryType();
  }

  async validate() {
    if (this.disabled) {
      return true; // skip validation
    }
    // do not allow two profiles with isic discount
    if (
      this.profileOneComponent?.selectedProfile?.isic &&
      this.profileTwoComponent?.selectedProfile?.isic &&
      this.customerData.isicNumber
    ) {
      const errorName = 'cardApplication.errors.MULTIPLE_CUSTOMER_PROFILES_WITH_ISIC';
      ToastModule.error({ message: this.$t(errorName) });
      return false;
    }

    // don't allow next step if isic is an authentication process
    if (
      this.systemConfigurationModule.isIsicAllowed &&
      this.customerData.isicNumber &&
      ((this.profileOneComponent?.selectedProfile?.isic && this.profileOneComponent?.isicCheckInProgress) ||
        (this.profileTwoComponent?.selectedProfile?.isic && this.profileTwoComponent?.isicCheckInProgress))
    ) {
      return false;
    }

    return Boolean(
      (await this.form.validate()).valid &&
        !this.cardApplicationModule.profilesAreInCollision &&
        this.profileOneComponent.valid() &&
        (!this.profileTwoComponent || (this.profileTwoComponent && this.profileTwoComponent?.valid())),
    );
  }

  async checkIfCanSendByPost() {
    if (
      this.dialogType === CardApplicationDialogType.Create ||
      this.dialogType === CardApplicationDialogType.CreateAssignedCard
    ) {
      const query: VerifyIfCardCanBeSendByPostQuery = {
        originId: this.cardApplicationModule.customerData.id,
      };
      this.canSendCardByPost =
        !query.originId || // don't check if new customer
        (await cardApplicationService.verifyIfCardCanBeSendByPost(query));
    } else {
      this.canSendCardByPost = true;
    }
  }

  get branchOfficeItems(): VuetifySelectOptionsVm[] {
    return this.branchOfficeList.map((branchOffice: BranchOfficeDto) => ({
      title: `${branchOffice.name} (${branchOffice.address.street} ${branchOffice.address.streetNumber})`,
      value: branchOffice.id,
    }));
  }

  get branchOfficeAddress(): string {
    const branch = this.branchOfficeList.find(branchOffice => branchOffice.id === this.branchOfficeData?.id);
    if (!branch) {
      return '';
    }

    return getFormattedAddress(branch.address);
  }
}
</script>
<style scoped lang="scss">
.card-text {
  height: 550px;
  overflow: auto;
}

.hide-input-with-animation {
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: 0.7s all;
  height: 0;
}

.show-input-with-animation {
  visibility: visible;
  opacity: 1;
  transition: 0.7s all;
}
</style>
