/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentStatus = {
  paymentStatus: PaymentStatus.paymentStatus;
};
export namespace PaymentStatus {
  export enum paymentStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    PROCESSING = 'PROCESSING',
  }
}
