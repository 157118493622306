/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Eet
 */
export type EetSentItemsDto = {
  /**
   * DIC
   */
  vatin: string;
  /**
   * Provozovna
   */
  branch: string;
  /**
   * Pokladna
   */
  counter: string;
  /**
   * Uctenka
   */
  receipt: string;
  /**
   * Datum a cas transakce. String in client time zone with the time zone. Time zone should be removed for EET receipt (seconds remain).
   */
  paidDate: string;
  /**
   * Celkova castka. Price converted to EET string, e.g. 122.50. Should be converted to e.g. 122,50 Kc for EET receipt.
   */
  priceAsEetString: string;
  /**
   * Rezim. Bezny:0. Zjednoduseny: 1.
   */
  regime: EetSentItemsDto.regime;
};
export namespace EetSentItemsDto {
  /**
   * Rezim. Bezny:0. Zjednoduseny: 1.
   */
  export enum regime {
    NORMAL = 'NORMAL',
    SIMPLIFIED = 'SIMPLIFIED',
    '_0' = 0,
    '_1' = 1,
  }
}
