/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLocalizationCommand } from './CreateLocalizationCommand';
export type CreateTicketCombinationCommand = {
  name: string;
  abbreviation: string;
  /**
   * Combination valid from date. Type: 'DATE'
   */
  validFrom?: string;
  /**
   * Combination valid to date. Type: 'DATE'
   */
  validTo?: string;
  /**
   * Represents a numeric order for selects.
   */
  orderNumber: number;
  /**
   * Customer profile identifier. Type: 'UUID'
   */
  customerProfileId: string;
  /**
   * Tariff profile identifier. Type: 'UUID'
   */
  tariffProfileId: string;
  /**
   * Price list identifier. Type: 'UUID'
   */
  priceListId: string;
  /**
   * Ticket combination identifier. Used for copying. Type: 'UUID'
   */
  oldId?: string;
  /**
   * The flag is used to check in vehicles and to inspect ticket inspectors
   */
  validDuringNightTransport: boolean;
  /**
   * Ticket combination type. Values: 'TIME', 'SINGLE'
   */
  type: CreateTicketCombinationCommand.type;
  /**
   * The flag determining the ticket with this combination can be sold at the counter.
   */
  counterSale: boolean;
  /**
   * The flag determining the ticket with this combination can be sold in the e-shop.
   */
  eshopSale: boolean;
  /**
   * The flag determining whether a short-term ticket with this combination can be sold in a mobile application cannot be combined with a sale at the counter or a sale in an e-shop. A short-term ticket with this combination can also be purchased in the mobile application without a transport / virtual card.
   */
  mobileEshopAccountSale: boolean;
  /**
   * A flag that determines whether a short-term ticket with this combination can be sold in a mobile application on a virtual card. A short-term ticket with this combination is tied to the customer profile on the virtual card.
   */
  mobileVirtualCardSale: boolean;
  localizations: Array<CreateLocalizationCommand>;
  /**
   * Represents a numeric identifier for clearing.
   */
  profileNumber?: number;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CreateTicketCombinationCommand {
  /**
   * Ticket combination type. Values: 'TIME', 'SINGLE'
   */
  export enum type {
    SINGLE = 'SINGLE',
    TIME = 'TIME',
  }
}
