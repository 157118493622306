<template>
  <v-card width="100%" class="fill-height pa-4">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchManagesAccounts"
      :i18n-group-key="i18nGroupKey"
      :no-data-text="t('noDataText')"
      disable-sort
      :data-cy="cy('table')"
    >
      <template #head-panel.end>
        <div :title="manageCardRequestTitle">
          <v-alert
            v-if="manageCardRequestDisabled && $vuetify.display.mobile"
            border="top"
            type="warning"
            elevation="2"
            variant="text"
            width="292px"
            class="mb-4 text-left"
          >
            {{ manageCardRequestTitle }}
          </v-alert>
          <v-btn
            :data-cy="cy('manageCardRequest')"
            class="mb-3 rdp-button"
            :disabled="manageCardRequestDisabled"
            :theme="!manageCardRequestDisabled ? 'dark' : ''"
            color="buttonPrimary"
            @click.stop="manageCardRequest()"
          >
            <template #prepend>
              <v-icon size="large" icon="$manageCardRequestIcon" />
            </template>
            <span class="rdp-button-text">
              {{ t('manageCardRequest') }}
            </span>
          </v-btn>
        </div>
      </template>
      <template #actions.end="{ item }">
        <v-btn
          :title="t('cancel')"
          color="error"
          theme="dark"
          class="mx-1 elevation-4"
          icon
          size="x-small"
          :data-cy="cy(`cancel-link-${item.id}`)"
          @click.stop="cancelManagingAccountLink(item)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template #no-data>
        {{ t('noDataTextPart1') }}
        <a @click="manageCardRequest">{{ t('noDataTextPart2') }}</a>
      </template>
    </RDPDataTable>
    <ManageCardRequestDialog ref="manageCardRequestDialog" @refresh-card-managing-account-list-page="refreshPage" />
    <RDPConfirmDialog
      ref="cancelConfirmationDialog"
      :title="t('cancelConfirmationTitle')"
      :text="t('cancelConfirmationText', { username: managingAccount.username })"
      :confirm="confirmCancelManagingAccountLink"
      :data-cy="cy('cancel-managing-account-link-dialog')"
    />
  </v-card>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import { ManagingAccountCardDto, PaginationQuery } from '@/api';
import customerService from '@/services/api/customerService';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import ManageCardRequestDialog from '@/components/cardManaging/dialogs/ManageCardRequestDialog.vue';
import CustomerModule from '@/store/modules/customer';
import ToastModule from '@/store/modules/toast';

const DEFAULT_SORT_BY_COLUMN = 'username';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDataTable,
    RDPConfirmDialog,
    ManageCardRequestDialog,
  },
})
export default class CardManagingAccountListPage extends ComponentBase {
  @Ref()
  readonly dataTable!: RDPDataTableInterface;
  @Ref()
  readonly cancelConfirmationDialog!: RDPDialogInterface;
  @Ref()
  readonly manageCardRequestDialog!: RDPDialogInterface;

  customerModule = CustomerModule;

  filter: PaginationQuery = {
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
  };
  managingAccount = {
    username: '',
    customerName: '',
    managingAccountId: '',
  } as ManagingAccountCardDto;

  headers = ['customerName', 'username'];

  dataCy = 'card-managing-account-list';
  i18nGroupKey = 'cardManagingAccountList';

  searchManagesAccounts(query: PaginationQuery) {
    return customerService.getCustomersManagingAccountsCard(query);
  }

  get manageCardRequestDisabled() {
    return !this.customerModule.currentlySelectedCustomerId;
  }

  get manageCardRequestTitle() {
    return this.manageCardRequestDisabled ? this.t('manageCardRequestDisabledTitle') : this.t('manageCardRequest');
  }

  manageCardRequest() {
    this.manageCardRequestDialog.openDialog();
  }

  async cancelManagingAccountLink(item: ManagingAccountCardDto) {
    this.managingAccount = item;
    this.cancelConfirmationDialog.openDialog();
  }

  async confirmCancelManagingAccountLink() {
    try {
      await customerAccountLinkService.cancelManagingAccountLink(this.managingAccount.managingAccountId);
      this.refreshPage();
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.cancelManagingAccountSuccess`),
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.cancelManagingAccountFailed`),
      });
    }
  }

  refreshPage() {
    this.dataTable.refresh();
  }
}
</script>
