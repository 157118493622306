<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="600"
    scrollable
    persistent
    data-cy="pak-transfer-dialog"
  >
    <v-card>
      <v-card-title class="text-h5 d-flex pl-3 bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white" class="mr-3" icon="$pakTransferIcon" />
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text px-11" style="max-height: 800px">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-alert border="top" type="info" elevation="2" class="pt-6 pr-6 mb-4" variant="text">
            {{ t('info1') }}
          </v-alert>
          <v-alert border="top" type="info" elevation="2" class="pt-6 pr-6" variant="text">
            {{ t('info2') }}
          </v-alert>
          <v-text-field
            v-model="pakCardNumber"
            :label="t('snr')"
            :rules="validations.pakCardNumber"
            variant="underlined"
            @keypress="allowOnlyNumeric"
          />
          <div class="mt-2 v-messages v-messages__message">
            {{ t('snrHint') }}
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn color="buttonWarning" :data-cy="cy('cancel-button')" variant="elevated" @click="closeDialog">
          <template #prepend>
            <v-icon>mdi-cancel</v-icon>
          </template>
          {{ $t('buttons.close') }}
        </v-btn>
        <ConfirmButton :confirm="confirmDialog" :data-cy="cy('confirm-button')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import { FormInterface } from '@/components/common/FormInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { numericStringValidator, requiredValidator } from '@/utils/validators';
import customerService from '@/services/api/customerService';
import keyboardEventHelper from '@common/utils/keyboardEventHelper';
import { toApiMapCardNumber } from '@/utils/customerCard';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';

@Component({
  components: { ConfirmButton },
})
export default class PakTransferDialog extends ComponentBase {
  @Emit('refresh-account-page')
  refreshAccountPage() {
    return;
  }

  @Ref()
  readonly form!: FormInterface;

  @Prop({ required: false, type: String })
  readonly mapCardNumber!: string;

  toastTimeout = 10000;
  pakCardNumber = '';
  allowOnlyNumeric = keyboardEventHelper.allowOnlyNumeric;

  show = false;
  validations = {
    pakCardNumber: [requiredValidator, numericStringValidator],
  };

  dataCy = 'transfer-dialog';
  i18nGroupKey = 'userAccountPage.pakTransferDialog';

  async openDialog() {
    this.show = true;
  }

  closeDialog() {
    this.show = false;
    this.resetForm();
  }

  resetForm() {
    this.pakCardNumber = '';
    this.form.resetValidation();
  }

  async confirmDialog() {
    if ((await this.form.validate()).valid) {
      try {
        const transaction = await customerService.transferPakToMap({
          pakCardNumber: this.pakCardNumber.replace(/\s/g, ''),
          mapCardNumber: toApiMapCardNumber(this.mapCardNumber),
        });
        this.refreshAccountPage();

        const text = this.t(`${this.i18nGroupKey}.saveSuccess`, {
          amount: this.filters.toPrice(transaction.amount.toString()),
        });
        ToastModule.success({
          message: text,
          options: { timeout: this.toastTimeout },
        });
        this.closeDialog();
      } catch (e) {
        if ((e as ApiErrorInterface).data?.httpCode === 404) {
          ToastModule.error({
            message: this.$t(`${this.i18nGroupKey}.saveFailedNotFound`),
          });
        } else {
          ToastModule.error({
            message: getApiError(e as ApiErrorInterface, this.i18nGroupKey, 'saveFailed'),
          });
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.headline {
  color: white;
}
</style>
