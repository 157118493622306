<template>
  <!--
    from file(s): platba_kartou.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.9l0 -863.8c0,-79.62 65.01,-144.65 144.64,-144.65l449.08 0c79.63,0 144.64,65.03 144.64,144.65l0 863.8c0,79.62 -65.01,144.65 -144.64,144.65l-449.08 0c-79.63,0 -144.64,-65.03 -144.64,-144.65zm120.6 -39.7l0 -217.18c0,-20.02 16.34,-36.37 36.37,-36.37l112.9 0c20.03,0 36.37,16.35 36.37,36.37l0 217.18c0,20.01 -16.34,36.36 -36.37,36.36l-112.9 0c-20.03,0 -36.37,-16.35 -36.37,-36.36z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class PayByCardIcon extends Vue {}
</script>

<style lang="scss" scoped></style>
<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
