/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ChangePasswordCommand } from '../models/ChangePasswordCommand';
import type { EshopCustomerAccountResponse } from '../models/EshopCustomerAccountResponse';
import type { EshopCustomerProfileResponse } from '../models/EshopCustomerProfileResponse';
import type { RegisterCommand } from '../models/RegisterCommand';
import type { StringValueCommand } from '../models/StringValueCommand';
import type { UpdatePasswordCommand } from '../models/UpdatePasswordCommand';
import type { UsernameLoginCommand } from '../models/UsernameLoginCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopAuthService {
  /**
   * Register a customer.
   * Creates unverified customer account.,Send a confirmation link to the given email.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **USERNAME_NOT_UNIQUE** - Username already exists.
   * @returns EshopCustomerAccountResponse
   * @throws ApiError
   */
  public static eshopAuthControllerRegister({
    requestBody,
  }: {
    /**
     * RegisterCommand
     */
    requestBody?: RegisterCommand;
  }): CancelablePromise<EshopCustomerAccountResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Resend a verification code for the given email.
   * Body contains an email.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_EMAIL** - Email does not exists or customer account is already verified.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopAuthControllerResend({
    requestBody,
  }: {
    /**
     * StringValueCommand
     */
    requestBody?: StringValueCommand;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/resend',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Confirm a customer account.
   * Verify a customer account based on token.,Body contains a token.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_VERIFICATION_TOKEN** - Token is invalid or has already expired.
   * @returns EshopCustomerAccountResponse
   * @throws ApiError
   */
  public static eshopAuthControllerConfirmation({
    requestBody,
  }: {
    /**
     * StringValueCommand
     */
    requestBody?: StringValueCommand;
  }): CancelablePromise<EshopCustomerAccountResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/confirmation',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Authenticate a customer
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CREDENTIALS** - Username, cardNumber or password is invalid. (also can mean: card is blocked, expired or not valid yet, user is disabled).<br>
   * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.
   * @returns EshopCustomerProfileResponse
   * @throws ApiError
   */
  public static eshopAuthControllerLogin({
    requestBody,
  }: {
    /**
     * UsernameLoginCommand
     */
    requestBody?: UsernameLoginCommand;
  }): CancelablePromise<EshopCustomerProfileResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Authenticate a map phone customer
   * For login is required set header: **map-phone-auth-token**<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CREDENTIALS** - Username, cardNumber or password is invalid. (also can mean: card is blocked, expired or not valid yet, user is disabled).<br>
   * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.
   * @returns EshopCustomerProfileResponse
   * @throws ApiError
   */
  public static eshopAuthControllerMapPhoneLogin({
    mapPhoneAuthToken,
    requestBody,
  }: {
    mapPhoneAuthToken?: any;
    /**
     * UsernameLoginCommand
     */
    requestBody?: UsernameLoginCommand;
  }): CancelablePromise<EshopCustomerProfileResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/map-phone-login',
      headers: {
        'map-phone-auth-token': mapPhoneAuthToken,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Request a password reset.
   * Send a reset password link to the given email.,Body contains an email.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_EMAIL** - Email does not exists or customer account is already verified.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopAuthControllerResetPassword({
    requestBody,
  }: {
    /**
     * StringValueCommand
     */
    requestBody?: StringValueCommand;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/reset-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Request a map phone password reset.
   * For reset password is required set header: **map-phone-auth-token**,Send a reset password link to the given email.,Body contains an email.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_EMAIL** - Email does not exists or customer account is already verified.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopAuthControllerMapPhoneResetPassword({
    mapPhoneAuthToken,
    requestBody,
  }: {
    mapPhoneAuthToken?: any;
    /**
     * StringValueCommand
     */
    requestBody?: StringValueCommand;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/map-phone-reset-password',
      headers: {
        'map-phone-auth-token': mapPhoneAuthToken,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Change a password for the given token.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_VERIFICATION_TOKEN** - Token is invalid or has already expired.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopAuthControllerChangePassword({
    requestBody,
  }: {
    /**
     * ChangePasswordCommand
     */
    requestBody?: ChangePasswordCommand;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/change-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Change user password.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_VERIFICATION_TOKEN** - Token is invalid or has already expired.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopAuthControllerUpdatePassword({
    requestBody,
  }: {
    /**
     * UpdatePasswordCommand
     */
    requestBody?: UpdatePasswordCommand;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/auth/update-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
