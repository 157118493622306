/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateReclamationCardCommand = {
  /**
   * Card state enum. Represents active, blocked, expired.
   */
  state: UpdateReclamationCardCommand.state;
  note?: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace UpdateReclamationCardCommand {
  /**
   * Card state enum. Represents active, blocked, expired.
   */
  export enum state {
    NOT_ISSUED = 'NOT_ISSUED',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    EXPIRED = 'EXPIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    TOKENIZED = 'TOKENIZED',
  }
}
