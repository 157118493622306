/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type VehicleTransactionQuery = {
  /**
   * Device number on which was transaction assigned.
   */
  deviceNumber?: number;
  /**
   * Number of transaction. Is unique.
   */
  transactionNumber?: number;
  /**
   * Transaction type. Example: CHECK_IN, CHECK_OUT...
   */
  transactionType?: VehicleTransactionQuery.transactionType;
  /**
   * Payment type. EXAMPLE: CASH, BANK_CARD...
   */
  paymentType?: VehicleTransactionQuery.paymentType;
  /**
   * Card type. Example: BANK_CARD, MAP_CARD
   */
  cardType?: VehicleTransactionQuery.cardType;
  /**
   * Card number on which was transaction assigned.
   */
  cardNumber?: string;
  /**
   * Payment variable symbol.
   */
  paymentVariableSymbol?: number;
  /**
   * Payment code.
   */
  paymentCode?: string;
  /**
   * Line number.
   */
  lineNumber?: number;
  /**
   * Station name.
   */
  currentStation?: string;
  /**
   * Bank card token.
   */
  token?: string;
  /**
   * Vehicle number.
   */
  vehicleNumber?: number;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
  /**
   * Filter by date within date range. Type: 'DATE'
   */
  date?: string;
};
export namespace VehicleTransactionQuery {
  /**
   * Transaction type. Example: CHECK_IN, CHECK_OUT...
   */
  export enum transactionType {
    PAPER_TICKET = 'PAPER_TICKET',
    ELECTRONIC_TICKET = 'ELECTRONIC_TICKET',
    CHECK_IN = 'CHECK_IN',
    CHECK_OUT = 'CHECK_OUT',
    CANCELLED_TICKET = 'CANCELLED_TICKET',
    GREEN_LIST_WALLET = 'GREEN_LIST_WALLET',
    GREEN_LIST_TICKET = 'GREEN_LIST_TICKET',
    WALLET_CHANGE = 'WALLET_CHANGE',
    CARD_BLOCKING = 'CARD_BLOCKING',
  }
  /**
   * Payment type. EXAMPLE: CASH, BANK_CARD...
   */
  export enum paymentType {
    CASH = 'CASH',
    BANK_CARD = 'BANK_CARD',
    ELECTRONIC_WALLET = 'ELECTRONIC_WALLET',
    INVOICE = 'INVOICE',
  }
  /**
   * Card type. Example: BANK_CARD, MAP_CARD
   */
  export enum cardType {
    BANK_CARD = 'BANK_CARD',
    MAP_CARD = 'MAP_CARD',
  }
}
