/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumDto } from './EnumDto';
export type PseudonymizedCustomerCardVm = {
  /**
   * Customer card identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Unique identifier of card.
   */
  cardNumber: string;
  /**
   * Chip number identifier. Is unique.
   */
  chipNumber: string;
  /**
   * Card valid to. Type: 'DATE'
   */
  validTo: string;
  /**
   * Card valid from. Type: 'DATE'
   */
  validFrom: string;
  note?: string;
  /**
   * Card state. Active, blocked, ...
   */
  state: EnumDto;
  /**
   * Card type. Smart or bank.
   */
  type: EnumDto;
  customerCardType: PseudonymizedCustomerCardVm.customerCardType;
  /**
   * Is this card a reclamation card
   */
  reclamation: boolean;
  /**
   * Contact me if card found
   */
  contactMeIfCardFound: boolean;
  /**
   * Data protection
   */
  dataProtection: string;
};
export namespace PseudonymizedCustomerCardVm {
  export enum customerCardType {
    PERSONAL = 'PERSONAL',
    PORTABLE = 'PORTABLE',
    RECLAMATION = 'RECLAMATION',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    BANK_CARD = 'BANK_CARD',
    WHITE_LIST_CARD = 'WHITE_LIST_CARD',
  }
}
