<template>
  <!--
    from file(s): zadosti.svg, zadosti_red.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65l0 863.78c0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65zm279.17 -438.11l180.02 0c74.25,0 134.99,60.74 134.99,134.99l0 180.02c0,74.25 -60.74,134.99 -134.99,134.99l-180.02 0c-74.25,0 -134.99,-60.74 -134.99,-134.99l0 -180.02c0,-74.25 60.74,-134.99 134.99,-134.99zm74.36 241.43c-3.07,3.41 -5.97,5.09 -8.7,5.06 -2.73,-0.02 -5.54,-1.33 -8.41,-3.91l-51.63 -46.49c-2.87,-2.58 -5.67,-3.89 -8.4,-3.91 -2.74,-0.03 -5.56,1.57 -8.46,4.8l-17.43 19.35c-2.91,3.23 -4.21,6.21 -3.89,8.92 0.31,2.72 1.9,5.37 4.77,7.95l90.89 81.84c2.87,2.58 5.68,3.89 8.41,3.92 2.73,0.03 5.55,-1.58 8.46,-4.8l128.72 -142.97c2.91,-3.22 4.21,-6.2 3.89,-8.91 -0.31,-2.72 -1.9,-5.37 -4.77,-7.95l-21.52 -19.37c-2.86,-2.58 -5.67,-3.89 -8.4,-3.92 -2.73,-0.03 -5.55,1.57 -8.46,4.8l-95.07 105.59zm-260.86 -387.1l398.54 0 0 47.07 -398.54 0 0 -47.07zm0 -90.35l553.01 0 0 47.06 -553.01 0 0 -47.06zm0 -88.25l398.54 0 0 47.07 -398.54 0 0 -47.07zm0 -88.24l553.01 0 0 47.06 -553.01 0 0 -47.06z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class CardApplicationListIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
