/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerAccountLinkQuery = {
  /**
   * Type of linked account.
   */
  customerAccountLinkType?: CustomerAccountLinkQuery.customerAccountLinkType;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
};
export namespace CustomerAccountLinkQuery {
  /**
   * Type of linked account.
   */
  export enum customerAccountLinkType {
    REGISTERED = 'REGISTERED',
    ASSIGNED = 'ASSIGNED',
    MANAGED = 'MANAGED',
  }
}
