<template>
  <v-card width="100%" class="fill-height py-2 px-4">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchCards"
      :row-click-callback="selectCard"
      :i18n-group-key="i18nGroupKey"
      disable-sort
      :data-cy="cy('table')"
    >
      <template #head-panel.start>
        <v-col
          v-if="accountRelatedAdultCustomers.length === 0 && telshopMigrationEnabled && !customerConnected"
          cols="12"
        >
          <v-alert border="top" type="warning" elevation="2" class="pt-6 mb-0" variant="text">
            {{ t('noAdultInAccountWarning') }}
          </v-alert>
        </v-col>
        <v-col v-if="mainAccountWasSet" cols="12">
          <v-alert border="top" type="info" elevation="2" class="pt-6 mb-0" variant="text">
            {{ t('mainAccountWasSet') }}
          </v-alert>
        </v-col>
      </template>
      <template #actions.start="{ item }">
        <v-btn
          v-if="isSetMainAccountAllowed(item)"
          :title="t('setMainAccount')"
          :data-cy="`set-main-account-button-${item.id}`"
          class="mx-1 elevation-4"
          color="success"
          icon
          size="x-small"
          @click.stop="setMainAccount(item)"
        >
          <v-icon>mdi-card-account-details-star-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="isAssignChildAllowed(item)"
          :title="t('setAssignedAccount')"
          :data-cy="`set-main-account-button-${item.id}`"
          class="mx-1 elevation-4"
          color="info"
          icon
          size="x-small"
          @click.stop="setAssignedAccount(item)"
        >
          <v-icon>mdi-account-child-circle</v-icon>
        </v-btn>
        <v-btn
          v-if="isEditPersonalInfoAllowed(item)"
          :title="t('editPersonalInfo')"
          :data-cy="`edit-personal-info-button-${item.id}`"
          class="mx-1 elevation-4"
          color="info"
          icon
          size="x-small"
          @click.stop="openEditPersonalInfoDialog(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="isMergeCustomersAllowed(item)"
          :title="t('mergeCustomers')"
          :data-cy="`merge-customers-button-${item.id}`"
          class="mx-1 elevation-4"
          color="info"
          icon
          size="x-small"
          @click.stop="openMergeCustomersDialog(item)"
        >
          <v-icon>mdi-merge</v-icon>
        </v-btn>
        <v-btn
          :title="t('selectCard')"
          :data-cy="`select-card-button-${item.id}`"
          class="mx-1 elevation-4"
          icon
          size="x-small"
          @click.stop="selectCard(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="item.cancelable"
          :title="t('cancelConfirmationTitle')"
          :data-cy="cy(`cancel-button-${item.id}`)"
          class="mx-1 elevation-4"
          color="error"
          icon
          size="x-small"
          @click.stop="cancelCardManagement(item)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template #no-data>
        {{ t('noDataText') }}
      </template>
    </RDPDataTable>
    <RDPConfirmDialog
      ref="cancelConfirmationDialog"
      :title="t('cancelConfirmationTitle')"
      :text="cancelConfirmationText"
      :confirm="confirmCancelCardManagement"
      :data-cy="cy('cancel-dialog')"
    >
    </RDPConfirmDialog>
    <RDPConfirmDialog
      ref="selectMainAccountConfirmationDialog"
      :title="t('setMainAccountConfirmationTitle')"
      :confirm="confirmSetMainAccount"
      :data-cy="cy('cancel-dialog')"
    >
      <strong>{{ setMainAccountConfirmationText }}</strong
      ><br /><br />
      {{ t('setMainAccountConfirmationInfo') }}
      <v-alert border="top" type="warning" variant="text" elevation="2" class="pt-6 mt-4">
        {{ t('setMainAccountConfirmationWarning') }}
      </v-alert>
    </RDPConfirmDialog>
    <RDPConfirmDialog
      ref="selectAssignedAccountConfirmationDialog"
      :title="t('setAssignedAccountConfirmationTitle')"
      :text="setAssignedAccountConfirmationText"
      :confirm="confirmSetAssignedAccount"
      :data-cy="cy('cancel-dialog')"
    >
    </RDPConfirmDialog>
    <PersonalInfoDialog ref="personalInfoDialog" @personal-info-saved="personalInfoSaved" />
    <MergeAccountsDialog ref="mergeAccountsDialog" @merge-accounts-saved="refreshPage" />
  </v-card>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import ComponentBase from '@/components/common/ComponentBase';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import { CustomerAccountLinkHeaderVm, CustomerAccountLinkQuery, CustomerHeaderVm } from '@/api';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import { formatName, formatNameReverse, isFakeCustomer } from '@/utils/customer.ts';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import CustomerModule from '@/store/modules/customer';
import Paths from '@/constants/Paths';
import { MIN_CUSTOMER_AGE } from '@/config/config';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import customerService from '@/services/api/customerService';
import accountService from '@/services/api/accountService';
import { MIGRATION_FLAGS } from '@T-system/common/src/api/constants';
import ToastModule from '@/store/modules/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { getApiError } from '@/utils/toast.ts';
import PersonalInfoDialog from '@/components/userAccount/dialogs/PersonalInfoDialog.vue';
import MergeAccountsDialog from '@/components/managedCard/MergeCustomersDialog.vue';
import { addDays, differenceInYears, subYears } from 'date-fns';

const DEFAULT_SORT_BY_COLUMN = 'name';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    PersonalInfoDialog,
    MergeAccountsDialog,
    RDPConfirmDialog,
    RDPDataTable,
  },
})
export default class ManagedCardListPage extends ComponentBase {
  @Ref()
  readonly dataTable!: RDPDataTableInterface;
  @Ref()
  readonly cancelConfirmationDialog!: RDPDialogInterface;
  @Ref()
  readonly selectMainAccountConfirmationDialog!: RDPDialogInterface;
  @Ref()
  readonly selectAssignedAccountConfirmationDialog!: RDPDialogInterface;
  @Ref()
  readonly personalInfoDialog!: RDPDialogInterface;
  @Ref()
  readonly mergeAccountsDialog!: RDPDialogInterface;

  linkToCancel: CustomerAccountLinkHeaderVm = {} as CustomerAccountLinkHeaderVm;
  linkToMainAccount: CustomerAccountLinkHeaderVm = {} as CustomerAccountLinkHeaderVm;
  linkToAssignedAccount: CustomerAccountLinkHeaderVm = {} as CustomerAccountLinkHeaderVm;
  accountRelatedAdultCustomers: CustomerHeaderVm[] = [];
  accountRelatedCustomers: CustomerHeaderVm[] = [];

  systemConfiguration = SystemConfigurationModule;
  customerModule = CustomerModule;
  onlyManagedRelations = false;
  mainAccountWasSet = false;

  MIN_BIRTH_DAY = addDays(subYears(new Date(), MIN_CUSTOMER_AGE), 1).toISOString().substring(0, 10);

  filter: CustomerAccountLinkQuery = {
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
    customerAccountLinkType: CustomerAccountLinkQuery.customerAccountLinkType.MANAGED,
  };

  headers = [
    {
      text: 'name',
      transform: (link: CustomerAccountLinkHeaderVm) => formatNameReverse(link.customer),
    },
    { text: 'customer.birthDay', type: DATA_TABLE_HEADER_TYPES.DATE },
    'activeCardNumber',
  ];

  dataCy = 'managed-card-list';
  i18nGroupKey = 'managedCardList';

  async mounted() {
    this.accountRelatedCustomers = await accountService.getAccountRelatedCustomerList({});
    this.accountRelatedAdultCustomers = this.accountRelatedCustomers.filter(customer => {
      const customerYears = differenceInYears(new Date(), customer.birthDay);
      return customerYears >= MIN_CUSTOMER_AGE;
    });
  }

  async created() {
    await this.initOnlyManagedRelations();
  }

  async initOnlyManagedRelations() {
    if (this.systemConfiguration.isTelshopMigrationAllowed) {
      this.onlyManagedRelations = Boolean(await customerService.hasOnlyManagedRelations());
    }
  }

  searchCards(query: CustomerAccountLinkQuery) {
    return customerAccountLinkService.getLinkList(query);
  }

  async selectCard(link: CustomerAccountLinkHeaderVm) {
    CustomerModule.setManagedAccountCustomer(link);
    await this.$router.push(Paths.USER_LINKED_ACCOUNT);
  }

  get cancelConfirmationText() {
    return this.t('cancelConfirmationText', {
      name: formatName(this.linkToCancel.customer),
    });
  }

  get setMainAccountConfirmationText() {
    return this.t('setMainAccountConfirmationText', {
      name: formatName(this.linkToMainAccount.customer),
    });
  }

  get setAssignedAccountConfirmationText() {
    return this.t('setAssignedAccountConfirmationText', {
      name: formatName(this.linkToAssignedAccount.customer),
    });
  }

  get telshopMigrationEnabled() {
    return this.systemConfiguration.configuration.migrationFlags.includes(MIGRATION_FLAGS.TELSHOP);
  }

  get customerConnected() {
    return this.customerModule.customerConnected;
  }

  isSetMainAccountAllowed(link: CustomerAccountLinkHeaderVm) {
    return (
      this.systemConfiguration.isTelshopMigrationAllowed &&
      this.onlyManagedRelations &&
      !CustomerModule.hasLoggedInAccountCustomer &&
      link.customer.birthDay <= this.MIN_BIRTH_DAY
    );
  }

  isAssignChildAllowed(link: CustomerAccountLinkHeaderVm) {
    return (
      this.systemConfiguration.isTelshopMigrationAllowed &&
      CustomerModule.hasLoggedInAccountCustomer &&
      link.customer.birthDay >= this.MIN_BIRTH_DAY
    );
  }

  isEditPersonalInfoAllowed(link: CustomerAccountLinkHeaderVm) {
    return (
      this.systemConfiguration.isSynchronizationWithExternalAllowed &&
      link.customer.external &&
      isFakeCustomer(link.customer)
    );
  }

  openEditPersonalInfoDialog(link: CustomerAccountLinkHeaderVm) {
    this.personalInfoDialog.openDialog(link.customer.id);
  }

  isMergeCustomersAllowed(link: CustomerAccountLinkHeaderVm) {
    return (
      this.systemConfiguration.isSynchronizationWithExternalAllowed &&
      link.customer.external &&
      isFakeCustomer(link.customer) &&
      this.accountRelatedCustomers.length > 1
    );
  }

  openMergeCustomersDialog(link: CustomerAccountLinkHeaderVm) {
    this.mergeAccountsDialog.openDialog(link.customer.id);
  }

  async personalInfoSaved() {
    if (!this.customerModule.registeredCustomer.id) {
      const customer = await customerService.getLoggedCustomer();
      if (customer) {
        this.customerModule.setCustomer(customer);
      }

      this.mainAccountWasSet = true;
    } else {
      this.mainAccountWasSet = false;
    }

    await this.refreshPage();
  }

  cancelCardManagement(link: CustomerAccountLinkHeaderVm) {
    this.linkToCancel = link;
    this.cancelConfirmationDialog.openDialog();
  }

  setMainAccount(link: CustomerAccountLinkHeaderVm) {
    this.linkToMainAccount = link;
    this.selectMainAccountConfirmationDialog.openDialog();
  }

  setAssignedAccount(link: CustomerAccountLinkHeaderVm) {
    this.linkToAssignedAccount = link;
    this.selectAssignedAccountConfirmationDialog.openDialog();
  }

  async confirmCancelCardManagement() {
    try {
      await customerAccountLinkService.cancelManagedCustomerLink(this.linkToCancel.id);
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.cancelCardManagementSuccess`),
      });
      await this.refreshPage();
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, this.i18nGroupKey, 'cancelCardManagementFailed'),
      });
    }
  }

  async confirmSetMainAccount() {
    try {
      await customerAccountLinkService.setMainAccountRequest(this.linkToMainAccount.id);
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.setMainAccountSuccess`),
      });
      const customer = await customerService.getLoggedCustomer();
      if (customer) {
        CustomerModule.setCustomer(customer);
      }
      await this.$router.push(Paths.USER_ACCOUNT);
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, this.i18nGroupKey, 'setMainAccountFailed'),
      });
    }
  }

  async confirmSetAssignedAccount() {
    try {
      await customerAccountLinkService.setAssignedAccountRequest(this.linkToAssignedAccount.id);
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.setAssignedAccountSuccess`),
      });
      await this.refreshPage();
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, this.i18nGroupKey, 'setAssignedAccountFailed'),
      });
    }
  }

  async refreshPage() {
    await this.initOnlyManagedRelations();
    this.dataTable.refresh();
  }
}
</script>
