import { EnumDto, ZoneService } from '@/api';
import { getDefaultAcceptLanguage } from '@/utils/api.ts';

export default {
  getZonePublicList: async (): Promise<EnumDto[]> => {
    const response = await ZoneService.zoneControllerGetAll(getDefaultAcceptLanguage());

    return response.data;
  },
};
