/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EvaluateReclamationCommand = {
  statement: string;
  /**
   * Evaluation type
   */
  evaluationType: EvaluateReclamationCommand.evaluationType;
  /**
   * Finalization type
   */
  finalizationType?: EvaluateReclamationCommand.finalizationType;
  /**
   * Amount to customer payout.
   */
  price?: number;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace EvaluateReclamationCommand {
  /**
   * Evaluation type
   */
  export enum evaluationType {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
  }
  /**
   * Finalization type
   */
  export enum finalizationType {
    COUNTER_PAY_OFF = 'COUNTER_PAY_OFF',
    INVOICE_PAY_OFF = 'INVOICE_PAY_OFF',
    CARD_UPLOAD = 'CARD_UPLOAD',
    OTHER = 'OTHER',
  }
}
