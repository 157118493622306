export interface ApiErrorInterface {
  data?: {
    name?: string;
    httpCode?: number;
    errors?: string[];
  };
}

export class ApiClientError extends Error {
  constructor(
    public message: string,
    public data: object,
  ) {
    super();
  }
}

export default ApiClientError;
