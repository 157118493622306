<template>
  <div class="customer-profile-cmp">
    <v-row>
      <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="py-0">
        <v-select
          v-model="customerProfile.profile.id"
          :class="{ 'disable-events': disabled || profilesAutofilled }"
          :items="customerProfilesTranslated"
          :rules="validations.profile"
          :label="t('profile')"
          :no-data-text="$t('common.noData')"
          :data-cy="cy('profile')"
          variant="underlined"
          :item-props="item => ({ disabled: item.disabled })"
        />
      </v-col>
      <v-col v-if="requireConfirmationCheck" :cols="$vuetify.display.mobile ? 12 : 6" class="d-flex align-end py-0">
        <v-checkbox
          v-model="profileConfirmed"
          class="disable-events"
          :label="confirmationLabel"
          :data-cy="cy('confirmation')"
          :rules="validations.confirmation"
          @update:model-value="confirmationChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="py-0">
        <RDPDatePicker
          v-model="customerProfile.validFrom"
          disabled
          :label="t('validFrom')"
          :required="true"
          :data-cy="cy('validFrom')"
          @change="validateProfile()"
        />
      </v-col>
      <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="d-flex align-center py-0">
        <RDPDatePicker
          v-if="isProfileValidToDisplayed()"
          v-model="customerProfile.validTo"
          disabled
          :label="t('validTo')"
          :required="true"
          :min="minProfileValidTo"
          :data-cy="cy('validTo')"
          @change="validateProfile()"
        />
        <v-text-field
          v-else
          class="disable-events pb-0"
          :data-cy="cy('validTo')"
          prepend-icon="mdi-calendar"
          :label="t('validTo')"
          :model-value="t('validToCardValidity')"
          variant="underlined"
        />
      </v-col>
    </v-row>

    <div style="padding-left: 45px">
      <div v-if="profileValidityErrorMessage" class="rdp-error-message v-messages__message mt-0">
        {{ profileValidityErrorMessage }}
      </div>
      <div v-if="profileInvalidInDateRangeErrorMessage" class="rdp-error-message v-messages__message mt-0">
        {{ profileInvalidInDateRangeErrorMessage }}
      </div>
      <div
        v-if="cardApplicationModule.profilesAreInCollision && !isProfileOne"
        class="rdp-error-message v-messages__message mt-0"
      >
        {{ t('profilesInCollisionErrorMessage') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { checkedValidator, requiredValidator } from '@/utils/validators';
import RDPDatePicker from '@/components/common/RDPDatePicker/RDPDatePicker.vue';
import CustomerProfileModule from '@/store/modules/customerProfile';
import { CardApplicationProfileDto, CustomerProfileDto, EnumsDto } from '@/api';
import { computeValidTo, requireConfirmationCheck, shouldSetValidTo } from '@/utils/customerProfileValidToDefault';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import {
  initializeCPValidFrom,
  isCustomerProfileValidityValid,
  isProfileValidInDateRange,
  profilesAreInCollision,
  REGULAR_PROFILE_NUMBER,
} from '@/utils/customerProfile';
import CardApplicationModule from '@/store/modules/cardApplication';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from '@/utils/dateTime';
import isDefined from '@common/utils/isDefined';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import { format } from 'date-fns';

export interface CustomerProfileComponentInterface {
  showProfileTwo: () => void;
  hideProfileTwo: () => void;
  selectedProfile: CustomerProfileDto;
  clearProfile: () => void;
  valid: () => boolean;
  isicCheckInProgress: boolean;
}

@Component({
  components: {
    RDPDatePicker,
  },
  emits: ['addProfile', 'removeProfile', 'changeProfile'],
})
export default class SimpleProfileComponent extends Vue {
  @Prop({ required: true, type: Object })
  customerProfile!: CardApplicationProfileDto;

  @Prop({ required: false, type: String, default: 'cardApplication.cardData' })
  readonly i18nGroupKey!: 'cardApplication.cardData';

  @Prop({ required: true, type: String, default: 'card-data' })
  readonly dataCy!: 'card-data';

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isProfileOne!: boolean;

  @Prop({ required: false, type: Boolean })
  readonly isVirtualCard!: boolean;

  @Prop({ required: false, type: Boolean })
  readonly cardTypeAllowedForIsic!: boolean;

  customerProfileModule = CustomerProfileModule;
  cardApplicationModule = CardApplicationModule;

  showCustomerProfileTwo = false;

  profileValidityErrorMessage = '';
  profileInvalidInDateRangeErrorMessage = '';
  profileConfirmed = false;
  minProfileValidTo: string | null = null;

  validations = {
    profile: [(v: string) => requiredValidator(v)],
    confirmation: [(v: boolean) => checkedValidator(v)],
  };

  created() {
    this.profileConfirmed = !!this.customerProfile.confirmationChecked;
    this.minProfileValidTo = this.customerProfile.validFrom;
  }

  get profilesAutofilled() {
    return this.cardApplicationModule.customerProfileAutoUpdated;
  }

  get selectedProfile() {
    return this.customerProfileModule.customerProfiles.find(
      customerProfile => customerProfile.id === this.customerProfile.profile.id,
    );
  }

  isProfileValidToDisplayed() {
    if (this.customerProfile.validTo) {
      return true;
    }
    return shouldSetValidTo(this.selectedProfile);
  }

  get requireConfirmationCheck() {
    return requireConfirmationCheck(this.selectedProfile);
  }

  @Watch('customerProfile.profile.id')
  async customerProfileChanged(newVal: string, oldVal: string) {
    if (oldVal !== '') {
      this.profileConfirmed = false;
    } else {
      this.profileConfirmed = !!this.customerProfile.confirmationChecked;
    }

    const selectedProfile = this.selectedProfile;
    const customerVerified = this.cardApplicationModule.isCustomerVerified;

    if (
      (this.profileIdIsChangingOrValidFromIsNotSet(oldVal) ||
        this.virtualCardAndCustomerNotVerified(customerVerified)) &&
      selectedProfile &&
      this.cardApplicationModule.customerDateBirthday
    ) {
      this.customerProfile.validFrom = initializeCPValidFrom(
        selectedProfile,
        this.cardApplicationModule.customerDateBirthday,
        customerVerified,
      );
    }

    // Set default when profile id is changing or profile validity is not set from previous card.
    if (oldVal !== '' || !this.customerProfile.validTo) {
      this.computeProfileValidTo();
    }

    this.profileValidityChanged();
    this.checkProfileValidInDateRange();
    if (this.showCustomerProfileTwo) {
      this.checkProfilesInCollision();
    }
  }

  showProfileTwo() {
    this.showCustomerProfileTwo = true;
  }

  hideProfileTwo() {
    this.showCustomerProfileTwo = false;
  }

  @Watch('customerProfile.validFrom')
  customerProfileValidFromChanged() {
    const selectedProfile = this.selectedProfile;
    if (
      selectedProfile?.validToDefault?.name ===
        EnumsDto.customerProfileValidToDefaults._365_DAYS_AFTER_CONFIRMATION_DELIVERY ||
      selectedProfile?.validToDefault?.name ===
        EnumsDto.customerProfileValidToDefaults._430_DAYS_AFTER_CONFIRMATION_DELIVERY
    ) {
      this.computeProfileValidTo();
    }
  }

  computeProfileValidTo() {
    this.customerProfile.validTo = computeValidTo(
      this.selectedProfile,
      this.cardApplicationModule.customerDateBirthday,
      new Date(this.customerProfile.validFrom).getFullYear(),
      this.customerProfile.validFrom,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any) {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }

  get customerProfilesTranslated(): VuetifySelectOptionsVm[] {
    return this.customerProfileModule.customerProfiles.map(
      (customerProfile: CustomerProfileDto): VuetifySelectOptionsVm => ({
        title: getCustomerProfileDisplayName(customerProfile.id),
        value: customerProfile.id,
        disabled: customerProfile.profileNumber !== REGULAR_PROFILE_NUMBER,
      }),
    );
  }

  get confirmationLabel() {
    return `${this.t('confirmation')}.${
      this.customerProfile.confirmationChecked
        ? ` (${format(this.customerProfile.confirmationChecked, DATE_PICKER_FORMAT)})`
        : ''
    }`;
  }

  profileIdIsChangingOrValidFromIsNotSet = (oldVal: string) => {
    return isDefined(oldVal) || !isDefined(this.customerProfile?.validFrom as string);
  };

  virtualCardAndCustomerNotVerified = (customerVerified: boolean) => {
    return this.isVirtualCard && !customerVerified;
  };

  confirmationChanged(value: boolean | null) {
    this.customerProfile.confirmationChecked = value ? format(new Date(), DATE_FORMAT) : '';
  }

  validateProfile() {
    this.profileValidityChanged();
    this.checkProfileValidInDateRange();
    if (this.showCustomerProfileTwo) {
      this.checkProfilesInCollision();
    }
  }

  profileValidityChanged() {
    this.profileValidityErrorMessage = isCustomerProfileValidityValid(
      this.customerProfile.validFrom,
      this.customerProfile.validTo,
    );

    this.minProfileValidTo = this.customerProfile.validFrom;
  }

  checkProfileValidInDateRange() {
    this.profileInvalidInDateRangeErrorMessage = isProfileValidInDateRange(
      this.selectedProfile,
      this.customerProfile.validFrom,
      this.customerProfile.validTo,
    );
  }

  checkProfilesInCollision() {
    if (
      this.cardApplicationModule.cardData.profileOne.validFrom &&
      this.cardApplicationModule.cardData.profileTwo?.validFrom
    ) {
      const collision = profilesAreInCollision(
        this.cardApplicationModule.cardData.profileOne.validFrom,
        this.cardApplicationModule.cardData.profileTwo.validFrom,
        this.cardApplicationModule.cardData.profileOne?.validTo,
        this.cardApplicationModule.cardData.profileTwo?.validTo,
      );
      this.cardApplicationModule.setProfilesInCollision(collision);
    }
  }

  valid() {
    return this.profileInvalidInDateRangeErrorMessage === '' && this.profileValidityErrorMessage === '';
  }
}
</script>

<style lang="scss">
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
