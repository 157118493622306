<template>
  <LeftMenu v-if="$vuetify.display.mobile" ref="leftMobileMenu" />
  <v-app-bar
    elevation="2"
    color="systemPrimary"
    theme="dark"
    location="top"
    :class="$vuetify.display.mobile ? 'px-2' : 'px-4'"
  >
    <v-row class="align-center overflow-hidden">
      <div v-if="$vuetify.display.mobile || systemConfiguration.isPersistSideMenuAllowed" class="ml-2">
        <v-btn icon @click="toggleMenu()">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <v-col v-if="!$vuetify.display.mobile" cols="auto" style="border-right: 2px solid white" class="text-center pa-0">
        <img class="app-logo" :src="logoPath" :alt="logoAlt" />
      </v-col>
      <v-col
        cols="auto"
        class="toolbar-parent-flex"
        :style="!$vuetify.display.mobile ? { marginLeft: '16px' } : { paddingLeft: '0px' }"
      >
        <v-toolbar-title class="text-uppercase font-weight-bold">
          {{ toolbarTitle }}
        </v-toolbar-title>
      </v-col>
    </v-row>
    <v-spacer />
    <div data-cy="app-layout-selected-customer" class="d-flex align-center">
      <template v-if="!$vuetify.display.mobile">
        <v-icon class="mr-1" icon="$userAccountTopIcon" />
        {{ authModule.loggedInAccountDisplayName }}
      </template>
      <div
        :class="{
          'mr-2': $vuetify.display.mobile,
          'mx-4': !$vuetify.display.mobile,
        }"
      >
        <ShoppingCartIcon v-if="customerModule.currentlySelectedCustomerId" />
      </div>
      <LogoutButton icon tooltip-bottom />
    </div>
  </v-app-bar>
  <v-main>
    <router-view v-if="!loadingCustomer" />
  </v-main>
  <v-footer app theme="dark">
    <v-row no-gutters class="ma-0 pl-4" justify="space-between">
      <v-col cols="12" sm="auto">
        <FooterLinks dark />
      </v-col>
      <v-col cols="12" sm="auto" align-self="center">
        <div :class="$vuetify.display.mobile ? 'text-left' : 'text-right'">
          {{ $t('footer.version') }} {{ version }}
        </div>
      </v-col>
    </v-row>
  </v-footer>
  <LeftMenu v-if="!$vuetify.display.mobile" ref="leftMenu" />
  <CardApplicationStepperDialog ref="cardStepperDialog" />
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import LeftMenu from '../components/layout/LeftMenu.vue';
import { version } from '../../package.json';
import AuthModule from '@/store/modules/auth';
import CustomerModule from '@/store/modules/customer';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import LogoutButton from '@/components/layout/LogoutButton.vue';
import ShoppingCartIcon from '@/components/shoppingCartIcon/ShoppingCartIcon.vue';
import Paths from '@/constants/Paths';
import customerService from '@/services/api/customerService';
import CardApplicationStepperDialog, {
  CardApplicationStepperDialogInterface,
} from '@/components/cardApplication/CardApplicationStepperDialog.vue';
import UserAccountModule from '@/store/modules/userAccount';
import { LOGO_ALT, LOGO_PATH } from '@/constants/Logo';
import SystemConfiguration from '@/store/modules/systemConfiguration.ts';

@Component({
  components: {
    LeftMenu,
    FooterLinks,
    LogoutButton,
    ShoppingCartIcon,
    CardApplicationStepperDialog,
  },
})
export default class AppLayout extends Vue {
  version: string = version;

  @Ref()
  readonly leftMobileMenu!: LeftMenu;

  @Ref()
  readonly leftMenu!: LeftMenu;

  @Ref()
  readonly cardStepperDialog!: CardApplicationStepperDialogInterface;

  authModule = AuthModule;
  customerModule = CustomerModule;
  loadingCustomer = true;
  userAccountModule = UserAccountModule;
  systemConfiguration = SystemConfiguration;

  get logoAlt() {
    return LOGO_ALT;
  }

  get logoPath() {
    return LOGO_PATH;
  }

  get toolbarTitle() {
    if (this.$route.path.includes(Paths.USER_LINKED_ACCOUNT)) {
      return `${this.linkedCustomerTitle} - ${this.linkedCustomerName}`;
    }
    return this.$t(this.$route.meta.titleI18nKey as string);
  }

  get linkedCustomerName() {
    return `${this.customerModule.linkedCustomer?.firstName} ${this.customerModule.linkedCustomer?.lastName}`;
  }

  get linkedCustomerTitle() {
    if (this.customerModule.isAssignedCustomerSelected) {
      return this.$t('assignedAccount.title');
    }
    if (this.customerModule.isManagedCustomerSelected) {
      return this.$t('managedAccount.title');
    }
    return '';
  }

  async created() {
    await this.checkHasActiveCardApplication();
    this.loadingCustomer = true;
    const customer = await customerService.getLoggedCustomer();
    this.loadingCustomer = false;
    if (customer) {
      this.customerModule.setCustomer(customer);
    }
  }

  async checkHasActiveCardApplication() {
    if (this.customerModule.currentlySelectedCustomerId) {
      const active = await customerService.hasActiveCardApplication(this.customerModule.currentlySelectedCustomerId);
      this.userAccountModule.setCardApplicationActive(active);

      const canBeCreated = await customerService.canVirtualCardApplicationBeCreated(
        this.customerModule.currentlySelectedCustomerId,
      );
      this.userAccountModule.setVirtualCardApplicationCanBeCreated(canBeCreated);
    } else {
      this.userAccountModule.setCardApplicationActive(false);
      this.userAccountModule.setVirtualCardApplicationCanBeCreated(true);
    }
  }

  toggleMenu() {
    if (this.$vuetify.display.mobile) {
      this.leftMobileMenu.toggleMenu();
    } else {
      this.leftMenu.toggleMenu();
    }
  }
}
</script>

<style>
.app-logo {
  margin-left: 12px;
  margin-right: 16px;
  max-height: 54px;
  width: auto;
  height: auto;
  vertical-align: middle;
}

.toolbar-parent-flex {
  flex: 1;
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;

  .v-toolbar-title__placeholder {
    white-space: normal !important;
  }
}
</style>
