export const HAULIERS = {
  DPMP: 'dpmp',
  NITRA: 'nitra',
  TRENCIN: 'trencin',
  RUZOMBEROK: 'ruzomberok',
  DPB: 'dpb',
  POLAND: 'poland',
  VISEUDESUS: 'viseudesus',
  DPMO: 'dpmo',
  DPMLB: 'dpmlb',
};

export const TABLE_TAB_FILTER_STYLES = 'margin-top: -95px; margin-right: -15px;';
