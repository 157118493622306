/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarrierTypesListResponse } from '../models/CarrierTypesListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CarrierTypesService {
  /**
   * Get possible card carrier types
   * @returns CarrierTypesListResponse
   * @throws ApiError
   */
  public static carrierTypesControllerGetAll(): CancelablePromise<CarrierTypesListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/carrier-types',
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
}
