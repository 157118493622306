/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiErrorResponse } from '../models/ApiErrorResponse';
import type { CardApplicationAttachmentResponse } from '../models/CardApplicationAttachmentResponse';
import type { CardApplicationResponse } from '../models/CardApplicationResponse';
import type { CreateBankCardApplicationCommand } from '../models/CreateBankCardApplicationCommand';
import type { CreateCardApplicationCommand } from '../models/CreateCardApplicationCommand';
import type { CreateVirtualCardApplicationCommand } from '../models/CreateVirtualCardApplicationCommand';
import type { RejectCardApplicationCommand } from '../models/RejectCardApplicationCommand';
import type { UploadAttachmentCommand } from '../models/UploadAttachmentCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopCardApplicationService {
  /**
   * Get card application.
   * @returns CardApplicationResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerGetOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<CardApplicationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/card-applications/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Check if card can be send by post.
   * @returns ApiErrorResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerVerifyIfCardCanBeSendByPost({
    originId,
    draftId,
  }: {
    originId: string;
    draftId?: string;
  }): CancelablePromise<ApiErrorResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/card-applications-delivery-check',
      query: {
        draftId: draftId,
        originId: originId,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create card application.
   * If an origin customer is passed to the application, Bad Request error `InvalidCustomer` is returned.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CUSTOMER** - Customer does not exist, belongs to another account, is a draft or origin.<br>
   * + **INVALID_CUSTOMER_PROFILE** - Customer profile does not exist, is not valid for the customer, is impersonal, is not primary or has invalid properties combination.<br>
   * + **INVALID_DELIVERY_OPTION** - At least one type of delivery must be set<br>
   * + **INVALID_BRANCH_OFFICE** - Branch office does not exist.<br>
   * + **INVALID_PROFILE_VALID_FROM** - Profile ValidFrom is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_PROFILE_VALID_TO** - Profile ValidTo is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.
   * @returns CardApplicationResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerCreate({
    requestBody,
  }: {
    /**
     * CreateCardApplicationCommand
     */
    requestBody?: CreateCardApplicationCommand;
  }): CancelablePromise<CardApplicationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/card-applications',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create a virtual card application.
   * If an origin customer is passed to the application, Bad Request error `InvalidCustomer` is returned.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CUSTOMER** - Customer does not exist, belongs to another account, is a draft or origin.<br>
   * + **INVALID_CUSTOMER_PROFILE** - Customer profile does not exist, is not valid for the customer, is impersonal, is not primary or has invalid properties combination.<br>
   * + **INVALID_PROFILE_VALID_FROM** - Profile ValidFrom is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_PROFILE_VALID_TO** - Profile ValidTo is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.<br>
   * + **CUSTOMER_ALREADY_HAS_ACTIVE_CARD_APPLICATION** - Customer already has active card application.
   * @returns CardApplicationResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerCreateVirtualCardApplication({
    requestBody,
  }: {
    /**
     * CreateVirtualCardApplicationCommand
     */
    requestBody?: CreateVirtualCardApplicationCommand;
  }): CancelablePromise<CardApplicationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/card-applications-virtual',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create a bank card application.
   * If an origin customer is passed to the application, Bad Request error `InvalidCustomer` is returned.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CUSTOMER** - Customer does not exist, belongs to another account, is a draft or origin.<br>
   * + **INVALID_CUSTOMER_PROFILE** - Customer profile does not exist, is not valid for the customer, is impersonal, is not primary or has invalid properties combination.<br>
   * + **INVALID_PROFILE_VALID_FROM** - Profile ValidFrom is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_PROFILE_VALID_TO** - Profile ValidTo is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.<br>
   * + **CUSTOMER_ALREADY_HAS_ACTIVE_CARD_APPLICATION** - Customer already has active card application.
   * @returns CardApplicationResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerCreateBankCardApplication({
    requestBody,
  }: {
    /**
     * CreateBankCardApplicationCommand
     */
    requestBody?: CreateBankCardApplicationCommand;
  }): CancelablePromise<CardApplicationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/card-applications-bank',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Reject a card application.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **CARD_APPLICATION_NOT_MODIFIABLE** - Card application cannot be changed, because it's already processed.
   * @returns CardApplicationResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerReject({
    id,
    requestBody,
  }: {
    id: string;
    /**
     * RejectCardApplicationCommand
     */
    requestBody?: RejectCardApplicationCommand;
  }): CancelablePromise<CardApplicationResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/card-applications/{id}/reject',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Add attachment to card application.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CUSTOMER** - Customer does not exist, belongs to another account, is a draft or origin.<br>
   * + **INVALID_CUSTOMER_PROFILE** - Customer profile does not exist, is not valid for the customer, is impersonal, is not primary or has invalid properties combination.<br>
   * + **INVALID_PROFILE_VALID_FROM** - Profile ValidFrom is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_PROFILE_VALID_TO** - Profile ValidTo is required or is not in card application validFrom-validTo range.<br>
   * + **INVALID_VALID_TO** - Valid to must be bigger than valid from or is in the past.<br>
   * + **CUSTOMER_ALREADY_HAS_ACTIVE_CARD_APPLICATION** - Customer already has active card application.
   * @returns CardApplicationAttachmentResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerUploadAttachment({
    id,
    requestBody,
  }: {
    id: string;
    /**
     * UploadAttachmentCommand
     */
    requestBody?: UploadAttachmentCommand;
  }): CancelablePromise<CardApplicationAttachmentResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/card-applications/{id}/attachment',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get last card application.
   * @returns CardApplicationResponse
   * @throws ApiError
   */
  public static eshopCardApplicationControllerGetLast(): CancelablePromise<CardApplicationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/card-applications/last',
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
