<template>
  <div class="align-center justify-center" :data-cy="dataCy">
    <v-form ref="form" validate-on="lazy" data-cy="cardApplication-custodian-dialog">
      <v-card-text class="card-text">
        <v-row>
          <v-col class="pt-0">
            <v-text-field
              v-model="custodian.firstName"
              :rules="validations.firstName"
              :label="$t(`${i18KeyGroup}.firstName`)"
              :data-cy="`${dataCy}-firstName`"
              :class="{ 'disable-events': isFormDisabled }"
              variant="underlined"
            />
            <v-text-field
              v-model="custodian.lastName"
              :rules="validations.lastName"
              :label="$t(`${i18KeyGroup}.lastName`)"
              :data-cy="`${dataCy}-lastName`"
              :class="{ 'disable-events': isFormDisabled }"
              variant="underlined"
            />
            <v-text-field
              v-model="custodian.birthDay"
              :placeholder="dateFormatPlaceholder"
              :label="$t(`${i18KeyGroup}.birthDay`)"
              :rules="validations.birthDay"
              clearable
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-birthDay`"
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="py-1">
            <v-text-field
              v-model="custodian.address.street"
              :rules="validations.addressStreet"
              :label="$t(`${i18KeyGroup}.addressStreet`)"
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-addressStreet`"
              variant="underlined"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="py-1">
            <v-text-field
              v-model="custodian.address.streetNumber"
              :rules="validations.addressStreetNumber"
              :label="$t(`${i18KeyGroup}.addressStreetNumber`)"
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-addressStreetNumber`"
              variant="underlined"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="py-1">
            <v-text-field
              v-model="custodian.address.city"
              :rules="validations.addressCity"
              :label="$t(`${i18KeyGroup}.addressCity`)"
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-addressCity`"
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="py-1">
            <v-text-field
              v-model="custodian.address.zip"
              :rules="validations.addressZipCode"
              :label="$t(`${i18KeyGroup}.addressZipCode`)"
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-addressZipCode`"
              variant="underlined"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="py-1">
            <v-text-field
              v-model="custodian.email"
              :rules="validations.email"
              :label="$t(`${i18KeyGroup}.email`)"
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-email`"
              variant="underlined"
              @change="emailOrPhoneChanged"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="py-1">
            <v-text-field
              v-model="custodian.phoneNumber"
              :rules="validations.phone"
              :label="$t(`${i18KeyGroup}.phone`)"
              :class="{ 'disable-events': isFormDisabled }"
              :data-cy="`${dataCy}-phone`"
              variant="underlined"
              @keypress="allowPhoneNumber"
              @change="emailOrPhoneChanged"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-facing-decorator';
import {
  dateValidator,
  emailValidator,
  maxLengthValidator,
  requiredValidator,
  requiredValidatorIf,
} from '@/utils/validators';
import { CardApplicationDto, CustomerDetailVm, CustomerDto } from '@/api';
import cardApplicationModule from '@/store/modules/cardApplication';
import KeyboardEventHelper from '@common/utils/keyboardEventHelper';
import { DATE_BIRTHDAY_FORMAT, MIN_DAY_OF_BIRTHDAY, toMomentDateFormat } from '@/utils/dateTime';
import { FormInterface } from '@/components/common/FormInterface';
import debounce from 'lodash/debounce';
import Table from '@/constants/Table';
import { CustomerUniqueCheckType } from '@/components/cardApplication/customerUniqueCheckType';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { ValidationInterface } from '@/components/common/ValidationInterface';
import { subYears } from 'date-fns';

@Component({
  expose: ['form', 'validate', 'stepActive'],
})
export default class CustodianForm extends Vue implements ValidationInterface {
  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly stepActive!: boolean;

  @Prop({ required: false, type: Function, default: undefined })
  readonly checkIfUserIsUnique!: (dto: CustomerDto, type: CustomerUniqueCheckType) => void;

  @Ref()
  readonly form!: FormInterface;

  i18KeyGroup = 'cardApplication.custodianData';
  dataCy = 'cardApplication-custodian';

  cardApplicationModule = cardApplicationModule;
  systemConfigurationModule = SystemConfigurationModule;
  dateFormat = DATE_BIRTHDAY_FORMAT;
  dateFormatPlaceholder = toMomentDateFormat(this.dateFormat);

  allowPhoneNumber = KeyboardEventHelper.allowPhoneNumber;

  validations = {
    firstName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    lastName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    birthDay: [
      (v: string) => requiredValidator(v),
      (v: string) =>
        dateValidator(
          v,
          this.dateFormat,
          MIN_DAY_OF_BIRTHDAY,
          this.getMaxDayOfBirthDay().toISOString().substring(0, 10),
        ),
    ],
    addressStreet: [
      (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetRequired),
      (v: string) => maxLengthValidator(v, 50),
    ],
    addressStreetNumber: [
      (v: string) =>
        requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetNumberRequired),
      (v: string) => maxLengthValidator(v, 15),
    ],
    addressCity: [
      (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressCityRequired),
      (v: string) => maxLengthValidator(v, 50),
    ],
    addressZipCode: [
      (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressZipCodeRequired),
      (v: string) => maxLengthValidator(v, 6),
    ],
    email: [(v: string) => emailValidator(v), (v: string) => maxLengthValidator(v, 50)],
    phone: [(v: string) => maxLengthValidator(v, 20)],
  };

  set custodian(data: CustomerDto) {
    this.cardApplicationModule.setCustodianData(data);
  }

  get custodian(): CustomerDto {
    return this.cardApplicationModule.custodianData;
  }

  get customer(): CustomerDetailVm {
    return this.cardApplicationModule.customerData;
  }

  get cardData(): CardApplicationDto {
    return this.cardApplicationModule.cardData;
  }

  getMaxDayOfBirthDay() {
    return subYears(new Date(), 18);
  }

  emailOrPhoneChanged() {
    this.cardData.contactMeIfCardFound =
      !!this.customer.email || !!this.customer.phoneNumber || !!this.custodian.email || !!this.custodian.phoneNumber;
  }

  get isFormDisabled() {
    return this.disabled || this.custodian.id !== '';
  }

  skip() {
    this.cardApplicationModule.resetCustodian();
    this.form.resetValidation();
    return true;
  }

  @Watch('custodian.birthDay')
  async birthDayChanged() {
    await this.checkIfIsUniqueDebounced();
  }

  @Watch('custodian.firstName')
  firstNameChanged() {
    this.checkIfIsUniqueDebounced();
  }

  @Watch('custodian.lastName')
  lastNameChanged() {
    this.checkIfIsUniqueDebounced();
  }

  checkIfIsUnique() {
    if (this.custodian.id === '') {
      this.checkIfUserIsUnique(this.custodian, CustomerUniqueCheckType.Custodian);
    }
  }

  checkIfIsUniqueDebounced = debounce(this.checkIfIsUnique, Table.DEFAULT_TABLE_DEBOUNCE_TIME);

  async validate() {
    if (this.disabled) {
      return true; // skip validation
    }

    return (await this.form.validate()).valid;
  }
}
</script>

<style scoped lang="scss">
.card-text {
  height: 550px;
  overflow: auto;
}
</style>
