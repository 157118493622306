/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchOfficeListResponse } from '../models/BranchOfficeListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BranchOfficeService {
  /**
   * Get all branch offices
   * @returns BranchOfficeListResponse
   * @throws ApiError
   */
  public static branchOfficeControllerGetAll({
    cardDeliveryAllowed,
    timezoneOffset,
    limit,
    offset,
    sortBy,
    sortDesc,
    sortLocale,
  }: {
    cardDeliveryAllowed?: boolean;
    timezoneOffset?: number;
    limit?: number;
    offset?: number;
    sortBy?: string;
    sortDesc?: boolean;
    sortLocale?: string;
  }): CancelablePromise<BranchOfficeListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/branch-offices',
      query: {
        cardDeliveryAllowed: cardDeliveryAllowed,
        timezoneOffset: timezoneOffset,
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDesc: sortDesc,
        sortLocale: sortLocale,
      },
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
}
