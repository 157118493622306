import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const viseudesusColors: Partial<AppColors> = {
  systemPrimary: '#a18e72',
  systemSecondary: '#0095eb',
  buttonPrimary: '#0095eb',
  primary: '#0095eb',
  buttonWarning: colors.red.darken1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#ffffff',
  leftpanelhover: '#a18e72',
};
