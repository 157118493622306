<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="550"
    scrollable
    persistent
    :data-cy="cy('dialog')"
  >
    <v-card>
      <v-card-title class="text-h5 d-flex pl-3 bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white">mdi-merge</v-icon>
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-row>
            <v-col>
              <v-select
                v-model="origin"
                :items="origins"
                :rules="validations.origin"
                :label="t('origin')"
                :data-cy="cy('origin')"
                variant="underlined"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn color="buttonWarning" :data-cy="cy('close-button')" variant="elevated" @click="closeDialog">
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>

          <ConfirmButton
            dark
            :manual-loading="true"
            :confirm="confirmDialog"
            :text="$t('buttons.send')"
            icon="mdi-check"
            :data-cy="cy('confirm-button')"
            :class="$vuetify.display.mobile ? '' : 'mx-2'"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Ref } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { requiredValidator } from '@/utils/validators';
import { FormInterface } from '@/components/common/FormInterface';
import ComponentBase from '@/components/common/ComponentBase';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import customerService from '@/services/api/customerService.ts';
import accountService from '@/services/api/accountService.ts';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm.ts';

@Component({
  components: {
    ConfirmButton,
  },
})
export default class MergeCustomersDialog extends ComponentBase {
  @Emit('merge-accounts-saved')
  mergeAccountsSaved() {
    return;
  }

  @Ref()
  readonly form!: FormInterface;

  show = false;
  customerId: string | null = null;
  validations = {
    origin: [(v: string) => requiredValidator(v)],
  };
  origin: string | null = null;
  origins: VuetifySelectOptionsVm[] = [];
  dataCy = 'managedCard-mergeCustomersDialog';
  i18nGroupKey = 'managedCardList.mergeCustomersDialog';

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto gap-1 ml-0' : '';
  }

  async openDialog(customerId: string) {
    const customers = await accountService.getAccountRelatedCustomerList({});
    this.origins = customers
      .filter(customer => customer.id !== customerId)
      .map(customer => ({ value: customer.id, title: customer.fullName }));

    this.customerId = customerId;
    this.show = true;
  }

  closeDialog() {
    this.resetForm();
  }

  async confirmDialog() {
    try {
      if ((await this.form.validate()).valid) {
        await customerService.setCustomerOrigin(this.customerId!, { originId: this.origin! });
        this.mergeAccountsSaved();
        this.closeDialog();
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.saveSuccess`),
        });
      }
    } catch (e) {
      ToastModule.error({ message: getApiError(e as ApiErrorInterface, this.i18nGroupKey) });
    }
  }

  resetForm() {
    this.customerId = null;
    this.origin = null;
    this.form.resetValidation();
    this.show = false;
  }
}
</script>

<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
