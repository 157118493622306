<template>
  <v-card width="100%" class="fill-height pa-6">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchOrders"
      :get-row-class-callback="getRowClass"
      :row-click-callback="rowClick"
      :i18n-group-key="i18nGroupKey"
      is-expand-enabled
      :data-cy="cy('table')"
      class="order-list-page-table"
    >
      <template #head-panel.start>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 6">
            <v-alert border="top" type="warning" elevation="2" :data-cy="cy('alert')" variant="text">
              {{ t('paymentAlert') }}
            </v-alert>
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 2">
            <div class="mr-3 ml-3">
              <v-select
                v-model="filter.state"
                :label="t('stateFilter')"
                :items="eshopOrderStateItems"
                :no-data-text="$t('common.noData')"
                clearable
                :data-cy="cy('stateFilter')"
                variant="underlined"
              />
            </div>
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 2">
            <div class="mr-3 ml-3">
              <v-select
                v-model="filter.paymentType"
                :label="t('paymentTypeFilter')"
                :items="paymentTypeItems"
                :no-data-text="$t('common.noData')"
                clearable
                :data-cy="cy('paymentTypeFilter')"
                variant="underlined"
              />
            </div>
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 2">
            <div class="mr-3 ml-3">
              <RDPDatePicker
                v-model="filter.date"
                :label="t('dateFilter')"
                :no-data-text="$t('common.noData')"
                :data-cy="cy('dateFilter')"
                :max="new Date().toISOString()"
                clearable
              />
            </div>
          </v-col>
        </v-row>
      </template>
      <template #expanded-item>
        <td v-if="!$vuetify.display.mobile"></td>
        <v-progress-linear v-if="expandLoading" color="systemSecondary" class="pt-1" indeterminate />
        <td v-else :colspan="6">
          <div v-if="isDetailEmpty" class="text-center font-italic pb-2">
            {{ t('noDetailFound') }}
          </div>
          <table
            v-else
            style="border-bottom: 1px solid lightgrey; font-size: 14px"
            :style="{ width: $vuetify.display.mobile ? '700px' : '100%' }"
          >
            <tr>
              <th style="text-align: left">
                {{ t('detail.operation') }}
              </th>
              <th style="text-align: right">
                {{ t('detail.price') }}
              </th>
              <th style="text-align: right">
                {{ t('detail.quantity') }}
              </th>
              <th style="text-align: right">
                {{ t('detail.totalPrice') }}
              </th>
              <th style="text-align: right">
                {{ t('detail.cardNumber') }}
              </th>
              <th style="text-align: right">
                {{ t('detail.customerFullName') }}
              </th>
            </tr>
            <tr v-for="(item, i) of eshopOrderDetail.items" :key="`order-detail-operation-${i}`">
              <td :style="{ width: $vuetify.display.mobile ? '225px' : '500px' }">
                {{ getItemName(item) }}
              </td>
              <td style="text-align: right">
                {{
                  $t('common.formattedCurrency', {
                    price: filters.toPrice(item.price.toString()),
                  })
                }}
              </td>
              <td style="text-align: right">
                {{ $t('common.formattedAmount', { amount: item.quantity }) }}
              </td>
              <td style="text-align: right">
                {{
                  $t('common.formattedCurrency', {
                    price: filters.toPrice(item.totalPrice.toString()),
                  })
                }}
              </td>
              <td style="text-align: right">
                {{ item.cardNumber }}
              </td>
              <td style="text-align: right">
                {{ item.customerFullName }}
              </td>
            </tr>
          </table>
        </td>
      </template>
      <template #actions.end="{ item }">
        <v-btn
          v-if="payable(item)"
          :title="t('payAgain')"
          color="buttonConfirm"
          theme="dark"
          class="mx-1 elevation-4"
          icon
          size="x-small"
          :data-cy="cy(`pay-order-${item.id}`)"
          @click.stop="payAgain(item)"
        >
          <v-icon>mdi-cash</v-icon>
        </v-btn>
        <v-btn
          v-if="cancellable(item)"
          :title="t('cancel')"
          color="error"
          theme="dark"
          class="mx-1 elevation-4"
          icon
          size="x-small"
          :data-cy="cy(`cancel-order-${item.id}`)"
          @click.stop="cancelOrder(item)"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
        <v-btn
          v-if="isPossibleSendEmail(item)"
          :title="t('sendOrderPaidEmailTooltip')"
          color="buttonConfirm"
          theme="dark"
          class="mx-1 elevation-4"
          :data-cy="cy(`send-order-paid-email-${item.id}`)"
          @click.stop="sendOrderPaidEmail(item.id)"
        >
          <template #prepend>
            <v-icon>mdi-email</v-icon>
          </template>
          {{ t('sendOrderPaidEmailTitle') }}
        </v-btn>
      </template>
    </RDPDataTable>
    <RDPConfirmDialog
      ref="payAgainConfirmDialog"
      :title="t('payAgainDialog.title')"
      :text="payAgainDialogText"
      :confirm="confirmPayAgain"
      :data-cy="cy('pay-again-dialog')"
    />

    <RDPConfirmDialog
      ref="cancelOrderConfirmDialog"
      :title="t('cancelOrderDialog.title')"
      :text="cancelOrderDialogText"
      :confirm="confirmCancelOrder"
      :data-cy="cy('cancel-order-dialog')"
    />
  </v-card>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import { EnumDto, EnumsDto, EshopOrderDetailDto, EshopOrderDto, EshopOrderQuery, OrderItemDto } from '@/api';
import RDPDatePicker from '@/components/common/RDPDatePicker/RDPDatePicker.vue';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import eshopOrderService from '@/services/api/eshopOrderService';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import { formatOrderItemName, Formattable } from '@/utils/orderItem';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import eshopOrderStateService from '@/services/api/eshopOrderStateService';
import { isArrayEmpty } from '@common/utils/isDefined';
import ToastModule from '@/store/modules/toast';
import * as filters from '@/filters';

const DEFAULT_SORT_BY_COLUMN = 'number';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDatePicker,
    RDPDataTable,
    RDPConfirmDialog,
  },
})
export default class OrderListPage extends Vue {
  @Ref()
  readonly payAgainConfirmDialog!: RDPDialogInterface;
  @Ref()
  readonly cancelOrderConfirmDialog!: RDPDialogInterface;
  @Ref()
  readonly dataTable!: RDPDataTableInterface;

  orderToPay: EshopOrderDto = {} as EshopOrderDto;
  orderToCancel: EshopOrderDto = {} as EshopOrderDto;
  systemConfiguration = SystemConfigurationModule;

  filter = {
    paymentType: undefined,
    state: undefined,
    date: '',
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
  } as EshopOrderQuery;
  filters = filters;

  i18nGroupKey = 'orderList';
  dataCy = 'order-list';
  eshopOrderDetail = {} as EshopOrderDetailDto;
  expandLoading = false;
  eshopOrderStateList: EnumDto[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any) {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }

  get headers() {
    return [
      {
        text: 'state.displayName',
        applyRowClass: true,
      },
      DEFAULT_SORT_BY_COLUMN,
      { text: 'issued', type: DATA_TABLE_HEADER_TYPES.DATETIME },
      { text: 'price', type: DATA_TABLE_HEADER_TYPES.PRICE, align: 'end' },
      {
        text: 'paymentType.displayName',
        // replace with custom displayNames as e.g. INVOICE needs to have a different name
        transform: (order: EshopOrderDto) => this.$t(`paymentType.${order.paymentType.name}`),
      },
      { text: 'note', sortable: false },
    ];
  }

  get isDetailEmpty() {
    return isArrayEmpty(this.eshopOrderDetail?.items);
  }

  get payAgainDialogText() {
    return this.t('payAgainDialog.text', {
      number: this.orderToPay.number,
    });
  }

  get cancelOrderDialogText() {
    return this.t('cancelOrderDialog.text', {
      number: this.orderToCancel.number,
    });
  }

  async created() {
    try {
      this.eshopOrderStateList = await eshopOrderStateService.getEshopOrderStates();
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.loadingEshopOrderStatesFailed`),
      });
    }
  }

  getRowClass(row: EshopOrderDto) {
    return `order-state-${row.state.name}`;
  }

  searchOrders(query: EshopOrderQuery) {
    return eshopOrderService.getOrderList(query);
  }

  async rowClick(order: EshopOrderDto, isExpanded: boolean) {
    this.expandLoading = true;
    this.eshopOrderDetail = {} as EshopOrderDetailDto;
    if (isExpanded) {
      try {
        this.eshopOrderDetail = await eshopOrderService.getOrder(order.id);
      } catch (e) {
        ToastModule.error({
          message: this.$t(`${this.i18nGroupKey}.loadingDetailFailed`),
        });
      } finally {
        this.expandLoading = false;
      }
    }
  }

  get eshopOrderStateItems(): VuetifySelectOptionsVm[] {
    return this.eshopOrderStateList.map((eshopOrderState: EnumDto) => ({
      title: eshopOrderState.displayName,
      value: eshopOrderState.name,
    }));
  }

  get paymentTypeItems(): VuetifySelectOptionsVm[] {
    return this.systemConfiguration.allowedPaymentTypes.map((paymentType: EnumDto) => ({
      title: this.$t(`paymentType.${paymentType.name}`),
      value: paymentType.name,
    }));
  }

  payable(order: EshopOrderDto) {
    return (
      order.state.name === EnumsDto.eshopOrderStates.PENDING &&
      order.paymentType.name === EnumsDto.paymentTypes.BANK_CARD
    );
  }

  async payAgain(order: EshopOrderDto) {
    this.orderToPay = order;
    this.payAgainConfirmDialog.openDialog();
  }

  async confirmPayAgain() {
    try {
      const payment = await eshopOrderService.payAgain(this.orderToPay.id);
      eshopOrderService.processedPayment(payment);
    } catch (e) {
      this.refreshTable();
      ToastModule.error({
        message: this.$t('shoppingCart.paymentFailed'),
      });
    } finally {
      this.orderToPay = {} as EshopOrderDto;
    }
  }

  async confirmCancelOrder() {
    try {
      await eshopOrderService.cancelOrder(this.orderToCancel.id);
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.cancelOrderDialog.success`),
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.cancelOrderDialog.failed`),
      });
    } finally {
      this.orderToCancel = {} as EshopOrderDto;
      this.dataTable.refresh();
    }
  }

  cancellable(order: EshopOrderDto) {
    return order.state.name === EnumsDto.eshopOrderStates.PENDING;
  }

  isPossibleSendEmail(order: EshopOrderDto) {
    return order.state.name === EnumsDto.eshopOrderStates.PAID;
  }

  cancelOrder(order: EshopOrderDto) {
    this.orderToCancel = order;
    this.cancelOrderConfirmDialog.openDialog();
  }

  async sendOrderPaidEmail(id: string) {
    try {
      await eshopOrderService.sendOrderPaidEmail(id);
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.sendOrderPaidEmailSuccess`),
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.sendOrderPaidEmailFailed`),
      });
    }
  }

  refreshTable() {
    this.dataTable.refresh();
  }

  getItemName(item: OrderItemDto) {
    return formatOrderItemName(item as Formattable);
  }
}
</script>

<style lang="scss">
.order-state-PENDING {
  background-color: #b7e2f1 !important;
}

.order-state-CANCELLED {
  background-color: #fff9c4 !important;
}

.order-state-PAID {
  background-color: #c7ffc9 !important;
}

.order-state-REJECTED {
  background-color: #ffd3d3 !important;
}

.order-state-RETURNED {
  background-color: #ffe0b2 !important;
}

.order-state-PROCESSING {
  background-color: #ffe0b2 !important;
}

.order-list-page-table {
  .expand-btn {
    margin-top: 21px;
  }
}
</style>
