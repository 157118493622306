import { ComponentBase, Vue } from 'vue-facing-decorator';
import * as filters from '@/filters';

@ComponentBase
export default class RDPComponentBase extends Vue {
  dataCy = '';
  i18nGroupKey = '';
  filters = filters;

  /**
   * Returns i18n translation.
   * @param i18nKey
   * @param param
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any): string {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  /**
   * Returns true if the i18n key exists.
   * @param i18nKey
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  te(i18nKey: string) {
    return this.$te(`${this.i18nGroupKey}.${i18nKey}`);
  }

  /**
   * Returns i18n translation with a suffix. Default suffix is ': '
   * @param i18nKey
   * @param param
   * @param suffix
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ts(i18nKey: string, param?: any, suffix = ': ') {
    return `${this.t(i18nKey, param)}${suffix}`;
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }
}
