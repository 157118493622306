/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardProfileVm } from './CardProfileVm';
import type { CustomerDto } from './CustomerDto';
import type { ElectronicWalletDto } from './ElectronicWalletDto';
import type { EnumDto } from './EnumDto';
import type { TicketFareDto } from './TicketFareDto';
export type ReclamationCardDetailVm = {
  electronicWallet: ElectronicWalletDto;
  ticketFares: Array<TicketFareDto>;
  /**
   * Card profile. Contains date range, profile itself and optional confirmation check date.
   */
  profileOne?: CardProfileVm;
  /**
   * Card profile. Contains date range, profile itself and optional confirmation check date.
   */
  profileTwo?: CardProfileVm;
  /**
   * Current card ownership from. Type: 'DATE'
   */
  ownershipFrom?: string;
  /**
   * Current card ownership to. Type: 'DATE'
   */
  ownershipTo?: string;
  customerCardType: ReclamationCardDetailVm.customerCardType;
  /**
   * Portable card identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Unique identifier of card.
   */
  cardNumber: string;
  /**
   * Chip number identifier. Is unique.
   */
  chipNumber: string;
  /**
   * Card state. Active, blocked, ...
   */
  state: EnumDto;
  customer?: CustomerDto;
  note?: string;
  /**
   * Card valid from. Type: 'DATE'
   */
  validFrom: string;
  /**
   * Card valid to. Type: 'DATE'
   */
  validTo: string;
};
export namespace ReclamationCardDetailVm {
  export enum customerCardType {
    PERSONAL = 'PERSONAL',
    PORTABLE = 'PORTABLE',
    RECLAMATION = 'RECLAMATION',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    BANK_CARD = 'BANK_CARD',
    WHITE_LIST_CARD = 'WHITE_LIST_CARD',
  }
}
