/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocalizationDto } from './LocalizationDto';
export type ZoneDto = {
  /**
   * Zone identifier. Type: 'UUID'
   */
  id: string;
  name: string;
  /**
   * Zone number.
   */
  number: number;
  /**
   * Whether is it displayed on FE or not.
   */
  availableInPriceList: boolean;
  deletable: boolean;
  type: ZoneDto.type;
  /**
   * Translations for FE.
   */
  localizations?: Array<LocalizationDto>;
  displayName: string;
  zones?: Array<any>;
};
export namespace ZoneDto {
  export enum type {
    SIMPLE = 'SIMPLE',
    COMBINED = 'COMBINED',
    ALL_NETWORK = 'ALL_NETWORK',
  }
}
