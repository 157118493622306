import {
  CustomerAccountNotificationDto,
  CustomerAccountNotificationQuery,
  CustomerAccountNotificationService,
  UpdateCustomerAccountNotificationCommand,
} from '@/api';
import { getRequestBody } from '@/utils/api';

export default {
  getNotificationSetting: async (
    query: CustomerAccountNotificationQuery,
  ): Promise<CustomerAccountNotificationDto[]> => {
    const response =
      await CustomerAccountNotificationService.customerAccountNotificationControllerGetCustomerAccountNotifications(
        query,
      );

    return response.data;
  },

  updateNotification: async (
    id: string,
    payload: UpdateCustomerAccountNotificationCommand,
  ): Promise<CustomerAccountNotificationDto> => {
    const response =
      await CustomerAccountNotificationService.customerAccountNotificationControllerUpdateCustomerAccountNotification({
        id,
        requestBody: getRequestBody(payload),
      });

    return response.data;
  },
};
