import { AssignCustodianCommand, CustomerDto } from '@/api';
import { toCreateBaseCustodianCommand } from '@/components/cardApplication/mappers/customerMapper';

export default {
  toAssignCustodianCommand(custodianData: CustomerDto): AssignCustodianCommand {
    return {
      parentId: custodianData.id,
      parent: custodianData.id ? undefined : toCreateBaseCustodianCommand(custodianData),
    };
  },
};
