import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const dpbColors: Partial<AppColors> = {
  systemPrimary: '#D71920',
  systemSecondary: '#D71920',
  buttonPrimary: '#191919',
  primary: '#D71920',
  buttonWarning: colors.red.darken1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#ffffff',
  leftpanelhover: '#b9242b',
};
