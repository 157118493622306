import { ClientInput } from '@/api';
import { getTimezoneOffset } from '@/utils/dateTime';
import { DEFAULT_LOCALE } from '@/config/config';
import { removeEmptyQuery } from '@/utils/removeEmptyQuery';

export const clientInputFactory = (): ClientInput => {
  return { timezoneOffset: getTimezoneOffset(new Date()) };
};

export const sortLocaleInputFactory = (): { sortLocale: string } => {
  return { sortLocale: DEFAULT_LOCALE };
};

export const getDefaultAcceptLanguage = () => {
  return { acceptLanguage: DEFAULT_LOCALE };
};

export const getQueryParams = (params: object = {}) => {
  removeEmptyQuery(params);

  return {
    ...clientInputFactory(),
    ...sortLocaleInputFactory(),
    ...getDefaultAcceptLanguage(),
    ...params,
  };
};

export const getRequestBody = <T>(payload: T) => {
  return {
    ...clientInputFactory(),
    ...payload,
  };
};
