<template>
  <v-main>
    <v-form ref="form" validate-on="lazy" @submit.prevent>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12 rounded-xl" :data-cy="cy('form')">
            <v-toolbar color="systemPrimary" theme="dark" flat>
              <v-toolbar-title>
                {{ t('header') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pl-6 pr-6">
              <v-text-field
                v-model="registration.username"
                autocomplete="off"
                prepend-icon="mdi-account"
                type="text"
                :rules="validations.username"
                :label="t('username')"
                :data-cy="cy('username')"
                variant="underlined"
              />

              <v-text-field
                v-model="registration.password"
                prepend-icon="mdi-lock"
                autocomplete="new-password"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :label="t('password')"
                :rules="validations.password"
                :data-cy="cy('password')"
                variant="underlined"
                @click:append="showPass = !showPass"
              />
              <v-text-field
                v-model="registration.passwordAgain"
                prepend-icon="mdi-lock"
                autocomplete="new-password"
                :type="showPassAgain ? 'text' : 'password'"
                :append-icon="showPassAgain ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="validations.passwordAgain"
                :label="t('passwordAgain')"
                :data-cy="cy('passwordAgain')"
                variant="underlined"
                @click:append="showPassAgain = !showPassAgain"
              />
              <v-checkbox
                v-model="moreThanRequiredYears"
                :label="t('moreThanRequiredYears', { minAge: minCustomerAge })"
                :rules="validations.moreThanRequiredYears"
                class="mt-5"
                :data-cy="cy('checkbox15years')"
              />
              <v-checkbox
                v-model="termsAndConditions"
                :rules="validations.termsAndConditions"
                :data-cy="cy('termsAndCondtitions')"
              >
                <template #label>
                  <div>
                    <span class="mr-1">
                      {{ t('termsAndConditions1') }}
                    </span>
                    <a :href="termsAndConditionsLink" target="_blank" @click="$event.stopPropagation()">{{
                      t('termsAndConditions2')
                    }}</a>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="gdpr" :rules="validations.termsAndConditions" :data-cy="cy('gdpr')">
                <template #label>
                  <div>
                    <span class="mr-1">
                      {{ t('gdpr1') }}
                    </span>
                    <a :href="gdprLink" target="_blank" @click="$event.stopPropagation()">{{ t('gdpr2') }}</a>
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions class="justify-end pr-6 pb-6 pt-0">
              <ConfirmButton
                :text="t('submitRegistration')"
                :confirm="register"
                class="rdp-button"
                style="width: 200px"
                button-color="buttonPrimary"
                icon="$registrationSendIcon"
                :data-cy="cy('btn')"
                type="submit"
                large-icon
              />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-main>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import {
  checkedValidator,
  confirmPasswordValidator,
  emailValidator,
  requiredValidator,
  strongPasswordValidator,
} from '@/utils/validators';
import authServices from '@/services/api/authService';
import { FormInterface } from '@/components/common/FormInterface';
import Paths from '@/constants/Paths';
import { GDPR_URL, MIN_CUSTOMER_AGE, TERMS_AND_CONDITIONS_URL } from '@/config/config';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({
  components: {
    ConfirmButton,
  },
})
export default class RegistrationForm extends Vue {
  @Ref()
  readonly form!: FormInterface;
  showPass = false;
  showPassAgain = false;
  registration = {
    username: '',
    password: '',
    passwordAgain: '',
  };

  get validations() {
    return {
      username: [(v: string) => requiredValidator(v), (v: string) => emailValidator(v)],
      password: [(v: string) => strongPasswordValidator(v)],
      passwordAgain: [(v: string) => confirmPasswordValidator(v, this.registration.password)],
      moreThanRequiredYears: [
        (v: boolean) =>
          v ||
          this.t('moreThanRequiredYearsValidation', {
            minAge: this.minCustomerAge,
          }),
      ],
      termsAndConditions: [(v: boolean) => checkedValidator(v)],
    };
  }

  i18nGroupKey = 'registration';
  dataCy = 'registration';
  moreThanRequiredYears = false;
  termsAndConditions = false;
  gdpr = false;
  minCustomerAge = MIN_CUSTOMER_AGE;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any) {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }

  get termsAndConditionsLink() {
    return TERMS_AND_CONDITIONS_URL.REGISTER;
  }

  get gdprLink() {
    return GDPR_URL.REGISTER;
  }

  async register() {
    if ((await this.form.validate()).valid) {
      const toastPosition = {
        x: 'center',
        y: 'top',
        timeout: PERMANENT_TOAST_INTERVAL,
      };
      try {
        const { username, password } = this.registration;
        const termsAndConditionsAgreed = this.moreThanRequiredYears && this.termsAndConditions && this.gdpr;
        await authServices.register({
          username,
          password,
          termsAndConditionsAgreed,
        });
        ToastModule.success({
          message: this.$t('registration.success'),
          options: toastPosition,
        });
        this.resetFormValues();
        await this.$router.push(Paths.LOGIN);
      } catch (e) {
        ToastModule.error({ message: getApiError(e as ApiErrorInterface, 'registration') });
      }
    }
  }

  resetFormValues() {
    this.registration = {
      username: '',
      password: '',
      passwordAgain: '',
    };
    this.form.resetValidation();
  }
}
</script>
