<template>
  <div class="customer-personal-info customer-detail-info mb-3">
    <v-row>
      <v-col class="text-right label">
        {{ ts('firstName') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy('firstName')" :class="verificationClass">
        {{ customerData.firstName }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right label">
        {{ ts('lastName') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy('lastName')" :class="verificationClass">
        {{ customerData.lastName }}
      </v-col>
    </v-row>
    <v-row v-if="showBirthDay">
      <v-col class="text-right label">
        {{ ts('birthDay') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy('birthDay')" :class="verificationClass">
        {{ toStandardDate(customerData.birthDay) }}
      </v-col>
    </v-row>
    <v-row v-if="userAccountModule.hasCardDetailData">
      <v-col class="text-right label">
        {{ ts('cardNumber') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy('cardNumber')" :class="verificationClass">
        {{ toMapCardNumber(customerCardData.cardNumber) }}
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator';
import RDPCustomerDetailAbstract from '@/components/common/RDPCustomerDetail/RDPCustomerDetailAbstract';
import { toStandardDate } from '@/filters';
import { toMapCardNumber } from '@/utils/customerCard';

@Component({
  name: 'CustomerMainPersonalInfo',
})
export default class CustomerMainPersonalInfo extends RDPCustomerDetailAbstract {
  toMapCardNumber(value: string) {
    return toMapCardNumber(value);
  }

  toStandardDate(value: string) {
    return toStandardDate(value);
  }
}
</script>
