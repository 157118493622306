/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateStockDocumentCommand = {
  /**
   * Stock Action. Accept, move, sell.
   */
  type: CreateStockDocumentCommand.type;
  /**
   * Stock transaction IDs for the new document.
   */
  stockTransactions: Array<string>;
  note?: string;
  /**
   * Organization identifier. Represents supplier or purchase. Type: 'UUID'
   */
  organizationId?: string;
  /**
   * Origin stock identifier. Type: 'UUID'
   */
  originStockId?: string;
  /**
   * Destination stock identifier. Type: 'UUID'
   */
  destinationStockId?: string;
  /**
   * User identifier. User that issue products or products were moved from him. Type: 'UUID'
   */
  issuedId?: string;
  /**
   * User identifier. User that accepted products or products were moved to him. Type: 'UUID'
   */
  acceptedId?: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CreateStockDocumentCommand {
  /**
   * Stock Action. Accept, move, sell.
   */
  export enum type {
    ACCEPT = 'ACCEPT',
    MOVE = 'MOVE',
    EXTERNAL_SELL = 'EXTERNAL_SELL',
    COUNTER_SELL = 'COUNTER_SELL',
    COUNTER_CANCEL = 'COUNTER_CANCEL',
    LIQUIDATION = 'LIQUIDATION',
  }
}
