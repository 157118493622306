<template>
  <div class="img-placeholder">
    <img v-if="src" :src="src" alt="alt" />
    <v-icon v-else size="x-large">mdi-camera</v-icon>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class RDPImage extends Vue {
  @Prop({ required: true, type: String })
  readonly src!: string;
  @Prop({ required: false, type: String, default: 'user-photo' })
  readonly alt!: string;
}
</script>
<style scoped lang="scss">
.img-placeholder {
  width: 150px;
  background: #dcdcdc;
  display: flex;
  justify-content: center;
  img {
    width: 150px;
  }
}
</style>
