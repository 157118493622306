<template>
  <!--
    from file(s): casove_jizdenky_red.svg, casove_jizdenky.svg, nova_casova_jizdenka.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65l0 863.78c0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65zm365.15 -708.48c152.76,0 276.59,123.83 276.59,276.59 0,152.76 -123.83,276.59 -276.59,276.59 -75.71,0 -144.3,-30.42 -194.25,-79.7l39.22 -39.02c39.93,39.21 94.65,63.4 155.03,63.4 122.21,0 221.27,-99.06 221.27,-221.27 0,-122.21 -99.06,-221.27 -221.27,-221.27 -70.14,0 -132.65,32.65 -173.19,83.56l51.36 51.08 -146.71 0 0 -145.95 55.94 55.66c50.73,-60.91 127.13,-99.67 212.6,-99.67zm22.53 273.7l52.25 47.36c9.19,8.33 9.89,22.64 1.56,31.82l0 0c-8.33,9.19 -22.63,9.9 -31.82,1.57l-58.06 -52.63c-5.49,-4.98 -7.95,-12.11 -7.26,-18.99 -1.11,-2.66 -1.73,-5.57 -1.73,-8.63l0 -144.97c0,-12.4 10.13,-22.53 22.53,-22.53l0 0c12.4,0 22.53,10.13 22.53,22.53l0 144.47z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class TicketFare extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
