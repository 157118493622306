/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EshopOrderQuery = {
  /**
   * Payment state
   */
  state?: EshopOrderQuery.state;
  /**
   * Payment type. Bank card or Invoice.
   */
  paymentType?: any;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
  /**
   * Filter by date within date range. Type: 'DATE'
   */
  date?: string;
};
export namespace EshopOrderQuery {
  /**
   * Payment state
   */
  export enum state {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    PROCESSING = 'PROCESSING',
  }
}
