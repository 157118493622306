export default class PageI18nNameKeys {
  public static LOGIN = 'auth.title';
  public static REGISTRATION = 'registration.title';
  public static FORGOTTEN_PASSWORD = 'forgottenPassword.title';
  public static VERIFICATION = 'verification.title';
  public static VERIFY = 'verify.title';
  public static APPROVE_MANAGED_CARD = 'manageCardConfirmationPage.title';
  public static CHANGE_PASSWORD = 'changePassword.title';
  public static USER_ACCOUNT = 'userAccount.title';
  public static USER_ASSIGNED_ACCOUNT = 'assignedAccount.title';
  public static NOTIFICATION_SETTING = 'notificationSetting.title';
  public static TICKET_FARE = 'ticketFare.title';
  public static ELECTRONIC_WALLET = 'electronicWallet.title';
  public static SHOPPING_CART = 'shoppingCart.title';
  public static ORDER_LIST = 'orderList.title';
  public static ACCOUNT_SETTING = 'accountSetting.title';
  public static ASSIGNED_CARD_LIST = 'assignedCardList.title';
  public static MANAGED_CARD_LIST = 'managedCardList.title';
  public static CARD_MANAGING_ACCOUNT_LIST = 'cardManagingAccountList.title';
  public static REJECT_CARD_MANAGE_REQUEST = 'rejectCardManageRequestPage.title';
}
