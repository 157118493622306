<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="850"
    scrollable
    persistent
    :data-cy="cy('dialog')"
  >
    <v-card>
      <v-card-title class="text-h5 d-flex pl-3 bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white">mdi-pencil</v-icon>
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text">
        <v-form
          v-show="formStep === formStepEnum.PersonalInfo"
          ref="formPersonalInfo"
          validate-on="lazy"
          @submit.prevent
        >
          <v-row>
            <v-col>
              <RDPCropper
                ref="cropper"
                :source-image="customer.photo"
                :crop-end="setCustomerPhoto"
                :crop-closed="cropClosed"
                :aspect-ratio="photoAspectRatio"
                overlay-destination-id="crop-overlay-personal-data-form"
                :data-cy="cy('photo')"
              />
              <div v-if="photoErrorMessage" class="common-error-message v-messages__message">
                {{ photoErrorMessage }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customer.firstName"
                :rules="validations.firstName"
                :label="t('firstName')"
                :data-cy="cy('firstName')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customer.lastName"
                :rules="validations.lastName"
                :label="t('lastName')"
                :data-cy="cy('lastName')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customer.birthDay"
                :rules="validations.birthDay"
                :label="t('birthDay')"
                :data-cy="cy('birthDay')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="customer.address.street"
                :rules="validations.addressStreet"
                :label="t('addressStreet')"
                :data-cy="cy('addressStreet')"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="customer.address.streetNumber"
                :rules="validations.addressStreetNumber"
                :label="t('addressStreetNumber')"
                :data-cy="cy('addressStreetNumber')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="customer.address.city"
                :rules="validations.addressCity"
                :label="t('addressCity')"
                :data-cy="cy('addressCity')"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="customer.address.zip"
                :rules="validations.addressZipCode"
                :label="t('addressZipCode')"
                :data-cy="cy('addressZipCode')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customer.email"
                :rules="validations.email"
                :label="t('email')"
                :data-cy="cy('email')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customer.phoneNumber"
                :rules="validations.phoneNumber"
                :label="t('phoneNumber')"
                :data-cy="cy('phoneNumber')"
                variant="underlined"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-form v-show="formStep === formStepEnum.Custodian" ref="formCustodian" validate-on="lazy" @submit.prevent>
          <v-alert border="top" type="info" elevation="2" class="mb-4" variant="text">
            {{ t('custodianIsRequired', { minCustomerAge }) }}
          </v-alert>

          <v-row v-if="userItems.length > 0">
            <v-col>
              <v-select
                v-model="searchedUserId"
                :label="t('dataSearch')"
                :items="userItems"
                :no-data-text="$t('common.noData')"
                clearable
                :data-cy="cy('custodian-selectUser')"
                variant="underlined"
                @update:model-value="searchedUserChanged"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="custodian.firstName"
                :rules="validations.firstName"
                :label="t('firstName')"
                :data-cy="cy('custodian-firstName')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="custodian.lastName"
                :rules="validations.lastName"
                :label="t('lastName')"
                :data-cy="cy('custodian-lastName')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="custodian.birthDay"
                :rules="validations.custodianBirthDay"
                :label="t('birthDay')"
                :data-cy="cy('custodian-birthDay')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="custodian.address.street"
                :rules="validations.addressStreet"
                :label="t('addressStreet')"
                :data-cy="cy('custodian-addressStreet')"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="custodian.address.streetNumber"
                :rules="validations.addressStreetNumber"
                :label="t('addressStreetNumber')"
                :data-cy="cy('custodian-addressStreetNumber')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="custodian.address.city"
                :rules="validations.addressCity"
                :label="t('addressCity')"
                :data-cy="cy('custodian-addressCity')"
                variant="underlined"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="custodian.address.zip"
                :rules="validations.addressZipCode"
                :label="t('addressZipCode')"
                :data-cy="cy('custodian-addressZipCode')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="custodian.email"
                :rules="validations.email"
                :label="t('email')"
                :data-cy="cy('custodian-email')"
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="custodian.phoneNumber"
                :rules="validations.phoneNumber"
                :label="t('phoneNumber')"
                :data-cy="cy('custodian-phoneNumber')"
                variant="underlined"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn color="buttonWarning" :data-cy="cy('close-button')" variant="elevated" @click="closeDialog">
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>

          <v-btn
            v-if="!hasRegisteredCustomer && needCustodian && formStep === formStepEnum.PersonalInfo"
            color="buttonPrimary"
            :data-cy="cy('next-button')"
            theme="dark"
            :class="$vuetify.display.mobile ? '' : 'mx-2'"
            variant="elevated"
            @click="nextStep()"
          >
            {{ $t('buttons.next') }}
            <template #append>
              <v-icon> mdi-chevron-right</v-icon>
            </template>
          </v-btn>

          <v-btn
            v-if="formStep === formStepEnum.Custodian"
            color="buttonPrimary"
            :data-cy="cy('back-button')"
            theme="dark"
            :class="$vuetify.display.mobile ? '' : 'ml-2'"
            variant="elevated"
            @click="previousStep()"
          >
            <template #prepend>
              <v-icon> mdi-chevron-left</v-icon>
            </template>
            {{ $t('buttons.back') }}
          </v-btn>

          <ConfirmButton
            v-if="
              (hasRegisteredCustomer && formStep === formStepEnum.PersonalInfo) ||
              (!needCustodian && formStep === formStepEnum.PersonalInfo) ||
              formStep === formStepEnum.Custodian
            "
            dark
            :manual-loading="true"
            :confirm="confirmDialog"
            :text="$t('buttons.send')"
            icon="mdi-check"
            :data-cy="cy('confirm-button')"
            :class="$vuetify.display.mobile ? '' : 'mx-2'"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Ref, Watch } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import {
  dateValidator,
  emailValidator,
  maxLengthValidator,
  requiredValidator,
  requiredValidatorIf,
} from '@/utils/validators';
import { FormInterface } from '@/components/common/FormInterface';
import ComponentBase from '@/components/common/ComponentBase';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { CustomerDto, CustomerHeaderVm } from '@/api';
import customerService from '@/services/api/customerService.ts';
import { DATE_BIRTHDAY_FORMAT, DATE_FORMAT, formatDate, MIN_DAY_OF_BIRTHDAY } from '@/utils/dateTime.ts';
import RDPCropper from '@/components/common/RDPCropper/RDPCropper.vue';
import { RDP_PHOTO_ASPECT_RATIO } from '@/constants/Image.ts';
import { toAddress, toCreateBaseCustomerCommand, toUpdateCustomerCommand } from '@/mappers/customerMapper.ts';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import isDefined from '@common/utils/isDefined';
import Errors from '@/constants/Errors.ts';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm.ts';
import accountService from '@/services/api/accountService.ts';
import { MIN_CUSTOMER_AGE } from '@/config/config.ts';
import CustomerModule from '@/store/modules/customer.ts';
import { format, isAfter, parse, subYears } from 'date-fns';

enum FormStep {
  PersonalInfo,
  Custodian,
}

@Component({
  components: {
    RDPCropper,
    ConfirmButton,
  },
})
export default class PersonalInfoDialog extends ComponentBase {
  @Emit('personal-info-saved')
  personalInfoSaved(customer: CustomerDto) {
    return customer;
  }

  @Ref()
  readonly formPersonalInfo!: FormInterface;

  @Ref()
  readonly formCustodian!: FormInterface;

  show = false;
  formStep = FormStep.PersonalInfo;
  formStepEnum = FormStep;
  photoErrorMessage = '';
  photoAspectRatio = RDP_PHOTO_ASPECT_RATIO;
  systemConfigurationModule = SystemConfigurationModule;
  validations = {
    firstName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    lastName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
    birthDay: [
      (v: string) => requiredValidator(v),
      (v: string) =>
        dateValidator(
          v,
          DATE_BIRTHDAY_FORMAT,
          MIN_DAY_OF_BIRTHDAY,
          this.getMaxDayOfBirthDay().toISOString().substring(0, 10),
        ),
    ],
    custodianBirthDay: [
      (v: string) => requiredValidator(v),
      (v: string) =>
        dateValidator(
          v,
          DATE_BIRTHDAY_FORMAT,
          MIN_DAY_OF_BIRTHDAY,
          this.getCustodianMaxDayOfBirthDay().toISOString().substring(0, 10),
        ),
    ],
    photo: [(v: string) => requiredValidator(v)],
    email: [(v: string) => emailValidator(v)],
    phoneNumber: [(v: string) => maxLengthValidator(v, 20)],
    addressStreet: [
      (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetRequired),
      (v: string) => maxLengthValidator(v, 50),
    ],
    addressStreetNumber: [
      (v: string) =>
        requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetNumberRequired),
      (v: string) => maxLengthValidator(v, 15),
    ],
    addressCity: [
      (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressCityRequired),
      (v: string) => maxLengthValidator(v, 50),
    ],
    addressZipCode: [
      (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressZipCodeRequired),
      (v: string) => maxLengthValidator(v, 6),
    ],
  };
  customer: CustomerDto;
  custodian: CustomerDto;
  searchedUserId = null;
  userItems: VuetifySelectOptionsVm[] = [];
  minCustomerAge = MIN_CUSTOMER_AGE;
  dataCy = 'userAccount-personalInfoDialog';
  i18nGroupKey = 'userAccountPage.personalInfoDialog';

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto gap-1 ml-0' : '';
  }

  getCustodianMaxDayOfBirthDay() {
    return subYears(new Date(), MIN_CUSTOMER_AGE);
  }

  getMaxDayOfBirthDay() {
    return subYears(new Date(), 5);
  }

  setCustomerPhoto(photo: string) {
    this.customer!.photo = photo;
  }

  cropClosed() {
    if (this.customer!.photo === '') {
      this.photoErrorMessage = this.$t('validators.required');
    } else {
      this.photoErrorMessage = '';
    }
  }

  @Watch('customer.photo')
  photoChanged() {
    if (this.customer.photo) {
      this.photoErrorMessage = '';
    }
  }

  async openDialog(customerId: string) {
    const customer = await customerService.getCustomer(customerId);

    this.customer = {
      ...customer,
      firstName: '',
      lastName: '',
      birthDay: customer.birthDay ? formatDate(customer.birthDay) : '',
      address: toAddress(customer.address),
    };

    this.custodian = {
      address: {},
    } as CustomerDto;

    const accountRelatedCustomers = await accountService.getAccountRelatedCustomerList({ adultOnly: true });
    this.transformUserAutocompleteItems(accountRelatedCustomers.filter(account => account.id !== customerId));
    this.show = true;
  }

  transformUserAutocompleteItems(responseData: CustomerHeaderVm[]) {
    this.userItems = responseData.map((customer: CustomerHeaderVm) => ({
      value: customer.id,
      title: `${customer.lastName} ${customer.firstName} (${formatDate(customer.birthDay)})`,
    }));
  }

  closeDialog() {
    this.resetForm();
  }

  get hasRegisteredCustomer() {
    return isDefined(CustomerModule.registeredCustomer.id);
  }

  get needCustodian() {
    return isAfter(
      parse(this.customer.birthDay, DATE_BIRTHDAY_FORMAT, new Date()),
      subYears(new Date(), MIN_CUSTOMER_AGE),
    );
  }

  previousStep() {
    this.formStep = FormStep.PersonalInfo;
  }

  async nextStep() {
    (await this.formPersonalInfo.validate()).valid;
    this.formStep = FormStep.Custodian;
  }

  async confirmDialog() {
    if (!isDefined(this.customer.photo)) {
      this.photoErrorMessage = this.$t('validators.required');
    }

    if (
      !(await this.formPersonalInfo.validate()).valid ||
      (this.formStep === FormStep.Custodian && !(await this.formCustodian.validate()).valid)
    ) {
      return;
    }

    try {
      const customer = {
        ...this.customer,
        birthDay: format(parse(this.customer.birthDay, DATE_BIRTHDAY_FORMAT, new Date()), DATE_FORMAT),
      };
      const customerUpdateCommand = toUpdateCustomerCommand(customer);

      if (this.needCustodian) {
        if (this.hasRegisteredCustomer) {
          customerUpdateCommand.parentId = CustomerModule.registeredCustomer.id;
        } else if (this.searchedUserId) {
          customerUpdateCommand.parentId = this.searchedUserId;
        } else {
          const custodian = {
            ...this.custodian,
            birthDay: format(parse(this.custodian.birthDay, DATE_BIRTHDAY_FORMAT, new Date()), DATE_FORMAT),
          };
          customerUpdateCommand.parent = toCreateBaseCustomerCommand(custodian);
        }
      }

      const updatedCustomer = await customerService.updateCustomer(this.customer.id, customerUpdateCommand);
      this.personalInfoSaved(updatedCustomer);
      this.closeDialog();
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.saveSuccess`),
      });
    } catch (e) {
      if ((e as ApiErrorInterface).data?.name === Errors.CUSTODIAN_IS_REQUIRED) {
        this.formStep = FormStep.Custodian;
      } else {
        ToastModule.error({ message: getApiError(e as ApiErrorInterface, this.i18nGroupKey) });
      }
    }
  }

  async searchedUserChanged() {
    if (this.searchedUserId) {
      try {
        const custodian = await customerService.getCustomer(this.searchedUserId);

        this.custodian = {
          ...custodian,
          birthDay: custodian.birthDay ? formatDate(custodian.birthDay) : '',
          address: toAddress(custodian.address),
        } as CustomerDto;
      } catch (e) {
        ToastModule.error({ message: getApiError(e as ApiErrorInterface, this.i18nGroupKey) });
      }
    } else {
      this.searchedUserId = null;
      this.custodian = {
        address: {},
      } as CustomerDto;
      this.formCustodian.resetValidation();
    }
  }

  resetForm() {
    this.searchedUserId = null;
    this.customer = {
      address: {},
    } as CustomerDto;
    this.custodian = {
      address: {},
    } as CustomerDto;
    this.formPersonalInfo.resetValidation();
    this.formCustodian.resetValidation();
    this.formStep = FormStep.PersonalInfo;
    this.show = false;
  }
}
</script>

<style scoped lang="scss">
.common-error-message {
  color: rgb(var(--v-theme-error));
  font-size: 12px;
  margin-top: 10px;
}

.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
