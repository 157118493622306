<template>
  <div class="align-center justify-center" data-cy="personal-data-form">
    <v-form ref="form" validate-on="lazy">
      <v-card-text class="card-text">
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 3" class="pt-6">
            <RDPCropper
              ref="cropper"
              :source-image="customer.photo"
              :disabled="disabled"
              :crop-end="setCustomerPhoto"
              :crop-closed="cropClosed"
              :aspect-ratio="photoAspectRatio"
              :allow-scan="true"
              data-cy="personal-data-form-photo"
            />
            <div v-if="photoErrorMessage" class="user-photo-error-message v-messages__message">
              {{ photoErrorMessage }}
            </div>
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 4" class="pt-0">
            <v-text-field
              v-model="customer.firstName"
              :rules="validations.firstName"
              :label="$t('cardApplication.personalData.firstName')"
              data-cy="personal-data-form-firstName"
              :class="{ 'disable-events': disabled }"
              variant="underlined"
            />
            <v-text-field
              v-model="customer.lastName"
              :rules="validations.lastName"
              :label="$t('cardApplication.personalData.lastName')"
              data-cy="personal-data-form-lastName"
              :class="{ 'disable-events': disabled }"
              variant="underlined"
            />
            <v-text-field
              v-model="customer.birthDay"
              :placeholder="dateFormatPlaceholder"
              :label="$t('cardApplication.personalData.birthDay')"
              :rules="validations.birthDay"
              :clearable="!disabled && editableBirthday"
              :class="{
                'disable-events': disabledBirthday,
              }"
              :readonly="disabledBirthday"
              :tabindex="disabledBirthday ? -1 : 0"
              data-cy="personal-data-form-birthDay"
              variant="underlined"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 5" class="pt-0">
            <v-text-field
              v-model="customer.email"
              :rules="validations.email"
              :label="`${$t('cardApplication.personalData.email')} - ${$t('cardApplication.personalData.optional')}`"
              :class="{ 'disable-events': disabled }"
              data-cy="personal-data-form-email"
              variant="underlined"
            />
            <v-text-field
              v-model="customer.phoneNumber"
              :rules="validations.phone"
              :label="`${$t('cardApplication.personalData.phone')} - ${$t('cardApplication.personalData.optional')}`"
              :class="{ 'disable-events': disabled }"
              data-cy="personal-data-form-phone"
              variant="underlined"
              @keypress="allowPhoneNumber"
            />
          </v-col>
        </v-row>
        <div v-if="cardApplicationModule.isHaulierCardType">
          <v-alert type="info" density="compact" variant="outlined" class="mb-4">
            {{ photoInfoAlert }}
          </v-alert>
        </div>
        <div class="text-subtitle-1 permanent-address">
          {{ $t('cardApplication.personalData.permanentAddress') }}
        </div>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 6">
            <v-text-field
              v-model="customer.address.street"
              :rules="validations.addressStreet"
              :label="$t('cardApplication.personalData.addressStreet')"
              :class="{ 'disable-events': disabled }"
              data-cy="personal-data-form-addressStreet"
              variant="underlined"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 6">
            <v-text-field
              v-model="customer.address.streetNumber"
              :rules="validations.addressStreetNumber"
              :label="$t('cardApplication.personalData.addressStreetNumber')"
              :class="{ 'disable-events': disabled }"
              data-cy="personal-data-form-addressStreetNumber"
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.display.mobile ? 12 : 6">
            <v-text-field
              v-model="customer.address.city"
              :rules="validations.addressCity"
              :label="$t('cardApplication.personalData.addressCity')"
              :class="{ 'disable-events': disabled }"
              data-cy="personal-data-form-addressCity"
              variant="underlined"
            />
          </v-col>
          <v-col :cols="$vuetify.display.mobile ? 12 : 6">
            <v-text-field
              v-model="customer.address.zip"
              :rules="validations.addressZipCode"
              :label="$t('cardApplication.personalData.addressZipCode')"
              :class="{ 'disable-events': disabled }"
              data-cy="personal-data-form-addressZipCode"
              variant="underlined"
            />
          </v-col>
        </v-row>
        <div v-if="!disabled" class="d-flex align-center">
          <v-checkbox
            v-model="termsAndConditionsApproval"
            :rules="validations.gdprTermsApproval"
            data-cy="personal-data-form-terms-and-conditions-checkbox"
          >
            <template #label>
              <div>
                <span class="pr-1">
                  {{ $t('cardApplication.personalData.termsAndConditions1') }}
                </span>
                <a :href="termsAndConditionsLink" target="_blank" @click="$event.stopPropagation()">{{
                  $t('cardApplication.personalData.termsAndConditions2')
                }}</a>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div v-if="!disabled" class="d-flex align-center">
          <v-checkbox
            v-model="gdprApproval"
            :rules="validations.gdprTermsApproval"
            data-cy="personal-data-form-gdpr-checkbox"
          >
            <template #label>
              <div>
                <span class="pr-1">
                  {{ $t('cardApplication.personalData.gdpr1') }}
                </span>
                <a :href="gdprLink" target="_blank" @click="$event.stopPropagation()">{{
                  $t('cardApplication.personalData.gdpr2')
                }}</a>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div v-if="cardApplicationModule.isHaulierCardType" class="mb-4">
          <v-alert type="info" density="compact" variant="outlined">
            {{ $t('cardApplication.personalData.cardApplicationInfoAlert') }}
          </v-alert>
        </div>
        <div
          v-if="systemConfigurationModule.configuration.marketingConsentCardEnabled && !disabled"
          class="d-flex align-center"
        >
          <v-checkbox v-model="marketingConsent" data-cy="personal-data-form-marketing-consent-checkbox">
            <template #label>
              <span v-html="$t('cardApplication.personalData.marketingConsentCard')" />
            </template>
          </v-checkbox>
        </div>
      </v-card-text>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-facing-decorator';
import {
  checkedValidator,
  dateValidator,
  emailValidator,
  maxLengthValidator,
  requiredValidator,
  requiredValidatorIf,
} from '@/utils/validators';
import cardApplicationModule from '@/store/modules/cardApplication';
import CustomerProfileModule from '@/store/modules/customerProfile';
import KeyboardEventHelper from '@common/utils/keyboardEventHelper';
import { CardApplicationDto, CustomerDetailVm, CustomerDto, CustomerProfileQuery } from '@/api';
import RDPCropper from '@/components/common/RDPCropper/RDPCropper.vue';
import { RDPCropperInterface } from '@/components/common/RDPCropper/RDPCropperInterface';
import {
  DATE_BIRTHDAY_FORMAT,
  DATE_FORMAT,
  DATE_PICKER_FORMAT,
  MIN_DAY_OF_BIRTHDAY,
  toDate,
  toMomentDateFormat,
} from '@/utils/dateTime';
import { GDPR_URL, MIN_CUSTOMER_AGE, TERMS_AND_CONDITIONS_URL } from '@/config/config';
import Table from '@/constants/Table';
import debounce from 'lodash/debounce';
import customerProfileService from '@/services/api/customerProfileService';
import isDefined from '@common/utils/isDefined';
import UserAccountModule from '@/store/modules/userAccount';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { RDP_PHOTO_ASPECT_RATIO } from '@/constants/Image';
import { CustomerUniqueCheckType } from '@/components/cardApplication/customerUniqueCheckType';
import { CardApplicationDialogType } from '@/components/cardApplication/cardApplicationDialogType';
import ToastModule from '@/store/modules/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';
import { FormInterface } from '@/components/common/FormInterface';
import { ValidationInterface } from '@/components/common/ValidationInterface';
import { addDays, differenceInYears, format, parse, startOfDay, subYears } from 'date-fns';

@Component({
  components: {
    RDPCropper,
  },
  expose: ['cropper', 'form', 'validate', 'stepActive'],
  emits: ['set-custodian-step'],
})
export default class PersonalDataForm extends Vue implements ValidationInterface {
  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: String, default: CardApplicationDialogType.Detail })
  readonly dialogType!: CardApplicationDialogType;

  @Prop({ required: false, type: Function, default: undefined })
  readonly checkIfUserIsUnique!: (dto: CustomerDto, type: CustomerUniqueCheckType) => void;

  @Prop({ required: false, type: Boolean, default: false })
  readonly stepActive!: boolean;

  @Ref()
  readonly form!: FormInterface;
  @Ref()
  readonly cropper!: RDPCropperInterface;

  photoErrorMessage = '';
  cardApplicationModule = cardApplicationModule;
  customerProfileModule = CustomerProfileModule;
  systemConfigurationModule = SystemConfigurationModule;
  userAccountModule = UserAccountModule;
  photoAspectRatio = RDP_PHOTO_ASPECT_RATIO;
  dateFormat = DATE_BIRTHDAY_FORMAT;
  dateFormatPlaceholder = toMomentDateFormat(this.dateFormat);

  allowPhoneNumber = KeyboardEventHelper.allowPhoneNumber;

  get validations() {
    return {
      firstName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
      lastName: [(v: string) => requiredValidator(v), (v: string) => maxLengthValidator(v, 50)],
      birthDay: [
        (v: string) => requiredValidator(v),
        (v: string) => dateValidator(v, this.dateFormat, this.getMinDayOfBirthDay(), this.getMaxDayOfBirthDay()),
      ],
      photo: [(v: string) => requiredValidator(v)],
      addressStreet: [
        (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetRequired),
        (v: string) => maxLengthValidator(v, 50),
      ],
      addressStreetNumber: [
        (v: string) =>
          requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressStreetNumberRequired),
        (v: string) => maxLengthValidator(v, 15),
      ],
      addressCity: [
        (v: string) => requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressCityRequired),
        (v: string) => maxLengthValidator(v, 50),
      ],
      addressZipCode: [
        (v: string) =>
          requiredValidatorIf(v, () => this.systemConfigurationModule.configuration.addressZipCodeRequired),
        (v: string) => maxLengthValidator(v, 6),
      ],
      email: [(v: string) => emailValidator(v), (v: string) => maxLengthValidator(v, 50)],
      phone: [(v: string) => maxLengthValidator(v, 20)],
      gdprTermsApproval: [(v: boolean) => checkedValidator(v)],
    };
  }

  async created() {
    await this.getCustomerProfiles();
  }

  async getCustomerProfiles() {
    try {
      const query = this.createCustomerProfileQuery();
      const profiles = await customerProfileService.getCustomerProfileList(query);
      this.customerProfileModule.setCustomerProfiles(profiles);
    } catch (e) {
      const errorName = `cardApplication.errors.${(e as ApiErrorInterface)?.data?.name}`;
      if (this.$te(errorName)) {
        ToastModule.error({ message: this.$t(errorName) });
      }
    }
  }

  get disabledBirthday() {
    return this.disabled || !this.editableBirthday;
  }

  get photoInfoAlert() {
    return this.cardApplicationModule.isVirtualCardType
      ? this.$t('cardApplication.personalData.virtualCardPhotoInfoAlert')
      : this.$t('cardApplication.personalData.chipCardPhotoInfoAlert');
  }

  get termsAndConditionsLink() {
    return TERMS_AND_CONDITIONS_URL.CARD_APPLICATION;
  }

  get gdprLink() {
    return GDPR_URL.CARD_APPLICATION;
  }

  get customer(): CustomerDetailVm {
    return this.cardApplicationModule.customerData;
  }

  set customer(data: CustomerDetailVm) {
    this.cardApplicationModule.setCustomerData(data);
  }

  get cardData(): CardApplicationDto {
    return this.cardApplicationModule.cardData;
  }

  get editableBirthday() {
    return (
      !this.userAccountModule.hasActiveCardApplication ||
      (this.dialogType === CardApplicationDialogType.CreateAssignedCard && !this.cardApplicationModule.isCustomerFilled)
    );
  }

  get termsAndConditionsApproval() {
    return this.cardApplicationModule.termsAndConditionsApproval;
  }

  set termsAndConditionsApproval(value: boolean) {
    this.cardApplicationModule.setTermsAndConditionsApproval(value);
  }

  get gdprApproval() {
    return this.cardApplicationModule.gdprApproval;
  }

  set gdprApproval(value: boolean) {
    this.cardApplicationModule.setGdprApproval(value);
  }

  get marketingConsent() {
    return this.cardApplicationModule.marketingConsent;
  }

  set marketingConsent(value: boolean) {
    this.cardApplicationModule.setMarketingConsent(value);
  }

  getMaxDayOfBirthDay() {
    let years: number;
    if (this.dialogType === CardApplicationDialogType.Create) {
      years = MIN_CUSTOMER_AGE;
    } else if (this.dialogType === CardApplicationDialogType.CreateAssignedCard) {
      years = 0; // not limited - see RDP-1322
    } else {
      years = 0;
    }

    return subYears(new Date(), years).toISOString().substring(0, 10);
  }

  getMinDayOfBirthDay() {
    if (
      this.dialogType === CardApplicationDialogType.CreateAssignedCard &&
      !this.systemConfigurationModule.isAssignCardWithoutAgeRestrictionAllowed
    ) {
      return addDays(subYears(new Date(), MIN_CUSTOMER_AGE), 1).toISOString().substring(0, 10);
    }

    return MIN_DAY_OF_BIRTHDAY;
  }

  cropClosed() {
    if (this.customer.photo === '') {
      this.photoErrorMessage = this.$t('validators.required');
    } else {
      this.photoErrorMessage = '';
    }
  }

  @Watch('customer.photo')
  photoChanged() {
    if (this.customer.photo) {
      this.photoErrorMessage = '';
    }
  }

  @Watch('customer.birthDay')
  async birthDayChanged(newValue: string) {
    this.checkIfIsUniqueDebounced(newValue);
    if (!this.cardData.profileOne.profile.id) {
      await this.searchProfilesDataDebounced();
    }

    this.$emit('set-custodian-step', this.isCustodianRequired());
  }

  @Watch('customer.firstName')
  firstNameChanged(newValue: string) {
    this.checkIfIsUniqueDebounced(newValue);
  }

  @Watch('customer.lastName')
  lastNameChanged(newValue: string) {
    this.checkIfIsUniqueDebounced(newValue);
  }

  checkIfIsUniqueDebounced(newValue: string) {
    if (!isDefined(newValue)) {
      // do not check empty value as there is no need to
      // and the function this.checkIfIsUnique() kills a success toast which we do not want
      // see RDP-527
      return;
    } else {
      debounce(this.checkIfIsUnique, Table.DEFAULT_TABLE_DEBOUNCE_TIME)();
    }
  }

  checkIfIsUnique() {
    this.checkIfUserIsUnique(this.customer, CustomerUniqueCheckType.Customer);
  }

  searchProfilesDataDebounced = debounce(this.getCustomerProfiles, Table.DEFAULT_TABLE_DEBOUNCE_TIME);

  getCustomerAge() {
    return differenceInYears(startOfDay(new Date()), startOfDay(this.customer.birthDay));
  }

  setCustomerPhoto(photo: string) {
    this.cardApplicationModule.setCustomerPhoto(photo);
  }

  async validate() {
    if (this.disabled) {
      return true; // skip validation
    }

    // here you can create your own rules for this component form
    if (this.customer.photo === '') {
      this.photoErrorMessage = this.$t('validators.required');
    }

    if (this.cropper.isCropperActive()) {
      this.photoErrorMessage = this.$t('cardApplication.personalData.errorCropperActive');
    }

    const formValid = await this.form.validate();
    if (!formValid.valid && !this.cropper.isCropperActive()) {
      const el = document.querySelector('.v-messages__message:first-of-type');
      el?.scrollIntoView();
    }

    return formValid.valid && this.customer.photo !== '' && !this.cropper.isCropperActive();
  }

  createCustomerProfileQuery() {
    const query: CustomerProfileQuery = {
      onlyPrimary: true,
      type: CustomerProfileQuery.type.PERSONAL,
      validity: CustomerProfileQuery.validity.PRESENT_OR_FUTURE,
    };
    return query;
  }

  isCustodianRequired(): boolean {
    if (!this.customer.birthDay || dateValidator(this.customer.birthDay as string, DATE_BIRTHDAY_FORMAT) !== true) {
      return false;
    }

    const birthDay = format(parse(this.customer.birthDay, DATE_PICKER_FORMAT, new Date()), DATE_FORMAT);
    const currentDate = toDate(new Date());
    const customerYears = differenceInYears(currentDate, birthDay);

    return customerYears < MIN_CUSTOMER_AGE;
  }
}
</script>

<style scoped lang="scss">
.user-photo-error-message {
  color: rgb(var(--v-theme-error));
  font-size: 12px;
  margin-top: 10px;
}

.card-text {
  height: 550px;
  overflow: auto;
}

.permanent-address {
  text-shadow: 0px 0px 0px black;
}
</style>
