<template>
  <!--
    from file(s): dobit_epenezenku.svg, epenezenka.svg, epenezenka_red.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65l0 232.05 -308.12 0c-44.1,0 -80,35.9 -80,80l0 239.68c0,44.1 35.9,80 80,80l308.12 0 0 232.05c0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65zm532.57 -493.19c33.1,0 59.93,27.44 59.93,61.3 0,33.86 -26.83,61.3 -59.93,61.3 -33.1,0 -59.93,-27.44 -59.93,-61.3 0,-33.86 26.83,-61.3 59.93,-61.3zm-102.33 -88.54l305.1 0 0 299.68 -305.1 0c-16.5,0 -30,-13.5 -30,-30l0 -239.68c0,-16.5 13.5,-30 30,-30z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class ElectronicWalletIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
