<template>
  <!--
    from file(s): muj_ucet.svg, muj_ucet_red.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="9mm"
    height="11mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 900 1100"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil1"
        d="M271.05 91.42l39.31 -4.35 -51.62 54.8c-6.87,7.55 -10.87,10.47 -17.01,21.68 -11.88,21.66 -40.42,74.57 -39.28,105.02 6.21,-6.85 13.86,-15.62 18.98,-23.06 -14.47,47.96 -42.77,113.89 -7.48,157.78 27.98,34.79 -6.31,22.04 16.83,73.6 11.35,25.28 17.07,49.28 46.31,54.46 6.14,38.77 12.45,85.04 33.54,106.49 17.78,18.08 23.75,12.54 23.8,46.58 0.03,22.69 -1.91,44.47 -5.58,66.4 -29.67,18.02 -26.02,4.7 -41.09,40.66 -94.28,4.87 -148.49,9.15 -196.35,65.76 -19.33,22.86 -26.46,56.55 -36.21,93.06 -1.62,6.04 -3.37,12.31 -5.2,18.74l0 84.68 799.99 0 0 -133.01c-8.61,-26.2 -17.27,-48.23 -24.3,-58.33 -39.48,-56.74 -95.92,-63.6 -188.46,-70.09 -22.55,-30.25 3.93,-24.59 -55.24,-41.47 -4.03,-20.54 -3.56,-41.66 -3.37,-62.9 0.85,-93.77 25.28,16.61 52.74,-156.41 31.86,-5.05 32.96,-21.24 46.1,-47.52 20.84,-41.72 0.12,-29.31 22.44,-75.33 29.04,-59.94 13.12,-89.9 -9.21,-145.86 10.13,5.76 20.19,13.36 29.7,17.3 -15.76,-56.04 -40.61,-84.41 -66.67,-122.26l23.26 6.64c-8.02,-29.66 -47.04,-59.18 -74.59,-73.03 -44.49,-22.36 -70.99,-18.72 -123.99,-15.7 -20.15,1.15 -46.54,-34.27 -109.51,-28.93 -32.32,2.74 -85.79,23.6 -97.84,44.6z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class UserAccountIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil1 {
  fill: currentColor;
}
</style>
