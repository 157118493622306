<template>
  <v-dialog
    v-model="show"
    max-width="1000"
    :max-height="$vuetify.display.mobile ? '100%' : '85%'"
    :fullscreen="$vuetify.display.mobile"
    persistent
    scrollable
    data-cy="card-application-dialog"
  >
    <v-card data-cy="card-application-form-dialog">
      <v-toolbar
        color="systemPrimary"
        theme="dark"
        flat
        :height="$vuetify.display.mobile ? '100%' : '64px'"
        :class="$vuetify.display.mobile ? 'pt-1' : ''"
        class="dialog-toolbar"
      >
        <v-card-title class="headlineWrapper">
          <div class="text-h5 dialog-title d-flex">
            <v-icon start size="large" color="white">
              {{ dialogTypes[dialogType].icon }}
            </v-icon>
            <div class="align-self-center">
              {{ dialogTitle }}
            </div>
          </div>
        </v-card-title>
      </v-toolbar>

      <v-card-text class="pa-0">
        <!-- eslint-disable-next-line vuetify/no-deprecated-components -->
        <v-stepper v-model="activeStep" :mobile="$vuetify.display.mobile">
          <v-stepper-header>
            <template v-for="(step, index) of steps" :key="`header-${index}`">
              <v-stepper-item
                :complete="activeStep > index + 1"
                :value="index + 1"
                :title="step.title"
                color="systemSecondary"
              />
              <v-divider v-if="index !== steps.length - 1" :key="`divider-${index}`" />
            </template>
          </v-stepper-header>

          <v-stepper-window :touch="false">
            <v-stepper-window-item v-for="(step, index) of steps" :key="`step-${step.title}`" :value="index + 1" eager>
              <component
                :is="step.form"
                ref="dialogSteps"
                :disabled="step.formDisabled"
                :dialog-type="dialogType"
                :step-active="activeStep === index + 1"
                :check-if-user-is-unique="checkIfUserIsUnique"
                @set-custodian-step="setCustodianStep"
              />
            </v-stepper-window-item>

            <RDPSpinner :show="showAttachmentLoading" i18n-key="cardApplication.attachmentUploading" />
            <RDPSpinner
              :show="showTokenizationRedirectLoading"
              i18n-key="cardApplication.tokenizationRedirectLoading"
            />
          </v-stepper-window>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn
            v-if="activeStep === 1 || (activeStep === steps.length && dialogType === dialogTypesEnum.Detail)"
            color="buttonWarning"
            theme="dark"
            data-cy="card-application-dialog-close-dialog-button"
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            variant="elevated"
            @click="closeDialog()"
          >
            <template #prepend>
              <v-icon> mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>

          <v-btn
            v-if="activeStep > 1"
            class="pl-2"
            color="buttonPrimary"
            data-cy="card-application-dialog-back-step-button"
            theme="dark"
            :class="$vuetify.display.mobile ? 'mb-2 ml-0' : ''"
            variant="elevated"
            @click="previousStep()"
          >
            <template #prepend>
              <v-icon> mdi-chevron-left</v-icon>
            </template>
            {{ $t('buttons.back') }}
          </v-btn>
          <v-btn
            v-if="showNextButton"
            class="pr-2"
            color="buttonPrimary"
            data-cy="card-application-dialog-next-step-button"
            :theme="!nextStepButtonsDisabled ? 'dark' : ''"
            :disabled="nextStepButtonsDisabled"
            :class="$vuetify.display.mobile ? 'mb-2 ml-0' : ''"
            variant="elevated"
            @click="nextStep()"
          >
            {{ $t('buttons.next') }}
            <template #append>
              <v-icon> mdi-chevron-right</v-icon>
            </template>
          </v-btn>
          <confirm-button
            v-if="showConfirmButton"
            :text="confirmText"
            :confirm="save"
            button-color="buttonConfirm"
            icon="mdi-check"
            dark
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            data-cy="card-application-dialog-saveContinue-button"
          />
          <confirm-button
            v-if="cardApplicationModule.isHaulierCardType && showConfirmButton && showRedirectConfirmButton"
            :text="saveAndRedirectText"
            :confirm="saveAndRedirect"
            button-color="buttonConfirm"
            icon="mdi-check"
            dark
            :class="$vuetify.display.mobile ? 'mb-2' : ''"
            data-cy="card-application-dialog-saveRedirect-button"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import PersonalDataForm from './dialogSteps/PersonalDataForm.vue';
import CardDataForm from './dialogSteps/CardDataForm.vue';
import SummaryForm from './dialogSteps/SummaryForm.vue';
import CustodianForm from '@/components/cardApplication/dialogSteps/CustodianForm.vue';
import CardApplicationModule, { Attachment, REGISTER_NEW_BANK_CARD } from '@/store/modules/cardApplication';
import AuthModule from '@/store/modules/auth';
import CustomerModule from '@/store/modules/customer';
import { RDPCropperInterface } from '@/components/common/RDPCropper/RDPCropperInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { CARD_APPLICATION_DIALOG_TYPE, CardApplicationDialogType } from './cardApplicationDialogType';
import customerService from '@/services/api/customerService';
import { CardApplicationDto, CardApplicationHeaderVm, CustomerDetailVm, EnumsDto } from '@/api';
import cardApplicationService from '@/services/api/cardApplicationService';
import { DATE_BIRTHDAY_FORMAT, DATE_PICKER_FORMAT, toDate } from '@/utils/dateTime';
import Errors from '@/constants/Errors';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import Paths from '@/constants/Paths';
import { CustomerUniqueCheckType } from '@/components/cardApplication/customerUniqueCheckType';
import RDPSpinner from '@/components/common/RDPSpinner.vue';
import { MIN_CUSTOMER_AGE } from '@/config/config';
import bankCardService from '@/services/api/bankCardService';
import { getUrl } from '@/utils/tokenizationRequest';
import { getNumberedApiError } from '@/utils/toast.ts';
import ToastModule from '@/store/modules/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { markRaw } from 'vue';
import { isValid, parse } from 'date-fns';

export interface CardApplicationStepperDialogInterface {
  openDialog: (
    dialogType: CardApplicationDialogType,
    carrierType: EnumsDto.carrierTypes,
    applicationId?: string,
    customerId?: string,
  ) => void;
  closeDialog: () => void;
}

interface DialogStepComponentInterface extends Vue {
  validate: () => Promise<boolean>;
  createCardApplication: (dialogType: CardApplicationDialogType) => Promise<CardApplicationHeaderVm>;
  updateCardApplication: () => Promise<CardApplicationHeaderVm>;
  searchedUser: {
    text: string;
    value: string;
  };
  form: {
    validate: () => Promise<boolean>;
    resetValidation: () => boolean;
  };
  photoErrorMessage: string;
  cropper: RDPCropperInterface;
  initExistingBankCardsData?: () => void;
  disabled: boolean;
  stepActive: boolean;
}

interface DialogStepInterface {
  title: string;
  formDisabled: boolean;
  form: typeof PersonalDataForm | typeof CardDataForm | typeof SummaryForm | typeof CustodianForm;
}

@Component({
  components: {
    ConfirmButton,
    RDPSpinner,
  },
  emits: ['confirmDialog'],
})
export default class CardApplicationStepperDialog extends Vue implements CardApplicationStepperDialogInterface {
  @Ref()
  readonly dialogSteps!: DialogStepComponentInterface[];

  dialogType = CardApplicationDialogType.Detail;
  dialogTypes = CARD_APPLICATION_DIALOG_TYPE;
  dialogTypesEnum = CardApplicationDialogType;
  activeStep = 1;
  cardApplicationModule = CardApplicationModule;
  authModule = AuthModule;
  customerModule = CustomerModule;
  shoppingCartModule = ShoppingCartModule;
  systemConfigurationModule = SystemConfigurationModule;
  nextStepButtonsDisabled = false;
  show = false;
  showAttachmentLoading = false;
  showTokenizationRedirectLoading = false;
  stepperIndex = {
    PERSON_DATA_INDEX: 0,
    CUSTODIAN_DATA_INDEX: 1,
    CARD_DATA_INDEX: 2,
    SUMMARY_DATA_INDEX: 3,
  };
  steps: DialogStepInterface[] = [];

  created() {
    this.setSteps();
  }

  setSteps() {
    const steps: DialogStepInterface[] = [
      {
        title: this.$t('cardApplication.personalData.stepName'),
        form: markRaw(PersonalDataForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.cardData.stepName'),
        form: markRaw(CardDataForm),
        formDisabled: false,
      },
      {
        title: this.$t('cardApplication.summary.stepName'),
        form: markRaw(SummaryForm),
        formDisabled: false,
      },
    ];

    if (
      this.cardApplicationModule.isCustodianAdded &&
      this.dialogType === CardApplicationDialogType.CreateAssignedCard
    ) {
      steps.splice(this.stepperIndex.CUSTODIAN_DATA_INDEX, 0, {
        title: this.$t('cardApplication.custodianData.stepName'),
        form: markRaw(CustodianForm),
        formDisabled: false,
      });
    }

    this.steps = steps;
  }

  get confirmText() {
    const i18nKey =
      this.$vuetify.display.mobile ||
      this.cardApplicationModule.isVirtualCardType ||
      this.cardApplicationModule.isBankCardType
        ? 'ok'
        : 'confirmAndContinue';
    return this.$t(`buttons.${i18nKey}`);
  }

  get saveAndRedirectText() {
    const i18nKey = this.$vuetify.display.mobile ? 'addToShoppingCart' : 'confirmAndGoToShoppingCart';
    return this.$t(`buttons.${i18nKey}`);
  }

  get dialogTitle() {
    const dialogTitle = CARD_APPLICATION_DIALOG_TYPE[this.dialogType].title[this.cardApplicationModule.carrierType];
    return dialogTitle ? this.$t(dialogTitle.translate, dialogTitle.param) : '';
  }

  get showConfirmButton() {
    return this.activeStep === this.steps.length && !this.dialogSteps?.find(step => step.stepActive)?.disabled;
  }

  get showRedirectConfirmButton() {
    return !this.cardApplicationModule.cardData.cardForFree;
  }

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto button-mobile' : '';
  }

  get showNextButton() {
    return this.activeStep === 1 || (this.activeStep > 1 && this.activeStep < this.steps.length);
  }

  async openDialog(
    dialogType: CardApplicationDialogType,
    carrierType: EnumsDto.carrierTypes,
    applicationId?: string,
    customerId?: string,
  ) {
    this.dialogType = dialogType;
    this.cardApplicationModule.setCarrierType(carrierType);
    this.cardApplicationModule.initCardApplication();
    if (applicationId) {
      const cardApplicationData: CardApplicationDto = await cardApplicationService.getCardApplication(applicationId);
      this.cardApplicationModule.setCardData(cardApplicationData);
      if (cardApplicationData.deliveryBranchOffice) {
        this.cardApplicationModule.setBranchOfficeData(cardApplicationData.deliveryBranchOffice);
      }

      if (cardApplicationData.recipientAddress) {
        this.cardApplicationModule.setRecipientAddressData(cardApplicationData.recipientAddress);
      }
      this.cardApplicationModule.setMarketingConsent(cardApplicationData.marketingConsent!);
      await this.initDialogs(cardApplicationData.customer.id);
      await this.disableStepperDialog();
    } else {
      const lastCardApplication = await cardApplicationService.getLastCardApplication();
      if (lastCardApplication) {
        this.cardApplicationModule.setMarketingConsent(lastCardApplication.marketingConsent!);
      }
      await this.initDialogs(customerId);
      this.cardApplicationModule.setCustomerPhoto(''); // let the eshop-user set a new photo when creating a new cardApplication
    }
  }

  initSteps() {
    const disabled = this.dialogType === CardApplicationDialogType.Detail;
    if (this.dialogSteps) {
      if (this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].form.resetValidation();
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].photoErrorMessage = '';
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].disabled = disabled;
      }

      if (this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX].form.resetValidation();
        this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX].photoErrorMessage = '';
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].disabled = disabled;
      }

      if (this.dialogSteps[this.stepperIndex.CARD_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.CARD_DATA_INDEX].form.resetValidation();
        this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX].disabled = disabled;

        if (this.cardApplicationModule.isBankCardType) {
          this.dialogSteps[this.stepperIndex.CARD_DATA_INDEX].initExistingBankCardsData?.();
        }
      }

      if (this.dialogSteps[this.stepperIndex.SUMMARY_DATA_INDEX]) {
        this.dialogSteps[this.stepperIndex.SUMMARY_DATA_INDEX].disabled = disabled;
      }
    }
  }

  async setCustodianStep(setCustodianStep: boolean) {
    if (setCustodianStep !== this.cardApplicationModule.custodianAdded) {
      if (setCustodianStep) {
        this.cardApplicationModule.setCustodianAdded(true);
        await this.setCustodianData();
      } else {
        this.cardApplicationModule.resetCustodian();
        this.initSteps();
      }
      this.setSteps();
    }
  }

  async setCustodianData() {
    const registeredOriginId = this.customerModule.registeredCustomer?.id;
    if (registeredOriginId) {
      const parent = await this.getCustomer(registeredOriginId);
      if (parent) {
        this.cardApplicationModule.setCustodianData(parent);
        this.steps[this.stepperIndex.CUSTODIAN_DATA_INDEX].formDisabled = true;
      }
    }
  }

  disableStepperDialog() {
    for (const step of this.steps) {
      step.formDisabled = true;
    }
  }

  async getCustomer(customerId: string) {
    const customer: CustomerDetailVm = await customerService.getCustomer(customerId);
    if (!customer.photo) {
      customer.photo = '';
    }
    if (!customer.email && this.dialogType !== CardApplicationDialogType.Detail) {
      customer.email = this.authModule.username;
    }

    return customer;
  }

  async initDialogs(customerId?: string) {
    this.activeStep = 1;
    this.initSteps();
    this.show = true;

    if (customerId) {
      const customer = await this.getCustomer(customerId);
      this.cardApplicationModule.setCustomerData(customer);
      if (customer.parent) {
        this.cardApplicationModule.setCustodianData(customer.parent);
        this.cardApplicationModule.setCustodianAdded(true);
      }

      this.cardApplicationModule.setVerifiedProfile(customer);
    }
  }

  async checkIfUserIsUnique(customer: CustomerDetailVm, type: CustomerUniqueCheckType) {
    const { id, originId, firstName, lastName, birthDay } = customer;
    // using DATE_BIRTHDAY_FORMAT allows to write (and then to check) 2.2.1980 and 02.02.1980 (both are valid)
    const isBirthDayValid = isValid(parse(birthDay, DATE_BIRTHDAY_FORMAT, new Date()));
    if (birthDay && isBirthDayValid && firstName && lastName && this.dialogType !== CardApplicationDialogType.Detail) {
      const customerIsUnique = await customerService.checkIfCustomerIsUnique({
        firstName,
        lastName,
        birthDay: toDate(birthDay, DATE_PICKER_FORMAT),
        excludedCustomerIds: [id, originId].filter(id => id),
      });
      if (customerIsUnique.errors.length > 0) {
        this.nextStepButtonsDisabled = true;
        const error = getNumberedApiError(
          { data: { name: `${Errors.CUSTOMER_NOT_UNIQUE}_${type}` } },
          'cardApplication',
        );
        ToastModule.error({
          message: error,
          options: {
            x: 'center',
            y: 'top',
            timeout: 10000,
          },
        });
        return;
      }
    }
    this.nextStepButtonsDisabled = false;
    ToastModule.clearQueue();
  }

  closeDialog() {
    this.dialogSteps[this.stepperIndex.PERSON_DATA_INDEX]?.cropper.closeCrop();
    if (this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX]?.cropper) {
      this.dialogSteps[this.stepperIndex.CUSTODIAN_DATA_INDEX].cropper.closeCrop();
    }
    this.show = false;
    this.cardApplicationModule.initCardApplication();
    this.clearDialog();
  }

  async nextStep() {
    if (await this.dialogSteps?.find(step => step.stepActive)?.validate()) {
      this.activeStep++;
    }

    // temporary workaround - this should be called after opening the dialog but for some reason it doesn't set the profile at all when called from there
    if (this.cardApplicationModule.isVirtualCardType && this.activeStep === this.stepperIndex.CARD_DATA_INDEX - 1) {
      this.cardApplicationModule.setVerifiedProfile(this.cardApplicationModule.customerData);
    }
  }

  get custodian() {
    return this.cardApplicationModule.custodianData;
  }

  previousStep() {
    this.activeStep--;
  }

  async addAttachments(cardApplicationId: string, attachments: Attachment[], isProfileOne = false) {
    for (const attachment of attachments) {
      await cardApplicationService.addAttachment(cardApplicationId, attachment.id, isProfileOne, attachment.file);
    }
  }

  async processAttachments(cardApplicationId: string) {
    try {
      if (!this.cardApplicationModule.emptyAttachments) {
        this.showAttachmentLoading = true;
        await this.addAttachments(
          cardApplicationId,
          this.cardApplicationModule.attachments.profileOneAttachments,
          true,
        );
        await this.addAttachments(cardApplicationId, this.cardApplicationModule.attachments.profileTwoAttachments);
      }
    } catch (e) {
      ToastModule.error({
        message: this.$t('cardApplication.errors.attachmentUploadFailed'),
      });
    }
    this.showAttachmentLoading = false;
  }

  async save() {
    try {
      const activeStep = this.dialogSteps.find(step => step.stepActive);
      const cardApplication = await activeStep?.createCardApplication(this.dialogType);
      if (cardApplication) {
        await this.shoppingCartModule.updateShoppingCart();
        if (this.systemConfigurationModule.cardApplicationAttachmentAllowed) {
          await this.processAttachments(cardApplication.id);
        }
        if (
          this.cardApplicationModule.isBankCardType &&
          this.cardApplicationModule.bankCardId === REGISTER_NEW_BANK_CARD
        ) {
          await this.initTokenization(cardApplication);
        } else {
          this.show = false;
          this.$emit('confirmDialog');
          this.cardApplicationModule.initCardApplication();
          this.activeStep = 1;
          this.clearDialog();
          ToastModule.success({ message: this.$t('cardApplication.createSuccess') });
        }
      }
    } catch (e) {
      ToastModule.error({
        message: getNumberedApiError(e as ApiErrorInterface, 'cardApplication', undefined, {
          minCustomerAge: MIN_CUSTOMER_AGE,
        }),
      });
    }
  }

  async initTokenization(cardApplication: CardApplicationHeaderVm) {
    this.showTokenizationRedirectLoading = true;
    try {
      const customerId = cardApplication.customer.originId || cardApplication.customer.id;
      let appRouterUrl = '';
      if (
        this.$router.currentRoute.value.path.includes(Paths.USER_LINKED_ACCOUNT) ||
        this.$router.currentRoute.value.path === Paths.ASSIGNED_CARD_LIST
      ) {
        appRouterUrl = `${Paths.USER_LINKED_ACCOUNT}/${customerId}`;
      } else {
        appRouterUrl = Paths.USER_ACCOUNT;
      }
      const tokenizationRequest = await bankCardService.initTokenization(cardApplication.id, appRouterUrl);

      window.location.href = getUrl(tokenizationRequest);
    } catch (e) {
      this.showTokenizationRedirectLoading = false;
      ToastModule.error({
        message: this.$t('userAccountPage.tokenizationFailed'),
      });
    }
  }

  async saveAndRedirect() {
    await this.save();
    await this.$router.push(Paths.SHOPPING_CART);
  }

  clearDialog() {
    this.cardApplicationModule.setCarrierType(EnumsDto.carrierTypes.HAULIER_CARD);
    this.dialogType = CardApplicationDialogType.Detail;
  }
}
</script>

<style lang="scss" scoped>
.headlineWrapper {
  width: 100%;
  padding: 12px;
}

.headline {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

header.dialog-toolbar > .v-toolbar__content {
  padding-left: 12px;
}

.v-toolbar__content .v-card__title.headline {
  padding-left: 0;
}

.button-mobile {
  button {
    min-width: 120px;
  }
}
</style>
