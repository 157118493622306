import { DateRangeValidityVm, TariffProfileService } from '@/api';

export default {
  getComputedValidity: async (id: string, date: string | Date): Promise<DateRangeValidityVm> => {
    const response = await TariffProfileService.tariffProfileControllerGetComputedValidity({
      id,
      date: date.toString(),
    });

    return response.data;
  },
};
