/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLocalizationCommand } from './CreateLocalizationCommand';
export type CreateZoneCommand = {
  name: string;
  number: number;
  availableInPriceList: boolean;
  type: CreateZoneCommand.type;
  /**
   * UUIDs of zones to assign to the combined zone.
   */
  zoneIds?: Array<string>;
  localizations: Array<CreateLocalizationCommand>;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CreateZoneCommand {
  export enum type {
    SIMPLE = 'SIMPLE',
    COMBINED = 'COMBINED',
    ALL_NETWORK = 'ALL_NETWORK',
  }
}
