/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEshopReclamationCommand } from '../models/CreateEshopReclamationCommand';
import type { EshopReclamationResponse } from '../models/EshopReclamationResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopReclamationService {
  /**
   * Create a eshop reclamation.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **ESHOP_RECLAMATION_NOT_ALLOWED** - Eshop reclamations are not enabled<br>
   * + **INVALID_GDPR_OR_TERMS_APPROVAL** - GDPR, terms and conditions must be approved.
   * @returns EshopReclamationResponse
   * @throws ApiError
   */
  public static eshopReclamationControllerCreate({
    requestBody,
  }: {
    /**
     * CreateEshopReclamationCommand
     */
    requestBody?: CreateEshopReclamationCommand;
  }): CancelablePromise<EshopReclamationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/reclamations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
