/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateEshopReclamationCommand = {
  state: UpdateEshopReclamationCommand.state;
  evaluationNote: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace UpdateEshopReclamationCommand {
  export enum state {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    FINALIZED = 'FINALIZED',
  }
}
