import { BranchOfficeDto, BranchOfficeQuery, BranchOfficeService } from '@/api';
import { getQueryParams } from '@/utils/api';

export default {
  getBranchOfficeList: async (query: BranchOfficeQuery): Promise<BranchOfficeDto[]> => {
    const response = await BranchOfficeService.branchOfficeControllerGetAll(getQueryParams(query));

    return response.data;
  },
};
