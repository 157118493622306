<template>
  <v-dialog v-model="show" max-width="600" :fullscreen="$vuetify.display.mobile" scrollable persistent>
    <v-card>
      <v-card-title class="text-h5 pl-3 d-flex bg-systemPrimary dialog-title">
        <v-icon size="small" start color="white" icon="$manageCardRequestIcon" />
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text px-11" style="max-height: 800px">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-row>
            <v-col class="text-justify">
              <v-alert border="top" type="info" variant="text" elevation="2" class="pt-6 pr-6">
                <span v-html="t('info')"></span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="username"
                :label="t('email')"
                :rules="validations.email"
                variant="underlined"
                :data-cy="cy('email')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn
          color="buttonWarning"
          :data-cy="cy('cancel-button')"
          variant="elevated"
          class="mr-2"
          @click="closeDialog"
        >
          <template #prepend>
            <v-icon>mdi-cancel</v-icon>
          </template>
          {{ $t('buttons.close') }}
        </v-btn>
        <ConfirmButton :confirm="confirmDialog" :data-cy="cy('confirm-button')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import { FormInterface } from '@/components/common/FormInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { emailValidator, requiredValidator } from '@/utils/validators';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import ToastModule from '@/store/modules/toast';
import { getNumberedApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({ components: { ConfirmButton } })
export default class ManageCardRequestDialog extends ComponentBase {
  @Ref()
  readonly form!: FormInterface;

  show = false;
  username = '';
  validations = {
    email: [(v: string) => requiredValidator(v), (v: string) => emailValidator(v)],
  };

  dataCy = 'card-manage-request-dialog';
  i18nGroupKey = 'cardManagingAccountList.cardManageRequestDialog';

  async openDialog() {
    this.show = true;
  }

  closeDialog() {
    this.show = false;
    this.resetForm();
  }

  resetForm() {
    this.username = '';
    this.form.resetValidation();
  }

  async confirmDialog() {
    if ((await this.form.validate()).valid) {
      try {
        await customerAccountLinkService.manageCardRequest({
          username: this.username,
        });
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.saveSuccess`),
        });
        this.closeDialog();
      } catch (e) {
        ToastModule.error({
          message: getNumberedApiError(e as ApiErrorInterface, this.i18nGroupKey, 'saveFailed'),
        });
      }
    }
  }
}
</script>
<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
