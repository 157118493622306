export const gpInitPaymentDataNames = {
  merchantNumber: 'MERCHANTNUMBER',
  operation: 'OPERATION',
  orderNumber: 'ORDERNUMBER',
  amount: 'AMOUNT',
  currency: 'CURRENCY',
  depositFlag: 'DEPOSITFLAG',
  url: 'URL',
  addInfo: 'ADDINFO',
  digest: 'DIGEST',
  merordernum: 'MERORDERNUM',
};
