import { CreateCustomerCommand, CustomerDetailVm, CustomerDto } from '@/api';
import { imageStringToBase64 } from '@/utils/imageStringToBase64';
import isDefined from '@common/utils/isDefined';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from '@/utils/dateTime';
import { format, parse } from 'date-fns';
import { CardApplicationDialogType } from '@/components/cardApplication/cardApplicationDialogType.ts';

export const toCreateBaseCustodianCommand = (customerData: CustomerDto) => {
  return {
    name: {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
    },
    birthDay: customerData.birthDay
      ? format(parse(customerData.birthDay, DATE_PICKER_FORMAT, new Date()), DATE_FORMAT)
      : '',
    email: customerData.email || undefined,
    phoneNumber: customerData.phoneNumber || undefined,
    address: customerData.address,
  };
};

export default {
  toCreateCustomerCommand: (
    customerData: CustomerDetailVm,
    gdprTermsApproval: boolean,
    custodianData: CustomerDto,
    type: CardApplicationDialogType,
  ) => {
    const linkType =
      type === CardApplicationDialogType.CreateAssignedCard
        ? CreateCustomerCommand.accountLinkType.ASSIGNED
        : CreateCustomerCommand.accountLinkType.REGISTERED;

    const customer: CreateCustomerCommand = {
      name: {
        firstName: customerData.firstName,
        lastName: customerData.lastName,
      },
      email: customerData.email || undefined,
      birthDay: customerData.birthDay
        ? format(parse(customerData.birthDay, DATE_PICKER_FORMAT, new Date()), DATE_FORMAT)
        : '',
      phoneNumber: customerData.phoneNumber || undefined,
      address: customerData.address,
      photo: imageStringToBase64(customerData.photo || ''),
      originId: isDefined(customerData.id) ? customerData.id : undefined,
      gdprTermsApproval,
      pin: customerData.pin || undefined, // pin is not required, so set to undefined if its equal to ''
      parentId: custodianData.id || undefined,
      parent: !custodianData.id && custodianData.firstName ? toCreateBaseCustodianCommand(custodianData) : undefined,
      isicNumber: customerData.isicNumber || undefined,
      accountLinkType: linkType,
    };

    return customer;
  },
};
