<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="600"
    scrollable
    persistent
    data-cy="marketing-consent-dialog"
  >
    <v-card>
      <v-card-title class="text-h5 d-flex pl-3 bg-systemPrimary dialog-title">
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text px-11">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-col class="py-0 d-flex align-center">
            <v-checkbox v-model="marketingConsent" data-cy="marketing-consent-checkbox">
              <template #label>
                <span v-html="t('marketingConsent')" />
              </template>
            </v-checkbox>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn
          class="mr-2"
          color="buttonWarning"
          :data-cy="cy('cancel-button')"
          variant="elevated"
          @click="closeDialog"
        >
          <template #prepend>
            <v-icon>mdi-cancel</v-icon>
          </template>
          {{ $t('buttons.close') }}
        </v-btn>
        <ConfirmButton :confirm="confirmDialog" :data-cy="cy('confirm-button')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import { FormInterface } from '@/components/common/FormInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import customerCardService from '@/services/api/customerCardService';
import { CustomerCardDetailVm } from '@/api';
import ToastModule from '@/store/modules/toast.ts';
import { getApiError } from '@/utils/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({ components: { ConfirmButton } })
export default class MarketingConsentDialog extends ComponentBase {
  @Ref()
  readonly form!: FormInterface;

  dataCy = 'marketing-consent-dialog';
  i18nGroupKey = 'userAccountPage.marketingConsentDialog';
  toastTimeout = 10000;
  marketingConsent = true;
  customerCard: CustomerCardDetailVm | null = null;

  show = false;

  async openDialog(id: string) {
    this.customerCard = await customerCardService.getCard(id);
    this.marketingConsent = this.customerCard.marketingConsent!;
    this.show = true;
  }

  closeDialog() {
    this.show = false;
    this.resetForm();
  }

  private resetForm() {
    this.form.resetValidation();
  }

  async confirmDialog() {
    if ((await this.form.validate()).valid) {
      try {
        await customerCardService.marketingConsent(this.customerCard!.id, this.marketingConsent);
        this.$emit('refresh-page');

        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.saveSuccess`),
          options: { timeout: this.toastTimeout },
        });
        this.closeDialog();
      } catch (e) {
        ToastModule.error({ message: getApiError(e as ApiErrorInterface, this.i18nGroupKey, 'saveFailed') });
      }
    }
  }
}
</script>
<style scoped lang="scss">
.headline {
  color: white;
}
</style>
