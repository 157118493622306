export default class Paths {
  public static LOGIN = '/';
  public static REGISTRATION = '/registration';
  public static FORGOTTEN_PASSWORD = '/forgotten-password';
  public static CHANGE_PASSWORD = '/change-password';
  public static VERIFICATION = '/verification';
  public static VERIFY = '/verify';
  public static APPROVE_MANAGED_CARD = '/approve-managed-card';
  public static REJECT_CARD_MANAGE_REQUEST_PAGE = '/reject-managed-card';
  public static APP = '/app';
  public static USER_ACCOUNT = '/app/user-account';
  /**
   * This does not have a left-menu item - it opens only from table in AssignedCardListPage.vue
   */
  public static USER_LINKED_ACCOUNT = '/app/user-linked-account';
  public static USER_LINKED_ACCOUNT_WITH_ID = '/app/user-linked-account/:customerId';
  public static NOTIFICATION_SETTING = '/app/notification-setting';
  public static TICKET_FARE = '/app/ticket-fare';
  public static ELECTRONIC_WALLET = '/app/electronic-wallet';
  public static SHOPPING_CART = '/app/shopping-cart';
  public static ORDER_LIST = '/app/order-list';
  public static ACCOUNT_SETTING = '/app/account-setting';
  public static ASSIGNED_CARD_LIST = '/app/assigned-card-list';
  public static MANAGED_CARD_LIST = '/app/managed-card-list';
  public static CARD_MANAGING_ACCOUNT_LIST = '/app/card-managing-account-list';
}

/**
 * To go to these paths, user must be logged in. Path.APP includes all the children automatically.
 */
export const LOGIN_REQUIRED_PATHS = [Paths.APP, Paths.APPROVE_MANAGED_CARD, Paths.REJECT_CARD_MANAGE_REQUEST_PAGE];
