/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MessageDto = {
  /**
   * Message identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Type of message
   */
  type: MessageDto.type;
  /**
   * Date and time the message was created
   */
  time: string;
};
export namespace MessageDto {
  /**
   * Type of message
   */
  export enum type {
    CUSTOMER_ACCOUNT_CREATED_EVENT = 'CustomerAccountCreatedEvent',
    CUSTOMER_ACCOUNT_UPDATED_EVENT = 'CustomerAccountUpdatedEvent',
    CUSTOMER_ACCOUNT_DELETED_EVENT = 'CustomerAccountDeletedEvent',
    TARIFF_PROFILE_CREATED_EVENT = 'TariffProfileCreatedEvent',
    TARIFF_PROFILE_UPDATED_EVENT = 'TariffProfileUpdatedEvent',
    TARIFF_PROFILE_DELETED_EVENT = 'TariffProfileDeletedEvent',
    CUSTOMER_CREATED_EVENT = 'CustomerCreatedEvent',
    CUSTOMER_UPDATED_EVENT = 'CustomerUpdatedEvent',
    CUSTOMER_DELETED_EVENT = 'CustomerDeletedEvent',
    CUSTOMER_PROFILE_CREATED_EVENT = 'CustomerProfileCreatedEvent',
    CUSTOMER_PROFILE_UPDATED_EVENT = 'CustomerProfileUpdatedEvent',
    CUSTOMER_PROFILE_DELETED_EVENT = 'CustomerProfileDeletedEvent',
    CUSTOMER_CARD_CREATED_EVENT = 'CustomerCardCreatedEvent',
    CUSTOMER_CARD_UPDATED_EVENT = 'CustomerCardUpdatedEvent',
    CUSTOMER_CARD_DELETED_EVENT = 'CustomerCardDeletedEvent',
    ZONE_CREATED_EVENT = 'ZoneCreatedEvent',
    ZONE_UPDATED_EVENT = 'ZoneUpdatedEvent',
    ZONE_DELETED_EVENT = 'ZoneDeletedEvent',
    ZONE_SYNC_EVENT = 'ZoneSyncEvent',
    TICKET_FARE_CREATED_EVENT = 'TicketFareCreatedEvent',
    TICKET_FARE_UPDATED_EVENT = 'TicketFareUpdatedEvent',
    TICKET_FARE_DELETED_EVENT = 'TicketFareDeletedEvent',
  }
}
