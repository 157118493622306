import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { EnumsDto, GlobalSettingDto } from '@/api';
import { GlobalSetting as GlobalSettingConstants } from '@/constants/GlobalSettting';

const globalSettingFactory = (): GlobalSettingDto => {
  return {
    id: '',
    displayName: '',
    name: '',
    value: 0,
  } as GlobalSettingDto;
};

export const GLOBAL_SETTING_MIN_TICKET_DAYS_IN_ADVANCE_VALUE = 1;
export const GLOBAL_SETTING_MAX_TICKET_DAYS_IN_ADVANCE_VALUE = 10;
export const GLOBAL_SETTING_MIN_CARD_DAYS_IN_ADVANCE_VALUE = 30;
export const GLOBAL_SETTING_MAX_CARD_DAYS_IN_ADVANCE_VALUE = 90;

@Module({ dynamic: true, store, namespaced: true, name: 'globalSetting' })
class GlobalSetting extends VuexModule {
  public globalSettings: Array<GlobalSettingDto> = [];
  public customerCardValidity: GlobalSettingDto = globalSettingFactory();
  public customerProfileValidToInYears: GlobalSettingDto = globalSettingFactory();
  public customerNotificationMinCardDaysInAdvance: number = GLOBAL_SETTING_MIN_CARD_DAYS_IN_ADVANCE_VALUE;
  public customerNotificationMaxCardDaysInAdvance: number = GLOBAL_SETTING_MAX_CARD_DAYS_IN_ADVANCE_VALUE;
  public customerNotificationMinTicketDaysInAdvance: number = GLOBAL_SETTING_MIN_TICKET_DAYS_IN_ADVANCE_VALUE;
  public customerNotificationMaxTicketDaysInAdvance: number = GLOBAL_SETTING_MAX_TICKET_DAYS_IN_ADVANCE_VALUE;

  @Mutation
  public setGlobalSettings(payload: Array<GlobalSettingDto>): void {
    this.globalSettings = payload;
    this.customerCardValidity =
      payload.find(setting => setting.name === GlobalSettingConstants.CUSTOMER_CARD_VALIDITY) || globalSettingFactory();
    this.customerProfileValidToInYears =
      payload.find(setting => setting.name === EnumsDto.globalSettings.CUSTOMER_PROFILE_DEFAULT_VALID_TO_IN_YEARS) ||
      globalSettingFactory();
    this.customerNotificationMinCardDaysInAdvance =
      payload.find(setting => setting.name === EnumsDto.globalSettings.CUSTOMER_NOTIFICATION_MIN_CARD_DAYS_IN_ADVANCE)
        ?.value || GLOBAL_SETTING_MIN_CARD_DAYS_IN_ADVANCE_VALUE;
    this.customerNotificationMaxCardDaysInAdvance =
      payload.find(setting => setting.name === EnumsDto.globalSettings.CUSTOMER_NOTIFICATION_MAX_CARD_DAYS_IN_ADVANCE)
        ?.value || GLOBAL_SETTING_MAX_CARD_DAYS_IN_ADVANCE_VALUE;
    this.customerNotificationMinTicketDaysInAdvance =
      payload.find(setting => setting.name === EnumsDto.globalSettings.CUSTOMER_NOTIFICATION_MIN_TICKET_DAYS_IN_ADVANCE)
        ?.value || GLOBAL_SETTING_MIN_TICKET_DAYS_IN_ADVANCE_VALUE;
    this.customerNotificationMaxTicketDaysInAdvance =
      payload.find(setting => setting.name === EnumsDto.globalSettings.CUSTOMER_NOTIFICATION_MAX_TICKET_DAYS_IN_ADVANCE)
        ?.value || GLOBAL_SETTING_MAX_TICKET_DAYS_IN_ADVANCE_VALUE;
  }
}

export default getModule(GlobalSetting);
