/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateZoneCommand } from '../models/CreateZoneCommand';
import type { UpdateZoneCommand } from '../models/UpdateZoneCommand';
import type { ZoneListResponse } from '../models/ZoneListResponse';
import type { ZonePublicListResponse } from '../models/ZonePublicListResponse';
import type { ZoneResponse } from '../models/ZoneResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ZoneService {
  /**
   * Get zones
   * @returns ZonePublicListResponse
   * @throws ApiError
   */
  public static zoneControllerGetAll({
    acceptLanguage,
  }: {
    acceptLanguage?: string;
  }): CancelablePromise<ZonePublicListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/zones-public',
      headers: {
        'Accept-Language': acceptLanguage,
      },
      errors: {
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get all zones.
   * @returns ZoneListResponse
   * @throws ApiError
   */
  public static zoneControllerGetAllZones({
    name,
    type,
    timezoneOffset,
    limit,
    offset,
    sortBy,
    sortDesc,
    sortLocale,
  }: {
    name?: string;
    type?: 'SIMPLE' | 'COMBINED' | 'ALL_NETWORK';
    timezoneOffset?: number;
    limit?: number;
    offset?: number;
    sortBy?: string;
    sortDesc?: boolean;
    sortLocale?: string;
  }): CancelablePromise<ZoneListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/zones',
      query: {
        name: name,
        type: type,
        timezoneOffset: timezoneOffset,
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDesc: sortDesc,
        sortLocale: sortLocale,
      },
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create zone.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_ZONE** - Zone does not exist or is missing in the given ticket combination.
   * @returns ZoneResponse
   * @throws ApiError
   */
  public static zoneControllerCreate({
    requestBody,
  }: {
    /**
     * CreateZoneCommand
     */
    requestBody?: CreateZoneCommand;
  }): CancelablePromise<ZoneResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/public/zones',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get zone by id.
   * @returns ZoneResponse
   * @throws ApiError
   */
  public static zoneControllerGetOne({ id }: { id: string }): CancelablePromise<ZoneResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/zones/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Update zone.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
   * @returns ZoneResponse
   * @throws ApiError
   */
  public static zoneControllerUpdate({
    id,
    requestBody,
  }: {
    id: string;
    /**
     * UpdateZoneCommand
     */
    requestBody?: UpdateZoneCommand;
  }): CancelablePromise<ZoneResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/public/zones/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Delete zone.
   * If an entity is being used, 409 Conflict will be returned.<br>
   * Require: `PERMISSION_ADMINISTRATION_VIEW`
   * @returns ApiResponse
   * @throws ApiError
   */
  public static zoneControllerDelete({ id }: { id: string }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/public/zones/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        409: `Request couldn't be completed due to a conflict.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get zone by id.
   * @returns ZoneResponse
   * @throws ApiError
   */
  public static zoneControllerGetOneByNumber({ number }: { number: number }): CancelablePromise<ZoneResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/zones-by-number/{number}',
      path: {
        number: number,
      },
      errors: {
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Returns combined zone by zone numbers.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_ZONE** - Zone does not exist or is missing in the given ticket combination.
   * @returns ZoneResponse
   * @throws ApiError
   */
  public static zoneControllerGetCombinedZoneByZoneNumbers({
    zoneNumbers,
    timezoneOffset,
  }: {
    zoneNumbers: Array<number>;
    timezoneOffset?: number;
  }): CancelablePromise<ZoneResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/zones-combined-by-zones',
      query: {
        zoneNumbers: zoneNumbers,
        timezoneOffset: timezoneOffset,
      },
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
