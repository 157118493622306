import { EshopElectronicWalletTransactionService, VehicleTicketDto } from '@/api';

export default {
  getVehicleTickets: async (id: string): Promise<VehicleTicketDto[]> => {
    const response =
      await EshopElectronicWalletTransactionService.eshopElectronicWalletTransactionControllerGetVehicleTickets({ id });

    return response.data;
  },
};
