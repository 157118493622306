/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardApplicationStateListResponse } from '../models/CardApplicationStateListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CardApplicationStateService {
  /**
   * Get card request possible states
   * @returns CardApplicationStateListResponse
   * @throws ApiError
   */
  public static cardApplicationStateControllerGetAll(): CancelablePromise<CardApplicationStateListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/card-application-states',
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
}
