/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalSettingListResponse } from '../models/GlobalSettingListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopGlobalSettingService {
  /**
   * Get eshop global settings
   * @returns GlobalSettingListResponse
   * @throws ApiError
   */
  public static eshopGlobalSettingControllerGetAll(): CancelablePromise<GlobalSettingListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/global-settings',
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get map phone global settings
   * @returns GlobalSettingListResponse
   * @throws ApiError
   */
  public static eshopGlobalSettingControllerGetMapPhoneGlobalSettings({
    mapPhoneAuthToken,
  }: {
    mapPhoneAuthToken?: any;
  }): CancelablePromise<GlobalSettingListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/global-settings-map-phone',
      headers: {
        'map-phone-auth-token': mapPhoneAuthToken,
      },
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
}
