import {
  ApiResponse,
  CustomerAccountLinkHeaderVm,
  CustomerAccountLinkListResponse,
  CustomerAccountLinkQuery,
  EshopCustomerAccountLinkService,
  ManagedAccountLinkRequestCommand,
  ManagedAccountLinkRequestHeader,
  ManagingAccountCardDto,
  StringValueCommand,
} from '@/api';
import { getRequestBody, getQueryParams } from '@/utils/api';

export default {
  manageCardRequest: async (payload: ManagedAccountLinkRequestCommand): Promise<ManagedAccountLinkRequestHeader> => {
    const response = await EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerManagingCardRequest({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  getLinkList: async (query: CustomerAccountLinkQuery): Promise<CustomerAccountLinkListResponse> => {
    return EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerGetAll(getQueryParams(query));
  },

  cancelManagedCustomerLink: async (id: string): Promise<void> => {
    await EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerRemoveManagedCustomerLink({ id });
  },

  cancelManagingAccountLink: async (id: string) => {
    await EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerCancelManagingAccountCard({ id });
  },

  approveManagedCardRequest: async (payload: StringValueCommand): Promise<ManagingAccountCardDto> => {
    const response = await EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerApproveManagedCard({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  rejectCardManageRequest: async (token: string): Promise<ApiResponse> => {
    return EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerRejectCardManageRequest({ token });
  },

  setMainAccountRequest: async (id: string): Promise<CustomerAccountLinkHeaderVm> => {
    const response = await EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerSetRegisteredType({ id });

    return response.data;
  },

  setAssignedAccountRequest: async (id: string): Promise<CustomerAccountLinkHeaderVm> => {
    const response = await EshopCustomerAccountLinkService.eshopCustomerAccountLinkControllerSetAssignedType({ id });

    return response.data;
  },
};
