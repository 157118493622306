export const ImageConst = {
  pngBase64Meta: 'data:image/png;base64,',
  jpgBase64Meta: 'data:image/jpg;base64,',
};

/**
 * This needs to be the same ration as 23.5mm/28mm which is the photo printed on a card
 * 23.5mm/28mm === 150px/178.72px === 0.8393
 * Width '150px' MUST be set in CSS in:
 *  - RDPCropper.vue
 *  - RDPImage.vue
 *  - CustomerPersonalInfoDialog.vue
 */
export const RDP_PHOTO_ASPECT_RATIO = 23.5 / 28; // 23.5mm/28mm === 150px/178.72px === 0.8393
/**
 * 1000 px
 *  This keeps the ratio 23.5mm / 28mm
 */
export const RDP_PHOTO_RESOLUTION_WIDTH = 1000;
/**
 *  1191.49px
 *  This keeps the ratio 23.5mm / 28mm
 */
export const RDP_PHOTO_RESOLUTION_HEIGHT = RDP_PHOTO_RESOLUTION_WIDTH / RDP_PHOTO_ASPECT_RATIO;
