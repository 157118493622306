/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ZoneSyncDto = {
  /**
   * Zone identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Zone name.
   */
  name: string;
  /**
   * Zone number.
   */
  number: number;
  type: ZoneSyncDto.type;
  /**
   * UUIDs of zones to assign to the combined zone.
   */
  zoneIds?: Array<string>;
};
export namespace ZoneSyncDto {
  export enum type {
    SIMPLE = 'SIMPLE',
    COMBINED = 'COMBINED',
    ALL_NETWORK = 'ALL_NETWORK',
  }
}
