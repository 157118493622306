/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EshopReclamationQuery = {
  /**
   * Customer name. "LastName FirstName.
   */
  customerName?: string;
  /**
   * Customer email.
   */
  customerEmail?: string;
  /**
   * Employee name. "LastName FirstName.
   */
  employeeName?: string;
  /**
   * Reclamation state.
   */
  state?: EshopReclamationQuery.state;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
};
export namespace EshopReclamationQuery {
  /**
   * Reclamation state.
   */
  export enum state {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    FINALIZED = 'FINALIZED',
  }
}
