/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveMainCardCommand } from '../models/ActiveMainCardCommand';
import type { ApiResponse } from '../models/ApiResponse';
import type { CustomerCardResponse } from '../models/CustomerCardResponse';
import type { MarketingConsentCommand } from '../models/MarketingConsentCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopCustomerCardService {
  /**
   * Customer card by id
   * @returns any Successful response
   * @throws ApiError
   */
  public static eshopCustomerCardControllerGetOneById({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/customer-cards/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Set marketing consent on card application.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
   * @returns CustomerCardResponse
   * @throws ApiError
   */
  public static eshopCustomerCardControllerMarketingConsent({
    id,
    requestBody,
  }: {
    id: string;
    /**
     * MarketingConsentCommand
     */
    requestBody?: MarketingConsentCommand;
  }): CancelablePromise<CustomerCardResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/customer-cards/{id}/marketing-consent',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Customer card by chip number
   * @returns any Successful response
   * @throws ApiError
   */
  public static eshopCustomerCardControllerGetOneByChipNumber({ number }: { number: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/customer-cards-by-chip-number/{number}',
      path: {
        number: number,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Set customer active card to active main card and block other active cards.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopCustomerCardControllerSetActiveMainCard({
    requestBody,
  }: {
    /**
     * ActiveMainCardCommand
     */
    requestBody?: ActiveMainCardCommand;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/customer-cards/active-main-card',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        409: `Request couldn't be completed due to a conflict.`,
        500: `Something went wrong.`,
      },
    });
  }
}
