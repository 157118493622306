<template>
  <v-row>
    <v-col :cols="personalInfoCols" class="customer-detail-info">
      <CustomerPersonalInfo :show-main-personal-info="showMainPersonalInfo" />
    </v-col>
    <v-col
      v-if="userAccountModule.hasCardDetailData"
      :cols="$vuetify.display.mobile ? 12 : 6"
      class="customer-detail-info"
    >
      <ProfileValidityInfo :card-profile="profileOne" :profile-number="1" />
      <ProfileValidityInfo :card-profile="profileTwo" :profile-number="2" />
      <EWalletInfo v-if="showElectronicWalletInfo" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-facing-decorator';
import RDPImage from '@/components/common/RDPImage/RDPImage.vue';
import CustomerPersonalInfo from '@/components/common/RDPCustomerDetail/CustomerPersonalInfo.vue';
import EWalletInfo from '@/components/common/RDPCustomerDetail/EWalletInfo.vue';
import ProfileValidityInfo from '@/components/common/RDPCustomerDetail/ProfileValidityInfo.vue';
import RDPCustomerDetailAbstract from '@/components/common/RDPCustomerDetail/RDPCustomerDetailAbstract';

@Component({
  name: 'RDPCustomerDetail',
  components: {
    RDPImage,
    CustomerPersonalInfo,
    ProfileValidityInfo,
    EWalletInfo,
  },
})
export default class RDPCustomerDetail extends RDPCustomerDetailAbstract {
  @Prop({ required: false, type: Boolean, default: true })
  readonly showMainPersonalInfo!: boolean;

  get personalInfoCols() {
    if (this.$vuetify.display.mobile) {
      return 12;
    }
    return this.customerCardData ? 6 : 5;
  }

  get showElectronicWalletInfo() {
    return (
      (!this.userAccountModule.isCardTypeVirtual || this.systemConfigurationModule.isVirtualCardEWalletAllowed) &&
      !this.userAccountModule.isCardTypeBank
    );
  }
}
</script>

<style scoped></style>
