/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StockTransactionQuery = {
  /**
   * Stock Action. Accept, move, sell.
   */
  action?: StockTransactionQuery.action;
  /**
   * Identifier of stocks. Origin or destination. Type: 'UUID'
   */
  stockId?: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
  /**
   * Filter by month. Default: current month. Type: 'DATE'
   */
  month: string;
  /**
   * Identifier of product. Type: 'UUID'
   */
  productId?: string;
};
export namespace StockTransactionQuery {
  /**
   * Stock Action. Accept, move, sell.
   */
  export enum action {
    ACCEPT = 'ACCEPT',
    MOVE = 'MOVE',
    EXTERNAL_SELL = 'EXTERNAL_SELL',
    COUNTER_SELL = 'COUNTER_SELL',
    COUNTER_CANCEL = 'COUNTER_CANCEL',
    LIQUIDATION = 'LIQUIDATION',
  }
}
