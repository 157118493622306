import Date from '@/constants/Date';
import { format } from 'date-fns';

export const toAmount = (value: string) => {
  const formatter = new Intl.NumberFormat('cs', {
    style: 'decimal',
    minimumFractionDigits: 0,
  });

  return formatter.format(Number(value));
};

export const toPrice = (value: string) => {
  if (value === undefined) {
    return '-';
  }

  const parsedValue = (parseFloat(value) / 100).toFixed(2);

  const formatter = new Intl.NumberFormat('cs', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });

  return formatter.format(Number(parsedValue));
};

export const toFloat = (value: string) => {
  const parsedValue = parseFloat(value).toFixed(2);

  const formatter = new Intl.NumberFormat('cs', {
    style: 'decimal',
    minimumFractionDigits: 0,
  });
  return formatter.format(Number(parsedValue));
};

export const toDecimal = (value: string) => {
  const parsedValue = parseFloat(value).toFixed(2);

  const formatter = new Intl.NumberFormat('cs', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });

  return formatter.format(Number(parsedValue));
};

export const toPercentage = (value: string) => {
  return `${value}%`;
};

export const toStandardDate = (value: string) => {
  return value ? format(value, Date.DATE_FORMAT_STRING) : '-';
};

export const toStandardDateTime = (value: string) => {
  return value ? format(value, Date.DATETIME_FORMAT_STRING) : '-';
};

export const toStandardMonthDate = (value: string) => {
  return value ? format(value, Date.DATE_MONTH_FORMAT_STRING) : '-';
};
