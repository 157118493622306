import { PriceListService, PriceListZoneDto, PriceListZoneQuery } from '@/api';
import { clientInputFactory, getDefaultAcceptLanguage } from '@/utils/api';

export default {
  getPriceListZones: async (id: string, query: PriceListZoneQuery): Promise<PriceListZoneDto[]> => {
    const response = await PriceListService.priceListControllerGetPriceListZones({
      id,
      ...clientInputFactory(),
      ...getDefaultAcceptLanguage(),
      ...query,
    });

    return response.data;
  },
};
