<template>
  <!--
    from file(s): prevod_epenezenky.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="12mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 1200 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M1031.89 1176.54l-863.78 0c-79.62,0 -144.65,-65.02 -144.65,-144.64l0 -449.08c0,-79.62 65.03,-144.64 144.65,-144.64l863.78 0c79.62,0 144.65,65.02 144.65,144.64l0 449.08c0,79.62 -65.03,144.64 -144.65,144.64z"
      />
      <path
        class="fil1"
        d="M1111.75 293.23l64.79 -0.18 -92.79 92.79 -92.31 -92.33 70.58 -0.16 1.91 -0.01c-2.53,-87.47 -75.77,-157.6 -165.78,-157.65l-0.01 2.28 -0.18 70.6 -92.31 -92.31 92.79 -92.79 -0.18 64.78 -0.01 2.71c114.64,0.11 207.82,90.21 210.41,202.28l3.09 -0.01z"
      />
      <path
        class="fil1"
        d="M23.46 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65l0 863.78c0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class PakTransferIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil1 {
  fill: #fefefe;
}
.fil0 {
  fill: #b2b3b3;
}
</style>
