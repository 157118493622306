/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerCardQuery = {
  customerName?: string;
  /**
   * Generated card application string identifier. Is unique.
   */
  applicationNumber?: string;
  cardNumber?: string;
  /**
   * Card state name.
   */
  state?: CustomerCardQuery.state;
  /**
   * If result should include virtual cards.
   */
  includeVirtualCard?: boolean;
  /**
   * Filter by customer card type.
   */
  cardType?: CustomerCardQuery.cardType;
  /**
   * Bank card token
   */
  token?: string;
  /**
   * Customer card group name
   */
  customerCardGroup?: string;
  /**
   * Filter only cards without customer card group. Default: false
   */
  withoutCustomerCardGroup?: boolean;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
};
export namespace CustomerCardQuery {
  /**
   * Card state name.
   */
  export enum state {
    NOT_ISSUED = 'NOT_ISSUED',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    EXPIRED = 'EXPIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    TOKENIZED = 'TOKENIZED',
  }
  /**
   * Filter by customer card type.
   */
  export enum cardType {
    PERSONAL = 'PERSONAL',
    PORTABLE = 'PORTABLE',
    RECLAMATION = 'RECLAMATION',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    BANK_CARD = 'BANK_CARD',
    WHITE_LIST_CARD = 'WHITE_LIST_CARD',
  }
}
