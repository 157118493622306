<template>
  <v-main>
    <v-container class="fill-height bg-rdpbackground" fluid>
      <v-row class="flex-column" align="center" justify="center">
        <v-col cols="12">
          <RDPHomePageLogo />
        </v-col>
        <h3 v-if="verification">{{ $t('verify.verification') }}</h3>
        <h3 v-else>{{ verificationMessage }}</h3>
        <div>
          <h3>
            <router-link :to="paths.LOGIN">{{ $t('verify.backToLogin') }}</router-link>
          </h3>
        </div>
      </v-row>
    </v-container>
    <v-footer app theme="dark">
      <FooterLinks dark />
    </v-footer>
  </v-main>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import authService from '@/services/api/authService';
import Paths from '@/constants/Paths';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import RDPHomePageLogo from '@/components/common/RDPHomePageLogo.vue';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast';
import ToastModule from '@/store/modules/toast';

@Component({
  components: {
    ConfirmButton,
    FooterLinks,
    RDPHomePageLogo,
  },
})
export default class VerifyPage extends Vue {
  paths = Paths;
  verification = true;
  verificationMessage = '';

  created() {
    this.verify();
  }

  async verify() {
    const errorPosition = {
      x: 'center',
      y: 'top',
      timeout: PERMANENT_TOAST_INTERVAL,
    };
    try {
      await authService.verify({ value: this.$route.query.token as string });
      this.verificationMessage = this.$t('verify.successfullyVerified');
      ToastModule.success({
        message: this.$t('verify.successfullyVerified'),
        options: errorPosition,
      });
      await this.$router.push(Paths.LOGIN);
    } catch (e) {
      this.verificationMessage = this.$t('verify.verificationFailed');
      ToastModule.error({
        message: this.$t('verify.verificationFailed'),
        options: errorPosition,
      });
    } finally {
      this.verification = false;
    }
  }
}
</script>
