/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EWalletTransactionMetadataVm = {
  /**
   * Electronic wallet transaction identifier. Type: 'UUID'
   */
  id: string;
  cardNumber: string;
  /**
   * Transaction number.
   */
  transactionNumber: number;
  /**
   * DateTime when transaction issued. Type: 'DATETIME'
   */
  issued: string;
  /**
   * Previous balance. In cents.
   */
  previousBalance: number;
  /**
   * New balance. In cents.
   */
  newBalance: number;
  /**
   * Amount of the transaction. In cents.
   */
  amount: number;
  operation: EWalletTransactionMetadataVm.operation;
};
export namespace EWalletTransactionMetadataVm {
  export enum operation {
    RECHARGE = 'RECHARGE',
    WITHDRAW = 'WITHDRAW',
    PAYMENT = 'PAYMENT',
    TRANSFER = 'TRANSFER',
    PAK_TRANSFER = 'PAK_TRANSFER',
  }
}
