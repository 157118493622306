<template>
  <div class="customer-profile-cmp">
    <v-row>
      <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="pb-0 pt-0">
        <div class="d-flex align-center flex-nowrap">
          <div v-if="showProfileAddButton || showProfileRemoveButton" class="pr-3">
            <v-btn
              v-if="showProfileAddButton"
              color="success"
              icon
              size="x-small"
              :title="t('addProfileTwo')"
              :data-cy="cy('add-profile-btn')"
              @click="addProfileTwo"
            >
              <v-icon>mdi-plus-thick</v-icon>
            </v-btn>
            <v-btn
              v-if="showProfileRemoveButton"
              color="error"
              icon
              size="x-small"
              :title="t('removeProfile')"
              :data-cy="cy('remove-profile-btn')"
              @click="removeProfileTwo"
            >
              <v-icon>mdi-minus-thick</v-icon>
            </v-btn>
          </div>
          <div class="pt-0 pb-0 flex-fill">
            <v-select
              v-model="customerProfile.profile.id"
              :class="{ 'disable-events': disabled }"
              :items="customerProfilesTranslated"
              :rules="validations.profile"
              :label="t('profile')"
              :no-data-text="$t('common.noData')"
              :data-cy="cy('profile')"
              variant="underlined"
              :item-props="item => ({ disabled: item.disabled })"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="py-0">
        <RDPDatePicker
          v-model="customerProfile.validFrom"
          :disabled="disabled"
          :label="t('validFrom')"
          :required="true"
          :data-cy="cy('validFrom')"
          :min="minProfileValidFrom"
          @change="validateProfile()"
        />
      </v-col>
      <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="d-flex align-center py-0">
        <RDPDatePicker
          v-if="isProfileValidToDisplayed()"
          v-model="customerProfile.validTo"
          :disabled="disabled || hasValidToDefault"
          :label="t('validTo')"
          :required="true"
          :min="minProfileValidTo"
          :data-cy="cy('validTo')"
          @change="validateProfile()"
        />
        <v-text-field
          v-else
          class="disable-events pb-0"
          :data-cy="cy('validTo')"
          prepend-icon="mdi-calendar"
          :label="t('validTo')"
          :model-value="t('validToCardValidity')"
          variant="underlined"
        />
      </v-col>
    </v-row>

    <div style="padding-left: 45px">
      <div v-if="profileValidityErrorMessage" class="rdp-error-message v-messages__message mt-0">
        {{ profileValidityErrorMessage }}
      </div>
      <div v-if="profileInvalidInDateRangeErrorMessage" class="rdp-error-message v-messages__message mt-0">
        {{ profileInvalidInDateRangeErrorMessage }}
      </div>
      <div
        v-if="cardApplicationModule.profilesAreInCollision && !isProfileOne"
        class="rdp-error-message v-messages__message mt-0"
      >
        {{ t('profilesInCollisionErrorMessage') }}
      </div>
    </div>

    <template v-if="requireConfirmationCheck">
      <div>
        <v-alert
          border="top"
          type="info"
          variant="text"
          elevation="2"
          class="my-6"
          data-cy="customer-profile-attachment-alert"
        >
          {{ $t('cardApplication.attachmentAllowedTypesInfo') }}
        </v-alert>
      </div>
      <v-row>
        <v-col
          v-for="(attachment, index) of selectedProfile?.cardApplicationAttachmentTypes"
          :key="index"
          :cols="$vuetify.display.mobile ? 12 : 6"
        >
          <v-file-input
            v-model="attachmentFiles[index]"
            :label="attachment.displayName"
            :rules="validations.attachments"
            :accept="allowedAttachmentFileTypes"
            :class="{ 'disable-events': disableCustomerProfile }"
            :data-cy="cy(`attachment-${index}`)"
            variant="underlined"
            @change="setAttachment(index, attachment.id)"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="allowIsicInProfile">
      <div>
        <v-alert
          border="top"
          type="info"
          variant="text"
          elevation="2"
          class="my-6"
          data-cy="customer-profile-isic-alert"
        >
          {{ $t('cardApplication.isicInfo') }}
        </v-alert>
      </div>
      <v-row>
        <v-col>
          <v-text-field
            ref="validIsicCardNumber"
            v-model="customer.isicNumber"
            :rules="validations.isic"
            :label="$t('cardApplication.isicNumber')"
            :class="{ 'disable-events': disabled }"
            data-cy="customer-profile-isic-card-number"
            variant="underlined"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-facing-decorator';
import { checkedValidator, maxLengthValidator, minLengthValidator, requiredValidator } from '@/utils/validators';
import RDPDatePicker from '@/components/common/RDPDatePicker/RDPDatePicker.vue';
import CustomerProfileModule from '@/store/modules/customerProfile';
import { CardApplicationProfileDto, CustomerDetailVm, CustomerProfileDto, EnumsDto } from '@/api';
import {
  allowIsicInProfile,
  computeValidTo,
  requireConfirmationCheck,
  shouldSetValidTo,
} from '@/utils/customerProfileValidToDefault';
import {
  initializeCPValidFrom,
  isCustomerProfileValidityValid,
  isProfileValidInDateRange,
  profilesAreInCollision,
  REGULAR_PROFILE_NUMBER,
} from '@/utils/customerProfile';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import CardApplicationModule, { AttachmentPayload } from '@/store/modules/cardApplication';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import { DATE_FORMAT, DATE_PICKER_FORMAT, getDateAfterDays, toDate } from '@/utils/dateTime';
import isDefined from '@common/utils/isDefined';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import customerService from '@/services/api/customerService';
import { IsIsicCardNumberValidQuery } from '@/api/models/IsIsicCardNumberValidQuery';
import ToastModule from '@/store/modules/toast.ts';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';
import { FormInterface } from '@/components/common/FormInterface';
import { format } from 'date-fns';

export interface CustomerProfileComponentInterface {
  showProfileTwo: () => void;
  hideProfileTwo: () => void;
  selectedProfile: CustomerProfileDto;
  clearProfile: () => void;
  valid: () => boolean;
}

export const MAX_ATTACHMENT_FILE_SIZE = 5242880; // 5MB
export const BYTES_TO_MB = 1000000; // bytes to MB
const ISIC_CARD_NUMBER_LENGTH = 14;
const ALLOWED_ATTACHMENT_FILE_EXTENSION = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

@Component({
  components: {
    RDPDatePicker,
  },
  emits: ['addProfile', 'removeProfile', 'changeProfile'],
})
export default class AdvancedProfileComponent extends Vue {
  @Prop({ required: true, type: Object })
  customerProfile!: CardApplicationProfileDto;

  @Prop({ required: false, type: String, default: 'cardApplication.cardData' })
  readonly i18nGroupKey!: 'cardApplication.cardData';

  @Prop({ required: true, type: String, default: 'card-data' })
  readonly dataCy!: 'card-data';

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isProfileOne!: boolean;

  @Prop({ required: false, type: Boolean })
  readonly isVirtualCard!: boolean;

  @Prop({ required: false, type: Boolean })
  readonly cardTypeAllowedForIsic!: boolean;

  @Ref()
  validIsicCardNumber!: FormInterface;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachmentFiles: any[] = [];

  customerProfileModule = CustomerProfileModule;
  cardApplicationModule = CardApplicationModule;
  systemConfigurationModule = SystemConfigurationModule;

  minProfileValidTo = '';
  showCustomerProfileTwo = false;

  profileValidityErrorMessage = '';
  profileInvalidInDateRangeErrorMessage = '';
  profileConfirmed = false;
  isicIsValid = false;
  isicCheckInProgress = false;

  mounted() {
    this.minProfileValidTo = this.customerProfile.validFrom;
    this.profileConfirmed = !!this.customerProfile.confirmationChecked;
  }

  get validations() {
    return {
      profile: [(v: string) => requiredValidator(v)],
      confirmation: [(v: boolean) => checkedValidator(v)],
      attachments: [
        (v: File) =>
          this.checkAttachmentFileNames(v[0]?.name) || this.t('attachmentNameAlreadyExists', { fileName: v[0]?.name }),
        (v: File) => requiredValidator(v[0]?.name),
        (v: File) => !v[0] || ALLOWED_ATTACHMENT_FILE_EXTENSION.includes(v[0].type) || this.t('allowedAttachmentTypes'),
        (v: File) =>
          !v[0] ||
          v[0].size <= MAX_ATTACHMENT_FILE_SIZE ||
          this.t('maxAttachmentSize', {
            // bytes to MB
            maxSize: Math.floor(MAX_ATTACHMENT_FILE_SIZE / BYTES_TO_MB),
          }),
      ],
      isic: [
        (v: string) => requiredValidator(v),
        (v: string) => minLengthValidator(v, ISIC_CARD_NUMBER_LENGTH),
        (v: string) => maxLengthValidator(v, ISIC_CARD_NUMBER_LENGTH),
        (v: string) => !v || this.isicIsValid || this.t('invalidIsicNumber'),
      ],
    };
  }

  get allowedAttachmentFileTypes() {
    return ALLOWED_ATTACHMENT_FILE_EXTENSION.toString();
  }

  get profilesAutofilled() {
    return this.cardApplicationModule.customerProfileAutoUpdated;
  }

  get selectedProfile() {
    return this.customerProfileModule.customerProfiles.find(
      customerProfile => customerProfile.id === this.customerProfile.profile.id,
    );
  }

  get disableCustomerProfile() {
    return this.disabled || (this.profilesAutofilled && !this.isCardApplicationAttachmentAllowed);
  }

  profileHasAttachments(profile: CustomerProfileDto | undefined) {
    return profile?.cardApplicationAttachmentTypes ? profile.cardApplicationAttachmentTypes.length > 0 : false;
  }

  get hasAttachments() {
    return this.profileHasAttachments(this.selectedProfile);
  }

  get isCardApplicationAttachmentAllowed() {
    return this.systemConfigurationModule.cardApplicationAttachmentAllowed;
  }

  checkAttachmentFileNames(name: string) {
    if (isDefined(name)) {
      const currentAttachments = this.isProfileOne
        ? this.cardApplicationModule.attachments.profileOneAttachments
        : this.cardApplicationModule.attachments.profileTwoAttachments;
      const attachmentsByName = currentAttachments.filter(attachment => {
        return attachment.file.name === name;
      });
      return attachmentsByName.length <= 1;
    }
    return true;
  }

  setAttachment(index: string | number, attachmentTypeId: string) {
    const file: File = this.attachmentFiles[index];
    const payload: AttachmentPayload = {
      attachmentTypeId,
      attachmentFile: file,
      isProfileOne: this.isProfileOne,
    };

    this.cardApplicationModule.setAttachment(payload);
  }

  isProfileValidToDisplayed() {
    if (this.customerProfile.validTo) {
      return true;
    }
    return shouldSetValidTo(this.selectedProfile);
  }

  get requireConfirmationCheck() {
    return requireConfirmationCheck(this.selectedProfile);
  }

  get allowIsicInProfile() {
    return (
      this.systemConfigurationModule.isIsicAllowed &&
      allowIsicInProfile(this.selectedProfile) &&
      this.cardTypeAllowedForIsic
    );
  }

  get customer(): CustomerDetailVm {
    return this.cardApplicationModule.customerData;
  }

  get isicReadyForCheck() {
    return (
      this.customer.isicNumber?.length === ISIC_CARD_NUMBER_LENGTH &&
      this.selectedProfile?.isic &&
      this.cardTypeAllowedForIsic
    );
  }

  @Watch('customerProfile.profile.id')
  async customerProfileChanged(newVal: string, oldVal: string) {
    if (oldVal !== '') {
      this.profileConfirmed = false;
    } else {
      this.profileConfirmed = !!this.customerProfile.confirmationChecked;
    }

    const selectedProfile = this.selectedProfile;
    const customerVerified = this.cardApplicationModule.isCustomerVerified;

    if (
      (this.profileIdIsChangingOrValidFromIsNotSet(oldVal) ||
        this.virtualCardAndCustomerNotVerified(customerVerified)) &&
      selectedProfile &&
      this.cardApplicationModule.customerDateBirthday
    ) {
      this.customerProfile.validFrom = initializeCPValidFrom(
        selectedProfile,
        this.cardApplicationModule.customerDateBirthday,
        customerVerified,
      );
    }

    // Set default when profile id is changing or profile validity is not set from previous card.
    if (oldVal !== '' || !this.customerProfile.validTo) {
      this.computeProfileValidTo();
    }

    this.profileValidityChanged();
    this.checkProfileValidInDateRange();
    if (this.showCustomerProfileTwo) {
      this.checkProfilesInCollision();
    }

    this.cardApplicationModule.clearAttachments(this.isProfileOne);
    this.attachmentFiles = [];

    this.$emit('changeProfile');

    if (this.isicReadyForCheck) {
      await this.checkIsicCard();
    }
  }

  addProfileTwo() {
    this.showProfileTwo();
    this.$emit('addProfile');
  }

  removeProfileTwo() {
    this.hideProfileTwo();
    this.$emit('removeProfile');
  }

  showProfileTwo() {
    this.showCustomerProfileTwo = true;
  }

  hideProfileTwo() {
    this.showCustomerProfileTwo = false;
  }

  @Watch('customerProfile.validFrom')
  customerProfileValidFromChanged() {
    const selectedProfile = this.selectedProfile;
    if (
      selectedProfile?.validToDefault?.name ===
        EnumsDto.customerProfileValidToDefaults._365_DAYS_AFTER_CONFIRMATION_DELIVERY ||
      selectedProfile?.validToDefault?.name ===
        EnumsDto.customerProfileValidToDefaults._430_DAYS_AFTER_CONFIRMATION_DELIVERY
    ) {
      this.computeProfileValidTo();
    }
  }

  @Watch('customer.isicNumber')
  async customerIsicCardNumberChanged() {
    if (this.isicReadyForCheck) {
      await this.checkIsicCard();
    }
  }

  async checkIsicCard() {
    this.isicIsValid = true;
    if (this.customer.isicNumber) {
      const isicValidationPayload: IsIsicCardNumberValidQuery = {
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        excludedCustomerId: this.customer.originId || this.customer.id,
        isicNumber: this.customer.isicNumber,
        customerProfileId: this.customerProfile.profile.id,
      };
      this.isicCheckInProgress = true;
      try {
        const isicValid = await customerService.validCustomerIsicCardNumber(isicValidationPayload);
        this.isicIsValid = isicValid.errors.length === 0;
      } catch (err) {
        ToastModule.error({
          message: getApiError(err as ApiErrorInterface, 'cardApplication'),
        });
      }
      this.isicCheckInProgress = false;
    }
    this.validIsicCardNumber?.validate();
  }

  computeProfileValidTo() {
    this.customerProfile.validTo = computeValidTo(
      this.selectedProfile,
      this.cardApplicationModule.customerDateBirthday,
      new Date(this.customerProfile.validFrom).getFullYear(),
      this.customerProfile.validFrom,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(i18nKey: string, param?: any) {
    return this.$t(`${this.i18nGroupKey}.${i18nKey}`, param);
  }

  cy(partialDataCy: string) {
    return `${this.dataCy}-${partialDataCy}`;
  }

  get customerProfilesTranslated(): VuetifySelectOptionsVm[] {
    return this.customerProfileModule.customerProfiles.map(
      (customerProfile: CustomerProfileDto): VuetifySelectOptionsVm => ({
        title: getCustomerProfileDisplayName(customerProfile.id),
        value: customerProfile.id,
        disabled:
          customerProfile.profileNumber !== REGULAR_PROFILE_NUMBER && !this.profileHasAttachments(customerProfile),
      }),
    );
  }

  get confirmationLabel() {
    return `${this.t('confirmation')}.${
      this.customerProfile.confirmationChecked
        ? ` (${format(this.customerProfile.confirmationChecked, DATE_PICKER_FORMAT)})`
        : ''
    }`;
  }

  profileIdIsChangingOrValidFromIsNotSet = (oldVal: string) => {
    return isDefined(oldVal) || !isDefined(this.customerProfile?.validFrom as string);
  };

  virtualCardAndCustomerNotVerified = (customerVerified: boolean) => {
    return this.isVirtualCard && !customerVerified;
  };

  confirmationChanged(value: boolean) {
    this.customerProfile.confirmationChecked = value ? format(new Date(), DATE_FORMAT) : '';
  }

  validateProfile() {
    this.profileValidityChanged();
    this.checkProfileValidInDateRange();
    if (this.showCustomerProfileTwo) {
      this.checkProfilesInCollision();
    }
  }

  profileValidityChanged() {
    this.profileValidityErrorMessage = isCustomerProfileValidityValid(
      this.customerProfile.validFrom,
      this.customerProfile.validTo,
    );

    this.minProfileValidTo = this.customerProfile.validFrom;
  }

  checkProfileValidInDateRange() {
    this.profileInvalidInDateRangeErrorMessage = isProfileValidInDateRange(
      this.selectedProfile,
      this.customerProfile.validFrom,
      this.customerProfile.validTo,
    );
  }

  checkProfilesInCollision() {
    if (
      this.cardApplicationModule.cardData.profileOne.validFrom &&
      this.cardApplicationModule.cardData.profileTwo?.validFrom
    ) {
      const collision = profilesAreInCollision(
        this.cardApplicationModule.cardData.profileOne.validFrom,
        this.cardApplicationModule.cardData.profileTwo.validFrom,
        this.cardApplicationModule.cardData.profileOne?.validTo,
        this.cardApplicationModule.cardData.profileTwo?.validTo,
      );
      this.cardApplicationModule.setProfilesInCollision(collision);
    }
  }

  get showProfileAddButton() {
    return !this.disabled && this.isProfileOne && !this.showCustomerProfileTwo;
  }

  get showProfileRemoveButton() {
    return !this.disabled && !this.isProfileOne && this.showCustomerProfileTwo;
  }

  get hasValidToDefault() {
    return !!this.selectedProfile?.validToDefault;
  }

  get minProfileValidFrom() {
    if (!this.isProfileOne) {
      return getDateAfterDays(this.cardApplicationModule.cardData.profileOne.validFrom, 1);
    } else {
      return toDate(new Date());
    }
  }

  valid() {
    return this.profileInvalidInDateRangeErrorMessage === '' && this.profileValidityErrorMessage === '';
  }
}
</script>

<style lang="scss">
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
