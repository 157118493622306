<template>
  <v-container class="table-component" fluid>
    <v-row align="center" class="justify-end justify-md-end">
      <slot name="head-panel">
        <slot name="head-panel.start" />
        <v-col v-if="isCreateAllowed" md="1" class="text-right mr-2">
          <v-btn
            color="success"
            icon
            :title="$t(i18nKeys.createTooltipTitleKey)"
            :data-cy="`${dataCy}-add-button`"
            @click.stop="createRow"
          >
            <v-icon>mdi-plus-thick</v-icon>
            <span v-if="$te(i18nKeys.createTitleKey)" class="pl-2">
              {{ $t(i18nKeys.createTitleKey) }}
            </span>
          </v-btn>
        </v-col>
        <slot name="head-panel.end" />
      </slot>
    </v-row>
    <v-row class="table-container">
      <v-data-table-server
        ref="dataTableRef"
        v-model:page="options.page"
        v-model:items-per-page="options.itemsPerPage"
        v-model:sort-by="options.sortBy"
        :height="tableHeight"
        :headers="translatedHeaders"
        :disable-pagination="disablePagination"
        :hide-default-footer="disablePagination"
        :items="items"
        :loading="loading"
        :loading-text="$t('rdpDataTable.searching')"
        :items-length="totalItems"
        items-per-page-text=""
        :data-cy="`${dataCy}-list-table`"
        :fixed-header="fixedHeader"
        :disable-sort="disableSort"
        :must-sort="mustSort"
        :show-expand="isExpandEnabled"
        :single-expand="singleExpand"
        :item-key="itemKey"
        :mobile-breakpoint="1200"
        class="data-table"
        hover
      >
        <!-- header.item slots doesn't exists yet -->
        <!-- <template v-for="(h, index) in translatedHeaders" :key="index" #[`header.${h.key}`]="{}">
          <v-tooltip v-if="h.tooltip" location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">{{ h.title }}</span>
            </template>
            <span>{{ h.tooltip }} </span>
          </v-tooltip>
          <span v-else>{{ h.title }} </span>
        </template> -->

        <template v-if="$vuetify.display.mobile && sortByOptions.length" #headers>
          <v-select
            :model-value="options.sortBy"
            :items="sortByOptions"
            :label="$t('$vuetify.dataTable.sortBy')"
            class="ma-3"
            density="compact"
            variant="underlined"
            :item-title="item => sortByOptions.find(option => option.key === (item as any).key)?.title"
            item-value="key"
            hide-details
            @update:model-value="key => (options.sortBy = [{ key: key as any, order: 'desc' }])"
          />
        </template>

        <template #expanded-row="props">
          <slot :headers="props.columns" :item="props.item" name="expanded-item" />
        </template>

        <template #item="{ internalItem, item, toggleExpand, isExpanded }">
          <tr
            :data-cy="`${dataCy}-list-table-row-${getDataCyRowKey(item)}`"
            @click.stop="rowClick(internalItem, toggleExpand, isExpanded(internalItem))"
          >
            <td
              v-for="(column, key) in translatedHeaders"
              :key="key"
              :title="column.tooltip"
              :class="`
                font-weight-${column.fontWeight || 'regular'}
                ${$vuetify.display.mobile ? 'xs-td' : ''}
                d-sm-table-cell
                d-block
                ${isExpanded(internalItem) ? 'row-expanded' : ''}
                ${isExpandEnabled ? 'expand-is-enabled' : ''}
                ${column.applyRowClass ? getRowClass(item) : ''}`"
              :data-cy="`${dataCy}-list-table-column-${column.dataCy || column.dataCy || column.value || column.name}`"
              :data-cy-state="`${getDataCyState(item, column.value) || ''}`"
            >
              <v-btn
                v-if="column.value === HEADER_EXPAND.value"
                :class="{
                  'expand-btn': true,
                  dataTable__upSideDown: isExpanded(internalItem),
                  'xs-expand-button': $vuetify.display.mobile,
                }"
                :data-cy="`${dataCy}-row-detail-${(item as any).id}`"
                icon
                variant="text"
                :density="$vuetify.display.mobile ? 'compact' : 'default'"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>

              <div v-if="column.value === HEADER_BUTTONS.value" class="d-flex justify-end">
                <slot :item="item" name="actions">
                  <slot :item="item" name="actions.start" />
                  <v-btn
                    v-if="isEditAllowed"
                    :title="$t(i18nKeys.editTitleKey)"
                    :data-cy="`${dataCy}-edit-${(item as any).id}`"
                    class="mx-1 elevation-4"
                    icon
                    size="x-small"
                    color="grey-darken-1"
                    theme="dark"
                    @click.stop="editRow(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <div
                    v-if="isDeleteAllowed"
                    :title="
                      isDeleteButtonDisabled(item) ? $t(i18nKeys.deleteDisabledTitleKey) : $t(i18nKeys.deleteTitleKey)
                    "
                    :class="{ 'btn-disabled': isDeleteButtonDisabled(item) }"
                  >
                    <v-btn
                      :data-cy="`${dataCy}-delete-${(item as any).id}`"
                      :disabled="isDeleteButtonDisabled(item)"
                      class="mx-1 elevation-4"
                      icon
                      size="x-small"
                      color="error"
                      @click.stop="deleteRow(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>

                  <slot :item="item" name="actions.end" />
                </slot>
              </div>
              <div v-else>
                <slot
                  :item="item"
                  :column="column"
                  :value="transformValue(item, column)"
                  :name="`item.${column.value}`"
                >
                  <div
                    class="data-container"
                    :class="`justify-sm-${column.align || 'start'} ${$vuetify.display.mobile ? 'xs-checkbox' : ''}`"
                  >
                    <div
                      v-if="$vuetify.display.mobile"
                      :class="`
                         'pr-2'
                         ${$vuetify.display.mobile ? 'text-truncate' : ''}
                         ${$vuetify.display.mobile && column.type === headerTypes.BOOLEAN ? 'checkbox-small' : ''}
                         `"
                    >
                      {{ column.text ? `${column.text}: ` : `` }}
                    </div>
                    <div class="data-content">
                      <div v-if="isColumnBoolean(column)" class="datatable-checkbox">
                        <v-checkbox-btn :ripple="false" readonly :model-value="transformValue(item, column)" />
                      </div>

                      <div
                        v-else
                        :class="{
                          'data-label text-truncate': $vuetify.display.mobile,
                        }"
                      >
                        {{ transformValue(item, column) }}
                      </div>
                    </div>
                  </div>
                </slot>
              </div>
            </td>
          </tr>
        </template>
        <template #body.append>
          <slot name="body.end" />
        </template>

        <template #no-data>
          <slot name="no-data">
            {{ $t('rdpDataTable.noData') }}
          </slot>
        </template>

        <template v-if="disablePagination" #bottom></template>
        <template v-else #bottom>
          <div class="v-data-table-footer mt-2">
            <div class="v-data-table-footer__items-per-page">
              <v-select
                v-model="options.itemsPerPage"
                :items="itemsPerPageOptions"
                hide-details
                variant="underlined"
                density="compact"
              />
            </div>
            <div class="v-data-table-footer__info">
              {{ $t('$vuetify.dataFooter.pageText', [startIndex + 1, stopIndex, totalItems]) }}
            </div>
            <div class="v-data-table-footer__pagination">
              <v-btn
                icon="mdi-chevron-left"
                density="compact"
                variant="plain"
                :disabled="options.page === 1"
                @click="prevPage"
              />
              <v-btn
                icon="mdi-chevron-right"
                density="compact"
                variant="plain"
                :disabled="options.page === pageCount"
                @click="nextPage"
              />
            </div>
          </div>
        </template>
      </v-data-table-server>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-facing-decorator';
import Table from '@/constants/Table';
import { KeyGroupI18n } from '@/utils/i18n';
import DataTableHelper, { DATA_TABLE_HEADER_TYPES } from './DataTableHelper';
import { ColumnInterface, HeaderInterface, ItemInterface } from './RDPDataTableInterfaces';
import { Dictionary } from 'lodash';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';
import { VDataTable } from 'vuetify/components';

type Options = {
  page: number;
  itemsPerPage: number;
  sortBy: VDataTable['$props']['sortBy'];
};

interface KeysI18nInterface {
  createTitleKey: string;
  createTooltipTitleKey: string;
  editTitleKey: string;
  deleteTitleKey: string;
  deleteTextKey: string;
  deleteSuccessKey: string;
  deleteDisabledTitleKey: string;
}

const APP_FOOTER_HEIGHT = 40;
const TABLE_FOOTER_HEIGHT = 59;
const TABLE_HEIGHT_ADJUSTMENT = 10;

@Component({
  emits: ['create', 'edit'],
})
export default class RDPDataTable extends Vue {
  @Prop({ required: false, type: String, default: '' })
  readonly dataCy!: string;
  @Prop({ required: false, type: String, default: 'id' })
  readonly dataCyRowKey!: string;
  @Prop({ required: true, type: Array })
  readonly headers!: Array<HeaderInterface | string>;
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {
        // you can define these in i18n (e.g. en.json) inside of an object named by this.i18nGroupKey property
        // e.g. { "invoice": { "createTitle": "Create new invoice" } }
        createTitleKey: 'createTitle',
        createTooltipTitleKey: 'createTooltipTitle',
        editTitleKey: 'editTitle',
        deleteTitleKey: 'deleteTitle',
        deleteTextKey: 'areYouSureToDelete',
        deleteSuccessKey: 'deleteSuccess',
        deleteDisabledTitleKey: 'deleteDisabledTitle',
      };
    },
  })
  readonly i18nKeys!: KeysI18nInterface;
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {
        sortBy: '',
        sortDesc: true,
      };
    },
  })
  private readonly filter!: { sortBy: string; sortDesc: boolean };
  @Prop({
    required: false,
    type: Function,
    default: () => {
      console.info('Search items callback not implemented');
    },
  })
  readonly searchItemsCallback!: (filter: Dictionary<string | number | boolean>) => Promise<any>;
  @Prop({
    required: false,
    type: Function,
    // this is not needed in case searchItemsCallback returns { data: { metadata: { totalItems: Number } } }
    default: () => undefined,
  })
  readonly getTotalItemsCallback!: () => Promise<number>;
  @Prop({ required: false, type: Function, default: undefined })
  readonly rowClickCallback!: (item: object, isExpanded: boolean) => void;
  @Prop({ required: false, type: Function, default: undefined })
  readonly getRowClassCallback!: (item: object) => string;
  @Prop({
    required: false,
    type: Function,
    default: () => {
      console.info('Create callback not implemented');
    },
  })
  readonly confirmDeleteDialogCallback!: (item: object) => void;
  @Prop({ required: false, type: Function, default: undefined })
  readonly getSortByCallback!: (sortBy: string) => string;
  @Prop({ required: false, type: Boolean, default: false })
  readonly isCreateAllowed!: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  readonly isEditAllowed!: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  readonly isDeleteAllowed!: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  readonly fixedHeader!: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  readonly disableSort!: boolean;
  @Prop({ required: false, type: Boolean, default: true })
  readonly mustSort!: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  readonly isExpandEnabled!: boolean;
  @Prop({ required: false, type: Boolean, default: true })
  readonly singleExpand!: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  readonly disablePagination!: boolean;
  @Prop({ required: false, type: Array, default: () => [] })
  readonly rejectQueryParamValues!: Array<string>;
  @Prop({ required: false, type: String, default: 'deletable' })
  readonly disableDeleteButtonProperty!: string;
  @Prop({ required: false, type: String, default: 'id' })
  readonly itemKey!: string;
  @Prop({ required: true, type: String })
  readonly i18nGroupKey!: string;

  @Ref()
  readonly dataTableRef!: Vue;

  MAX_ITEMS_PER_PAGE = Table.MAX_ITEMS_PER_PAGE;
  items = [];
  totalItems = 0;
  options: Options = {
    itemsPerPage: Table.DEFAULT_ITEMS_PER_PAGE,
    page: 1,
    sortBy: [],
  };
  itemsPerPageOptions = Table.ITEMS_PER_PAGE_OPTIONS;
  loading = true;
  i18n: KeyGroupI18n;
  dataTableHelper: DataTableHelper;
  HEADER_BUTTONS = {
    title: '',
    text: '',
    value: '__buttons',
    key: '__buttons',
    width: '1%',
    sortable: false,
  };
  HEADER_EXPAND = { text: '', title: '', value: 'data-table-expand', key: 'data-table-expand' };
  aboveTableHeight = 0;
  headerTypes = DATA_TABLE_HEADER_TYPES;
  expandedItems = new Map<string, any>();

  get hasRowButtons() {
    return (
      this.isEditAllowed ||
      this.isDeleteAllowed ||
      this.$slots.actions ||
      this.$slots['actions.start'] ||
      this.$slots['actions.end']
    );
  }

  get translatedHeaders() {
    const headers = this.dataTableHelper.translateHeaders(this.headers);
    if (this.hasRowButtons) {
      headers.push(this.HEADER_BUTTONS);
    }
    if (this.isExpandEnabled) {
      headers.unshift(this.HEADER_EXPAND);
    }

    return headers;
  }

  get sortBy() {
    return this.filter && this.filter.sortBy;
  }

  get tableHeight() {
    const tableFooterHeight = this.disablePagination ? 0 : TABLE_FOOTER_HEIGHT;
    return this.fixedHeader
      ? `calc(100vh - ${this.aboveTableHeight + tableFooterHeight + APP_FOOTER_HEIGHT + TABLE_HEIGHT_ADJUSTMENT}px)`
      : undefined;
  }

  get pageCount() {
    if (this.totalItems === 0) return 1;
    return Math.ceil(this.totalItems / this.options.itemsPerPage);
  }

  get startIndex() {
    return this.options.itemsPerPage * (this.options.page - 1);
  }

  get stopIndex() {
    return Math.min(this.totalItems, this.startIndex + this.options.itemsPerPage);
  }

  prevPage() {
    this.options.page = Math.max(1, this.options.page - 1);
  }

  nextPage() {
    this.options.page = Math.min(this.pageCount, this.options.page + 1);
  }

  get sortByOptions() {
    return this.translatedHeaders.filter(header =>
      typeof header !== 'string'
        ? !('sortable' in (header as HeaderInterface)) ||
          ('sortable' in (header as HeaderInterface) && (header as HeaderInterface).sortable)
        : true,
    );
  }

  created() {
    window.addEventListener('resize', this.calculateAboveTableHeight);
    this.$nextTick(() => {
      this.calculateAboveTableHeight();
    });

    this.options.sortBy = this.filter.sortBy
      ? [{ key: this.filter.sortBy, order: this.filter.sortDesc ? 'desc' : 'asc' }]
      : [];
    this.i18n = new KeyGroupI18n(this.i18nGroupKey, {
      ignoreNonExisting: true,
    });
    this.dataTableHelper = new DataTableHelper(this.i18nGroupKey);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateAboveTableHeight);
  }

  calculateAboveTableHeight() {
    this.aboveTableHeight = this.dataTableRef?.$el.getBoundingClientRect().y;
  }

  @Watch('options', {
    deep: true,
    immediate: true,
  })
  optionsChanged() {
    this.fetchItemsDebounced(this.fetchItems);
  }

  @Watch('filter', {
    deep: true,
    immediate: true,
  })
  filterChanged() {
    this.moveToFirstPage();
    this.fetchItemsDebounced(this.fetchItems);
  }

  moveToFirstPage() {
    this.options.page = 1;
  }

  public fetchItemsDebounced = debounce(async (fetchItemsFunction: () => Promise<void>) => {
    await fetchItemsFunction();
  }, Table.DEFAULT_TABLE_DEBOUNCE_TIME);

  public refresh() {
    this.moveToFirstPage();
    this.search();
  }

  search(query = {}) {
    this.fetchItems(query);
  }

  async fetchItems(query = {}) {
    try {
      this.loading = true;
      const filter = this.createFilter(query);
      const result = await this.searchItemsCallback(filter as Dictionary<any>);
      const axiosData = result;

      this.totalItems = await this.getTotalItemsCallback();
      if (!this.totalItems && this.totalItems !== 0) {
        this.totalItems = axiosData && axiosData.metadata ? axiosData.metadata.totalItems : result.length;
      }

      this.items = axiosData ? axiosData.data : result;
    } catch (err) {
      console.log(err);
      this.items = [];
    } finally {
      this.loading = false;
      this.$emit('itemsChanged', this.items);
    }
  }

  createFilter(query = {}) {
    const { page, itemsPerPage, sortBy } = this.options;

    const filter = {
      limit: this.disablePagination ? undefined : itemsPerPage,
      offset: this.disablePagination ? undefined : (page - 1) * itemsPerPage,
      ...this.filter,
      ...query,
    };

    if (get(sortBy, 'length')) {
      let callBackSortBy;
      if (this.getSortByCallback) {
        callBackSortBy = this.getSortByCallback(sortBy![0].key);
      }
      filter.sortBy = callBackSortBy || sortBy![0].key;
      filter.sortDesc = sortBy![0].order === 'desc';
    }

    return pickBy(filter, (paramValue: string) => !this.rejectQueryParamValues.includes(paramValue));
  }

  isColumnBoolean(column: ColumnInterface) {
    return column.type === DATA_TABLE_HEADER_TYPES.BOOLEAN;
  }

  transformValue(item: ItemInterface, column: ColumnInterface): string | boolean {
    return this.dataTableHelper.transformValue(item, column);
  }

  public getRowClass(item: object) {
    return this.getRowClassCallback ? this.getRowClassCallback(item) : '';
  }

  editRow(item: object) {
    if (this.isEditAllowed) {
      this.$emit('edit', item);
    }
  }

  createRow() {
    this.$emit('create');
  }

  rowClick(internalItem: any, toggleExpand: (item: any) => void, isExpanded: boolean) {
    if (this.isExpandEnabled) {
      this.rowClickCallback ? this.rowClickCallback(internalItem.raw, !isExpanded) : undefined;
      this.expandItems(internalItem, toggleExpand, isExpanded);
    } else {
      this.rowClickCallback ? this.rowClickCallback(internalItem.raw, isExpanded) : this.editRow(internalItem.raw);
    }
  }

  async deleteRow(item: object) {
    if (this.isDeleteAllowed) {
      await this.confirmDeleteDialogCallback(item);
      this.search();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isDeleteButtonDisabled(item: any) {
    return item[this.disableDeleteButtonProperty] === false;
  }

  getDataCyRowKey(item: object) {
    return get(item, this.dataCyRowKey);
  }

  getDataCyState(item: object, key: string) {
    return get(item, key);
  }

  expandItems(internalItem: any, toggleExpand: (item: any) => void, isExpanded: boolean) {
    if (!isExpanded) {
      if (this.singleExpand) {
        this.expandedItems.forEach(expandedItem => {
          toggleExpand(expandedItem);
        });
        this.expandedItems.clear();
      }

      this.expandedItems.set(internalItem.value, internalItem);
    } else {
      this.expandedItems.delete(internalItem.value);
    }

    toggleExpand(internalItem);
  }
}
</script>

<style lang="scss" scoped>
.data-table {
  height: 100%;
  width: 100%;
  cursor: pointer;

  :deep(table) {
    padding-bottom: 8px;
  }

  .auto-cursor {
    cursor: auto;
  }

  .dataTable__upSideDown {
    color: red;
    transform: rotate(180deg);
  }

  .btn-disabled {
    cursor: no-drop;
  }

  .data-container {
    display: flex !important;
    align-items: center;
  }

  .xs-checkbox {
    align-items: flex-start !important;
  }

  .datatable-checkbox {
    :deep(.v-selection-control__input) {
      align-items: flex-start !important;
    }
  }

  .xs-td {
    border-bottom: 0 !important;
    height: 24px !important;
    width: 100%;

    &:first-child {
      border-top: #cecece 2px dotted !important;

      &:not(.expand-is-enabled) {
        display: table !important;
        padding-top: 15px !important;
      }
    }

    &:last-child {
      height: 35px !important;
    }

    .data-container {
      .data-label {
        font-weight: 900;
        padding-left: 0.5rem;
      }
    }
  }

  .xs-expand-button {
    float: right;
  }

  .row-expanded {
    background: #e4e4e4;
  }

  .checkbox-small {
    width: 200px;
    text-align: left;
  }

  .v-data-table-footer__info {
    font-size: 12px;
  }

  .v-data-table-footer__items-per-page :deep(.v-field) {
    font-size: 12px;
    align-items: center;
  }

  .v-data-table-footer__items-per-page :deep(.v-field .v-field__input) {
    min-height: 24px;
    padding-top: 0;
  }

  .v-data-table-footer__items-per-page :deep(.v-field__append-inner) {
    padding-top: 0;
  }

  .v-data-table-footer__pagination :deep(.v-btn) {
    --v-btn-size: 12px;
  }
}
</style>
