import { CustomerProfileDto, CustomerProfileQuery, CustomerProfileService } from '@/api';
import { getQueryParams } from '@/utils/api';

export default {
  getCustomerProfileList: async (queryParams?: CustomerProfileQuery): Promise<CustomerProfileDto[]> => {
    const defaultQuery = { sortBy: 'orderNumber', sortDesc: false };
    const response = await CustomerProfileService.customerProfileControllerGetAll({
      ...defaultQuery,
      ...getQueryParams(queryParams || {}),
    });

    return response.data;
  },
};
