import isDefined from '@common/utils/isDefined';
import {
  addDays,
  differenceInDays,
  endOfDay,
  endOfMonth,
  format,
  isAfter,
  isValid,
  isWithinInterval,
  parse,
  startOfDay,
} from 'date-fns';

export const DATE_PICKER_FORMAT = 'dd.MM.yyyy';
export const DATE_BIRTHDAY_FORMAT = 'd.M.yyyy';
export const MONTH_PICKER_FORMAT = 'MM/yyyy';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FIRST_DAY_FORMAT = 'yyyy-MM-01';
export const MONTH_FORMAT = 'yyyy-MM';
export const YEAR_FORMAT = 'yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const MIN_DAY_OF_BIRTHDAY = '1900-01-01';
export const DATE_WITH_TIMEZONE_OFFSET = "yyyy-MM-dd'T'HH:mm:ssxxx";

/**
 * Format date with timezone offset.
 * E.g.: 2013-03-01T00:00:00+01:00
 * @param date
 */
export const toDateTimeOffset = (date: Date | string): string => {
  return format(date, DATE_WITH_TIMEZONE_OFFSET);
};

/**
 * Format date without time part.
 * @param date
 * @param format
 */
export const toDate = (date: Date | string, dateFormat?: string): string => {
  if (dateFormat) {
    return format(parse(date.toString(), dateFormat, new Date()), DATE_FORMAT);
  }
  return format(date, DATE_FORMAT);
};

/**
 * Format date to the first day of month.
 * @param date
 */
export const toMonth = (date: Date | string): string => {
  return format(date, DATE_FIRST_DAY_FORMAT);
};

/**
 * Get client timezone offset in minutes.
 * E.g.: if +02:00 then returns 120 minutes.
 * @param date
 */
export const getTimezoneOffset = (date: Date | string): number => {
  // Need to be reversed, +02:00 returns diff from UTC so -120.
  return -new Date(date).getTimezoneOffset();
};
/**
 * Get end of month
 *
 * @param date
 */
export const toEndOfMonth = (date: Date | string): string => {
  return format(endOfMonth(date), DATE_FORMAT);
};

/**
 * Checks validFrom and validTo and returns false if validFrom is after validTo, otherwise returns true
 *
 * @param validFrom
 * @param validTo
 */
export const checkValidityDates = (validFrom: Date | string, validTo: Date | string): boolean => {
  return !(validFrom && validTo && isAfter(validFrom, validTo));
};

/**
 * Format date for date picker, etc.
 * E.g.: 31.03.2020
 * @param date
 */
export const formatDate = (date: string | Date): string => {
  return date ? format(date, DATE_PICKER_FORMAT) : '';
};

/**
 * Format date time.
 * E.g.: 31.03.2020 13:45
 * @param date
 */
export const formatDateTime = (date: string | Date): string => {
  return format(date, DATE_TIME_FORMAT);
};

/**
 * Returns number of days between two dates including the start day
 *
 * @param {Date} start
 * @param {Date} end
 * @param options
 * @returns {number}
 */
export const getDaysDiffBetweenDates = (start: Date | string, end: Date | string, options = { includeStart: true }) => {
  return differenceInDays(startOfDay(end), startOfDay(start)) + (options.includeStart ? 1 : 0);
};

/**
 * Filter active or future records.
 * @param validTo
 */
export const filterActiveOrFuture = (validTo: string | Date) => {
  return differenceInDays(startOfDay(validTo), startOfDay(new Date())) >= 0;
};

export const isDateValid = (day: number | string | undefined, month: number | string | undefined) => {
  const DEFAULT_YEAR = 2000;
  return isValid(parse(`${day}.${month}.${DEFAULT_YEAR}`, DATE_PICKER_FORMAT, new Date()));
};

/**
 * Check if dateOne is invalid.
 * @param dateOne
 * @param dateTwo
 */
export const isDateOneAfterDateTwo = (dateOne?: string | Date, dateTwo?: string | Date): boolean => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  return isAfter(startOfDay(dateOne), startOfDay(dateTwo));
};

/**
 * Check if dateOne is same or after dateTwo.
 * @param dateOne
 * @param dateTwo
 */
export const isDateOneSameOrAfterDateTwo = (dateOne?: string | Date, dateTwo?: string | Date): boolean => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  return differenceInDays(startOfDay(dateOne), startOfDay(dateTwo)) >= 0;
};

/**
 * Check if dateOne is same or before dateTwo.
 * @param dateOne
 * @param dateTwo
 */
export const isDateOneSameOrBeforeDateTwo = (dateOne?: string | Date, dateTwo?: string | Date): boolean => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  return differenceInDays(startOfDay(dateOne), startOfDay(dateTwo)) <= 0;
};

/**
 * Check if date is between given date range.
 * @param date
 * @param validFrom
 * @param validTo
 */
export const isBetween = (date: string | Date, validFrom?: string | Date, validTo?: string | Date): boolean => {
  if (isDefined(validFrom as string) && isDefined(validTo as string) && validTo && validFrom) {
    return isWithinInterval(date, { start: startOfDay(validFrom), end: endOfDay(validTo) });
  }

  if (!isDefined(validFrom as string) && validTo) {
    return differenceInDays(startOfDay(date), startOfDay(validTo)) <= 0;
  }

  if (!isDefined(validTo as string)) {
    return isDateOneSameOrAfterDateTwo(date, validFrom);
  }

  return true;
};

/**
 * Get tomorrow.
 */
export const getTomorrow = (): Date => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getDateAfterDays = (date: string | Date, amount: number): string => {
  return format(addDays(date, amount), DATE_FORMAT);
};

/**
 * Returns earlier date of the two dates
 * @param dateOne
 * @param dateTwo
 */
export const getEarlierDate = (dateOne: string, dateTwo: string): string | Date => {
  if (!isDefined(dateOne)) {
    return dateTwo;
  }

  if (!isDefined(dateTwo)) {
    return dateOne;
  }
  return isDateOneAfterDateTwo(dateOne, dateTwo) ? dateTwo : dateOne;
};

/**
 * Returns later date of the two dates
 * @param dateOne
 * @param dateTwo
 */
export const getLaterDate = (dateOne: string, dateTwo: string): string | Date => {
  if (!isDefined(dateOne)) {
    return dateTwo;
  }

  if (!isDefined(dateTwo)) {
    return dateOne;
  }

  return isDateOneAfterDateTwo(dateOne, dateTwo) ? dateOne : dateTwo;
};

/**
 * convert date-fns date format to moment date format
 */
export const toMomentDateFormat = (dateFnsDateFormat: string) => {
  return dateFnsDateFormat.replaceAll('d', 'D').replaceAll('y', 'Y');
};
