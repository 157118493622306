<template>
  <!--
    from file(s): predat_do_spravy.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.9l0 -863.8c0,-79.62 65.01,-144.65 144.64,-144.65l449.08 0c79.63,0 144.64,65.03 144.64,144.65l0 863.8c0,79.62 -65.01,144.65 -144.64,144.65l-449.08 0c-79.63,0 -144.64,-65.03 -144.64,-144.65zm484.93 -392.57l-330.98 0c-19.15,0 -34.77,-15.63 -34.77,-34.78l0 -9.11c0,-19.14 15.62,-34.77 34.77,-34.77l330.99 0 -103.16 -103.14c-13.54,-13.54 -13.54,-35.64 0,-49.18l6.43 -6.43c13.54,-13.54 35.64,-13.54 49.18,0l171.61 171.61c6.77,6.77 10.19,15.01 10.19,24.59l0 3.76c0,9.58 -3.42,17.82 -10.19,24.59l-171.61 171.61c-13.54,13.54 -35.64,13.54 -49.18,0l-6.43 -6.43c-13.54,-13.54 -13.54,-35.64 0,-49.18l103.15 -103.14z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class ManageCardRequestIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
