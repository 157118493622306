<template>
  <v-main>
    <v-container class="fill-height bg-rdpbackground" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <RDPHomePageLogo />
        </v-col>
        <v-col cols="12">
          <registration-form />
        </v-col>
        <v-col sm="8" md="4" cols="12" class="d-flex justify-space-between pt-0">
          <router-link :to="paths.LOGIN">
            {{ $t('registration.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app theme="dark">
      <FooterLinks dark />
    </v-footer>
  </v-main>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import RegistrationForm from '@/components/registration/RegistrationForm.vue';
import Paths from '@/constants/Paths';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import RDPHomePageLogo from '@/components/common/RDPHomePageLogo.vue';

@Component({
  components: {
    RegistrationForm,
    FooterLinks,
    RDPHomePageLogo,
  },
})
export default class RegistrationPage extends Vue {
  paths = Paths;
}
</script>
