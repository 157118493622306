<template>
  <v-main>
    <v-container class="fill-height bg-rdpbackground" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <RDPHomePageLogo />
        </v-col>
        <v-col cols="12">
          <v-form ref="form" validate-on="lazy" @submit.prevent>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12 rounded-xl">
                  <v-toolbar color="systemPrimary" dark flat>
                    <v-toolbar-title>
                      {{ $t('forgottenPassword.header') }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pl-6 pr-6">
                    <v-text-field
                      v-model="username"
                      prepend-icon="mdi-account"
                      type="text"
                      :rules="validations.username"
                      :label="$t('forgottenPassword.username')"
                      data-cy="forgotten-password-username"
                      variant="underlined"
                    />
                  </v-card-text>
                  <v-card-actions class="justify-end pr-6 pb-6 pt-0">
                    <ConfirmButton
                      :text="$t('forgottenPassword.send')"
                      :confirm="askForPassword"
                      class="rdp-button"
                      style="width: 140px"
                      button-color="buttonPrimary"
                      data-cy="forgotten-password-btn"
                      type="submit"
                      icon="$forgottenPasswordIcon"
                      large-icon
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col sm="8" md="4" cols="12" class="d-flex justify-space-between pt-0">
          <router-link :to="paths.LOGIN">
            {{ $t('forgottenPassword.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app theme="dark">
      <FooterLinks dark />
    </v-footer>
  </v-main>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import Paths from '@/constants/Paths';
import { emailValidator, requiredValidator } from '@/utils/validators';
import authService from '@/services/api/authService';
import Errors from '@/constants/Errors';
import { FormInterface } from '@/components/common/FormInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import RDPHomePageLogo from '@/components/common/RDPHomePageLogo.vue';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast';
import ToastModule from '@/store/modules/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';

@Component({
  components: {
    ConfirmButton,
    FooterLinks,
    RDPHomePageLogo,
  },
})
export default class ForgottenPasswordPage extends Vue {
  @Ref()
  readonly form!: FormInterface;

  paths = Paths;
  username = '';
  validations = {
    username: [(v: string) => requiredValidator(v), (v: string) => emailValidator(v)],
  };

  async askForPassword() {
    if ((await this.form.validate()).valid) {
      const toastPosition = {
        x: 'center',
        y: 'top',
        timeout: PERMANENT_TOAST_INTERVAL,
      };
      try {
        await authService.resetPassword({ value: this.username });
        ToastModule.success({
          message: this.$t('forgottenPassword.success'),
          options: toastPosition,
        });
        this.resetForm();
        await this.$router.push(Paths.LOGIN);
      } catch (e) {
        switch ((e as ApiErrorInterface).data?.name) {
          case Errors.INVALID_EMAIL:
            ToastModule.error({
              message: this.$t('apiErrors.invalidEmail'),
              options: toastPosition,
            });
            break;
          case Errors.VALIDATION_ERROR:
            ToastModule.error({
              message: this.$t('apiErrors.validationError'),
              options: toastPosition,
            });
            break;
        }
      }
    }
  }

  resetForm() {
    this.username = '';
    this.form.resetValidation();
  }
}
</script>
