import { DATE_FORMAT, getDateAfterDays, isDateOneAfterDateTwo } from '@/utils/dateTime';
import { CustomerProfileDto, EnumsDto } from '@/api';
import isDefined from '@common/utils/isDefined';
import { addDays, addYears, endOfYear, format, getYear, isAfter, set, subDays } from 'date-fns';
import GlobalSettingModule from '@/store/modules/globalSetting';

const ADD_19_YEARS = 19;

const getNextDate = (day: number, month: number, year: number) => {
  const today = set(new Date(), { year });
  const givenDate = set(new Date(), { month: month - 1, date: day, year });
  if (isAfter(today, givenDate)) {
    set(givenDate, { year: getYear(today) + 1 });
  }
  return format(givenDate, DATE_FORMAT);
};

export const computeValidTo = (
  customerProfile: CustomerProfileDto | undefined,
  birthday: string,
  currentYear: number,
  validFrom?: string,
): string => {
  let validTo = '';
  switch (customerProfile?.validToDefault?.name) {
    case EnumsDto.customerProfileValidToDefaults.END_OF_YEAR:
      validTo = format(endOfYear(set(new Date(), { year: currentYear })), DATE_FORMAT);
      break;
    case EnumsDto.customerProfileValidToDefaults.END_OF_JANUARY:
      validTo = getNextDate(31, 1, currentYear);
      break;
    case EnumsDto.customerProfileValidToDefaults.END_OF_AUGUST:
      validTo = getNextDate(31, 8, currentYear);
      break;
    case EnumsDto.customerProfileValidToDefaults.END_OF_SEPTEMBER:
      validTo = getNextDate(30, 9, currentYear);
      break;
    case EnumsDto.customerProfileValidToDefaults.TO_AGE: {
      const ageTo = customerProfile.ageTo || 0;
      validTo = format(subDays(addYears(birthday, ageTo + 1), 1), DATE_FORMAT);
      break;
    }
    case EnumsDto.customerProfileValidToDefaults._365_DAYS_AFTER_CONFIRMATION_DELIVERY: {
      // add only 364 days, today is the last day
      validTo = format(addDays(validFrom || new Date(), 364), DATE_FORMAT);
      break;
    }
    case EnumsDto.customerProfileValidToDefaults.TO_DEFINED_YEARS: {
      const yearsToAdd = GlobalSettingModule.customerProfileValidToInYears;
      // add years from global setting
      validTo = format(addYears(validFrom || new Date(), yearsToAdd.value), DATE_FORMAT);
      break;
    }
    case EnumsDto.customerProfileValidToDefaults.DAY_BEFORE_19_BIRTHDAY_OR_END_OF_AUGUST: {
      const endOfAugust = getNextDate(31, 8, currentYear);
      const dayBefore19Birthday = format(subDays(addYears(birthday, ADD_19_YEARS), 1), DATE_FORMAT);
      validTo = isDateOneAfterDateTwo(endOfAugust, dayBefore19Birthday) ? dayBefore19Birthday : endOfAugust;
      break;
    }
    case EnumsDto.customerProfileValidToDefaults._430_DAYS_AFTER_CONFIRMATION_DELIVERY: {
      // add only 429 days, today is the last day
      validTo = format(addDays(validFrom || new Date(), 429), DATE_FORMAT);
      break;
    }
    default:
      validTo = '';
      break;
  }

  if (
    isDefined(validTo) &&
    isDefined(customerProfile?.validToDefault?.name) &&
    isDefined(customerProfile?.minProfileDaysValidity)
  ) {
    const minProfileDaysValidity = customerProfile?.minProfileDaysValidity || 0;
    const minDateValidTo = getDateAfterDays(new Date(), minProfileDaysValidity);
    if (isDateOneAfterDateTwo(minDateValidTo, validTo)) {
      const newYear = currentYear + 1;
      return computeValidTo(customerProfile, birthday, newYear, validFrom);
    }
  }

  return validTo;
};

export const isProfileReduced = (profile?: CustomerProfileDto): boolean => {
  return !!(profile && profile.reduced);
};

export const shouldSetValidTo = (profile?: CustomerProfileDto): boolean => {
  // Show date picker when default is not defined or is not CARD_VALIDITY.
  return (
    (profile && !isDefined(profile?.validToDefault?.name)) ||
    profile?.validToDefault?.name !== EnumsDto.customerProfileValidToDefaults.CARD_VALIDITY
  );
};

export const requireConfirmationCheck = (profile?: CustomerProfileDto): boolean => {
  return !!profile?.requireConfirmation;
};

export const allowIsicInProfile = (profile?: CustomerProfileDto): boolean => {
  return !!profile?.isic;
};
