<template>
  <!--
    from file(s): odeslat_registrovat.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65 0,323.79 0,539.99 0,863.78 0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65zm335.25 -396.25c-6.66,7.39 -12.96,11.04 -18.88,10.99 -5.92,-0.07 -12,-2.89 -18.23,-8.49l-111.97 -100.83c-6.23,-5.6 -12.31,-8.44 -18.23,-8.49 -5.93,-0.06 -12.05,3.41 -18.36,10.41l-37.8 41.98c-6.3,7 -9.12,13.45 -8.43,19.35 0.67,5.88 4.12,11.63 10.35,17.24l197.12 177.49c6.23,5.6 12.31,8.44 18.24,8.5 5.91,0.06 12.05,-3.43 18.34,-10.42l279.19 -310.07c6.3,-6.99 9.12,-13.45 8.43,-19.33 -0.67,-5.88 -4.13,-11.64 -10.35,-17.24l-46.66 -42.01c-6.22,-5.6 -12.3,-8.44 -18.23,-8.49 -5.92,-0.07 -12.04,3.41 -18.34,10.4l-206.19 229.01z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class RegistrationSendIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
