/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerCardSendCreateDto = {
  /**
   * Customer card identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Unique identifier of card.
   */
  cardNumber: string;
  /**
   * Chip number identifier. Is unique.
   */
  chipNumber: string;
  /**
   * Card valid from. Type: 'DATE'
   */
  validFrom: string;
  /**
   * Card valid to. Type: 'DATE'
   */
  validTo: string;
  /**
   * Card type. Smart or bank.
   */
  type: string;
  /**
   * Card state. Active, blocked, ...
   */
  state: string;
  /**
   * Customer profile valid from date. Type: 'DATE'
   */
  profileOneValidFrom: string;
  /**
   * Customer profile valid to date. Type: 'DATE'
   */
  profileOneValidTo: string;
  /**
   * Customer profile confirmation date. Type: 'DATE'
   */
  profileOneConfirmationChecked?: string;
  /**
   * Customer profile identifier. Type: 'UUID'
   */
  profileOneId: string;
  /**
   * Customer profile valid from date. Type: 'DATE'
   */
  profileTwoValidFrom?: string;
  /**
   * Customer profile valid to date. Type: 'DATE'
   */
  profileTwoValidTo?: string;
  /**
   * Customer profile confirmation date. Type: 'DATE'
   */
  profileTwoConfirmationChecked?: string;
  /**
   * Customer profile identifier. Type: 'UUID'
   */
  profileTwoId?: string;
  customerCardType: CustomerCardSendCreateDto.customerCardType;
  /**
   * Customer identifier. Type: 'UUID'
   */
  customerId: string;
  bankCardIssuer?: string;
  /**
   * Agree with marketing consent.
   */
  marketingConsent?: boolean;
};
export namespace CustomerCardSendCreateDto {
  export enum customerCardType {
    PERSONAL = 'PERSONAL',
    PORTABLE = 'PORTABLE',
    RECLAMATION = 'RECLAMATION',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    BANK_CARD = 'BANK_CARD',
    WHITE_LIST_CARD = 'WHITE_LIST_CARD',
  }
}
