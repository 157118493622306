/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumDto } from './EnumDto';
import type { PersonDto } from './PersonDto';
export type ShoppingCartItemVm = {
  /**
   * Item identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Type of shopping cart item.
   */
  type: ShoppingCartItemVm.type;
  /**
   * Marks the shopping cart item as a cancellation.
   */
  cancellation: boolean;
  feeName?: string;
  feeId?: string;
  productName?: string;
  /**
   * Ticket fare zone.
   */
  zone: EnumDto;
  /**
   * Ticket fare customer profile identifier. Type: 'UUID'
   */
  customerProfileId?: string;
  /**
   * Ticket fare tariff profile identifier. Type: 'UUID'
   */
  tariffProfileId?: string;
  /**
   * Ticket fare combination identifier. Type: 'UUID'
   */
  ticketCombinationId?: string;
  /**
   * Ticket fare validity start date. Type: 'DATE'
   */
  validFrom?: string;
  /**
   * Ticket fare validity end date. Type: 'DATE'
   */
  validTo?: string;
  /**
   * Electronic wallet operation type.
   */
  eWalletOperationType?: string;
  /**
   * The customer to whom the item is attached. Should be needed only for e-shop.
   */
  customer?: PersonDto;
  /**
   * Additional description.
   */
  description?: string;
  /**
   * Quantity.
   */
  quantity: number;
  /**
   * Max quantity. If 1 do not show increment or decrement button.
   */
  maxQuantity: number;
  /**
   * Price per one item.
   */
  price: number;
  /**
   * Tax percentage.
   */
  taxPercentage: number;
  /**
   * Total price. Price x Quantity.
   */
  totalPrice: number;
  /**
   * Extra data used for dynamic metadata. E.g.: productId
   */
  payload?: any;
};
export namespace ShoppingCartItemVm {
  /**
   * Type of shopping cart item.
   */
  export enum type {
    FEE = 'FEE',
    PRODUCT = 'PRODUCT',
    TICKET_FARE = 'TICKET_FARE',
    PAK_TICKET_FARE = 'PAK_TICKET_FARE',
    ELECTRONIC_WALLET = 'ELECTRONIC_WALLET',
    RECLAMATION = 'RECLAMATION',
    ROUNDING = 'ROUNDING',
  }
}
