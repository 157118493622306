import type { CardApplicationAttachmentResponse, CancelablePromise } from '@/api';
import { OpenAPI } from '@/api';
import FormData from 'form-data';
import { request as __request } from '@/api/core/request';

export class CustomEshopCardApplicationService {
  public static eshopCardApplicationControllerUploadAttachment({
    id,
    formData,
  }: {
    id: string;
    formData?: FormData;
  }): CancelablePromise<CardApplicationAttachmentResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/card-applications/{id}/attachment',
      path: {
        id: id,
      },
      body: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
}
