import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const trencinColors: Partial<AppColors> = {
  systemPrimary: '#0c60ad',
  systemSecondary: '#0c60ad',
  buttonPrimary: '#0c60ad',
  primary: '#0c60ad',
  buttonWarning: colors.red.darken1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#ffffff',
  leftpanelhover: '#0c60ad',
};
