/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TicketFareStateResponse } from '../models/TicketFareStateResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TicketFareStateService {
  /**
   * Get ticket fare state
   * @returns TicketFareStateResponse
   * @throws ApiError
   */
  public static ticketFareStateControllerGetAll(): CancelablePromise<TicketFareStateResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/ticket-fare-states',
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
}
