import { ImageConst } from '@/constants/Image';
import {
  ApiErrorResponse,
  AssignCustodianCommand,
  AssignOriginCommand,
  CardApplicationListResponse,
  CardApplicationQuery,
  ConnectAccountCommand,
  CreateCustomerCommand,
  CustomerCardDetailVm,
  CustomerCardInfoListQuery,
  CustomerCardInfoListResponse,
  CustomerCardResponse,
  CustomerDetailVm,
  CustomerDto,
  CustomerHeaderVm,
  CustomerTicketFareListResponse,
  ElectronicWalletTransactionQuery,
  ElectronicWalletTransactionVm,
  EshopCustomerService,
  EshopTransferEWalletFromPakCardCommand,
  EWalletsTransactionListResponse,
  IsCustomerUniqueQuery,
  IsIsicCardNumberValidQuery,
  ManagingAccountsCardResponse,
  PaginationQuery,
  TicketFareIsicPriceDto,
  TicketFareIsicPriceQuery,
  TicketStateListQuery,
  UpdateBaseCustomerCommand,
} from '@/api';
import { getQueryParams, getRequestBody } from '@/utils/api';

export function setCustomerPhoto(response: CustomerCardResponse) {
  if (response.data.customer?.photo) {
    response.data.customer.photo = ImageConst.pngBase64Meta + response.data.customer.photo;
  } else {
    response.data.customer.photo = '';
  }

  if (response.data.draftCustomer) {
    if (response.data.draftCustomer.photo) {
      response.data.draftCustomer.photo = ImageConst.pngBase64Meta + response.data.draftCustomer.photo;
    } else {
      response.data.draftCustomer.photo = '';
    }
  }
}

export default {
  getCustomer: async (id: string): Promise<CustomerDetailVm> => {
    const response = await EshopCustomerService.eshopCustomerControllerGetOne({
      id,
    });
    if (response.data.photo) {
      response.data.photo = ImageConst.pngBase64Meta + response.data.photo;
    }
    if (response.data.parent && response.data.parent.photo) {
      response.data.parent.photo = ImageConst.pngBase64Meta + response.data.parent.photo;
    }
    return response.data;
  },

  getCustomerTicketFareList: (id: string, query?: TicketStateListQuery): Promise<CustomerTicketFareListResponse> => {
    return EshopCustomerService.eshopCustomerControllerGetCustomerTicketFares({
      id,
      ...getQueryParams(query),
    });
  },

  getCustomersManagingAccountsCard: (query?: PaginationQuery): Promise<ManagingAccountsCardResponse> => {
    return EshopCustomerService.eshopCustomerControllerGetAccountsManagingCard(getQueryParams(query));
  },

  createCustomer: async (payload: CreateCustomerCommand): Promise<CustomerDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerCreate({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  updateCustomer: async (id: string, payload: UpdateBaseCustomerCommand): Promise<CustomerDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerUpdate({
      id,
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  hasActiveCardApplication: async (id: string): Promise<boolean> => {
    const response = await EshopCustomerService.eshopCustomerControllerGetActiveCardApplication({ id });

    return response.errors.length > 0;
  },

  canVirtualCardApplicationBeCreated: async (id: string): Promise<boolean> => {
    const response = await EshopCustomerService.eshopCustomerControllerCheckCreationOfVirtualCardApplication({ id });

    return response.errors.length === 0;
  },

  getCustomerActiveCard: async (id: string): Promise<CustomerCardDetailVm> => {
    const response = await EshopCustomerService.eshopCustomerControllerGetCustomerActiveCard({
      id,
    });
    setCustomerPhoto(response);
    return response.data;
  },

  getCustomerLastActiveCard: async (id: string): Promise<CustomerCardDetailVm> => {
    const response = await EshopCustomerService.eshopCustomerControllerGetCustomerLastActiveCard({ id });
    setCustomerPhoto(response);
    return response.data;
  },

  getCustomerCardList: async (id: string, query: CustomerCardInfoListQuery): Promise<CustomerCardInfoListResponse> => {
    return EshopCustomerService.eshopCustomerControllerGetCustomerCards({
      id,
      ...getQueryParams(query),
    });
  },

  getCustomerEWalletTransactionList: async (
    id: string,
    query: ElectronicWalletTransactionQuery,
  ): Promise<EWalletsTransactionListResponse> => {
    return EshopCustomerService.eshopCustomerControllerGetCustomerEWalletsTransactions({
      id,
      ...getQueryParams(query),
    });
  },

  getCustomerCardApplicationList: async (
    id: string,
    query: CardApplicationQuery,
  ): Promise<CardApplicationListResponse> => {
    return EshopCustomerService.eshopCustomerControllerGetCardApplications({
      id,
      ...getQueryParams(query),
    });
  },

  checkIfCustomerIsUnique: async (query: IsCustomerUniqueQuery): Promise<ApiErrorResponse> => {
    return EshopCustomerService.eshopCustomerControllerVerifyIfCustomerIsUnique(query);
  },

  connectRegisteredAccount: async (payload: ConnectAccountCommand): Promise<CustomerDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerConnectRegisteredAccount({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  connectAssignedAccount: async (payload: ConnectAccountCommand): Promise<CustomerDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerConnectAssignedAccount({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  transferPakToMap: async (payload: EshopTransferEWalletFromPakCardCommand): Promise<ElectronicWalletTransactionVm> => {
    const response = await EshopCustomerService.eshopCustomerControllerEWalletPakTransfer({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  getLoggedCustomer: async (): Promise<CustomerHeaderVm | undefined> => {
    const response = await EshopCustomerService.eshopCustomerControllerGetLoggedCustomer();

    return response.data;
  },

  hasOnlyManagedRelations: async (): Promise<boolean | undefined> => {
    const response = await EshopCustomerService.eshopCustomerControllerCheckRegisteredCustomer();

    return response.data?.onlyManagedRelations;
  },

  isCustomerManageable: async (id: string): Promise<boolean | undefined> => {
    const response = await EshopCustomerService.eshopCustomerControllerIsCustomerManageable({
      id,
    });

    return response.data?.customerManageable;
  },

  assignCustomerCustodian: async (id: string, payload: AssignCustodianCommand): Promise<CustomerDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerSetCustomerCustodian({
      id,
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  validCustomerIsicCardNumber: async (query: IsIsicCardNumberValidQuery): Promise<ApiErrorResponse> => {
    return await EshopCustomerService.eshopCustomerControllerVerifyIsicCardNumber(query);
  },

  setCustomerOrigin: async (id: string, payload: AssignOriginCommand): Promise<CustomerDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerSetCustomerOrigin({
      id,
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  calculateIsicTicketPrice: async (id: string, query: TicketFareIsicPriceQuery): Promise<TicketFareIsicPriceDto> => {
    const response = await EshopCustomerService.eshopCustomerControllerGetIsicTicketFarePrice({
      id,
      ...query,
    });
    return response.data;
  },
};
