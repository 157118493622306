import {
  BankCardService,
  CustomerCardDetailVm,
  CustomerCardInfoVm,
  TokenizationRequest,
  TokenizationResponse,
} from '@/api';

export default {
  getBankCards: async (customerId: string): Promise<CustomerCardInfoVm[]> => {
    const response = await BankCardService.bankCardControllerGetCustomerBankCards({
      customerId,
    });

    return response.data;
  },

  initTokenization: async (cardApplicationId: string, appRouteUrl: string): Promise<TokenizationRequest> => {
    const response = await BankCardService.bankCardControllerInitTokenization({
      cardApplicationId,
      requestBody: {
        appRouteUrl,
      },
    });

    return response.data;
  },

  finishTokenization: async (requestBody: TokenizationResponse): Promise<CustomerCardDetailVm> => {
    const response = await BankCardService.bankCardControllerFinishTokenization({ requestBody });

    return response.data;
  },

  blockBankCard: async (id: string): Promise<CustomerCardDetailVm> => {
    const response = await BankCardService.bankCardControllerBlockBankCard({
      id,
    });

    return response.data;
  },

  activateBankCard: async (id: string): Promise<CustomerCardDetailVm> => {
    const response = await BankCardService.bankCardControllerActivateBankCard({
      id,
    });

    return response.data;
  },

  deleteBankCard: async (id: string): Promise<void> => {
    await BankCardService.bankCardControllerRemoveBankCard({ id });
  },
};
