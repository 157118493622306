import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const dpmoColors: Partial<AppColors> = {
  systemPrimary: '#191970',
  systemSecondary: '#191970',
  buttonPrimary: '#191970',
  primary: '#191970',
  buttonWarning: '#FF0000',
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#C0C0C0',
  leftpanelhover: '#191970',
};
