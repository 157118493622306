import {
  ApiResponse,
  ChangePasswordCommand,
  CustomerAccountDto,
  CustomerProfileVm,
  EshopAuthService,
  RegisterCommand,
  StringValueCommand,
  UpdatePasswordCommand,
  UsernameLoginCommand,
} from '@/api';
import { getRequestBody } from '@/utils/api';

export default {
  login: async (payload: UsernameLoginCommand): Promise<CustomerProfileVm> => {
    const response = await EshopAuthService.eshopAuthControllerLogin({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  register: async (payload: RegisterCommand): Promise<CustomerAccountDto> => {
    const response = await EshopAuthService.eshopAuthControllerRegister({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  resendVerificationCode: (payload: StringValueCommand): Promise<ApiResponse> => {
    return EshopAuthService.eshopAuthControllerResend({
      requestBody: getRequestBody(payload),
    });
  },

  resetPassword: (payload: StringValueCommand): Promise<ApiResponse> => {
    return EshopAuthService.eshopAuthControllerResetPassword({
      requestBody: getRequestBody(payload),
    });
  },

  changePassword: (payload: ChangePasswordCommand): Promise<ApiResponse> => {
    return EshopAuthService.eshopAuthControllerChangePassword({
      requestBody: getRequestBody(payload),
    });
  },

  verify: async (payload: StringValueCommand): Promise<CustomerAccountDto> => {
    const response = await EshopAuthService.eshopAuthControllerConfirmation({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  updatePassword: (payload: UpdatePasswordCommand): Promise<ApiResponse> => {
    return EshopAuthService.eshopAuthControllerUpdatePassword({
      requestBody: getRequestBody(payload),
    });
  },
};
