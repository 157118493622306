import { MIN_CUSTOMER_AGE } from '@/config/config';

export enum ConnectAccountTypeDialog {
  REGISTER = 'REGISTER',
  ASSIGN = 'ASSIGN',
}

export const ConnectAccountTypesDialog: ConnectAccountDialogTypesInterface = {
  REGISTER: {
    title: { translate: 'registerTitle' },
    info: { translate: 'registerInfo' },
    icon: '$newCardApplicationIcon',
  },
  ASSIGN: {
    title: {
      translate: 'assignTitle',
      param: { minCustomerAge: MIN_CUSTOMER_AGE },
    },
    info: {
      translate: 'assignInfo',
      param: { minCustomerAge: MIN_CUSTOMER_AGE },
    },
    icon: '$assignCardIcon',
  },
};

export interface ConnectAccountDialogInterface {
  openDialog: (type: ConnectAccountTypeDialog) => void;
  closeDialog: () => void;
}

export interface ConnectAccountDialogTypesInterface {
  REGISTER: ConnectAccountDialogTypeInterface;
  ASSIGN: ConnectAccountDialogTypeInterface;
}

export interface ConnectAccountDialogTypeInterface {
  title: { translate: string; param?: { [key: string]: string | number } };
  info: { translate: string; param?: { [key: string]: string | number } };
  icon: string;
}
