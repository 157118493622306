import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { DEFAULT_LOCALE } from '@/config/config';
import router from '../../../src/router';

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'locale',
  preserveState: localStorage.getItem('vuex') !== null && !!JSON.parse(localStorage.getItem('vuex') || '{}').locale,
})
class Locale extends VuexModule {
  public locale = DEFAULT_LOCALE;
  public supportedLocales: string[] = [];

  @Mutation
  public setSupportedLocales(supportedLocales: string[]): void {
    this.supportedLocales = supportedLocales;
  }

  @Mutation
  public changeLocale(locale) {
    this.locale = locale;
    router.go(0); // refresh actual view to reload all data from API
  }
}

export default getModule(Locale);
