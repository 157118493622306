<template>
  <v-row v-if="$vuetify.display.mobile" data-cy="customer-detail-component">
    <v-col>
      <CustomerMainPersonalInfo />
    </v-col>

    <v-expansion-panels v-if="userAccountModule.hasCardDetailData">
      <v-expansion-panel>
        <v-expansion-panel-title class="font-weight-bold text-subtitle-1">
          {{ $t('userAccountPage.customerDetail.detailedInformation') }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <RDPCustomerDetail v-if="customerModule.currentlySelectedCustomerId" :show-main-personal-info="false" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import UserAccountModule from '@/store/modules/userAccount';
import CustomerModule from '@/store/modules/customer';
import CustomerMainPersonalInfo from '@/components/common/RDPCustomerDetail/CustomerMainPersonalInfo.vue';
import RDPCustomerDetail from '@/components/common/RDPCustomerDetail/RDPCustomerDetail.vue';

@Component({
  components: {
    CustomerMainPersonalInfo,
    RDPCustomerDetail,
  },
})
export default class RDPCustomerDetailXsOnly extends Vue {
  userAccountModule = UserAccountModule;
  customerModule = CustomerModule;
}
</script>
