import {
  AccountActiveTicketListResponse,
  AccountRelatedCustomersQuery,
  ApiResponse,
  CustomerAccountService,
  CustomerHeaderVm,
} from '@/api';

export default {
  getAccountRelatedCustomerList: async (query: AccountRelatedCustomersQuery): Promise<CustomerHeaderVm[]> => {
    const response = await CustomerAccountService.customerAccountControllerGetAccountRelatedCustomers(query);

    return response.data;
  },

  getActiveTicketsList: async (): Promise<AccountActiveTicketListResponse> => {
    return await CustomerAccountService.customerAccountControllerGetAccountActiveTickets();
  },

  removeCustomerAccount: async (): Promise<ApiResponse> => {
    return await CustomerAccountService.customerAccountControllerRemoveCustomerAccount();
  },
};
