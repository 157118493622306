<template>
  <v-card width="100%" class="fill-height py-2 px-4"> </v-card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class TicketFarePage extends Vue {}
</script>
