<template>
  <div
    :title="$t('shoppingCart.title')"
    data-cy="shopping-cart-icon"
    class="shoppingCart"
    :style="$vuetify.display.mobile ? { width: '50px' } : { width: '60px' }"
    @click.stop="redirectToShoppingCart()"
  >
    <v-icon size="x-large" class="shopping-cart-top-icon" icon="$shoppingCartTopIcon" />
    <span
      v-if="customerModule.currentlySelectedCustomerId && shoppingCartItemsCount"
      data-cy="shopping-cart-icon-count"
      class="shoppingCartCounter"
    >
      {{ shoppingCartItemsCount }}
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import CustomerModule from '@/store/modules/customer';
import shoppingCartService from '@/services/api/shoppingCartService';
import Paths from '@/constants/Paths';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component
export default class ShoppingCartIcon extends Vue {
  shoppingCartModule = ShoppingCartModule;
  customerModule = CustomerModule;

  async created() {
    try {
      const shoppingCart = await shoppingCartService.getShoppingCart();
      this.shoppingCartModule.setShoppingCart(shoppingCart);
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, 'shoppingCart', 'loadingFailed'),
      });
    }
  }

  redirectToShoppingCart() {
    this.$router.push(Paths.SHOPPING_CART);
  }

  get shoppingCartItemsCount() {
    return this.shoppingCartModule.items.length
      ? this.shoppingCartModule.items.length > 9
        ? '9+'
        : this.shoppingCartModule.items.length
      : 0;
  }
}
</script>

<style scoped>
.shoppingCart {
  width: 60px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.82);
  border: 2px solid rgba(0, 0, 0, 0.25);
}

.shoppingCartCounter {
  height: 10px;
  width: 10px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: bold;
  color: #073855;
}

.shopping-cart-top-icon {
  color: #073855;
}
</style>
