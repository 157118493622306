/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TicketStateListQuery = {
  stateIds?: Array<'CANCELLED' | 'EXPIRED' | 'ACTIVE' | 'FUTURE'>;
  combinationType?: TicketStateListQuery.combinationType;
  cardNumber?: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
};
export namespace TicketStateListQuery {
  export enum combinationType {
    SINGLE = 'SINGLE',
    TIME = 'TIME',
  }
}
