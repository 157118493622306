import {
  CardApplicationAttachmentDto,
  CardApplicationDto,
  CreateCardApplicationCommand,
  CreateVirtualCardApplicationCommand,
  EshopCardApplicationService,
  VerifyIfCardCanBeSendByPostQuery,
} from '@/api';
import { CustomEshopCardApplicationService } from '@/api-custom';
import { getRequestBody } from '@/utils/api';
import { getTimezoneOffset } from '@/utils/dateTime';
import FormData from 'form-data';

export default {
  getCardApplication: async (id: string): Promise<CardApplicationDto> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerGetOne({
      id,
    });

    return response.data;
  },

  getLastCardApplication: async (): Promise<CardApplicationDto> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerGetLast();

    return response.data;
  },

  createCardApplication: async (payload: CreateCardApplicationCommand): Promise<CardApplicationDto> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerCreate({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  createVirtualCardApplication: async (payload: CreateVirtualCardApplicationCommand): Promise<CardApplicationDto> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerCreateVirtualCardApplication({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  createBankCardApplication: async (payload: CreateVirtualCardApplicationCommand): Promise<CardApplicationDto> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerCreateBankCardApplication({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  verifyIfCardCanBeSendByPost: async (query: VerifyIfCardCanBeSendByPostQuery): Promise<boolean> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerVerifyIfCardCanBeSendByPost(query);

    return response.errors.length === 0;
  },

  rejectCardApplication: async (id: string, rejectReason: string): Promise<CardApplicationDto> => {
    const response = await EshopCardApplicationService.eshopCardApplicationControllerReject({
      id,
      requestBody: { note: rejectReason },
    });

    return response.data;
  },

  addAttachment: async (
    id: string,
    cardApplicationAttachmentTypeId: string,
    isProfileOne: boolean,
    file: File,
  ): Promise<CardApplicationAttachmentDto> => {
    const formData = new FormData();
    formData.append('attachment', file, file.name);
    formData.append('cardApplicationAttachmentTypeId', cardApplicationAttachmentTypeId);
    formData.append('isProfileOne', isProfileOne.toString());
    formData.append('timezoneOffset', getTimezoneOffset(new Date()).toString());
    const response = await CustomEshopCardApplicationService.eshopCardApplicationControllerUploadAttachment({
      id,
      formData,
    });

    return response.data;
  },
};
