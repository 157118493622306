import systemConfigurationService from '@/services/api/systemConfigurationService';
import AuthModule from '@/store/modules/auth';
import SystemConfigurationModule, { SystemConfigurationPayload } from '@/store/modules/systemConfiguration';
import i18n from '@/i18n';
import ToastModule from '@/store/modules/toast';

export default async function loadSystemConfiguration(redirect = false) {
  try {
    const systemConfiguration = await systemConfigurationService.getSystemConfiguration();
    const payload: SystemConfigurationPayload = {
      redirect: redirect,
      systemConfiguration,
    };

    SystemConfigurationModule.setSystemConfiguration(payload);
  } catch (e) {
    const errorPosition = {
      x: 'center',
      y: 'top',
    };
    ToastModule.error({
      message: i18n.global.t('apiErrors.systemConfigurationFailed'),
      options: errorPosition,
    });
    AuthModule.logout();
  }
}
