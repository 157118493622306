<template>
  <!--
    from file(s): upozorneni.svg, upozorneni_red.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="9mm"
    height="14mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 900 1400"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2623404321248">
        <path
          class="fil0"
          d="M10 1214.74c0,94.9 77.49,172.4 172.39,172.4l535.22 0c94.9,0 172.39,-77.5 172.39,-172.4 0,-343.16 0,-686.32 0,-1029.48 0,-94.9 -77.49,-172.4 -172.39,-172.4l-535.22 0c-94.9,0 -172.39,77.5 -172.39,172.4 0,343.16 0,686.32 0,1029.48z"
        />
        <path
          class="fil1"
          d="M830 858.09l-760 0 0 356.65c0,61.78 50.61,112.4 112.39,112.4l535.22 0c61.78,0 112.39,-50.62 112.39,-112.4l0 -356.65z"
        />
        <path
          class="fil0"
          d="M726.51 1216.49l-553.01 0 0 -47.06 553.01 0 0 47.06zm0 -176.49l-333.82 0 0 -47.07 333.82 0 0 47.07zm0 88.25l-398.54 0 0 -47.07 398.54 0 0 47.07z"
        />
        <path
          class="fil1"
          d="M449.99 694.41l0.02 0c29.42,0 53.47,-24.05 53.47,-53.47l0 -19.61 -106.96 0 0 19.61c0,29.42 24.05,53.47 53.47,53.47zm0 -517.86l0.01 0c15.11,0 27.46,12.35 27.46,27.46l0 17.69c76.63,13.14 135.42,80.31 135.42,160.54l0 171.23 43.33 0c10.34,0 18.79,8.45 18.79,18.78l0 0.02c0,10.34 -8.45,18.79 -18.79,18.79l-412.42 0c-10.34,0 -18.79,-8.45 -18.79,-18.79l0 -0.02c0,-10.33 8.45,-18.78 18.79,-18.78l43.33 0 0 -171.23c0,-80.23 58.78,-147.39 135.41,-160.54l0 -17.69c0,-15.11 12.35,-27.46 27.46,-27.46z"
        />
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class NotificationSettingIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}

.fil1 {
  fill: #fefefe;
}
</style>
