<template>
  <v-card width="100%" class="fill-height pa-4 overflow-visible">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchCardApplications"
      i18n-group-key="userAccountPage.cardApplicationList"
      :get-row-class-callback="getRowClass"
      :row-click-callback="openCardApplicationDetail"
      data-cy="table-card-application"
    >
      <template #head-panel.start>
        <v-col md="2" :style="filterStyle">
          <v-select
            v-model="filter.cardApplicationStateId"
            :label="$t('userAccountPage.cardApplicationList.stateSelect')"
            :items="cardApplicationStateItems"
            :no-data-text="$t('common.noData')"
            clearable
            variant="underlined"
            data-cy="table-card-application-state-filter"
          />
        </v-col>
      </template>
      <template #item.note="{ item, column }">
        <div class="data-container">
          <div v-if="$vuetify.display.mobile">
            {{ `${column.text}:` }}
          </div>
          <div class="card-application-note ml-2">
            {{ item.note }}
          </div>
        </div>
      </template>
      <template #actions.start="{ item }">
        <v-btn
          :title="$t(`cardApplication.detail`)"
          :data-cy="`table-card-application-item-${item.id}`"
          class="mx-1 elevation-4"
          icon
          size="x-small"
          @click.stop="openCardApplicationDetail(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </RDPDataTable>
    <CardApplicationStepperDialog ref="cardStepperDialog" />
  </v-card>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import { CardApplicationHeaderVm, CardApplicationListResponse, CardApplicationQuery, EnumDto, EnumsDto } from '@/api';
import customerService from '@/services/api/customerService';
import CustomerModule from '@/store/modules/customer';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import cardApplicationStateService from '@/services/api/cardApplicationStateService';
import carrierTypeService from '@/services/api/carrierTypeService';
import CardApplicationStepperDialog, {
  CardApplicationStepperDialogInterface,
} from '@/components/cardApplication/CardApplicationStepperDialog.vue';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import ToastModule from '@/store/modules/toast.ts';
import { getApiError } from '@/utils/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { TABLE_TAB_FILTER_STYLES } from '@/constants/Constants';
import { CardApplicationDialogType } from '@/components/cardApplication/cardApplicationDialogType.ts';

const DEFAULT_SORT_BY_COLUMN = 'serialNumber';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPDataTable,
    CardApplicationStepperDialog,
  },
})
export default class CardApplicationListPage extends Vue {
  @Ref()
  readonly dataTable!: RDPDataTableInterface;
  @Ref()
  readonly cardStepperDialog!: CardApplicationStepperDialogInterface;

  customerModule = CustomerModule;
  systemConfigurationModule = SystemConfigurationModule;

  filter = {
    applicationNumber: undefined,
    customerName: undefined,
    cardApplicationStateId: undefined,
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
    eshop: false,
  } as CardApplicationQuery;

  cardApplicationStates: EnumDto[] = [];
  cardApplicationCarrierTypes: EnumDto[] = [];

  get headers() {
    return [
      {
        text: 'state.displayName',
        applyRowClass: true,
      },
      'serialNumber',
      'applicationNumber',
      {
        text: 'createDate',
        value: 'created',
        type: DATA_TABLE_HEADER_TYPES.DATE,
      },
      'customer.fullName',
      {
        text: 'profileOne',
        transform: (item: CardApplicationHeaderVm) => getCustomerProfileDisplayName(item.profileOne.profile.id),
      },
      {
        text: 'profileTwo',
        transform: (item: CardApplicationHeaderVm) =>
          item.profileTwo && getCustomerProfileDisplayName(item.profileTwo.profile.id),
      },
      { text: 'note', sortable: false },
      { text: 'eshop', type: DATA_TABLE_HEADER_TYPES.BOOLEAN, align: 'center' },
      { text: 'paid', type: DATA_TABLE_HEADER_TYPES.BOOLEAN, align: 'center' },
      {
        text: 'carrierType',
        transform: (item: CardApplicationHeaderVm) => this.$t(`carrierTypes.${item.carrierType.name}`),
      },
    ];
  }

  async created() {
    try {
      this.cardApplicationStates = await cardApplicationStateService.getCardApplicationStates();
      this.cardApplicationCarrierTypes = await carrierTypeService.getCarrierTypes();
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, 'userAccountPage.cardApplicationList'),
      });
      const errorName = `userAccountPage.cardApplicationList.errors.${(e as ApiErrorInterface)?.data?.name}`;
      if (this.$te(errorName)) {
        ToastModule.error({ message: this.$t(errorName) });
      }
    }
  }

  async searchCardApplications(query: CardApplicationQuery): Promise<CardApplicationListResponse> {
    return customerService.getCustomerCardApplicationList(this.customerModule.currentlySelectedCustomerId, query);
  }

  getRowClass(row: CardApplicationHeaderVm) {
    return `request-sate-${row.state.name}`;
  }

  get filterStyle() {
    const styles = this.systemConfigurationModule.isHaulierCardAsCarrierAllowed
      ? 'margin-top: -255px; margin-right: -15px;'
      : TABLE_TAB_FILTER_STYLES;
    return this.$vuetify.display.mobile ? '' : styles;
  }

  get cardApplicationStateItems(): VuetifySelectOptionsVm[] {
    return this.cardApplicationStates.map((cardApplicationState: EnumDto) => ({
      title: cardApplicationState.displayName,
      value: cardApplicationState.id,
    }));
  }

  openCardApplicationDetail(cardApplication: CardApplicationHeaderVm) {
    this.cardStepperDialog.openDialog(
      CardApplicationDialogType.Detail,
      cardApplication.carrierType.name as EnumsDto.carrierTypes,
      cardApplication.id,
    );
  }

  /**
   * This is called from UserAccountPage.vue
   */
  refresh() {
    this.dataTable.refresh();
  }
}
</script>

<style>
.request-sate-WAITING_TO_BE_PRINTED {
  background-color: #b7e2f1 !important;
}

.request-sate-REJECTED {
  background-color: #ffd3d3 !important;
}

.request-sate-PROCESSED {
  background-color: #c7ffc9 !important;
}

.card-application-note {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
