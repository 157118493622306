/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EshopOrderStateListResponse } from '../models/EshopOrderStateListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopOrderStateService {
  /**
   * Get eshop order possible states
   * @returns EshopOrderStateListResponse
   * @throws ApiError
   */
  public static eshopOrderStateControllerGetAll(): CancelablePromise<EshopOrderStateListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/eshop-order-states',
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
}
