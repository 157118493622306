<template>
  <!--
    from file(s): prihlasit_se.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil1"
        d="M30.82 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65 0,323.79 0,539.99 0,863.78 0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65zm628.35 -507.7c34.89,0 63.18,28.29 63.18,63.18 0,34.89 -28.29,63.18 -63.18,63.18 -19.68,0 -37.26,-9 -48.85,-23.11l-126.27 0c-22.04,0 -40.07,-18.03 -40.07,-40.07l0 0c0,-22.04 18.03,-40.07 40.07,-40.07l126.27 0c11.59,-14.11 29.17,-23.11 48.85,-23.11z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class LoginIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil1 {
  fill: currentColor;
}
</style>
