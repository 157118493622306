/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentRedirectVm } from './PaymentRedirectVm';
export type CsobPaymentVm = {
  /**
   * Unique ID of payment assigned when payment init. 15 characters.
   */
  payId: string;
  resultCode: number;
  resultMessage: string;
  paymentStatus?: CsobPaymentVm.paymentStatus;
  statusDetail?: number;
  redirect?: PaymentRedirectVm;
};
export namespace CsobPaymentVm {
  export enum paymentStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    PROCESSING = 'PROCESSING',
  }
}
