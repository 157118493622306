/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiErrorResponse } from '../models/ApiErrorResponse';
import type { CreateEshopOrderCommand } from '../models/CreateEshopOrderCommand';
import type { EshopOrderDetailResponse } from '../models/EshopOrderDetailResponse';
import type { EshopOrderListResponse } from '../models/EshopOrderListResponse';
import type { PaymentResponse } from '../models/PaymentResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopOrderService {
  /**
   * Get eshop orders.
   * @returns EshopOrderListResponse
   * @throws ApiError
   */
  public static eshopOrderControllerGetAll({
    state,
    paymentType,
    timezoneOffset,
    limit,
    offset,
    sortBy,
    sortDesc,
    sortLocale,
    date,
  }: {
    state?: 'PENDING' | 'PAID' | 'CANCELLED' | 'REJECTED' | 'RETURNED' | 'PROCESSING';
    paymentType?: any;
    timezoneOffset?: number;
    limit?: number;
    offset?: number;
    sortBy?: string;
    sortDesc?: boolean;
    sortLocale?: string;
    date?: string;
  }): CancelablePromise<EshopOrderListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/orders',
      query: {
        state: state,
        paymentType: paymentType,
        timezoneOffset: timezoneOffset,
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDesc: sortDesc,
        sortLocale: sortLocale,
        date: date,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create an order from eshop shopping cart.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
   * + **SHOPPING_CART_IS_EMPTY** - Cannot create an order. Shopping cart is empty.<br>
   * + **INVALID_PAYMENT_TYPE** - Payment type does not exist or is not allowed to use.<br>
   * + **INVALID_FEE_TRANSACTION_STATE** - Fee transaction is already assigned to order or is paid.
   * @returns EshopOrderDetailResponse
   * @throws ApiError
   */
  public static eshopOrderControllerCreateOrder({
    requestBody,
  }: {
    /**
     * CreateEshopOrderCommand
     */
    requestBody?: CreateEshopOrderCommand;
  }): CancelablePromise<EshopOrderDetailResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/orders',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get eshop order.
   * @returns EshopOrderDetailResponse
   * @throws ApiError
   */
  public static eshopOrderControllerGetOrderDetail({
    id,
  }: {
    id: string;
  }): CancelablePromise<EshopOrderDetailResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/orders/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Init a payment.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
   * + **CANNOT_INIT_PAYMENT** - CSOB Gateway cannot be inited.
   * @returns PaymentResponse
   * @throws ApiError
   */
  public static eshopOrderControllerInitPayment({ id }: { id: string }): CancelablePromise<PaymentResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/orders/{id}/init-payment',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Init a payment.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
   * + **CANNOT_PAY_AGAIN** - Cannot pay this order again.
   * @returns PaymentResponse
   * @throws ApiError
   */
  public static eshopOrderControllerPayAgain({ id }: { id: string }): CancelablePromise<PaymentResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/orders/{id}/pay-again',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Cancel order
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **CUSTOMER_ACCOUNT_NOT_VERIFIED** - Customer account is not verified.<br>
   * + **INVALID_ESHOP_ORDER_STATE** - Eshop order state is invalid or does not exist.
   * @returns EshopOrderDetailResponse
   * @throws ApiError
   */
  public static eshopOrderControllerCancel({ id }: { id: string }): CancelablePromise<EshopOrderDetailResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/orders/{id}/cancel',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Send payment email
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
   * @returns EshopOrderDetailResponse
   * @throws ApiError
   */
  public static eshopOrderControllerSendOrderPaidEmail({
    id,
  }: {
    id: string;
  }): CancelablePromise<EshopOrderDetailResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/orders/{id}/send-payment-email',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Check if order is for card application and if is in processing state.
   * @returns ApiErrorResponse
   * @throws ApiError
   */
  public static eshopOrderControllerVerifyIfCardApplicationCanBeCreated({
    originId,
  }: {
    originId: string;
  }): CancelablePromise<ApiErrorResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/orders-application-state-check',
      query: {
        originId: originId,
      },
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
