<template>
  <!--
    from file(s): objednavky.svg, objednavky_red.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="9mm"
    height="14mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 900 1400"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2744250938496">
        <path
          class="fil0"
          d="M10 1387.13l0 -1113.18c0,-94.9 77.49,-172.4 172.39,-172.4l118.49 0c21.58,-51.93 72.91,-88.69 132.41,-88.69l33.41 0c59.5,0 110.83,36.76 132.41,88.69l118.5 0c94.9,0 172.39,77.51 172.39,172.4l0 1113.18 -880 0z"
        />
        <polygon class="fil1" points="830,1327.13 830,307.53 70,307.53 70,1327.13 " />
        <rect class="fil1" x="349.98" y="72.86" width="200" height="166.6" rx="15.04" ry="147.25" />
        <rect class="fil0" x="156.03" y="385.27" width="553.01" height="47.06" />
        <rect class="fil0" x="156.03" y="473.51" width="398.54" height="47.06" />
        <rect class="fil0" x="156.02" y="561.76" width="333.82" height="47.06" />
        <g>
          <rect class="fil0" x="216.63" y="735.55" width="450" height="450" rx="40.29" ry="135" />
          <path
            class="fil2"
            d="M425.98 976.98c-3.07,3.41 -5.97,5.09 -8.7,5.06 -2.73,-0.02 -5.54,-1.33 -8.41,-3.91l-51.63 -46.49c-2.87,-2.58 -5.67,-3.89 -8.4,-3.91 -2.74,-0.03 -5.56,1.57 -8.46,4.8l-17.43 19.35c-2.91,3.23 -4.21,6.21 -3.89,8.92 0.31,2.72 1.9,5.37 4.77,7.95l90.89 81.84c2.87,2.58 5.68,3.89 8.41,3.92 2.73,0.03 5.55,-1.58 8.46,-4.8l128.72 -142.97c2.91,-3.22 4.21,-6.2 3.89,-8.91 -0.31,-2.72 -1.9,-5.37 -4.77,-7.95l-21.52 -19.37c-2.86,-2.58 -5.67,-3.89 -8.4,-3.92 -2.73,-0.03 -5.55,1.57 -8.46,4.8l-95.07 105.59z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class OrderListIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}

.fil1 {
  fill: #fefefe;
}

.fil2 {
  fill: #fefefe;
  fill-rule: nonzero;
}
</style>
