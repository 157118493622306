/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TariffProfileQuery = {
  /**
   * Validity of tariff profiles. Past, Present, Future.
   */
  validity?: TariffProfileQuery.validity;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
};
export namespace TariffProfileQuery {
  /**
   * Validity of tariff profiles. Past, Present, Future.
   */
  export enum validity {
    PAST = 'PAST',
    PRESENT = 'PRESENT',
    FUTURE = 'FUTURE',
    PRESENT_OR_FUTURE = 'PRESENT_OR_FUTURE',
  }
}
