/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardApplicationProfileSaveCommand } from './CardApplicationProfileSaveCommand';
import type { SaveAddressCommand } from './SaveAddressCommand';
export type CreateCardApplicationCommand = {
  /**
   * Card application unique number.
   */
  applicationNumber?: string;
  /**
   * Contact a customer when his card was found. Default: true
   */
  contactMeIfCardFound: boolean;
  /**
   * Identifier that card application was created from eshop. Default: false
   */
  eshop?: boolean;
  note?: string;
  /**
   * Customer identifier. Type: 'UUID'
   */
  customerId: string;
  /**
   * Delivery branch office identifier. Type: 'UUID'
   */
  deliveryBranchOfficeId?: string;
  recipientAddress?: SaveAddressCommand;
  profileOne: CardApplicationProfileSaveCommand;
  profileTwo?: CardApplicationProfileSaveCommand;
  /**
   * Whenever should photo be saved for 36 months.
   */
  keepPhoto: boolean;
  /**
   * Customer email for eshop to pair card application and account.
   */
  username?: string;
  /**
   * Identifier that card application is for free. Default: false
   */
  cardForFree?: boolean;
  /**
   * Agree with marketing consent. Default: false
   */
  marketingConsent?: boolean;
  /**
   * Carrier card type
   */
  carrierType: CreateCardApplicationCommand.carrierType;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CreateCardApplicationCommand {
  /**
   * Carrier card type
   */
  export enum carrierType {
    HAULIER_CARD = 'HAULIER_CARD',
    WHITE_LIST_CARD = 'WHITE_LIST_CARD',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    BANK_CARD = 'BANK_CARD',
  }
}
