import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ShoppingCartVm, ShoppingCartItemVm } from '@/api';
import shoppingCartService from '@/services/api/shoppingCartService';
import isDefined, { isArrayEmpty } from '@common/utils/isDefined';
import UserAccount from '@/store/modules/userAccount';
import { getEarliestTicketFareValidFrom } from '@/utils/ticketFare';
import { isBefore, startOfDay } from 'date-fns';

export const MIN_DAYS_BETWEEN_TICKET_FARE_VALID_FROM_AND_NOW = 3;

@Module({ dynamic: true, store, namespaced: true, name: 'shoppingCart' })
class ShoppingCart extends VuexModule {
  public items: ShoppingCartItemVm[] = [];
  public ticketFareParentIds: string[] = [];
  public totalPrice = 0;

  @Mutation
  public setShoppingCart(payload: ShoppingCartVm): void {
    this.items = payload.items;
    this.totalPrice = payload.totalPrice;

    this.ticketFareParentIds = [];
    for (const item of payload.items) {
      if (isDefined(item.payload?.ticketFareParentId)) {
        this.ticketFareParentIds.push(item.payload.ticketFareParentId);
      }
    }
  }

  public get isEmpty() {
    return isArrayEmpty(this.items);
  }

  public get isCardApplicationInShoppingCartForSelectedUser() {
    return !!this.items.find(item => {
      return item.payload?.applicationId && item.payload?.originId === UserAccount.selectedCustomerData.id;
    });
  }

  public get isCardApplicationInShoppingCart() {
    return !!this.items.find(item => item.payload?.applicationId);
  }

  public get isReChargeWalletRequestInShoppingCart() {
    return !!this.items.find(item => item.payload?.eWalletId && item.payload?.cardNumber === UserAccount.cardNumber);
  }

  public get isTicketFareInShoppingCart() {
    return !!this.findTicketFareItem;
  }

  private get findTicketFareItem() {
    return this.items.find(
      item => item.payload?.ticketFareId && item.payload?.cardId === UserAccount.cardDetailData.id,
    );
  }

  public get isTicketFareTooSoon() {
    const earliestValidFrom = getEarliestTicketFareValidFrom();
    return this.items.some(item => {
      const ticketFareValidFrom = item.payload?.ticketFareValidFrom;
      if (!item.payload?.ticketFareId || !ticketFareValidFrom) {
        return false;
      }
      const validFrom = startOfDay(ticketFareValidFrom);
      return isBefore(validFrom, earliestValidFrom);
    });
  }

  @Action
  public async updateShoppingCart() {
    const shoppingCart = await shoppingCartService.getShoppingCart();
    this.setShoppingCart(shoppingCart);
  }
}

export default getModule(ShoppingCart);
