<template>
  <v-app>
    <RouterView />
    <RDPToast />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import AuthModule from '@/store/modules/auth';
import loadSystemConfiguration from '@/utils/systemConfiguration';
import { loadSupportedLocales } from '@/utils/i18n';
import RDPToast from '@/components/common/RDPToast.vue';

@Component({
  components: {
    RDPToast,
  },
})
export default class App extends Vue {
  async beforeCreate() {
    await loadSupportedLocales();
    if (AuthModule.logged && !AuthModule.tokenExpired) {
      await loadSystemConfiguration();
    }
  }

  public async created() {
    // this event is only fired when persisted vuex storage changes in other tabs
    // it means that on login or logout, the other tabs are refreshed
    // this prevents from two different users being logged-in in two different tabs - see RDP-330
    window.addEventListener('storage', event => {
      const oldValue = JSON.parse(event.oldValue as string);
      const newValue = JSON.parse(event.newValue as string);

      // reload the page only on customer change, not on any change of the storage - @see RDP-501
      // if customer changed, it means new user is logged in or sub-account was switched
      // compare the customers on their email (aka username) - @see RDP-1063
      if (oldValue && newValue && oldValue.auth?.username !== newValue.auth?.username) {
        // timeout is needed otherwise the cypress tests won't work
        setTimeout(() => location.reload(), 100);
      }
    });
  }
}
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) .v-icon {
  color: #757575 !important;
}

.row {
  margin: 0 -12px;
}
</style>
