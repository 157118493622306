<template>
  <v-card width="100%" class="fill-height pa-4 overflow-visible">
    <RDPDataTable
      ref="dataTable"
      :headers="headers"
      :filter="filter"
      :search-items-callback="searchCustomerCards"
      :get-row-class-callback="getRowClass"
      i18n-group-key="userAccountPage.cardInfoList"
      data-cy="user-account-card-list"
    >
      <template #head-panel.start>
        <v-col class="ml" :cols="$vuetify.display.mobile ? 12 : 3" :style="filterStyle">
          <v-select
            v-model="filter.stateIds"
            multiple
            :label="$t('userAccountPage.cardInfoList.stateSelect')"
            :items="customerCardStateItems"
            clearable
            chips
            variant="underlined"
            data-cy="user-account-card-list-state-filter"
          >
            <template #chip="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.title }}</span>
                <span v-if="filter.stateIds!.length > 1" class="text-grey text-caption ml-1">{{
                  $t('multiselectOthers', {
                    selectionLength: filter.stateIds!.length - 1,
                  })
                }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </template>
      <template #item.currentState.state.displayName="{ item }">
        {{ getCustomerCardState(item.reclamation, item.currentState.state) }}
      </template>
      <template #item.profileOne="{ item }">
        {{ getCustomerProfileDisplayName(item.profileOne) }}
      </template>
      <template #item.profileTwo="{ item }">
        {{ getCustomerProfileDisplayName(item.profileTwo) }}
      </template>
      <template #actions.end="{ item }">
        <div class="d-flex">
          <v-tooltip v-if="isCardActive(item)" location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-btn
                  :data-cy="`active-card-set-to-main-${item.id}`"
                  class="mx-1 elevation-4"
                  icon
                  size="x-small"
                  color="success"
                  @click.stop="setMainActiveCard(item)"
                >
                  <v-icon>mdi-credit-card-check</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('userAccountPage.cardInfoList.setActivateMainCard') }}</span>
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-btn
                  v-if="isBankCardActivable(item)"
                  :data-cy="`bank-card-activate-${item.id}`"
                  class="mx-1 elevation-4"
                  icon
                  size="x-small"
                  color="success"
                  @click.stop="activateBankCard(item)"
                >
                  <v-icon>mdi-card-plus</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('userAccountPage.cardInfoList.activateBankCard') }}</span>
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-btn
                  v-if="isBankCardBlockable(item)"
                  :data-cy="`bank-card-block-${item.id}`"
                  class="mx-1 elevation-4"
                  icon
                  size="x-small"
                  color="error"
                  @click.stop="blockBankCard(item)"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('userAccountPage.cardInfoList.blockBankCard') }}</span>
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-btn
                  v-if="isBankCardDeletable(item) && isAllowedBankCard"
                  :data-cy="`bank-card-delete-${item.id}`"
                  class="mx-1 elevation-4"
                  icon
                  size="x-small"
                  color="error"
                  @click.stop="deleteBankCard(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('userAccountPage.cardInfoList.deleteBankCard') }}</span>
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-btn
                  v-if="systemConfigurationModule.configuration.marketingConsentCardEnabled"
                  :data-cy="`card-edit-${item.id}`"
                  class="mx-1 elevation-4"
                  icon
                  size="x-small"
                  @click.stop="editMarketingConsent(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('userAccountPage.cardInfoList.editCard') }}</span>
          </v-tooltip>
        </div>
      </template>
    </RDPDataTable>
    <RDPConfirmDialog
      ref="confirmDeleteDialog"
      :title="$t('deleteBankCardDialog.title')"
      :confirm="confirmDeleteBankCard"
      data-cy="userAccountPage-cardInfoList-deleteBankCardDialog"
    >
      <strong>{{ $t('deleteBankCardDialog.deleteBankCardText') }}</strong>
      <br /><br />
      {{ $t('deleteBankCardDialog.deleteBankCardInfo') }}
    </RDPConfirmDialog>

    <RDPConfirmDialog
      ref="confirmActivateBankCardDialog"
      :title="$t('activateBankCardDialog.title')"
      :confirm="confirmActivateBankCard"
      data-cy="userAccountPage-cardInfoList-activateBankCardDialog"
    >
      <strong>{{ $t('activateBankCardDialog.activateBankCardText') }}</strong>
      <br /><br />
      {{ $t('activateBankCardDialog.activateBankCardInfo') }}
    </RDPConfirmDialog>

    <RDPConfirmDialog
      ref="confirmBlockBankCardDialog"
      :title="$t('blockBankCardDialog.title')"
      :confirm="confirmBlockBankCard"
      data-cy="userAccountPage-cardInfoList-blockBankCardDialog"
    >
      <strong>{{ $t('blockBankCardDialog.blockBankCardText') }}</strong>
      <br /><br />
      {{ $t('blockBankCardDialog.blockBankCardInfo') }}
    </RDPConfirmDialog>

    <RDPConfirmDialog
      ref="confirmSetActiveMainCardDialog"
      :title="$t('setActiveMainCardDialog.title')"
      :confirm="confirmSetActiveMainCard"
      data-cy="userAccountPage-cardInfoList-setActiveMainCardDialog"
    >
      <strong>{{ $t('setActiveMainCardDialog.setActiveMainCardText') }}</strong>
      <br /><br />
      {{ $t('setActiveMainCardDialog.setActiveMainCardInfo') }}
    </RDPConfirmDialog>

    <MarketingConsentDialog ref="marketingConsentDialog" @refresh-page="refresh" />
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import { HeaderInterface, RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import customerService from '@/services/api/customerService';
import UserAccountModule from '@/store/modules/userAccount';
import bankCardService from '@/services/api/bankCardService';
import customerCardService from '@/services/api/customerCardService';
import RDPDataTable from '@/components/common/RDPDataTable/RDPDataTable.vue';
import { DATA_TABLE_HEADER_TYPES } from '@/components/common/RDPDataTable/DataTableHelper';
import { VuetifySelectOptionsVm } from '@/models/VuetifySelectOptionsVm';
import { CustomerCardInfoListQuery, CustomerCardInfoVm, CustomerProfileInfoVm, EnumDto, EnumsDto } from '@/api';
import cardStateService from '@/services/api/cardStateService';
import { getCustomerCardState } from '@/components/userAccount/helper/customerCardHelper';
import CustomerModule from '@/store/modules/customer';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import MarketingConsentDialog from '@/components/userAccount/dialogs/MarketingConsentDialog.vue';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import ToastModule from '@/store/modules/toast.ts';
import { getApiError } from '@/utils/toast.ts';
import { TABLE_TAB_FILTER_STYLES } from '@/constants/Constants';

const DEFAULT_SORT_BY_COLUMN = 'validTo';
const DEFAULT_SORT_DESC = true;

@Component({
  components: {
    RDPConfirmDialog,
    RDPDataTable,
    MarketingConsentDialog,
  },
})
export default class ChipCardTab extends Vue {
  @Ref()
  readonly dataTable!: RDPDataTableInterface;

  @Ref()
  readonly confirmDeleteDialog!: RDPDialogInterface;

  @Ref()
  readonly confirmActivateBankCardDialog!: RDPDialogInterface;

  @Ref()
  readonly confirmBlockBankCardDialog!: RDPDialogInterface;

  @Ref()
  readonly confirmSetActiveMainCardDialog!: RDPDialogInterface;

  @Ref()
  private readonly marketingConsentDialog!: MarketingConsentDialog;

  @Prop({ required: false, type: Boolean, default: true })
  readonly customerIsManageable!: boolean;

  customerModule = CustomerModule;

  systemConfigurationModule = SystemConfigurationModule;

  getCustomerCardState = getCustomerCardState;

  selectedBankCard: CustomerCardInfoVm | null = null;

  selectedActiveCard: CustomerCardInfoVm | null = null;

  userAccountModule = UserAccountModule;

  filter: CustomerCardInfoListQuery = {
    stateIds: [],
    sortBy: DEFAULT_SORT_BY_COLUMN,
    sortDesc: DEFAULT_SORT_DESC,
  };

  private cardsStates: EnumDto[] = [];

  get headers() {
    const headers = [
      {
        text: 'currentState',
        transform: (card: CustomerCardInfoVm) => card.currentState.state.displayName,
        applyRowClass: true,
      },
      { text: 'cardNumber', type: DATA_TABLE_HEADER_TYPES.CARD_NUMBER },
      { text: 'validFrom', type: DATA_TABLE_HEADER_TYPES.DATE },
      { text: 'validTo', type: DATA_TABLE_HEADER_TYPES.DATE },
      { text: 'profileOne' },
      { text: 'profileTwo' },
      { text: 'created.date', type: DATA_TABLE_HEADER_TYPES.DATE },
      { text: 'issued.date', type: DATA_TABLE_HEADER_TYPES.DATE },
      {
        text: 'contactMeIfCardFound',
        tooltip: 'contactMeIfCardFoundTooltip',
        type: DATA_TABLE_HEADER_TYPES.BOOLEAN,
        align: 'center',
        sortable: false,
      },
    ] as HeaderInterface[];

    if (this.systemConfigurationModule.configuration.marketingConsentCardEnabled) {
      headers.splice(headers.length - 1, 0, {
        text: 'marketingConsent',
        type: DATA_TABLE_HEADER_TYPES.BOOLEAN,
        align: 'center',
      } as HeaderInterface);
    }

    return headers;
  }

  getCustomerProfileDisplayName(profile: CustomerProfileInfoVm | undefined): string {
    return getCustomerProfileDisplayName(profile?.id);
  }

  async created() {
    try {
      this.cardsStates = await cardStateService.getCardStateList();
    } catch (e) {
      const errorName = `cardList.errors.${(e as ApiErrorInterface)?.data?.name}`;
      if (this.$te(errorName)) {
        ToastModule.error({ message: this.$t(errorName) });
      }
    }
  }

  get filterStyle() {
    return this.$vuetify.display.mobile ? '' : TABLE_TAB_FILTER_STYLES;
  }

  get isAllowedBankCard() {
    return this.systemConfigurationModule.isBankCardAsCarrierAllowed;
  }

  async searchCustomerCards(filters: object) {
    return customerService.getCustomerCardList(this.customerModule.currentlySelectedCustomerId, filters);
  }

  get customerCardStateItems(): VuetifySelectOptionsVm[] {
    return this.cardsStates.map((cardState: EnumDto) => ({
      title: cardState.displayName,
      value: cardState.id,
    }));
  }

  getRowClass(row: CustomerCardInfoVm) {
    return `card-state-${row.currentState.state.name}`;
  }

  /**
   * This is called from UserAccountPage.vue
   */
  refresh() {
    this.dataTable.refresh();
  }

  isBankCardDeletable(card: CustomerCardInfoVm) {
    return (
      card.customerCardType === CustomerCardInfoVm.customerCardType.BANK_CARD &&
      (card.currentState.state.name === EnumsDto.cardStates.TOKENIZED ||
        card.currentState.state.name === EnumsDto.cardStates.BLOCKED) &&
      this.customerIsManageable
    );
  }

  isBankCardBlockable(card: CustomerCardInfoVm) {
    return (
      card.customerCardType === CustomerCardInfoVm.customerCardType.BANK_CARD &&
      card.currentState.state.name === EnumsDto.cardStates.ACTIVE &&
      this.customerIsManageable
    );
  }

  isBankCardActivable(card: CustomerCardInfoVm) {
    return (
      card.customerCardType === CustomerCardInfoVm.customerCardType.BANK_CARD &&
      card.currentState.state.name === EnumsDto.cardStates.BLOCKED &&
      // Returns false if there is an ongoing card-application. Also returns an error if the customer has a card in_progress or not_issued.
      // TODO: rename
      this.userAccountModule.canVirtualCardApplicationBeCreated &&
      !this.userAccountModule.isCardActive &&
      this.customerIsManageable
    );
  }

  isCardActive(card: CustomerCardInfoVm) {
    return (
      card.currentState.state.name === EnumsDto.cardStates.ACTIVE &&
      !this.customerIsManageable &&
      this.customerModule.hasLoggedInAccountCustomer
    );
  }

  activateBankCard(bankCard: CustomerCardInfoVm) {
    this.selectedBankCard = bankCard;
    this.confirmActivateBankCardDialog.openDialog();
  }

  blockBankCard(bankCard: CustomerCardInfoVm) {
    this.selectedBankCard = bankCard;
    this.confirmBlockBankCardDialog.openDialog();
  }

  deleteBankCard(bankCard: CustomerCardInfoVm) {
    this.selectedBankCard = bankCard;
    this.confirmDeleteDialog.openDialog();
  }

  setMainActiveCard(card: CustomerCardInfoVm) {
    this.selectedActiveCard = card;
    this.confirmSetActiveMainCardDialog.openDialog();
  }

  async confirmActivateBankCard() {
    try {
      await bankCardService.activateBankCard(this.selectedBankCard!.id);
      this.$emit('refresh-account-page');
      ToastModule.success({
        message: this.$t('userAccountPage.cardInfoList.bankCardActivated'),
      });
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, 'userAccountPage.cardInfoList', 'errors.bankCardActivate'),
      });
    }
  }

  async confirmSetActiveMainCard() {
    try {
      await customerCardService.setActiveMainCard(this.selectedActiveCard!.id);
      this.$emit('refresh-account-page');
      ToastModule.success({
        message: this.$t('userAccountPage.cardInfoList.activeMainCardSet'),
      });
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, 'userAccountPage.cardInfoList', 'errors.setActivateMainCard'),
      });
    }
  }

  async confirmBlockBankCard() {
    try {
      await bankCardService.blockBankCard(this.selectedBankCard!.id);
      this.$emit('refresh-account-page');
      ToastModule.success({
        message: this.$t('userAccountPage.cardInfoList.bankCardBlocked'),
      });
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, 'userAccountPage.cardInfoList', 'errors.bankCardBlock'),
      });
    }
  }

  async confirmDeleteBankCard() {
    try {
      await bankCardService.deleteBankCard(this.selectedBankCard!.id);
      this.$emit('refresh-account-page');
      ToastModule.success({
        message: this.$t('userAccountPage.cardInfoList.bankCardDeleted'),
      });
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, 'userAccountPage.cardInfoList', 'errors.bankCardDelete'),
      });
    }
  }

  public editMarketingConsent(item: CustomerCardInfoVm) {
    this.marketingConsentDialog.openDialog(item.id);
  }
}
</script>

<style>
.card-state-EXPIRED {
  background-color: #fff9c4 !important;
}

.card-state-NOT_ISSUED {
  background-color: #b7e2f1 !important;
}

.card-state-BLOCKED {
  background-color: #ffcdd2 !important;
}

.card-state-ACTIVE {
  background-color: #c7ffc9 !important;
}
</style>
