import { KeyGroupI18n } from '@/utils/i18n';
import isDefined from '@common/utils/isDefined';
import get from 'lodash/get';
import { ItemInterface, HeaderInterface, ColumnInterface } from './RDPDataTableInterfaces';
import { toMapCardNumber } from '@/utils/customerCard';
import { toAmount, toDecimal, toPercentage, toPrice, toStandardDate, toStandardDateTime } from '@/filters';

export const DATA_TABLE_HEADER_TYPES = {
  /**
   * A simple number - returns '0' if undefined.
   */
  NUMBER: 'number',
  CARD_NUMBER: 'card-number',
  PERSON: 'person',
  DATE: 'date',
  DATETIME: 'datetime',
  DECIMAL: 'decimal',
  BOOLEAN: 'boolean',
  PERCENTAGE: 'percentage',
  /**
   * Divides by 100.
   */
  PRICE: 'price',
  /**
   * Does not divide by 100.
   */
  ORIGINAL_DECIMAL_PRICE: 'original-decimal-price',
  AMOUNT: 'amount',
};
const HEADER_TYPES = DATA_TABLE_HEADER_TYPES;

export default class DataTableHelper {
  private i18n: KeyGroupI18n;
  constructor(i18nGroupKey: string) {
    this.i18n = new KeyGroupI18n(i18nGroupKey);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translateHeaders(headers: any) {
    return headers.map((header: HeaderInterface | string) => {
      if (typeof header === 'string') {
        const text = this.i18n.t(header);
        return { text, title: text, tooltip: text, value: header, name: header, key: header };
      }
      const text = this.i18n.t(header.text);
      const tooltip = header.tooltip ? this.i18n.t(header.tooltip) : text;
      return {
        ...header,
        title: text,
        text,
        tooltip: tooltip,
        value: header.value || header.text,
        key: header.key || header.value || header.title || header.text,
        name: header.text,
        align: this.getHeaderAlignment(header),
        applyRowClass: header.applyRowClass,
      };
    });
  }

  private getHeaderAlignment(header: HeaderInterface) {
    if (header.align) {
      // use custom-alignment if specified
      return header.align;
    }

    const ALIGN_RIGHT = 'end';
    switch (header.type) {
      case HEADER_TYPES.NUMBER:
      case HEADER_TYPES.DECIMAL:
      case HEADER_TYPES.PERCENTAGE:
      case HEADER_TYPES.PRICE:
      case HEADER_TYPES.ORIGINAL_DECIMAL_PRICE:
      case HEADER_TYPES.AMOUNT:
        return ALIGN_RIGHT;
      default:
      // just continue
    }
    return header.align;
  }

  transformValue(item: ItemInterface, column: ColumnInterface) {
    if (column.transform) {
      return column.transform(item);
    }

    const value = get(item, column.value);
    if (column.type === HEADER_TYPES.NUMBER) {
      return value || 0;
    }
    if (!isDefined(value)) {
      return column.type === HEADER_TYPES.BOOLEAN ? false : '';
    }

    switch (column.type) {
      case HEADER_TYPES.PERSON:
        return `${value.lastName || ''} ${value.firstName}`;
      case HEADER_TYPES.CARD_NUMBER:
        return toMapCardNumber(value);
      case HEADER_TYPES.DATE:
        return toStandardDate(value);
      case HEADER_TYPES.DATETIME:
        return toStandardDateTime(value);
      case HEADER_TYPES.DECIMAL:
        return toDecimal(value);
      case HEADER_TYPES.PERCENTAGE:
        return toPercentage(value);
      case HEADER_TYPES.PRICE:
        return `${toPrice(value)} ${
          item.currency ? this.i18n.t(`currency.${item.currency}`) : this.i18n.t('common.currency')
        }`;
      case HEADER_TYPES.ORIGINAL_DECIMAL_PRICE:
        return `${toDecimal(value)} ${this.i18n.t('common.currency')}`;
      case HEADER_TYPES.AMOUNT:
        return `${toAmount(value)} ${this.i18n.t('common.amount')}`;
      default:
      // just continue
    }

    return value;
  }
}
