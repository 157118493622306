import { CreateEshopReclamationCommand, EshopReclamationService } from '@/api';
import { getRequestBody } from '@/utils/api.ts';

export default {
  createReclamation: async (createReclamationCommand: CreateEshopReclamationCommand) => {
    await EshopReclamationService.eshopReclamationControllerCreate({
      requestBody: getRequestBody(createReclamationCommand),
    });
  },
};
