import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { CustomerProfileDto } from '@/api';

@Module({ dynamic: true, store, namespaced: true, name: 'customerProfile' })
class CustomerProfile extends VuexModule {
  public customerProfiles: CustomerProfileDto[] = [];

  @Mutation
  public setCustomerProfiles(payload: CustomerProfileDto[]): void {
    this.customerProfiles = payload;
  }
}

export default getModule(CustomerProfile);
