import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import store from './store';
import router from './router';
import { Store } from 'vuex';
import AuthModule from '@/store/modules/auth';
import LocaleModule from '@/store/modules/locale';
import { SENTRY_ENV, SENTRY_URL, SERVER_URL } from '@/config/config';
import ApiClientError from '@/models/ApiClientError';
import './assets/global.scss';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import * as Sentry from '@sentry/vue';
import { version } from '../package.json';

declare global {
  interface Window {
    app: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      vuexStore: Store<any>;
    };
  }
}

export const app = createApp(App);

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: SENTRY_ENV,
    release: version,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(i18n);
app.use(router);
app.use(vuetify);
app.use(store);

app.component('VueCropper', VueCropper);
app.component('VueDatePicker', VueDatePicker);

axios.defaults.baseURL = SERVER_URL;

axios.interceptors.request.use(request => {
  if (AuthModule.logged) {
    request.headers['Authorization'] = `Bearer ${AuthModule.token}`;
  }
  request.headers['Accept-Language'] = LocaleModule.locale;
  return request;
});

app.config.globalProperties.$http = axios;

axios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      store.dispatch('toast/error', {
        message: i18n.global.t('globalErrors.ERR_CONNECTION_REFUSED'),
      });
    } else if (error.response.data.httpCode === 500) {
      store.dispatch('toast/error', {
        message: i18n.global.t('globalErrors.SERVER_ERROR'),
      });
    } else {
      throw new ApiClientError('API Error', error.response.data);
    }
  },
);

app.mount('#app');

window.app.vuexStore = store;
