import {
  AddEWalletTransactionCommand,
  AddTicketFareCommand,
  ApiResponse,
  ChangeFeeCommand,
  EshopShoppingCartService,
  ShoppingCartItemVm,
  ShoppingCartVm,
} from '@/api';
import { getRequestBody } from '@/utils/api';

export default {
  getShoppingCart: async (): Promise<ShoppingCartVm> => {
    const response = await EshopShoppingCartService.eshopShoppingCartControllerGetShoppingCart();

    return response.data;
  },

  chargeFee: async (requestBody: ChangeFeeCommand): Promise<ShoppingCartItemVm> => {
    const response = await EshopShoppingCartService.eshopShoppingCartControllerAddFeeToShoppingCart({ requestBody });

    return response.data;
  },

  createEWalletTransaction: async (payload: AddEWalletTransactionCommand): Promise<ShoppingCartItemVm> => {
    const response =
      await EshopShoppingCartService.eshopShoppingCartControllerAddElectronicWalletTransactionToShoppingCart({
        requestBody: getRequestBody(payload),
      });

    return response.data;
  },

  addTicketFare: async (payload: AddTicketFareCommand): Promise<ShoppingCartItemVm> => {
    const response = await EshopShoppingCartService.eshopShoppingCartControllerAddTicketFareToShoppingCart({
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },

  removeItem: async (id: string): Promise<ApiResponse> => {
    return EshopShoppingCartService.eshopShoppingCartControllerRemoveItemFromShoppingCart({ id });
  },

  removeAllItems: async (): Promise<ApiResponse> => {
    return EshopShoppingCartService.eshopShoppingCartControllerRemoveAllItemsFromShoppingCart();
  },
};
