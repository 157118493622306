<template>
  <div v-if="cardProfile" class="mb-3 pb-3">
    <v-row>
      <v-col offset="6">
        <h4>
          {{ t('customerProfileLabel', { number: profileNumber }) }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right label">
        {{ ts('profileName') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy(`profileName-${profileNumber}`)">
        {{ profileDisplayName }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right label">
        {{ ts('profileValidity') }}
      </v-col>
      <v-col class="text-left" :data-cy="cy(`profileValidity-${profileNumber}`)">
        {{ filters.toStandardDate(cardProfile.validFrom) }}
        {{ ` - ` }}
        {{ filters.toStandardDate(cardProfile.validTo) }}
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import { CardProfileVm } from '@/api';

@Component({
  name: 'ProfileValidityInfo',
})
export default class ProfileValidityInfo extends ComponentBase {
  @Prop({ required: false, type: Object })
  readonly cardProfile!: CardProfileVm;
  @Prop({ required: true, type: Number })
  readonly profileNumber!: number;

  dataCy = 'profile-validity-info';
  i18nGroupKey = 'userAccountPage.profileValidityInfo';

  get profileDisplayName(): string {
    return getCustomerProfileDisplayName(this.cardProfile.profile?.id);
  }
}
</script>
