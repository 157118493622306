/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateVehicleBankCardTokenCommand = {
  /**
   * Status
   */
  status: UpdateVehicleBankCardTokenCommand.status;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace UpdateVehicleBankCardTokenCommand {
  /**
   * Status
   */
  export enum status {
    NOT_IN_DENY_LIST = 'NOT_IN_DENY_LIST',
    IS_IN_DENY_LIST = 'IS_IN_DENY_LIST',
    WAS_IN_DENY_LIST = 'WAS_IN_DENY_LIST',
    DENY_LIST_RECOVER_DEPLETED = 'DENY_LIST_RECOVER_DEPLETED',
    CARD_NOT_FOUND = 'CARD_NOT_FOUND',
  }
}
