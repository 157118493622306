/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBaseCustomerCommand } from './CreateBaseCustomerCommand';
import type { SaveAddressCommand } from './SaveAddressCommand';
import type { SaveNameCommand } from './SaveNameCommand';
export type CreateCustomerCommand = {
  name: SaveNameCommand;
  /**
   * Customer birthday. Type: 'DATE'
   */
  birthDay: string;
  email?: string;
  phoneNumber?: string;
  address: SaveAddressCommand;
  /**
   * Base64 representation of picture.
   */
  photo: string;
  originId?: string;
  /**
   * Custodian identifier. Type: "UUID"
   */
  parentId?: string;
  /**
   * Custodian object.
   */
  parent?: CreateBaseCustomerCommand;
  /**
   * GDPR, terms and conditions approval.
   */
  gdprTermsApproval?: boolean;
  /**
   * PIN for card blockage after loss.
   */
  pin?: string;
  isicNumber?: string;
  /**
   * Type for customer account link. Need to know when age restriction for ASSIGNED type is disabled
   */
  accountLinkType?: CreateCustomerCommand.accountLinkType;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CreateCustomerCommand {
  /**
   * Type for customer account link. Need to know when age restriction for ASSIGNED type is disabled
   */
  export enum accountLinkType {
    REGISTERED = 'REGISTERED',
    ASSIGNED = 'ASSIGNED',
    MANAGED = 'MANAGED',
  }
}
