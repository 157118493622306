import { CardApplicationProfileDto, CardApplicationProfileSaveCommand } from '@/api';

export default {
  toCardApplicationProfileSaveCommand: (cardApplicationProfile: CardApplicationProfileDto) => {
    const profile: CardApplicationProfileSaveCommand = {
      profileId: cardApplicationProfile.profile.id,
      validFrom: cardApplicationProfile.validFrom,
      validTo: cardApplicationProfile.validTo || undefined,
    };

    return profile;
  },
};
