<template>
  <!--
    from file(s): storno.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 800 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M30.82 1031.9l0 -863.8c0,-79.62 65.01,-144.65 144.64,-144.65l449.08 0c79.63,0 144.64,65.03 144.64,144.65l0 863.8c0,79.62 -65.01,144.65 -144.64,144.65l-449.08 0c-79.63,0 -144.64,-65.03 -144.64,-144.65zm369.18 -708.86c152.95,0 276.95,124 276.95,276.96 0,152.95 -124,276.95 -276.95,276.95 -152.95,0 -276.95,-124 -276.95,-276.95 0,-152.96 124,-276.96 276.95,-276.96zm-119.49 107.97l288.47 288.48c23.91,-33.76 37.97,-74.98 37.97,-119.49 0,-114.3 -92.65,-206.95 -206.95,-206.95 -44.51,0 -85.74,14.05 -119.49,37.96zm238.98 337.97l-288.48 -288.47c-23.91,33.76 -37.96,74.98 -37.96,119.49 0,114.3 92.65,206.95 206.95,206.95 44.52,0 85.74,-14.06 119.49,-37.97z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class CancelAllIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
