/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CounterOrderChangeDto } from './CounterOrderChangeDto';
import type { EetResponseDto } from './EetResponseDto';
import type { EnumDto } from './EnumDto';
import type { OrderItemDto } from './OrderItemDto';
import type { PersonDto } from './PersonDto';
import type { TaxDto } from './TaxDto';
export type CounterOrderDetailDto = {
  items: Array<OrderItemDto>;
  vatin: string;
  receipt: string;
  counter: string;
  branch: number;
  paidUtc: string;
  paidOffset: number;
  changes: Array<CounterOrderChangeDto>;
  taxes?: Array<TaxDto>;
  totalTax?: TaxDto;
  /**
   * Order identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Order number. Is unique.
   */
  orderNumber: number;
  /**
   * DateTime when order was processed. Issued, paid or cancelled. Type: 'DATETIME'
   */
  processed: string;
  /**
   * Shift number. Format: 'YYMMDDCCCCCUUUUSS'
   * + **YY**: year
   * + **MM**: month
   * + **DD**: day
   * + **CCCCC**: counter number - has 5 digits
   * + **UUUU**: user number - has 4 digits
   * + **SS**: sequence number for the given combination of user and counter - 2 digits
   */
  shiftNumber?: number;
  /**
   * Price paid.
   */
  price: number;
  /**
   * Payment state
   */
  state: CounterOrderDetailDto.state;
  /**
   * Payment type. Cash, card, e-wallet, invoice.
   */
  paymentType: EnumDto;
  /**
   * User who confirmed the payment or canceled the order.
   */
  operator?: PersonDto;
  /**
   * Eet details.
   */
  eet?: EetResponseDto;
};
export namespace CounterOrderDetailDto {
  /**
   * Payment state
   */
  export enum state {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    PROCESSING = 'PROCESSING',
  }
}
