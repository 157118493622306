import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const ruzomberokColors: Partial<AppColors> = {
  systemPrimary: '#c3050d',
  systemSecondary: '#c3050d',
  buttonPrimary: '#073855',
  primary: '#ba050d',
  buttonWarning: colors.red.darken1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#ffffff',
  leftpanelhover: '#b9242b',
};
