import { PaymentService, PaymentStatus, UpdatePaymentStatusCommand } from '@/api';
import { getRequestBody } from '@/utils/api';

export default {
  getPaymentStatus: async (id: string): Promise<PaymentStatus> => {
    const response = await PaymentService.paymentControllerGetPaymentStatus({
      id,
    });
    return response.data;
  },

  updatePaymentStatus: async (id: string | number, payload?: UpdatePaymentStatusCommand): Promise<PaymentStatus> => {
    const response = await PaymentService.paymentControllerCheckPaymentStatus({
      id: id.toString(),
      requestBody: getRequestBody(payload),
    });

    return response.data;
  },
};
