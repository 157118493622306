import { ComponentBase } from 'vue-facing-decorator';
import { getCustomerCardState } from '@/components/userAccount/helper/customerCardHelper';
import UserAccountModule from '@/store/modules/userAccount';
import CustomerModule from '@/store/modules/customer';
import RDPComponentBase from '@/components/common/ComponentBase';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';

/**
 * Class to be inherited by a new one with added <template> above.
 */
@ComponentBase({
  name: 'RDPCustomerDetailAbstract',
})
export default class RDPCustomerDetailAbstract extends RDPComponentBase {
  userAccountModule = UserAccountModule;
  customerModule = CustomerModule;
  systemConfigurationModule = SystemConfigurationModule;

  dataCy = 'customer-detail';
  i18nGroupKey = 'userAccountPage.customerDetail';

  getCustomerCardState = getCustomerCardState;

  get customerCardData() {
    return this.userAccountModule.cardDetailData;
  }

  get customerData() {
    return this.userAccountModule.selectedCustomerData;
  }

  get profileOne() {
    return this.customerCardData.profileOne || undefined;
  }

  get profileTwo() {
    return this.customerCardData.profileTwo || undefined;
  }

  get showBirthDay() {
    return this.customerModule.isLinkedCustomerSelected;
  }

  get verificationClass() {
    return this.userAccountModule.personalDataVerified ? 'verified' : 'unverified';
  }
}
