/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReclamationCardQuery = {
  /**
   * Unique identifier of card.
   */
  cardNumber?: string;
  customerName?: string;
  /**
   * Card state
   */
  state?: ReclamationCardQuery.state;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
  /**
   * Page Size. Default: 25
   */
  limit?: number;
  /**
   * Offsetting x items. Default: 0
   */
  offset?: number;
  /**
   * Sort by. Default: id
   */
  sortBy?: string;
  /**
   * Sort order descendent. Default: true
   */
  sortDesc?: boolean;
  /**
   * Sort with locale. Default: sk
   */
  sortLocale?: string;
};
export namespace ReclamationCardQuery {
  /**
   * Card state
   */
  export enum state {
    NOT_ISSUED = 'NOT_ISSUED',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    EXPIRED = 'EXPIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    TOKENIZED = 'TOKENIZED',
  }
}
