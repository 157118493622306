<template>
  <v-row v-if="cardProfile">
    <v-col cols="12" class="py-1 text-subtitle-1 font-weight-bold">
      <span>
        {{ ts('customerProfileLabel', { number: profileNumber }) }}
      </span>
      <span class="mx-1" :data-cy="cy(`profileName-${profileNumber}`)">
        {{ profileDisplayName }}
      </span>
      <span class="mx-1" :data-cy="cy('customerProfile-validity')">
        {{ filters.toStandardDate(cardProfile.validFrom) }}
        {{ ` - ` }}
        {{ filters.toStandardDate(cardProfile.validTo) }}
      </span>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import { getCustomerProfileDisplayName } from '@/utils/i18n';
import { CardProfileVm } from '@/api';

@Component({
  name: 'ProfileValidityInfoLight',
})
export default class ProfileValidityInfoLight extends ComponentBase {
  @Prop({ required: false, type: Object })
  readonly cardProfile!: CardProfileVm;
  @Prop({ required: true, type: Number })
  readonly profileNumber!: number;

  dataCy = 'profile-validity-info';
  i18nGroupKey = 'userAccountPage.profileValidityInfo';

  get profileDisplayName(): string {
    return getCustomerProfileDisplayName(this.cardProfile.profile?.id);
  }
}
</script>
