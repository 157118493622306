import {
  AddressDto,
  CreateBaseCustomerCommand,
  CreateCustomerCommand,
  CustomerDto,
  UpdateBaseCustomerCommand,
} from '@/api';
import { imageStringToBase64 } from '@/utils/imageStringToBase64';

export const toAddress = (address: AddressDto | undefined) => {
  return {
    street: address?.street,
    streetNumber: address?.streetNumber,
    city: address?.city,
    zip: address?.zip,
  } as AddressDto;
};

export const toCreateCustomerCommand = (customerData: CustomerDto) => {
  return {
    name: {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
    },
    birthDay: customerData.birthDay,
    email: customerData.email || undefined,
    phoneNumber: customerData.phoneNumber || undefined,
    address: toAddress(customerData.address),
    photo: imageStringToBase64(customerData.photo || ''),
  } as CreateCustomerCommand;
};

export const toCreateBaseCustomerCommand = (customerData: CustomerDto) => {
  return {
    name: {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
    },
    birthDay: customerData.birthDay,
    email: customerData.email || undefined,
    phoneNumber: customerData.phoneNumber || undefined,
    address: toAddress(customerData.address),
  } as CreateBaseCustomerCommand;
};

export const toUpdateCustomerCommand = (customerData: CustomerDto) => {
  return {
    name: {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
    },
    birthDay: customerData.birthDay,
    email: customerData.email || undefined,
    phoneNumber: customerData.phoneNumber || undefined,
    address: toAddress(customerData.address),
    photo: imageStringToBase64(customerData.photo || ''),
  } as UpdateBaseCustomerCommand;
};
