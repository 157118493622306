import { DATE_FORMAT, isDateOneAfterDateTwo } from '@/utils/dateTime';
import { EnumDto, EnumsDto } from '@/api';
import {
  addDays,
  addMinutes,
  addMonths,
  addYears,
  endOfMonth,
  format,
  getDate,
  getYear,
  startOfMinute,
  startOfMonth,
  startOfYear,
  subDays,
  subYears,
} from 'date-fns';

export const computeSlidingTicketValidity = (
  startDay: string,
  quantity: number,
  period: EnumDto | undefined,
): [string, string] => {
  const validTo = computeValidTo(startDay, quantity, period);

  return [startDay as string, validTo];
};

const computeValidTo = (validFrom: string, quantity: number, period: EnumDto | undefined) => {
  let validTo = new Date();
  if (period?.name === EnumsDto.tariffProfilePeriods.MINUTE) {
    validTo = addMinutes(validFrom, quantity);
  }

  if (period?.name === EnumsDto.tariffProfilePeriods.MONTH) {
    validTo = addMonths(validFrom, quantity);
  }

  if (period?.name === EnumsDto.tariffProfilePeriods.HALF_YEAR) {
    validTo = addMonths(validFrom, 6 * quantity);
  }

  if (period?.name === EnumsDto.tariffProfilePeriods.YEAR) {
    validTo = addYears(validFrom, quantity);
  }

  return format(subDays(validTo, 1), DATE_FORMAT);
};

export const computeCalendarTicketValidity = (
  startDay: string,
  quantity: number,
  period: EnumDto | undefined,
): [string, string] => {
  let validFrom;
  let validTo;
  const startDayDate = new Date(startDay);

  if (period?.name === EnumsDto.tariffProfilePeriods.DAY) {
    validFrom = startDay;
    validTo = format(addDays(startDayDate, quantity - 1), DATE_FORMAT);
  } else {
    if (period?.name === EnumsDto.tariffProfilePeriods.MINUTE) {
      validFrom = format(startOfMinute(startDayDate), DATE_FORMAT);
    }

    if (
      period?.name === EnumsDto.tariffProfilePeriods.MONTH ||
      period?.name === EnumsDto.tariffProfilePeriods.HALF_YEAR
    ) {
      validFrom = format(startOfMonth(startDayDate), DATE_FORMAT);
    }

    if (period?.name === EnumsDto.tariffProfilePeriods.YEAR) {
      validFrom = format(startOfYear(startDayDate), DATE_FORMAT);
    }
    validTo = computeValidTo(validFrom, quantity, period);
  }

  return [validFrom, validTo];
};

export const correctFixedDate = (year: number, month: number | undefined, day: number | undefined) => {
  let date = new Date(year, (month || 1) - 1, day);
  // Check if date overflows.
  if (getDate(date) !== day) {
    // If so, set the last day of the given month.
    date = endOfMonth(new Date(year, (month || 1) - 1, 1));
  }

  return format(date, DATE_FORMAT);
};

export const computeFixedTicketValidFrom = (
  startDay: string,
  fixedValidFromDay: number | undefined,
  fixedValidFromMonth: number | undefined,
  fixedValidToDay: number | undefined,
  fixedValidToMonth: number | undefined,
): [string, string] => {
  const year = getYear(startDay);
  let validFrom = correctFixedDate(year, fixedValidFromMonth, fixedValidFromDay);
  let validTo = correctFixedDate(year, fixedValidToMonth, fixedValidToDay);

  if (isDateOneAfterDateTwo(validFrom, validTo)) {
    if (isDateOneAfterDateTwo(validTo, startDay)) {
      validFrom = format(subYears(validFrom, 1), DATE_FORMAT);
    } else {
      validTo = format(addYears(validTo, 1), DATE_FORMAT);
    }
  }

  return [validFrom, validTo];
};
