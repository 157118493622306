<template>
  <!--
    from file(s): kosik_blue_top.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="12mm"
    height="10mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 1200 1000"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        class="fil0"
        d="M251.2 48.03c9.39,2.95 17.22,10.29 20.38,20.35l38.5 122.78 0 -0.01 722.91 0 0 1.67 76.71 0c0.78,0 1.44,0.02 2.22,0.07l10.32 0.74c10.08,0.73 18.44,5.69 23.91,14.19 5.47,8.5 6.51,18.17 2.99,27.64l-149.25 401.04c-1.81,15.41 -14.98,27.43 -30.86,27.43l-510.67 0 24.16 77.04 399.84 0c4.24,-0.51 8.56,-0.78 12.94,-0.78 59.2,0 107.18,47.99 107.18,107.18 0,59.19 -47.98,107.18 -107.18,107.18 -59.19,0 -107.18,-47.99 -107.18,-107.18 0,-14.13 2.74,-27.63 7.72,-39.98l-238.87 0c4.98,12.35 7.72,25.85 7.72,39.98 0,59.19 -47.99,107.18 -107.18,107.18 -59.2,0 -107.18,-47.99 -107.18,-107.18 0,-42.21 24.4,-78.71 59.86,-96.19l-198.73 -633.66 -131.59 0c-17.11,0 -31.07,-13.95 -31.07,-31.07l0 -9.93c0,-17.11 13.96,-31.07 31.07,-31.07l158.96 0c4.39,0 8.58,0.92 12.37,2.58zm82.01 216.86l102.54 326.97 503.85 0 121.69 -326.97 -28.3 0 -143.44 0 -556.34 0zm562.09 545.21c20.59,0 37.28,16.69 37.28,37.27 0,20.59 -16.69,37.27 -37.28,37.27 -20.58,0 -37.27,-16.68 -37.27,-37.27 0,-20.58 16.69,-37.27 37.27,-37.27zm-437.79 0c20.59,0 37.27,16.69 37.27,37.27 0,20.59 -16.68,37.27 -37.27,37.27 -20.59,0 -37.28,-16.68 -37.28,-37.27 0,-20.58 16.69,-37.27 37.28,-37.27z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class ShoppingCartTopIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}
</style>
