import i18n from '@/i18n';
import { ToastOptions } from '@/store/modules/toast';
import { NUMBERED_ERRORS } from '@/constants/Errors';
import { ApiErrorInterface } from '@/models/ApiClientError';

const TOAST_TIMEOUT_BY_TEXT_LENGTH = [
  { length: 200, timeout: 20000 },
  { length: 100, timeout: 10000 },
  { length: 0, timeout: 4000 },
];

const setTimeoutForToast = (text: string): ToastOptions | undefined => {
  for (const toastOption of TOAST_TIMEOUT_BY_TEXT_LENGTH) {
    if (text.length > toastOption.length) {
      return { timeout: toastOption.timeout };
    }
  }
  // TODO return undefined, the timeout will be default
  return undefined;
};

export const getTimeoutForToast = (text: string): number | undefined => {
  for (const toastOption of TOAST_TIMEOUT_BY_TEXT_LENGTH) {
    if (text.length > toastOption.length) {
      return toastOption.timeout;
    }
  }

  return undefined;
};

export const getApiError = (
  error: ApiErrorInterface,
  i18nGroupKey: string,
  defaultErrorI18nKey?: string,
  payload?: Record<string, unknown>,
) => {
  const errorName = `${i18nGroupKey}.errors.${error?.data?.name}`;
  if (i18n.global.te(errorName)) {
    return payload ? i18n.global.t(errorName, payload) : i18n.global.t(errorName);
  } else {
    const defaultError = defaultErrorI18nKey || 'form.saveFailed';
    if (i18n.global.te(defaultError)) {
      return i18n.global.t(defaultError);
    } else {
      return i18n.global.t(`${i18nGroupKey}.${defaultError}`);
    }
  }
};

export const getNumberedApiError = (
  error: ApiErrorInterface,
  i18nGroupKey: string,
  defaultErrorI18nKey?: string,
  payload?: { [key: string]: string | number },
) => {
  const translatedError = getApiError(error, i18nGroupKey, defaultErrorI18nKey, payload);

  const errorName = error?.data?.name;
  if (!errorName) {
    return translatedError;
  }
  const number = NUMBERED_ERRORS[errorName];
  if (!number) {
    return translatedError;
  }
  const errorNumber = i18n.global.t('numberedErrors.errorNumber', { number });
  return `${errorNumber} ${translatedError}`;
};

export const getTextAndOptionsForToast = (
  i18nGroupKey: string,
  i18nKey: string,
  options?: ToastOptions,
): [string, ToastOptions?] => {
  const text: string = i18n.global.t(`${i18nGroupKey}.${i18nKey}`);
  if (!options) {
    return [text, setTimeoutForToast(text)];
  }
  return [text, options];
};
