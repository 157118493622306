<template>
  <v-row v-if="userAccountModule.hasCardDetailData" data-cy="customer-detail-component">
    <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="customer-detail-info">
      <v-row>
        <v-col :class="$vuetify.display.mobile ? 'text-left' : 'text-right'" class="label pl-4">
          {{ ts('cardNumber') }}
        </v-col>
        <v-col class="text-left" :data-cy="cy('cardNumber')" :style="$vuetify.display.mobile ? { 'flex-grow': 2 } : {}">
          {{ customerCardData.cardNumber }}
        </v-col>
      </v-row>
      <v-row>
        <v-col :class="$vuetify.display.mobile ? 'text-left' : 'text-right'" class="pb-0 pt-1 pl-4 label">
          {{ ts('wallet') }}
        </v-col>
        <v-col
          class="pb-0 pt-1 pl-0 font-weight-bold text-left"
          :data-cy="cy('wallet')"
          :style="$vuetify.display.mobile ? { 'flex-grow': 2 } : {}"
        >
          {{ filters.toPrice(customerCardData.electronicWallet!.currentAmount.toString()) }}
          {{ $t('common.currency') }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator';
import RDPImage from '@/components/common/RDPImage/RDPImage.vue';
import ProfileValidityInfo from '@/components/common/RDPCustomerDetail/ProfileValidityInfo.vue';
import RDPCustomerDetailAbstract from '@/components/common/RDPCustomerDetail/RDPCustomerDetailAbstract';

@Component({
  name: 'CustomerDetailForDialog',
  components: {
    RDPImage,
    ProfileValidityInfo,
  },
})
export default class CustomerDetailForDialog extends RDPCustomerDetailAbstract {}
</script>
