<template>
  <v-card class="fill-height pa-4">
    <v-alert v-if="customerModule.linkedCustomer.id" border="top" type="info" variant="text" elevation="2" class="mb-4">
      {{ linkedCustomerInfo }}
    </v-alert>
    <v-alert
      v-if="pageCreated && !customerManageable && !showManagedCardsInfo"
      border="top"
      type="warning"
      variant="text"
      elevation="2"
      class="mb-4"
    >
      {{ $t('userAccountPage.nonManageableCustomer') }}
    </v-alert>
    <template v-if="!customerModule.currentlySelectedCustomerId && !showManagedCardsInfo && pageCreated">
      <v-alert border="top" type="success" variant="text" elevation="2" class="mb-4">
        {{ $t('userAccountPage.noActiveCard') }}
      </v-alert>
      <v-alert
        v-if="showHaulierCardApplicationButton"
        border="top"
        type="info"
        variant="text"
        elevation="2"
        class="mb-4"
      >
        <i18n-t keypath="userAccountPage.noActiveCardInfo.info" tag="p" class="mb-0">
          <template #link>
            <router-link :to="paths.ASSIGNED_CARD_LIST">
              {{ $t('userAccountPage.noActiveCardInfo.linkText') }}
            </router-link>
          </template>
        </i18n-t>
      </v-alert>
    </template>
    <v-alert v-if="showManagedCardsInfo" border="top" type="info" variant="text" elevation="2" class="mb-4">
      <i18n-t keypath="userAccountPage.telshopMigration.info" tag="p" class="mb-0">
        <template #link>
          <router-link :to="paths.MANAGED_CARD_LIST"
            >{{ $t('userAccountPage.telshopMigration.linkText') }}
          </router-link>
        </template>
      </i18n-t>
    </v-alert>
    <CustomerDetail
      :customer-is-manageable="customerManageable && pageCreated"
      @card-application-created="refreshCardApplicationsTab"
      @refresh-account-page="refreshPage"
    />

    <v-tabs
      v-if="customerModule.currentlySelectedCustomerId"
      v-model="tab"
      class="user-account-page-tabs"
      color="systemPrimary"
      :direction="$vuetify.display.mobile ? 'vertical' : 'horizontal'"
      show-arrows
      @update:model-value="reloadSelectedTabTable"
    >
      <v-tab
        v-for="(item, i) in tabs"
        :key="i"
        :data-cy="`${item.key}-tab`"
        :class="{ 'mr-auto': $vuetify.display.mobile }"
      >
        <v-icon start size="large">{{ item.icon }}</v-icon>
        {{ $t(item.i18n) }}
      </v-tab>
    </v-tabs>
    <v-window
      v-if="customerModule.currentlySelectedCustomerId"
      v-model="tab"
      class="mt-5 overflow-visible"
      :touch="false"
    >
      <v-window-item :key="ticketFareTabKey">
        <TicketFareTab ref="ticketFareTab" />
      </v-window-item>
      <v-window-item :key="chipCardTabKey">
        <ChipCardTab
          ref="chipCardTab"
          :customer-is-manageable="customerManageable"
          @refresh-account-page="refreshPage"
        />
      </v-window-item>
      <v-window-item v-if="isEWalletTabAllowed && showHaulierCardApplicationButton" :key="eWalletTabKey">
        <EWalletTab ref="eWalletTab" />
      </v-window-item>
      <v-window-item :key="cardApplicationsTabKey">
        <v-alert
          v-if="showHaulierCardApplicationButton"
          border="top"
          type="info"
          variant="text"
          elevation="2"
          class="mb-4"
        >
          {{ $t('userAccountPage.cardAssignedToApplicationInfo') }}
        </v-alert>
        <CardApplicationTab ref="cardApplicationsTab" />
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import CustomerDetail from '@/components/userAccount/CustomerDetail.vue';
import EWalletTab from '@/components/userAccount/tabs/EWalletTab.vue';
import TicketFareTab from '@/components/userAccount/tabs/TicketFareTab.vue';
import ChipCardTab from '@/components/userAccount/tabs/ChipCardTab.vue';
import CardApplicationTab from '@/components/userAccount/tabs/CardApplicationTab.vue';
import CustomerModule from '@/store/modules/customer';
import { CustomerCardDetailVm, CustomerDetailVm, TokenizationResponse } from '@/api';
import UserAccountModule from '@/store/modules/userAccount';
import customerService from '@/services/api/customerService';
import { RDPDataTableInterface } from '@/components/common/RDPDataTable/RDPDataTableInterfaces';
import Paths from '@/constants/Paths';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast';
import bankCardService from '@/services/api/bankCardService';
import ToastModule from '@/store/modules/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';

interface TabItem {
  key: string;
  icon: string;
  i18n: string;
}

@Component({
  components: {
    EWalletTab,
    TicketFareTab,
    ChipCardTab,
    CardApplicationTab,
    CustomerDetail,
  },
})
export default class UserAccountPage extends Vue {
  @Ref()
  readonly ticketFareTab!: RDPDataTableInterface;
  @Ref()
  readonly chipCardTab!: RDPDataTableInterface;
  @Ref()
  readonly eWalletTab!: RDPDataTableInterface;
  @Ref()
  readonly cardApplicationsTab!: RDPDataTableInterface;

  tab = -1;
  customerModule = CustomerModule;
  userAccountModule = UserAccountModule;
  noActiveCard = false;
  tabs = new Array<TabItem>();
  ticketFareTabKey = 'ticketFareTab';
  chipCardTabKey = 'chipCardTab';
  eWalletTabKey = 'eWalletTab';
  cardApplicationsTabKey = 'cardApplicationsTab';
  ticketFareTabIndex = -1;
  chipCardTabIndex = -1;
  eWalletTabIndex = -1;
  cardApplicationsTabIndex = -1;
  paths = Paths;
  systemConfiguration = SystemConfigurationModule;
  onlyManagedRelations = false;
  customerManageable = true;
  pageCreated = false;

  async created() {
    await this.loadCustomerData();
    this.initTabs();

    if (this.systemConfiguration.isBankCardAsCarrierAllowed && this.$route.query.orderId) {
      const tokenizationResponse = this.$route.query as unknown as TokenizationResponse;
      const toastOptions = { timeout: PERMANENT_TOAST_INTERVAL };

      try {
        this.$router.replace({ query: {} });
        await bankCardService.finishTokenization(tokenizationResponse);
        ToastModule.success({
          message: this.$t('userAccountPage.tokenizationSuccess'),
          options: toastOptions,
        });
        await this.refreshPage();
        this.tab = this.chipCardTabIndex;
      } catch (e) {
        ToastModule.error({
          message: this.$t('userAccountPage.tokenizationFailed'),
          options: toastOptions,
        });
      }
    }

    await this.loadCustomerManageableInformation();
    this.pageCreated = true;
  }

  async loadCustomerManageableInformation() {
    if (
      this.systemConfiguration.isTelshopMigrationAllowed &&
      !this.systemConfiguration.isAssignCardWithoutAgeRestrictionAllowed
    ) {
      this.onlyManagedRelations = Boolean(await customerService.hasOnlyManagedRelations());
      this.customerManageable = !this.onlyManagedRelations;
      if (this.customerModule.currentlySelectedCustomerId) {
        this.customerManageable = Boolean(
          await customerService.isCustomerManageable(this.customerModule.currentlySelectedCustomerId),
        );
      }
    }
  }

  initTabs() {
    this.tabs.push({
      key: this.ticketFareTabKey,
      icon: '$ticketFareIcon',
      i18n: 'userAccountPage.ticketFareTab.title',
    });
    this.ticketFareTabIndex = this.tabs.length - 1;
    this.tabs.push({
      key: this.chipCardTabKey,
      icon: '$chipCardIcon',
      i18n: 'userAccountPage.chipCardTab',
    });
    this.chipCardTabIndex = this.tabs.length - 1;

    if (this.isEWalletTabAllowed && this.showHaulierCardApplicationButton) {
      this.tabs.push({
        key: this.eWalletTabKey,
        icon: '$electronicWalletIcon',
        i18n: 'userAccountPage.eWalletTab',
      });
      this.eWalletTabIndex = this.tabs.length - 1;
    }
    this.tabs.push({
      key: this.cardApplicationsTabKey,
      icon: '$cardApplicationListIcon',
      i18n: 'userAccountPage.cardApplicationTab',
    });
    this.cardApplicationsTabIndex = this.tabs.length - 1;
  }

  reloadSelectedTabTable() {
    switch (this.tab) {
      case this.ticketFareTabIndex:
        this.refreshTicketFareTab();
        break;
      case this.chipCardTabIndex:
        this.refreshChipCardTab();
        break;
      case this.eWalletTabIndex:
        this.refreshEWalletTab();
        break;
      case this.cardApplicationsTabIndex:
        this.refreshCardApplicationsTab();
        break;
    }
  }

  get isEWalletTabAllowed() {
    return !this.userAccountModule.isCardTypeVirtual || this.systemConfiguration.isVirtualCardEWalletAllowed;
  }

  get showManagedCardsInfo() {
    return this.systemConfiguration.isTelshopMigrationAllowed && this.onlyManagedRelations;
  }

  get linkedCustomerInfo() {
    if (this.customerModule.isAssignedCustomerSelected) {
      return this.$t('userAccountPage.assignedAccount');
    }
    if (this.customerModule.isManagedCustomerSelected) {
      return this.$t('userAccountPage.managedAccount');
    }
    return '';
  }

  get showHaulierCardApplicationButton() {
    return this.systemConfiguration.isHaulierCardAsCarrierAllowed;
  }

  /**
   * This reloads all the data in the page. It just needs to have a 'customer' in AuthModule filled out.
   */
  async refreshPage() {
    await Promise.all([this.loadCustomerData(), this.loadCustomerManageableInformation()]);
    this.refreshTicketFareTab();
    this.refreshChipCardTab();
    this.refreshEWalletTab();
    this.refreshCardApplicationsTab();
  }

  async loadCustomerData() {
    try {
      this.userAccountModule.clearSelectedCustomerData();
      this.userAccountModule.clearCardData();
      if (this.customerModule.currentlySelectedCustomerId) {
        const customer: CustomerDetailVm = await customerService.getCustomer(
          this.customerModule.currentlySelectedCustomerId,
        );
        this.userAccountModule.setSelectedCustomerData(customer);

        const customerCard: CustomerCardDetailVm = await customerService.getCustomerLastActiveCard(customer.id);
        this.userAccountModule.setCardData(customerCard);
        this.noActiveCard = false;
      } else {
        this.noActiveCard = true;
      }
    } catch (e) {
      if ((e as ApiErrorInterface).data?.httpCode === 404) {
        this.noActiveCard = true;
        this.userAccountModule.clearCardData();
      } else {
        ToastModule.error({ message: this.$t('userAccountPage.loadingError') });
      }
    }
  }

  refreshTicketFareTab() {
    this.ticketFareTab?.refresh();
  }

  refreshChipCardTab() {
    this.chipCardTab?.refresh();
  }

  refreshEWalletTab() {
    this.eWalletTab?.refresh();
  }

  refreshCardApplicationsTab() {
    this.cardApplicationsTab?.refresh();
  }
}
</script>

<style lang="scss">
.user-account-page-tabs {
  .v-slide-group__wrapper {
    touch-action: auto;
  }
}
</style>
