<template>
  <v-main>
    <v-container class="fill-height bg-rdpbackground" fluid>
      <v-row class="text-center" align="center" justify="center">
        <v-col cols="12">
          <RDPHomePageLogo />
        </v-col>
        <v-col cols="12">
          <v-form ref="form" validate-on="lazy" @submit.prevent>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12 rounded-xl">
                  <v-toolbar color="systemPrimary" dark flat>
                    <v-toolbar-title>
                      {{ $t('changePassword.header') }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pl-6 pr-6">
                    <v-text-field
                      id="password"
                      v-model="resetPassword.password"
                      name="password"
                      prepend-icon="mdi-lock"
                      :type="showPass ? 'text' : 'password'"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :label="$t('changePassword.password')"
                      :rules="validations.password"
                      data-cy="change-password-password"
                      variant="underlined"
                      @click:append="showPass = !showPass"
                    />
                    <v-text-field
                      id="password"
                      v-model="resetPassword.passwordAgain"
                      name="password"
                      prepend-icon="mdi-lock"
                      :type="showPassAgain ? 'text' : 'password'"
                      :append-icon="showPassAgain ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="validations.passwordAgain"
                      :label="$t('changePassword.passwordAgain')"
                      data-cy="change-password-passwordAgain"
                      variant="underlined"
                      @click:append="showPassAgain = !showPassAgain"
                    />
                  </v-card-text>
                  <v-card-actions class="justify-end pr-6 pb-6 pt-0">
                    <ConfirmButton
                      :text="$t('buttons.ok')"
                      :confirm="changePassword"
                      class="rdp-button"
                      style="width: 140px"
                      button-color="buttonPrimary"
                      type="submit"
                      data-cy="change-password-btn"
                      large-icon
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col sm="8" md="4" cols="12" class="d-flex justify-space-between pt-0">
          <router-link :to="paths.LOGIN">
            {{ $t('changePassword.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app theme="dark">
      <FooterLinks dark />
    </v-footer>
  </v-main>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { FormInterface } from '@/components/common/FormInterface';
import { confirmPasswordValidator, strongPasswordValidator } from '@/utils/validators';
import authServices from '@/services/api/authService';
import Errors from '@/constants/Errors';
import Paths from '@/constants/Paths';
import FooterLinks from '@/components/layout/FooterLinks.vue';
import RDPHomePageLogo from '@/components/common/RDPHomePageLogo.vue';
import ToastModule from '@/store/modules/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';

@Component({
  components: {
    ConfirmButton,
    FooterLinks,
    RDPHomePageLogo,
  },
})
export default class ChangePasswordPage extends Vue {
  @Ref()
  readonly form!: FormInterface;

  paths = Paths;
  showPass = false;
  showPassAgain = false;
  resetPassword = {
    password: '',
    passwordAgain: '',
  };
  validations = {
    password: [(v: string) => strongPasswordValidator(v)],
    passwordAgain: [(v: string) => confirmPasswordValidator(v, this.resetPassword.password)],
  };

  async changePassword() {
    if ((await this.form.validate()).valid) {
      const toastPosition = {
        x: 'center',
        y: 'top',
      };
      try {
        const { password } = this.resetPassword;
        await authServices.changePassword({
          password,
          token: this.$route.query.token as string,
        });
        ToastModule.success({
          message: this.$t('changePassword.success'),
          options: toastPosition,
        });
        this.resetForm();
        this.$router.push(Paths.LOGIN);
      } catch (e) {
        switch ((e as ApiErrorInterface).data?.name) {
          case Errors.CUSTOMER_EMAIL_NOT_UNIQUE:
            ToastModule.error({
              message: this.$t('apiErrors.emailNotUnique'),
              options: toastPosition,
            });
            break;
          case Errors.INVALID_VERIFICATION_TOKEN:
            ToastModule.error({
              message: this.$t('apiErrors.invalidToken'),
              options: toastPosition,
            });
            break;
          case Errors.VALIDATION_ERROR:
            ToastModule.error({
              message: this.$t('apiErrors.validationError'),
              options: toastPosition,
            });
            break;
        }
      }
    }
  }

  resetForm() {
    this.resetPassword = {
      password: '',
      passwordAgain: '',
    };
    this.form.resetValidation();
  }
}
</script>
