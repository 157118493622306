/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardStateInfoVm } from './CardStateInfoVm';
import type { KeyValuePairDto } from './KeyValuePairDto';
export type CustomerCardInfoVm = {
  /**
   * Customer card identifier. Type: 'UUID'
   */
  id: string;
  /**
   * Unique identifier of card.
   */
  cardNumber: string;
  /**
   * Card valid from. Type: 'DATE'
   */
  validFrom: string;
  /**
   * Card valid to. Type: 'DATE'
   */
  validTo: string;
  /**
   * Customer profile. Student, regular, senior, ...
   */
  profileOne?: KeyValuePairDto;
  /**
   * Customer profile. Student, regular, senior, ...
   */
  profileTwo?: KeyValuePairDto;
  /**
   * Current card state info.
   */
  currentState: CardStateInfoVm;
  /**
   * Created card state info.
   */
  created: CardStateInfoVm;
  /**
   * Issued card state info.
   */
  issued?: CardStateInfoVm;
  /**
   * Is this card a reclamation card
   */
  reclamation: boolean;
  /**
   * Contact me if card found
   */
  contactMeIfCardFound: boolean;
  /**
   * Customer card type.
   */
  customerCardType?: CustomerCardInfoVm.customerCardType;
  /**
   * Agree with marketing consent. Default: false
   */
  marketingConsent?: boolean;
  /**
   * Card application identifier. Type: 'UUID'
   */
  applicationId: string;
};
export namespace CustomerCardInfoVm {
  /**
   * Customer card type.
   */
  export enum customerCardType {
    PERSONAL = 'PERSONAL',
    PORTABLE = 'PORTABLE',
    RECLAMATION = 'RECLAMATION',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    BANK_CARD = 'BANK_CARD',
    WHITE_LIST_CARD = 'WHITE_LIST_CARD',
  }
}
