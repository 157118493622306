<template>
  <div class="text-center">
    <img :src="homepageLogoPath" :height="$vuetify.display.mobile ? 60 : logoHeight" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { HOMEPAGE_LOGO_PATH, LOGO_HEIGHT } from '@/constants/Logo';

@Component
export default class RDPHomePageLogo extends Vue {
  get homepageLogoPath() {
    return HOMEPAGE_LOGO_PATH;
  }
  get logoHeight() {
    return LOGO_HEIGHT;
  }
}
</script>
