import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const dpmpColors: Partial<AppColors> = {
  systemPrimary: '#E40613', // see RDP-1528
  systemSecondary: '#073855', // see RDP-1528
  buttonPrimary: '#073855', // see RDP-1528
  primary: '#0C7396', // see RDP-1529
  buttonWarning: colors.red.lighten1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#d4d4d4',
  leftpanelhover: '#c46064',
};
