<template>
  <!--
    from file(s): prirazeni_karty_ditete.svg
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="11mm"
    height="12mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 1100 1200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Vrstva_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2708291035520">
        <path
          class="fil1"
          d="M52.71 1031.89l0 -863.78c0,-79.62 65.02,-144.65 144.64,-144.65l449.08 0c79.62,0 144.64,65.03 144.64,144.65l0 863.78c0,79.62 -65.02,144.65 -144.64,144.65l-449.08 0c-79.62,0 -144.64,-65.03 -144.64,-144.65z"
        />
        <g>
          <path
            class="fil0"
            d="M486.76 946.56l0 -655.76c0,-60.44 49.35,-109.81 109.8,-109.81l340.93 0c60.45,0 109.8,49.37 109.8,109.81l0 655.76c0,60.44 -49.35,109.81 -109.8,109.81l-340.93 0c-60.45,0 -109.8,-49.37 -109.8,-109.81z"
          />
          <polygon
            class="fil2"
            points="802.42,578.88 956.82,578.88 956.82,645.11 802.42,645.11 802.42,820.16 731.64,820.16 731.64,645.11 577.24,645.11 577.24,578.88 731.64,578.88 731.64,417.2 802.42,417.2 "
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class AssignCardIcon extends Vue {}
</script>

<style lang="scss" scoped>
.fil0 {
  fill: currentColor;
}

.fil1 {
  fill: #b2b3b3;
}

.fil2 {
  fill: #073855;
}
</style>
