import {
  AddressDto,
  BranchOfficeDto,
  CardApplicationDto,
  CreateBankCardApplicationCommand,
  CreateCardApplicationCommand,
  CreateVirtualCardApplicationCommand,
} from '@/api';
import cardApplicationProfileMapper from '@/components/cardApplication/mappers/cardApplicationProfileMapper';
import isDefined from '@common/utils/isDefined';
import { REGISTER_NEW_BANK_CARD } from '@/store/modules/cardApplication';

export default {
  toCreateCardApplicationCommand: (
    cardData: CardApplicationDto,
    customerId: string,
    recipientAddress?: AddressDto,
    branchOffice?: BranchOfficeDto,
  ) => {
    const cardApplication: CreateCardApplicationCommand = {
      customerId: customerId,
      contactMeIfCardFound: cardData.contactMeIfCardFound,
      profileOne: cardApplicationProfileMapper.toCardApplicationProfileSaveCommand(cardData.profileOne),
      profileTwo: cardData.profileTwo?.profile?.id
        ? cardApplicationProfileMapper.toCardApplicationProfileSaveCommand(cardData.profileTwo)
        : undefined,
      keepPhoto: false,
      cardForFree: cardData.cardForFree,
      marketingConsent: cardData.marketingConsent,
      carrierType: CreateCardApplicationCommand.carrierType.HAULIER_CARD,
    };

    if (recipientAddress && recipientAddress.street !== '') {
      cardApplication.recipientAddress = recipientAddress;
    }

    if (branchOffice && branchOffice.id) {
      cardApplication.deliveryBranchOfficeId = branchOffice.id;
    }

    return cardApplication;
  },

  toCreateVirtualCardApplicationCommand: (cardData: CardApplicationDto, customerId: string) => {
    const cardApplication: CreateVirtualCardApplicationCommand = {
      customerId: customerId,
      profileOne: cardApplicationProfileMapper.toCardApplicationProfileSaveCommand(cardData.profileOne),
      profileTwo: cardData.profileTwo?.profile?.id
        ? cardApplicationProfileMapper.toCardApplicationProfileSaveCommand(cardData.profileTwo)
        : undefined,
      marketingConsent: cardData.marketingConsent,
    };
    return cardApplication;
  },

  toCreateBankCardApplicationCommand: (cardData: CardApplicationDto, customerId: string, bankCardId: string) => {
    const cardApplication: CreateBankCardApplicationCommand = {
      customerId: customerId,
      profileOne: cardApplicationProfileMapper.toCardApplicationProfileSaveCommand(cardData.profileOne),
      profileTwo: cardData.profileTwo?.profile?.id
        ? cardApplicationProfileMapper.toCardApplicationProfileSaveCommand(cardData.profileTwo)
        : undefined,

      bankCardId: isDefined(bankCardId) && bankCardId !== REGISTER_NEW_BANK_CARD ? bankCardId : undefined,
      marketingConsent: cardData.marketingConsent,
    };
    return cardApplication;
  },
};
