<template>
  <UserAccountPage />
</template>

<script lang="ts">
import { Component, Hook, Vue } from 'vue-facing-decorator';
import UserAccountPage from '@/pages/UserAccountPage.vue';
import UserAccountModule from '@/store/modules/userAccount';
import { AccountTypes } from '@/constants/AccountTypes';
import CustomerModule from '@/store/modules/customer';
import Paths from '@/constants/Paths';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecord } from 'vue-router';
import customerService from '@/services/api/customerService';
import customerAccountLinkService from '@/services/api/customerAccountLinkService';
import { isArrayEmpty } from '@common/utils/isDefined';
@Component({
  components: { UserAccountPage },
})
export default class UserLinkedAccountPage extends Vue {
  @Hook
  async beforeRouteEnter(to: RouteLocationNormalized, from: RouteRecord, next: NavigationGuardNext) {
    const linkedCustomerId = to.params['customerId'] as string;
    if (linkedCustomerId) {
      const linkedCustomer = await customerService.getCustomer(linkedCustomerId);
      const customerLinks = (await customerAccountLinkService.getLinkList({})).data;
      if (linkedCustomer && !isArrayEmpty(customerLinks)) {
        const customerLink = customerLinks.find(link => link.customer.id === linkedCustomerId);
        if (customerLink) {
          CustomerModule.setLinkedAccountCustomer(customerLink);
        }
      }
    }

    if (!CustomerModule.linkedCustomer.id) {
      return next(Paths.USER_ACCOUNT);
    }

    UserAccountModule.setSelectedAccount(AccountTypes.LINKED);
    next();
  }

  @Hook
  beforeRouteLeave(to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) {
    UserAccountModule.setSelectedAccount(AccountTypes.REGISTERED);
    next();
  }
}
</script>
