/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CancelTicketCommand = {
  returnedAmount: number;
  /**
   * Reason of refund.
   */
  refundReason?: CancelTicketCommand.refundReason;
  /**
   * The way the customer will be paid off.
   */
  finalizationType?: CancelTicketCommand.finalizationType;
  /**
   * Count days of hospitalisation.
   */
  hospitalDays?: number;
  /**
   * Date of death.
   */
  deathDate?: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CancelTicketCommand {
  /**
   * Reason of refund.
   */
  export enum refundReason {
    REFUND_NO_REASON = 'REFUND_NO_REASON',
    REFUND_HOSPITAL = 'REFUND_HOSPITAL',
    REFUND_DEATH = 'REFUND_DEATH',
  }
  /**
   * The way the customer will be paid off.
   */
  export enum finalizationType {
    COUNTER_PAY_OFF = 'COUNTER_PAY_OFF',
    GATEWAY_PAY_OFF = 'GATEWAY_PAY_OFF',
  }
}
