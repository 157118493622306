<template>
  <v-main>
    <v-form ref="form" validate-on="lazy" class="login-form" @submit.prevent>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="7" md="5">
          <v-card class="elevation-12 rounded-xl">
            <v-toolbar color="systemPrimary" theme="dark" flat>
              <v-toolbar-title>
                {{ $t('auth.loginHeader') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pl-6 pr-6">
              <v-text-field
                v-model="credentials.username"
                name="login"
                prepend-icon="$userAccountIcon"
                type="text"
                placeholder=" "
                :label="$t('auth.username')"
                data-cy="username-input"
                density="compact"
                variant="underlined"
              />

              <v-text-field
                id="password"
                v-model="credentials.password"
                name="password"
                placeholder=" "
                prepend-icon="mdi-lock"
                type="password"
                :label="$t('auth.password')"
                data-cy="password-input"
                density="compact"
                variant="underlined"
              />
            </v-card-text>
            <v-card-actions class="justify-end pr-6 pb-6 pt-0">
              <ConfirmButton
                :text="$t('auth.login')"
                :confirm="login"
                class="rdp-button"
                style="width: 180px"
                type="submit"
                icon="$loginIcon"
                button-color="buttonPrimary"
                data-cy="login"
                large-icon
              />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-main>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import AuthModule from '@/store/modules/auth';
import authService from '@/services/api/authService';
import { UsernameLoginCommand } from '@/api';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import Errors from '@/constants/Errors';
import loadSystemConfiguration from '@/utils/systemConfiguration';
import ToastModule from '@/store/modules/toast';
import { ApiErrorInterface } from '@/models/ApiClientError';

@Component({
  components: {
    ConfirmButton,
  },
})
export default class LoginForm extends Vue {
  authModule = AuthModule;
  credentials: UsernameLoginCommand = {
    username: '',
    password: '',
  };

  get isFormValid(): boolean {
    const invalidValues = [null, undefined, ''];

    return invalidValues.includes(this.credentials.username) || invalidValues.includes(this.credentials.password);
  }

  async login() {
    ToastModule.clearQueue();
    try {
      const data = await authService.login(this.credentials);
      this.authModule.login(data);
      await loadSystemConfiguration(true);
    } catch (e) {
      const errorPosition = {
        x: 'center',
        y: 'top',
      };
      if ((e as ApiErrorInterface).data?.name === Errors.INVALID_CREDENTIALS) {
        ToastModule.error({
          message: this.$t('apiErrors.invalidCredentials'),
          options: errorPosition,
        });
      } else if ((e as ApiErrorInterface).data?.name === Errors.CUSTOMER_ACCOUNT_NOT_VERIFIED) {
        ToastModule.error({
          message: this.$t('apiErrors.customerAccountNotVerified'),
          options: errorPosition,
        });
      } else if ((e as ApiErrorInterface).data?.errors![0] === Errors.EMPTY_PASSWORD) {
        ToastModule.error({
          message: this.$t('apiErrors.emptyPassword'),
          options: errorPosition,
        });
      } else if ((e as ApiErrorInterface).data?.errors![0] === Errors.EMPTY_USERNAME) {
        ToastModule.error({
          message: this.$t('apiErrors.emptyUsername'),
          options: errorPosition,
        });
      }
    }
  }
}
</script>

<style lang="scss">
.login-form {
  .rounded-xl {
    border-radius: 18px !important;
  }

  input,
  label {
    padding-left: 10px;
  }
}
</style>
