import colors from 'vuetify/lib/util/colors.mjs';
import { AppColors } from '@/themes/theme';

export const nitraColors: Partial<AppColors> = {
  systemPrimary: '#71ADDA',
  systemSecondary: '#71ADDA',
  buttonPrimary: '#073855',
  primary: '#0C7396',
  buttonWarning: colors.red.darken1,
  buttonConfirm: colors.green.darken2,
  buttonDefault: colors.grey.darken1,
  rdpbackground: '#d4d4d4',
  leftpanelhover: '#c46064',
};
