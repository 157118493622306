import { AddressDto } from '@/api';

export const getFormattedAddress = (addressDto: AddressDto) => {
  const streetAddress: string[] = [];
  if (addressDto.street && addressDto.street.length > 0) {
    streetAddress.push(addressDto.street);
  }
  if (addressDto.streetNumber && addressDto.streetNumber.length > 0) {
    streetAddress.push(addressDto.streetNumber);
  }

  const address: string[] = [];
  if (streetAddress.length > 0) {
    address.push(streetAddress.join(' '));
  }
  if (addressDto.city && addressDto.city.length > 0) {
    address.push(addressDto.city);
  }
  if (addressDto.zip && addressDto.zip.length > 0) {
    address.push(addressDto.zip);
  }

  return address.join(', ');
};
