<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="600"
    scrollable
    persistent
    data-cy="connect-account-dialog"
  >
    <v-card height="100%">
      <v-card-title class="text-h5 d-lex pl-3 bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white" class="mr-3">{{ dialogTypes[dialogType].icon }}</v-icon>
        <div class="align-self-center">
          {{ t(dialogTypes[dialogType].title.translate, dialogTypes[dialogType].title.param) }}
        </div>
      </v-card-title>
      <v-card-text class="card-text px-11">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-row>
            <v-col class="text-justify">
              <v-alert border="top" type="info" elevation="2" class="pt-6 pr-6" variant="text">
                <span
                  class=""
                  v-html="t(dialogTypes[dialogType].info.translate, dialogTypes[dialogType].info.param)"
                ></span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="cardNumber"
                :class="{
                  'register-card-text-background': isRegister,
                }"
                :label="t('cardNumber')"
                :rules="validations.cardNumber"
                variant="underlined"
                :data-cy="cy('card-number')"
                @update:model-value="closeLastToast()"
              />
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
              <v-text-field
                v-model="applicationNumber"
                :label="t('applicationNumber')"
                :rules="validations.applicationNumber"
                variant="underlined"
                :data-cy="cy('application-number')"
                @update:model-value="closeLastToast()"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn
          class="mr-2"
          color="buttonWarning"
          variant="elevated"
          :data-cy="cy('cancel-button')"
          @click="closeDialog"
        >
          <template #prepend>
            <v-icon>mdi-cancel</v-icon>
          </template>
          {{ $t('buttons.close') }}
        </v-btn>
        <ConfirmButton :confirm="confirmDialog" :data-cy="cy('confirm-button')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Ref } from 'vue-facing-decorator';
import ComponentBase from '@/components/common/ComponentBase';
import { FormInterface } from '@/components/common/FormInterface';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { definedLengthValidator } from '@/utils/validators';
import customerService from '@/services/api/customerService';
import CustomerModule from '@/store/modules/customer';
import UserAccountModule from '@/store/modules/userAccount';
import { CustomerDto } from '@/api';
import { ConnectAccountTypeDialog, ConnectAccountTypesDialog } from '@/models/ConectAccountTypeDialog';
import { toApiMapCardNumber } from '@/utils/customerCard';
import { MIN_CUSTOMER_AGE } from '@/config/config';
import ToastModule from '@/store/modules/toast';
import { getApiError, getNumberedApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({
  components: { ConfirmButton },
})
export default class ConnectAccountDialog extends ComponentBase {
  @Emit('refresh-account-page')
  refreshAccountPage() {
    return;
  }

  @Emit('refresh-assigned-card-list-page')
  refreshAssignedCardListPage() {
    return;
  }

  @Ref()
  readonly form!: FormInterface;

  accountModule = UserAccountModule;
  dialogType = ConnectAccountTypeDialog.REGISTER;
  dialogTypes = ConnectAccountTypesDialog;
  show = false;
  cardNumber = '';
  applicationNumber = '';
  validations: Record<string, any> = {};

  dataCy = 'connect-account-dialog';
  i18nGroupKey = 'connectAccountDialog';

  async openDialog(type: ConnectAccountTypeDialog) {
    this.dialogType = type;
    if (this.isRegister) {
      this.validations = {
        cardNumber: [(v: string) => definedLengthValidator(v, 18)],
      };
    } else if (this.isAssign) {
      this.validations = {
        cardNumber: [(v: string) => definedLengthValidator(v, 18)],
        applicationNumber: [(v: string) => definedLengthValidator(v, 10)],
      };
    }
    this.show = true;
  }

  closeDialog() {
    this.show = false;
    this.resetForm();
  }

  resetForm() {
    this.cardNumber = '';
    this.applicationNumber = '';
    this.form.resetValidation();
  }

  get isRegister() {
    return this.dialogType === ConnectAccountTypeDialog.REGISTER;
  }

  get isAssign() {
    return this.dialogType === ConnectAccountTypeDialog.ASSIGN;
  }

  async confirmDialog() {
    if ((await this.form.validate()).valid) {
      const payload = {
        cardNumber: toApiMapCardNumber(this.cardNumber),
        applicationNumber: this.applicationNumber.replace(/\s/g, ''),
      };
      try {
        let customer: CustomerDto | null = null;
        if (this.isRegister) {
          customer = await customerService.connectRegisteredAccount(payload);
          this.onAccountSuccessfullyRegistered(customer!);
          ToastModule.success({
            message: this.$t(`${this.i18nGroupKey}.registerSaveSuccess`),
          });
        }
        if (this.isAssign) {
          customer = await customerService.connectAssignedAccount(payload);
          this.refreshAssignedCardListPage();
          ToastModule.success({
            message: this.$t(`${this.i18nGroupKey}.assignSaveSuccess`),
          });
        }
        this.closeDialog();

        if (!CustomerModule.currentlySelectedCustomerId) {
          await this.setRegisteredCustomer(customer?.parentId);
        }
      } catch (e) {
        ToastModule.error({
          message: getNumberedApiError(e as ApiErrorInterface, this.i18nGroupKey, 'saveFailed', {
            minCustomerAge: MIN_CUSTOMER_AGE,
          }),
        });
      }
    }
  }

  onAccountSuccessfullyRegistered(customer: CustomerDto) {
    CustomerModule.setCustomer(customer);
    UserAccountModule.setCardApplicationActive(true);
    this.refreshAccountPage();
  }

  async setRegisteredCustomer(customerId?: string) {
    if (!customerId) {
      return;
    }

    try {
      const customerData = await customerService.getCustomer(customerId);
      CustomerModule.setCustomer(customerData);
      this.accountModule.setSelectedCustomerData(customerData);
    } catch (e) {
      ToastModule.error({
        message: getApiError(e as ApiErrorInterface, this.i18nGroupKey, 'parentRefreshFailed'),
      });
    }
  }

  closeLastToast() {
    ToastModule.clearQueue();
  }
}
</script>
<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
