/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OldPendingEshopOrdersQuery = {
  /**
   * Payment state
   */
  state: OldPendingEshopOrdersQuery.state;
  /**
   * Filter by issued older than. Type: 'DATE'
   */
  issuedDate: string;
  /**
   * Ticket valid from date. Type: 'DATE'
   */
  ticketValidFrom: string;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace OldPendingEshopOrdersQuery {
  /**
   * Payment state
   */
  export enum state {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    PROCESSING = 'PROCESSING',
  }
}
