<template>
  <span>
    <v-row>
      <v-col class="text-right pb-0 pt-1 pl-0 label">
        {{ ts('wallet') }}
      </v-col>
      <v-col class="text-left pb-0 pt-1 pl-0 font-weight-bold" :data-cy="cy('wallet')">
        {{ toPrice(customerCardData.electronicWallet!.currentAmount.toString()) }}
        {{ $t('common.currency') }}
      </v-col>
    </v-row>
  </span>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator';
import RDPCustomerDetailAbstract from '@/components/common/RDPCustomerDetail/RDPCustomerDetailAbstract';
import { toPrice } from '@/filters';

@Component({
  name: 'EWalletInfo',
})
export default class EWalletInfo extends RDPCustomerDetailAbstract {
  toPrice(value: string) {
    return toPrice(value);
  }
}
</script>
