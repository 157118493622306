import { HAULIER_NAME } from '@/config/config';
import { HAULIERS } from '@/constants/Constants';

const logoExtension = {
  [HAULIERS.DPMP]: 'svg',
  [HAULIERS.NITRA]: 'svg',
  [HAULIERS.TRENCIN]: 'svg',
  [HAULIERS.RUZOMBEROK]: 'svg',
  [HAULIERS.DPB]: 'svg',
  [HAULIERS.POLAND]: 'svg',
  [HAULIERS.VISEUDESUS]: 'png',
  [HAULIERS.DPMO]: 'svg',
  [HAULIERS.DPMLB]: 'svg',
};

const getLogoAlt = () => {
  let logoAlt = 'Default logo';
  switch (HAULIER_NAME) {
    case HAULIERS.DPMP: {
      logoAlt = 'DPMP logo';
      break;
    }
    case HAULIERS.NITRA: {
      logoAlt = 'Nitra logo';
      break;
    }
    case HAULIERS.TRENCIN: {
      logoAlt = 'Trečín logo';
      break;
    }
    case HAULIERS.RUZOMBEROK: {
      logoAlt = 'Ružomberok logo';
      break;
    }
    case HAULIERS.DPB: {
      logoAlt = 'DPB logo';
      break;
    }
    case HAULIERS.POLAND: {
      logoAlt = 'Poland logo';
      break;
    }
    case HAULIERS.VISEUDESUS: {
      logoAlt = 'Viseu de Sus logo';
      break;
    }
    case HAULIERS.DPMO: {
      logoAlt = 'DPMO logo';
      break;
    }
    case HAULIERS.DPMLB: {
      logoAlt = 'DPMLB logo';
      break;
    }
  }
  return logoAlt;
};

const getLogoHeight = () => {
  let height = 120;
  switch (HAULIER_NAME) {
    case HAULIERS.VISEUDESUS:
      height = 200;
      break;
    case HAULIERS.DPMLB:
      height = 200;
      break;
  }
  return height;
};

export const LOGO_ALT = getLogoAlt();
export const LOGO_HEIGHT = getLogoHeight();
export const LOGO_PATH = new URL(`../assets/${HAULIER_NAME}/logo.${logoExtension[HAULIER_NAME]}`, import.meta.url).href;

export const HOMEPAGE_LOGO_PATH = new URL(
  `../assets/${HAULIER_NAME}/logo_homepage.${logoExtension[HAULIER_NAME]}`,
  import.meta.url,
).href;
