import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { BranchOfficeDto } from '@/api';

@Module({ dynamic: true, store, namespaced: true, name: 'branchOffice' })
class BranchOffice extends VuexModule {
  public branchOfficeList: BranchOfficeDto[] = [];

  @Mutation
  public setBranchOfficeList(payload: BranchOfficeDto[]): void {
    this.branchOfficeList = payload;
  }
}

export default getModule(BranchOffice);
