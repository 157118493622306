/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEWalletTransactionForEshopCommand } from '../models/AddEWalletTransactionForEshopCommand';
import type { AddTicketFareCommand } from '../models/AddTicketFareCommand';
import type { ApiResponse } from '../models/ApiResponse';
import type { ChangeFeeCommand } from '../models/ChangeFeeCommand';
import type { ShoppingCartItemResponse } from '../models/ShoppingCartItemResponse';
import type { ShoppingCartResponse } from '../models/ShoppingCartResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EshopShoppingCartService {
  /**
   * Get shopping cart based on logged account.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
   * @returns ShoppingCartResponse
   * @throws ApiError
   */
  public static eshopShoppingCartControllerGetShoppingCart(): CancelablePromise<ShoppingCartResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/shopping-cart',
      errors: {
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Add a fee to shopping cart.
   * Only available fee in eshop is fee for card application<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_FEE** - Fee does not exist, is disabled or cannot be disabled.<br>
   * + **INVALID_CARD_APPLICATION** - Card application is invalid or does not exist.<br>
   * + **CARD_APPLICATION_ALREADY_HAS_ORDER** - Card application already has order.
   * @returns ShoppingCartItemResponse
   * @throws ApiError
   */
  public static eshopShoppingCartControllerAddFeeToShoppingCart({
    requestBody,
  }: {
    /**
     * ChangeFeeCommand
     */
    requestBody?: ChangeFeeCommand;
  }): CancelablePromise<ShoppingCartItemResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/shopping-cart-items/fee',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Add an electronic wallet transaction to shopping cart.
   * Operation `RECHARGE` is not permitted when card is invalid.,Only available operation Type is RECHARGE.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_ELECTRONIC_WALLET** - Electronic wallet does not exist.<br>
   * + **INVALID_ELECTRONIC_WALLET_OPERATION** - Electronic wallet operation does not exist or is not allowed.<br>
   * + **INVALID_E_WALLET_MIN_MAX_RECHARGE_VALUE** - Invalid min or max e-wallet recharge value.<br>
   * + **INVALID_E_WALLET_MAX_AMOUNT** - Invalid e-wallet max amount.<br>
   * + **INVALID_CUSTOMER_CARD** - Customer card does not exist or has invalid card state.<br>
   * + **VIRTUAL_CARD_EWALLET_NOT_ALLOWED** - Virtual card e-wallet is not allowed in system configuration.
   * @returns ShoppingCartItemResponse
   * @throws ApiError
   */
  public static eshopShoppingCartControllerAddElectronicWalletTransactionToShoppingCart({
    requestBody,
  }: {
    /**
     * AddEWalletTransactionForEshopCommand
     */
    requestBody?: AddEWalletTransactionForEshopCommand;
  }): CancelablePromise<ShoppingCartItemResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/shopping-cart-items/e-wallet-transaction',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Add a ticket fare to shopping cart.
   * Business error `InvalidDate` is returned when property `validFrom` is in past.,Business error `TicketFareValidToMoreThanCardValidTo` is carrying card and ticket validTo in payload.,Business error `TicketFareValidToMoreThanProfileValidTo` is carrying profile and ticket validTo in payload.<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_CUSTOMER_CARD** - Customer card does not exist or has invalid card state.<br>
   * + **INVALID_ZONE** - Zone does not exist or is missing in the given ticket combination.<br>
   * + **INVALID_TICKET_COMBINATION** - Ticket combination does not exist, is expired or isn´t enable for sell.<br>
   * + **INVALID_DATE** - Date is invalid. See extra information in swagger documentation.<br>
   * + **TICKET_FARE_VALID_TO_MORE_THAN_CARD_VALID_TO** - Ticket fare validTo is more than customer card validTo.<br>
   * + **TICKET_FARE_VALIDITY_NOT_IN_PROFILE_VALIDITY** - Ticket fare validity is not within customer profile validity.<br>
   * + **INVALID_CUSTOMER_PROFILE_TYPE** - Customer profile type does not exist or for the given ticket combination does not match with card.<br>
   * + **INVALID_PRICE_LIST_ZONE** - Price list zone does not exist.<br>
   * + **INVALID_TICKET_FARE_VALID_FROM** - Ticket valid from must be in must be in interval defined by global setting.<br>
   * + **TICKET_ALREADY_IN_CART_FOR_CARD** - Ticket already in shopping cart for card.
   * @returns ShoppingCartItemResponse
   * @throws ApiError
   */
  public static eshopShoppingCartControllerAddTicketFareToShoppingCart({
    requestBody,
  }: {
    /**
     * AddTicketFareCommand
     */
    requestBody?: AddTicketFareCommand;
  }): CancelablePromise<ShoppingCartItemResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/eshop/shopping-cart-items/ticket-fare',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Remove all items from shopping cart.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopShoppingCartControllerRemoveAllItemsFromShoppingCart(): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/eshop/shopping-cart-items',
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Remove item from shopping cart.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static eshopShoppingCartControllerRemoveItemFromShoppingCart({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/eshop/shopping-cart-items/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
