<template>
  <v-tooltip :location="location">
    <template #activator="{ props }">
      <v-btn
        :class="buttonClass"
        data-cy="logout"
        v-bind="props"
        :icon="icon"
        theme="dark"
        size="small"
        :color="icon ? '' : 'buttonDefault'"
        @click="logout"
      >
        <span v-if="showText" class="pr-2">
          {{ $t('auth.logout') }}
        </span>
        <v-icon color="lighten-1" icon="$logoutIcon" size="x-large" />
      </v-btn>
    </template>
    <span>{{ $t('auth.logout') }}</span>
  </v-tooltip>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import AuthModule from '@/store/modules/auth';
import UserAccountModule from '@/store/modules/userAccount';

@Component
export default class LogoutButton extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  readonly icon!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly tooltipBottom!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly tooltipRight!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly showText!: boolean;

  async logout() {
    AuthModule.logout();
    UserAccountModule.clearCardData();
    UserAccountModule.setCardApplicationActive(false);
    UserAccountModule.setVirtualCardApplicationCanBeCreated(true);
  }

  get buttonClass() {
    return this.$vuetify.display.mobile ? 'logout-btn' : 'logout-btn ml-4';
  }

  get location() {
    if (this.tooltipBottom) {
      return 'bottom';
    } else if (this.tooltipRight) {
      return 'right';
    }

    return undefined;
  }
}
</script>
<style lang="scss" scoped></style>
