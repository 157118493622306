/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TicketStateVm = {
  /**
   * Ticket state
   */
  code: TicketStateVm.code;
  /**
   * Ticket display name
   */
  displayName: string;
};
export namespace TicketStateVm {
  /**
   * Ticket state
   */
  export enum code {
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
  }
}
