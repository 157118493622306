<template>
  <div>
    <input
      ref="input"
      type="file"
      hidden
      name="image"
      accept="image/*"
      :data-cy="`${dataCy}-cropper`"
      @change="setImage"
    />

    <div class="crop-content" :class="{ 'disable-events': disabled }">
      <section class="cropper-area">
        <v-btn
          :class="uploadButtonClass"
          style="cursor: pointer"
          color="buttonPrimary"
          theme="dark"
          @click.prevent="showFileChooser"
        >
          <v-progress-circular v-if="uploading" :color="progressColor" indeterminate size="68" />
          <template v-if="!cropperActive && resultImg === '' && !uploading">
            <span class="flex flex-column">
              <p class="upload-from-computer-text">
                {{ $t('rdpCropper.uploadFromComputer') }}
              </p>
              <v-icon size="x-large">mdi-camera</v-icon>
            </span>
          </template>
          <img v-if="!cropperActive && resultImg !== '' && !uploading" :src="resultImg" alt="result-image" />
        </v-btn>
      </section>
    </div>
    <v-overlay :model-value="cropperActive" contained persistent class="cropper-overlay" @keydown.esc="closeCrop">
      <div v-if="!smallerDeviceForCropper" class="rdp-cropper">
        <vue-cropper
          v-if="cropperActive"
          ref="cropper"
          :src="cropImg"
          :container-style="{ height: '500px' }"
          :aspect-ratio="aspectRatio"
        />
      </div>

      <div class="action-buttons">
        <div class="control-buttons">
          <v-btn
            class="action-button"
            icon
            size="x-small"
            color="buttonConfirm"
            theme="dark"
            :title="$t('rdpCropper.rotateLeft')"
            @click.prevent="rotate(-90)"
          >
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>

          <v-btn
            class="action-button"
            icon
            size="x-small"
            color="buttonConfirm"
            theme="dark"
            :title="$t('rdpCropper.rotateRight')"
            @click.prevent="rotate(90)"
          >
            <v-icon>mdi-rotate-right</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn
            class="action-button"
            icon
            size="x-small"
            :title="$t('rdpCropper.cancel')"
            color="buttonWarning"
            theme="dark"
            :data-cy="`${dataCy}-cancel-button`"
            @click.prevent="closeCrop"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            class="action-button"
            icon
            size="x-small"
            :title="$t('rdpCropper.crop')"
            color="buttonPrimary"
            theme="dark"
            :data-cy="`${dataCy}-crop-button`"
            @click.prevent="cropImage"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="smallerDeviceForCropper" class="rdp-cropper">
        <vue-cropper
          v-if="cropperActive"
          ref="cropper"
          :src="cropImg"
          :container-style="{ height: '550px' }"
          :aspect-ratio="aspectRatio"
        />
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Watch, Vue } from 'vue-facing-decorator';
import { VueCropperMethods } from 'vue-cropperjs';
import { RDPCropperInterface } from '@/components/common/RDPCropper/RDPCropperInterface';
import { RDP_PHOTO_RESOLUTION_HEIGHT, RDP_PHOTO_RESOLUTION_WIDTH } from '@/constants/Image';
import ToastModule from '@/store/modules/toast';
import { nextTick } from 'vue';
const MAX_FILE_SIZE = 5242880; // 5Mb

/**
 * This is sent through API
 */
const MAX_PHOTO_SIZE_IN_BYTES = 122880; // 120kB
const MIN_IMAGE_CONVERSION_QUALITY = 0.01;
const CROPPER_HEIGHT_BREAKPOINT = 1080;
const CROPPER_WIDTH_BREAKPOINT = 1920;

@Component
export default class RDPCropper extends Vue implements RDPCropperInterface {
  @Prop({
    required: true,
    type: Function,
    default: () => {
      console.info('Crop end not implemented');
    },
  })
  readonly cropEnd!: (resultImg: string) => void;

  @Prop({
    required: false,
    type: Function,
    default: () => undefined,
  })
  readonly cropClosed!: () => void;

  @Prop({ type: String, required: false, default: '' })
  readonly sourceImage!: string;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String, required: false, default: '' })
  readonly dataCy!: string;

  @Prop({ type: Number, required: false, default: NaN })
  readonly aspectRatio!: number;

  @Prop({ required: false, type: String, default: 'blue-grey darken-4' })
  readonly progressColor!: string;

  @Prop({ type: Number, required: false, default: MAX_FILE_SIZE })
  readonly maxFileSize!: number;

  @Prop({
    required: false,
    type: Function,
    default: () => {
      console.info('Crop opened not implemented');
    },
  })
  readonly cropOpened!: () => void;

  @Ref()
  cropper!: VueCropperMethods;
  @Ref()
  input!: HTMLElement;

  resultImg = '';
  cropImg = '';
  cropperActive = false;
  uploading = false;

  created() {
    this.resultImg = this.sourceImage;
  }

  get smallerDeviceForCropper() {
    return (
      this.$vuetify.display.mobile ||
      this.$vuetify.display.height < CROPPER_HEIGHT_BREAKPOINT ||
      this.$vuetify.display.width < CROPPER_WIDTH_BREAKPOINT
    );
  }

  setImage(event: Event) {
    this.uploading = true;
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const file: File | undefined = target?.files?.[0];
    if (file) {
      if (file.type.indexOf('image/') === -1) {
        ToastModule.error({ message: this.$t('rdpCropper.errorImageType') });
        this.uploading = false;
        return;
      }

      if (file.size > this.maxFileSize) {
        ToastModule.error({
          message: this.$t('rdpCropper.maxFilesize', {
            // bytes to MB
            size: Math.floor(this.maxFileSize / 1000000),
          }),
        });
        this.uploading = false;
        // clear the input, otherwise the method setImage() is not called the second time a user tries to upload the same file
        this.clearInput();
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          const target: FileReader = event.target as FileReader;
          const result = target?.result as string;
          this.cropImg = result;
          this.uploading = false;
          if (this.cropImg && this.cropImg !== '') {
            this.cropperActive = true;
            nextTick(() => {
              this.cropper.initCrop();
              this.cropper.replace(result);
              this.cropOpened();
            });
          }
        };
        reader.readAsDataURL(file);
      } else {
        ToastModule.error({ message: this.$t('rdpCropper.uploadFailed') });
        this.uploading = false;
      }
    } else {
      ToastModule.error({ message: this.$t('rdpCropper.uploadFailed') });
      this.uploading = false;
    }
  }

  get uploadButtonClass() {
    return `img-placeholder ${this.resultImg === '' ? 'rdp-button' : ''}`;
  }

  cropImage() {
    this.resultImg = this.compressImage();

    this.cropperActive = false;
    this.cropEnd(this.resultImg);
    this.clearInput();
  }

  /**
   * Gets the cropper image and compresses it below 120 kB
   */
  compressImage() {
    const outputType = 'image/jpeg'; // WARNING: do not set type: 'image/png' here because the conversion does not work for png (the input image can be png though)
    let canvas: HTMLCanvasElement = this.cropper.getCroppedCanvas();
    let result = '';

    // 1. first decrease the resolution, if needed
    const width = RDP_PHOTO_RESOLUTION_WIDTH;
    const height = RDP_PHOTO_RESOLUTION_HEIGHT;
    if (this.cropper.getCroppedCanvas().width > width) {
      const tempCanvas = document.createElement('canvas') as HTMLCanvasElement;
      tempCanvas.setAttribute('width', width.toFixed(2));
      tempCanvas.setAttribute('height', height.toFixed(2));
      tempCanvas.getContext('2d')?.drawImage(this.cropper.getCroppedCanvas(), 0, 0, width, height);

      // replace the cropper canvas with the newly created tempCanvas
      canvas = tempCanvas;
    }

    result = canvas.toDataURL(outputType);

    // 2. second decrease the quality
    //  - warning: this does not lower the quality in Firefox as much as in Chrome (so Firefox might not get below MAX_PHOTO_SIZE_IN_BYTES but it's very unlikely)
    let quality = 0.9;
    while (result.length > MAX_PHOTO_SIZE_IN_BYTES && quality >= MIN_IMAGE_CONVERSION_QUALITY) {
      result = canvas.toDataURL(outputType, quality);
      quality *= 0.9; // lower quality to 90% and then try to convert again
    }

    /**
     * This means we got to a point where quality is too low and the image is still too big.
     */
    if (quality < MIN_IMAGE_CONVERSION_QUALITY) {
      result = '';
      ToastModule.error({
        message: this.$t('cardApplication.errors.INVALID_PHOTO_SIZE'),
      });
    }

    return result;
  }

  showFileChooser() {
    if (!this.cropperActive && !this.disabled) {
      this.input.click();
    }
  }

  closeCrop() {
    this.cropperActive = false;
    this.clearInput();
    this.cropClosed();
  }

  isCropperActive(): boolean {
    return this.cropperActive;
  }

  clearInput() {
    (this.input as HTMLInputElement).value = '';
  }

  rotate(degree: number) {
    if (this.cropper) {
      this.cropper.rotate(degree);
    }
  }

  clearImage() {
    this.resultImg = '';
  }

  @Watch('sourceImage')
  sourceImgChanged() {
    this.resultImg = this.sourceImage;
  }
}
</script>

<style lang="scss">
.crop-content {
  display: flex;
}

.img-placeholder {
  max-width: 150px;
  width: 150px;
  min-height: 150px;
  background: #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 150px;
  }
}

.control-buttons {
  padding-right: 50px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .action-button {
    margin-right: 10px;
  }
}

.upload-buttons {
  text-align: center;
}

.rdp-cropper {
  display: flex;
  justify-content: center;

  .cropper-modal {
    opacity: 0.3;
  }

  .cropper-bg {
    background-image: unset;
  }
}

.upload-from-computer-text {
  width: 138px;
  text-align: center;
  white-space: normal !important;
}
</style>

<style>
.cropper-overlay .v-overlay__scrim {
  opacity: 0.8;
}
.cropper-overlay .v-overlay__content {
  width: 100%;
}
</style>
